<template>
  <div class="interactive-container">
    <ImageComponent
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/images/max/session10/1354.png"
      srcPlaceholder="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/images/min/session10/1354.jpg"
      class="session-background"
    />
    <ImageComponent
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/images/max/session10/superhero-kids.png"
      srcPlaceholder="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/images/min/session10/superhero-kids.jpg"
      class="kids"
    />
    <div class="text-box">
      <p>Pretend you are a Superhero. Make yourself as big as you can
        <br>by stretching your neck, arms, legs, fingers or toes.</p>
      <p>What does a superhero do to help everybody?</p>
    </div>
    <svg id="female" class="superhero" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319 238" width="319" height="238">
      <defs>
        <linearGradient id="grd1" gradientUnits="userSpaceOnUse"  x1="300.955" y1="68.27" x2="333.888" y2="135.451">
          <stop offset="0" stop-color="#c04b00"  />
          <stop offset="0.989" stop-color="#b73400"  />
        </linearGradient>
      </defs>
      <g id="1">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp0-fsuperhero" d="M137.18 46.79C123.09 57.94 103.74 63.62 102.49 50.73C101.24 37.84 20.21 116.81 0.11 39.17C-2.61 28.69 38.54 38.83 43.03 38.4C47.51 37.96 68.72 5.92 100.5 1.14C132.28 -3.64 160.82 28.09 137.18 46.79Z" />
            <path id="&lt;Path&gt;" class="shp1-fsuperhero" d="M139.02 14.49C130.3 23.7 115.88 32.34 98.48 37.85C83.11 42.71 68.25 44.22 56.42 42.77C53.14 52.18 40.84 59.18 26.12 59.18C18.18 59.18 10.96 57.13 5.46 53.79C31.66 105.71 101.33 38.78 102.49 50.73C103.74 63.62 123.09 57.94 137.18 46.79C149.28 37.22 147.7 24.25 139.02 14.49Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M149.55 45.59L140.48 36.7L132.05 45.28L141.13 54.18L149.55 45.59Z" />
            <path id="&lt;Path&gt;" class="shp3-fsuperhero" d="M214.66 179.18C199.98 163.75 187.88 146.3 145.7 161.5C118.53 171.21 86.94 151.19 67.35 160.47C84.22 182.78 91.95 201.42 90.55 216.39C132.03 225.89 193.53 238.84 214.66 179.18Z" />
            <path id="&lt;Path&gt;" class="shp4-fsuperhero" d="M148.54 234.95C125.76 237.76 80.81 231.21 56.64 218.86L56.88 214.15C64.74 221.77 92.55 224.93 140.29 223.61L148.54 234.95Z" />
            <path id="&lt;Path&gt;" class="shp5-fsuperhero" d="M217.79 182.49C226.3 181.53 254.51 198.06 298.46 170.25L302.91 172.84C293.26 191.31 266.1 199.11 221.43 196.25C210.45 195.54 209.23 190.96 217.79 182.49Z" />
            <path id="&lt;Path&gt;" class="shp5-fsuperhero" d="M173.96 235.64C164.46 237.37 150.01 239.16 138.1 232.41C134.08 230.14 131.3 226.18 130.51 221.64L127.66 205.31C136.77 198.04 145.21 192.91 153 189.94L175.34 235.47" />
            <path id="&lt;Path&gt;" class="shp6-fsuperhero" d="M147.47 200.33C150.49 199.04 153.5 197.95 156.48 197.03L153 189.94C145.21 192.92 136.77 198.04 127.66 205.31L128.68 211.14C134.04 207.06 140.39 203.35 147.47 200.33Z" />
            <path id="&lt;Path&gt;" class="shp7-fsuperhero" d="M178.56 232.89C178.56 231.18 177.73 229.8 176.69 229.8C175.66 229.8 174.82 231.18 174.82 232.89C174.82 234.6 175.66 235.98 176.69 235.98C177.73 235.98 178.56 234.6 178.56 232.89Z" />
            <path id="&lt;Path&gt;" class="shp7-fsuperhero" d="M159.62 188.95C159.62 187.24 158.09 185.86 156.2 185.86C154.32 185.86 152.79 187.24 152.79 188.95C152.79 190.66 154.32 192.04 156.2 192.04C158.09 192.04 159.62 190.66 159.62 188.95Z" />
            <path id="&lt;Path&gt;" class="shp4-fsuperhero" d="M175.34 235.47C165.89 237.54 137.2 212.53 153 189.59C174.82 178.26 208.07 164.85 227.78 176.56C232.77 180.52 244.93 218.2 175.34 235.47Z" />
            <path id="&lt;Path&gt;" class="shp8-fsuperhero" d="M151.54 200.34C170.83 187.29 199.1 171.54 221.37 173.75C201.43 167.57 172.61 179.41 153 189.59C148.75 195.76 147.74 202.08 148.68 207.98C149.24 205.48 150.17 202.92 151.54 200.34Z" />
            <path id="&lt;Path&gt;" class="shp5-fsuperhero" d="M204.48 176.41C202.91 172.52 197.3 171.11 191.96 173.27C186.61 175.42 183.55 180.33 185.12 184.22C186.69 188.11 192.29 189.52 197.64 187.36C202.98 185.21 206.05 180.3 204.48 176.41Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M231.27 186.24C232.54 183.38 233.35 172.98 220.96 166.4C208.56 159.82 194.67 158.41 183.84 156.61C185.91 157.75 228.23 193.09 231.27 186.24Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M227.73 182.83L214.59 170.82L214.96 156.93L229.98 158.44L227.73 182.83Z" />
            <path id="&lt;Path&gt;" class="shp5-fsuperhero" d="M193.86 182.93C188.14 179.29 143.36 160.98 131.09 158.32L131.25 152.9C169.42 162.17 192.04 169.02 199.13 173.46C206.21 177.9 204.46 181.06 193.86 182.93Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M194.41 223.36L202.66 209.7L224.05 206.34L210.91 219.23L194.41 223.36Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M197.25 209.34L199.9 204.95L206.78 203.87L202.55 208.01L197.25 209.34Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M225.04 204.03L224.85 198.89L230.02 194.23L228.75 200.02L225.04 204.03Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M183.12 227.62L186.64 223.87L193.59 224.26L188.59 227.43L183.12 227.62Z" />
            <path id="&lt;Path&gt;" class="shp4-fsuperhero" d="M59.25 219.73C49.28 220.93 43.27 220.5 41.21 218.44C48.58 215.37 53.6 213.74 56.27 213.56C58.94 213.38 59.93 215.44 59.25 219.73Z" />
            <path id="&lt;Path&gt;" class="shp4-fsuperhero" d="M129.98 218.2C110.65 219.23 80.24 207.63 50.58 197.83L50.89 193.13C63.59 200.01 93.82 203.12 141.58 202.48C145.18 207.37 140.03 217.39 129.98 218.2Z" />
            <path id="&lt;Path&gt;" class="shp4-fsuperhero" d="M53.87 198.53C43.9 199.73 37.89 199.3 35.82 197.24C43.2 194.17 48.22 192.54 50.89 192.36C53.56 192.18 54.55 194.23 53.87 198.53Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M146.73 190.62C144.67 195 133.33 228.51 168.64 238.05L177.66 235.98C152.4 227.05 144.93 210.38 155.24 185.98L146.73 190.62Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M67.87 182.63C78.69 172.5 96.99 166.4 122.76 164.34C99.91 155.74 81.44 154.46 67.35 160.47C60.48 165.45 60.65 172.84 67.87 182.63Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M53.69 199.9C50.6 196.98 50.94 194.32 54.72 191.91L68.64 195L68.64 206.09L53.69 199.9Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M59.69 223.25C56.42 220.54 56.59 217.86 60.21 215.21L74.3 217.42L75 228.48L59.69 223.25Z" />
            <path id="&lt;Path&gt;" class="shp10-fsuperhero" d="M297.84 175.74C298.2 166.81 301.45 161.85 305.67 162.56C309.9 163.27 317.33 176.51 297.84 175.74Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M302.16 168.65C302.65 166.46 302.27 164.47 301.3 164.2C300.34 163.92 299.18 165.47 298.69 167.66C298.2 169.85 298.58 171.84 299.54 172.12C300.5 172.39 301.67 170.84 302.16 168.65Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M302.32 167.99C303.96 169.12 304.69 169.16 305.31 168.38C305.93 167.61 306.21 166.31 304.57 165.17C302.93 164.03 301.1 163.74 300.48 164.52C299.85 165.3 300.68 166.85 302.32 167.99Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M307.24 164.33C308.25 162.96 308.25 162.3 307.49 161.65C306.73 161 305.51 160.61 304.51 161.98C303.5 163.34 303.3 164.98 304.06 165.62C304.82 166.28 306.24 165.7 307.24 164.33Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M309.08 168.12C310.52 167.23 310.77 166.62 310.3 165.73C309.85 164.85 308.85 164.03 307.42 164.92C305.98 165.82 305.19 167.26 305.64 168.15C306.11 169.04 307.64 169.02 309.08 168.12Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M309.45 172.32C311.12 172.05 311.58 171.58 311.49 170.58C311.41 169.59 310.8 168.45 309.13 168.73C307.46 169.01 306.18 170.04 306.26 171.04C306.35 172.03 307.77 172.61 309.45 172.32Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M133.08 156.78C125.02 159.51 119.48 158.15 118.66 153.93C117.83 149.71 127.04 138.18 133.08 156.78Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M125.29 155.14C123.18 155.44 121.53 156.48 121.62 157.48C121.71 158.47 123.49 159.04 125.61 158.75C127.72 158.46 129.37 157.42 129.28 156.42C129.19 155.43 127.4 154.86 125.29 155.14Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M124.64 155.22C125.09 153.29 124.87 152.59 123.96 152.27C123.06 151.95 121.81 152.14 121.36 154.07C120.91 156 121.29 157.83 122.2 158.15C123.1 158.47 124.2 157.16 124.64 155.22Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M119.69 151.85C118.13 151.37 117.54 151.59 117.23 152.53C116.91 153.46 116.98 154.75 118.55 155.22C120.1 155.7 121.63 155.33 121.94 154.39C122.26 153.46 121.25 152.32 119.69 151.85Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M122.43 148.82C121.14 147.77 120.51 147.75 119.88 148.48C119.25 149.21 118.86 150.43 120.16 151.48C121.46 152.52 123.01 152.78 123.64 152.04C124.27 151.3 123.73 149.86 122.43 148.82Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M126.05 147.03C125.22 145.55 124.64 145.28 123.79 145.7C122.93 146.12 122.13 147.08 122.95 148.55C123.79 150.03 125.14 150.89 126 150.47C126.85 150.05 126.87 148.51 126.05 147.03Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M282.81 172.84L293.89 187.79L301.11 174.13L297.5 169.75L282.81 172.84Z" />
            <path id="&lt;Path&gt;" class="shp2-fsuperhero" d="M138.38 170.15L148.04 154.25L132.67 152.71L129.97 157.71L138.38 170.15Z" />
            <path id="&lt;Path&gt;" class="shp11-fsuperhero" d="M265.36 184.53C251.3 187.69 239.99 186.46 231.69 184.88C232.26 182.2 232.1 177.39 228.66 172.77L229.98 158.44L214.96 156.93L214.78 163.57C204.7 159.59 192.35 158.01 183.59 156.61C185.66 158.09 215.03 213.3 265.36 184.53Z" />
            <path id="&lt;Path&gt;" class="shp12-fsuperhero" d="M147.75 90.04C138.48 103.77 133.78 110.86 133.64 111.31C133.51 111.76 135.02 113.11 138.18 115.35L149.74 93.55L147.75 90.04Z" />
            <path id="&lt;Path&gt;" class="shp12-fsuperhero" d="M146.82 88.73C130.03 89.97 121.44 90.72 121.05 90.97C120.65 91.23 120.94 93.23 121.91 96.98L144.93 96.3L146.82 88.73Z" />
            <path id="&lt;Path&gt;" class="shp13-fsuperhero" d="M143.27 92.56C134.48 97.1 130 99.58 129.81 100.02C129.62 100.45 130.98 104.44 133.87 111.98L148.86 94.87L143.27 92.56Z" />
            <path id="&lt;Path&gt;" class="shp13-fsuperhero" d="M141.66 87.19C132.73 82.95 128.03 80.89 127.57 81C127.12 81.12 124.77 84.61 120.53 91.48L143.21 93.03L141.66 87.19Z" />
            <path id="&lt;Path&gt;" class="shp0-fsuperhero" d="M198.26 154.3C169.74 159.68 140.27 137.98 143.64 108.7C147.02 79.42 120.55 69.85 141.95 48.45C163.35 27.05 190.94 19.73 224.72 22.55C258.5 25.37 277.65 2.84 301.86 39.44C326.07 76.04 317.62 131.79 198.26 154.3Z" />
            <path id="&lt;Path&gt;" class="shp1-fsuperhero" d="M308.98 53.25C301.43 86.78 263.75 112.27 218.38 112.27C180.63 112.27 148.21 94.63 134.02 69.4C136.34 79.93 145.74 90.52 143.64 108.7C140.27 137.98 169.74 159.68 198.26 154.3C303.4 134.47 322.48 88.85 308.98 53.25Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M234.86 171.2C201.88 167.9 154.9 139.67 168.42 95.75C181.93 51.83 241.61 42.82 273.14 46.2C304.68 49.58 331.7 111.51 302.42 148.68C290.43 163.89 268.64 174.57 234.86 171.2Z" />
            <path id="&lt;Path&gt;" class="shp14-fsuperhero" d="M234.86 171.2C201.88 167.9 154.9 139.67 168.42 95.75C181.93 51.83 241.61 42.82 273.14 46.2C304.68 49.58 331.7 111.51 302.42 148.68C290.43 163.89 268.64 174.57 234.86 171.2Z" />
            <path id="&lt;Path&gt;" class="shp4-fsuperhero" d="M318.75 86.18L169.54 87.11L163.91 103.07L315.94 121.09L318.75 86.18Z" />
            <path id="&lt;Path&gt;" class="shp13-fsuperhero" d="M188.48 103C188.48 97.45 189.45 92.07 191.23 86.98L169.54 87.11L163.91 103.07L188.58 105.99C188.52 105 188.48 104 188.48 103Z" />
            <path id="&lt;Path&gt;" class="shp15-fsuperhero" d="M304.59 114.86C301.57 106.95 292.76 83.8 265.77 108.7L304.59 114.86Z" />
            <path id="&lt;Path&gt;" class="shp15-fsuperhero" d="M250.51 107.82C242.86 99.84 220.53 76.49 204.7 103.89L250.51 107.82Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M175.18 106.64C164.29 99.5 158.19 91.07 153.03 99.88C147.02 110.14 152.9 117.89 170.67 123.15L175.18 106.64Z" />
            <path id="&lt;Path&gt;" class="shp16-fsuperhero" d="M209.13 104.24C212.72 97.94 223.17 79.53 251.14 108.08L209.13 104.24Z" />
            <path id="&lt;Path&gt;" class="shp16-fsuperhero" d="M304.59 114.86C301.66 108.43 293.12 89.61 268.17 109.39L304.59 114.86Z" />
            <path id="&lt;Path&gt;" class="shp17-fsuperhero" d="M301.59 109.72C301.95 107.15 300.14 104.79 297.57 104.44C295 104.09 292.62 105.89 292.27 108.46C291.92 111.02 293.72 113.39 296.29 113.74C298.87 114.09 301.24 112.29 301.59 109.72Z" />
            <path id="&lt;Path&gt;" class="shp17-fsuperhero" d="M241.94 102.7C242.34 99.83 240.36 97.18 237.5 96.79C234.64 96.39 232 98.39 231.59 101.25C231.19 104.12 233.18 106.77 236.04 107.16C238.9 107.56 241.54 105.57 241.94 102.7Z" />
            <path id="&lt;Path&gt;" class="shp18-fsuperhero" d="M240.77 101.26C240.93 100.14 240.16 99.12 239.05 98.96C237.94 98.8 236.91 99.59 236.75 100.7C236.59 101.81 237.37 102.84 238.48 103C239.59 103.15 240.62 102.37 240.77 101.26Z" />
            <path id="&lt;Path&gt;" class="shp18-fsuperhero" d="M300.86 108.65C300.99 107.66 300.3 106.74 299.3 106.6C298.3 106.47 297.37 107.17 297.24 108.16C297.1 109.16 297.8 110.08 298.8 110.22C299.8 110.35 300.72 109.65 300.86 108.65Z" />
            <path id="&lt;Path&gt;" class="shp17-fsuperhero" d="M244.5 125.66L275.5 128.77C259.8 150.62 249.46 149.58 244.5 125.66Z" />
            <path id="&lt;Path&gt;" class="shp19-fsuperhero" d="M264.85 136.29L269.55 129.18L246.44 126.75L250.07 135.53L264.85 136.29Z" />
            <path id="&lt;Path&gt;" class="shp10-fsuperhero" d="M269.58 117C269.58 115.15 266.75 113.65 263.27 113.65C259.78 113.65 256.95 115.15 256.95 117C256.95 118.85 259.78 120.35 263.27 120.35C266.75 120.35 269.58 118.85 269.58 117Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M269.75 115.71C269.75 114.19 267.42 112.96 264.55 112.96C261.69 112.96 259.37 114.19 259.37 115.71C259.37 117.23 261.69 118.47 264.55 118.47C267.42 118.47 269.75 117.23 269.75 115.71Z" />
            <path id="&lt;Path&gt;" class="shp10-fsuperhero" d="M169.89 107.95C163.8 103.96 160.38 99.23 157.49 104.17C154.13 109.91 157.42 114.26 167.37 117.2L169.89 107.95Z" />
            <path id="&lt;Path&gt;" class="shp9-fsuperhero" d="M170.26 111.59C167.11 109.52 165.34 107.07 163.84 109.63C162.09 112.61 163.8 114.86 168.96 116.39L170.26 111.59Z" />
            <path id="&lt;Path&gt;" class="shp20-fsuperhero" d="M234.45 118.81C234.45 116.15 229.21 114 222.76 114C216.31 114 211.08 116.15 211.08 118.81C211.08 121.46 216.31 123.62 222.76 123.62C229.21 123.62 234.45 121.46 234.45 118.81Z" />
            <path id="&lt;Path&gt;" class="shp20-fsuperhero" d="M304.5 127.77C304.73 125.12 299.72 122.5 293.29 121.92C286.87 121.34 281.46 123.01 281.22 125.66C280.98 128.3 286 130.92 292.43 131.5C298.85 132.08 304.25 130.41 304.5 127.77Z" />
            <path id="&lt;Path&gt;" class="shp0-fsuperhero" d="M209.7 82.38C216.58 79.29 233.07 74.82 237.54 75.85L237.2 78.6L209.7 82.38Z" />
            <path id="&lt;Path&gt;" class="shp0-fsuperhero" d="M306.42 84.5C300.24 81.72 285.58 78 281.72 79.23L282.16 81.96L306.42 84.5Z" />
          </g>
        </g>
      </g>
    </svg>
    <svg id="male" class="superhero" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229 373" width="229" height="373">
      <defs>
        <linearGradient id="grd1" gradientUnits="userSpaceOnUse"  x1="183.835" y1="141.779" x2="176.521" y2="178.347">
          <stop offset="0" stop-color="#c04b00"  />
          <stop offset="1" stop-color="#b73400"  />
        </linearGradient>
      </defs>
      <g id="1">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp0" d="M119.35 142.72C115.23 147.96 113.32 157.76 113.61 172.13L105.4 166.94C98.73 170.75 95.14 173.15 94.63 174.17C93.86 175.68 90.79 163.94 87.1 164.86C84.64 165.47 79.95 165.88 73.01 166.07C68.96 149.21 65.58 140.7 62.87 140.55C60.17 140.4 78.99 141.12 119.35 142.72Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M154.2 133.17C153 139.72 155.88 149.29 162.85 161.86L153.16 161.1C149.04 167.58 146.99 171.38 147 172.51C147.03 174.21 138.84 165.26 136.01 167.79C134.12 169.48 130.15 172.03 124.1 175.44C114.33 167.1 108.21 163.5 105.75 164.63C103.28 165.76 119.43 155.27 154.2 133.17Z" />
            <path id="&lt;Path&gt;" class="shp0" d="M187.54 117.55C187.54 124.22 192.09 133.11 201.21 144.22L191.54 145.22C188.65 152.33 187.32 156.44 187.54 157.55C187.87 159.22 178.21 151.88 175.87 154.88C174.32 156.88 170.87 160.11 165.54 164.55C154.43 158.11 147.76 155.66 145.54 157.22C143.32 158.77 157.32 145.55 187.54 117.55Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M113.76 153.52L101.63 152.91L100.3 179.7L112.43 180.3L113.76 153.52Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M98.16 349C110.79 342.2 142 346.33 158.26 332C174.53 317.66 221.62 329.22 229.08 341.6C191.05 224.23 149.71 167.55 105.05 171.55C63.76 158.5 61.46 217.65 98.16 349Z" />
            <path id="&lt;Path&gt;" class="shp3" d="M105.05 171.55C97.26 169.09 91.33 170.06 86.9 173.62C88.04 318.55 139.89 289.9 150.46 337.06C153.29 335.68 155.94 334.04 158.26 332C174.53 317.66 221.62 329.22 229.08 341.6C191.05 224.23 149.71 167.55 105.05 171.55Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M96.76 268.41C88.39 246.63 72.88 176.06 82.37 171.88C110.8 166.68 134.76 179.66 139.92 190.07C144.16 198.63 145.92 232.28 147.25 248.09C147.52 251.28 145.3 254.21 141.88 255.18C129.92 258.54 103.19 266.12 96.76 268.41Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M139.92 190.07C137.47 185.13 130.76 179.63 121.38 175.75C128.13 179.34 132.93 183.72 134.92 187.73C139.16 196.3 140.92 229.94 142.25 245.75C142.52 248.95 140.3 251.88 136.88 252.84C126.57 255.75 105.27 261.78 95.49 264.86C95.93 266.14 96.36 267.35 96.76 268.41C103.19 266.12 129.92 258.54 141.88 255.18C145.3 254.21 147.52 251.28 147.25 248.09C145.92 232.28 144.16 198.63 139.92 190.07Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M95.09 262.55C98.86 276.38 93.41 291.04 124.84 341.31L129.86 340.48C110.67 301.55 109.31 280.26 125.79 276.63C142.27 273.01 156.2 290.52 167.57 329.17L173.02 327.07L148.72 250.82L95.09 262.55Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp5" d="M125.79 276.63C126.87 276.4 127.94 276.32 128.99 276.31C126.67 275.7 124.27 275.59 121.79 276.13C105.31 279.76 106.67 301.05 125.86 339.98L124.19 340.26C124.41 340.61 124.61 340.95 124.84 341.31L129.86 340.48C110.67 301.55 109.31 280.26 125.79 276.63Z" />
              <path id="&lt;Path&gt;" class="shp5" d="M148.72 250.82L145.13 251.6L169.02 326.57L167 327.35C167.25 328.11 167.44 328.72 167.57 329.17L173.02 327.07L148.72 250.82Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp6" d="M107.24 319.95L125.26 309.89L141.18 342.99C145.09 363.94 142.43 373.72 133.22 372.32C124 370.92 120.23 361.7 121.9 344.67L107.24 319.95Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M141.18 342.99L125.26 309.89L118.04 313.92C122.08 318.2 126.1 323.9 129.52 330.58C138.16 347.45 139.66 363.96 132.86 367.44C130.83 368.48 128.3 368.22 125.51 366.93C127.22 369.71 129.68 371.79 133.22 372.32C142.43 373.72 145.09 363.94 141.18 342.99Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M169.17 296.82L150.23 304.07L165.98 337.08C179.76 353.32 188.96 357.51 193.59 349.65C198.22 341.8 193.43 333.11 179.21 323.58L169.17 296.82Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M179.21 323.58L169.17 296.82L160.07 300.31C163.62 305.55 167.25 312.56 170.35 320.54C174.37 330.88 176.64 340.64 176.95 347.61C183.04 352.32 190.07 355.62 193.59 349.65C198.22 341.8 193.43 333.11 179.21 323.58Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M149.79 257.13L96.54 267.38L96.54 274.13L149.79 263.88L149.79 257.13Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M87.71 248.72L94.71 272.55L152.71 257.22L147.71 234.88L87.71 248.72Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M138.37 243.17C139.9 249.34 140.1 255.38 139.23 260.78L152.71 257.22L147.71 234.88L136.56 237.45C137.26 239.28 137.88 241.18 138.37 243.17Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M94.21 254.1C93.66 251.87 93.28 249.66 93.05 247.49L87.71 248.72L94.71 272.55L102.06 270.61C98.58 266.15 95.79 260.52 94.21 254.1Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M123.71 177.55C129.56 174.43 189.84 221.92 198.05 227.9L196.02 231.36C151.27 205.98 127.18 193.7 123.75 194.54C119.82 185.29 119.8 179.63 123.71 177.55Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M135.41 183.56C136.34 180.1 128.75 173.04 111.17 172.06C96.58 171.26 84.78 166.17 83.84 169.63C82.91 173.1 93.69 179.03 107.93 182.87C122.17 186.72 134.47 187.03 135.41 183.56Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M194.72 225.4L193.64 227.23C171.13 214.46 152.72 204.52 138.41 197.4L138.04 201.01C152.2 207.94 171.53 218.05 196.02 231.36L198.05 227.9C197.33 227.37 196.18 226.51 194.72 225.4Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M171.31 199.43L163.09 219.65L182.17 236.51C179.79 236.63 182.15 244.92 187.79 248.88C196.57 252.68 214.14 236.41 203.36 226.72C196.17 220.26 185.49 211.16 171.31 199.43Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M191.36 241.1C189.12 240.24 186.79 240.58 186.16 241.84C185.52 243.11 186.82 244.83 189.06 245.69C191.3 246.54 193.63 246.21 194.27 244.94C194.9 243.67 193.6 241.95 191.36 241.1Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M203.36 226.72C201 224.6 198.14 222.1 195.12 219.49C195.39 221.61 195.54 223.78 195.54 226.01C195.54 235.03 193.21 243.21 189.43 249.35C198.55 250.78 213.5 235.84 203.36 226.72Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M125.76 253.96C125.98 249.69 122.69 246.06 118.42 245.85C114.15 245.63 110.52 248.92 110.3 253.19C110.09 257.46 113.37 261.1 117.64 261.31C121.91 261.52 125.55 258.23 125.76 253.96Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M134.68 180.69C135.6 177.22 128.02 170.63 110.52 170.54C96 170.47 84.24 166.04 83.32 169.51C82.4 172.98 93.15 178.29 107.34 181.38C121.52 184.47 133.76 184.16 134.68 180.69Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M81.46 192.25C47.86 152.09 28.96 129.47 24.76 124.38L27.27 121.4C63.01 155.88 82.51 173.07 85.78 172.97L81.46 192.25Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M123.5 253.85C123.65 250.83 121.33 248.26 118.31 248.11C115.29 247.96 112.72 250.29 112.57 253.3C112.42 256.32 114.74 258.89 117.76 259.04C120.78 259.2 123.35 256.87 123.5 253.85Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M120.45 249.92L113.95 251.43L114.63 254.35L121.13 252.84L120.45 249.92Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M119.62 258.25L118.3 252.33L117.34 252.55L118.66 258.47L119.62 258.25Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M113.54 193.88C108.95 193.88 104.74 195.48 101.41 198.14C104.01 197.01 106.94 196.36 110.05 196.36C120.83 196.36 129.57 203.98 129.57 213.38C129.57 222.79 120.83 230.41 110.05 230.41C106.94 230.41 104.01 229.76 101.41 228.63C104.74 231.29 108.95 232.88 113.54 232.88C124.31 232.88 133.04 224.15 133.04 213.38C133.04 202.62 124.31 193.88 113.54 193.88Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M109.49 227.08C112.63 227.08 115.5 225.99 117.78 224.18C116 224.95 114 225.39 111.87 225.39C104.51 225.39 98.54 220.19 98.54 213.76C98.54 207.33 104.51 202.12 111.87 202.12C114 202.12 116 202.57 117.78 203.34C115.5 201.53 112.63 200.44 109.49 200.44C102.13 200.44 96.16 206.4 96.16 213.76C96.16 221.12 102.13 227.08 109.49 227.08Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M120.29 212.88C120.29 209.36 117.44 206.51 113.91 206.51C110.39 206.51 107.54 209.36 107.54 212.88C107.54 216.41 110.39 219.26 113.91 219.26C117.44 219.26 120.29 216.41 120.29 212.88Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M179.67 206.38L172.39 198.33L158.57 220.62L168.17 223.88L179.67 206.38Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M190.98 244.58C188.62 244.18 186.4 244.95 186.02 246.32C185.64 247.69 187.25 249.13 189.61 249.54C191.96 249.94 194.19 249.16 194.57 247.8C194.95 246.43 193.34 244.99 190.98 244.58Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M189.82 239.13C187.96 234.22 184.7 230.74 182.56 231.35C180.4 231.95 180.17 236.42 182.04 241.33C183.9 246.24 187.15 249.72 189.3 249.12C191.45 248.51 191.68 244.04 189.82 239.13Z" />
            <path id="&lt;Path&gt;" class="shp10" d="M114.47 187.15C126.61 189.37 138 190.37 147.32 190.23C143.21 186.37 139.01 182.98 134.74 180.19C134.86 176.8 127.94 171.23 112.91 170.61L113.53 158.1C109.36 157.46 105.31 156.97 101.44 156.63L100.78 169.92C91.05 168.81 84.02 166.86 83.32 169.51C83.14 170.16 83.39 170.89 84 171.65C83.75 171.69 83.5 171.71 83.25 171.76C79.99 169.62 74.28 164.83 67.71 159.03C68.37 167.22 76.86 180.26 114.47 187.15Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M105.21 19.38C111.14 5.21 131.11 -5.3 142.34 4.72C143.18 19.57 144.84 28.94 147.34 32.84C149.84 36.73 135.79 32.25 105.21 19.38Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M96.37 161.55C74.06 157.22 8.75 128.35 46.81 51.14C59.7 24.99 94.53 3.84 144.79 20.38C174.6 30.19 206.25 60.11 188.05 126.29C177.71 163.88 123.79 166.88 96.37 161.55Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M34.61 80.87C34.06 87.99 34.37 95.43 35.65 103.12C44.21 79.68 145.71 77.28 187.96 131.29C190.26 127.33 192.47 122.23 194.57 115.98C150.9 66.82 61.7 63.46 34.61 80.87Z" />
            <path id="&lt;Path&gt;" class="shp12" d="M96.37 161.55C74.06 157.22 8.75 128.35 46.81 51.14C59.7 24.99 94.53 3.84 144.79 20.38C174.6 30.19 206.25 60.11 188.05 126.29C177.71 163.88 123.79 166.88 96.37 161.55Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M35.71 100.88C44.29 77.45 146.54 75.22 189.2 129.32C216.54 82.7 206.36 46.36 158.65 20.31C90.22 -17.06 24.04 32.22 35.71 100.88Z" />
            <path id="&lt;Path&gt;" class="shp13" d="M189.2 129.32C207.62 97.89 206.93 69.35 187.12 43.68C169.34 44.21 150.7 64.57 138.71 94.84C158.15 101.88 176.37 113.05 189.2 129.32Z" />
            <path id="&lt;Path&gt;" class="shp14" d="M185.11 41.19C178.78 33.71 170.19 26.61 158.65 20.31C91.21 -16.52 25.96 30.81 35.25 97.92C60.38 47.16 119.98 19.67 185.11 41.19Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M184.63 115.64C199.13 110.57 204.02 118.61 202.1 127.45C200.18 136.3 179.94 150.56 169.46 140.54C158.13 129.7 171.29 120.31 184.63 115.64Z" />
            <path id="&lt;Path&gt;" class="shp15" d="M70.78 95.37L109.97 96.31C97.06 127.41 83.99 127.1 70.78 95.37Z" />
            <path id="&lt;Path&gt;" class="shp16" d="M72.97 96.34L78.1 104.5L100.65 107.39L106.73 96.79L72.97 96.34Z" />
            <path id="&lt;Path&gt;" class="shp16" d="M52.08 81.91C48.57 80.41 52.46 62.16 57.71 58.88C71.04 50.55 83.96 57.88 83.69 70.88C83.62 74.21 61.55 85.96 52.08 81.91Z" />
            <path id="&lt;Path&gt;" class="shp16" d="M129.94 83.26C133.81 81.83 129.25 62.99 123.29 60.05C107.37 52.22 94.71 60.22 96.32 71.53C96.79 74.83 119.47 87.12 129.94 83.26Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M35.23 150.72L47.16 129.95L30.1 111.62C33.5 102.88 32.13 96.77 26.01 93.3C16.82 88.09 -6.99 105.31 3.58 117.15C10.63 125.04 21.18 136.24 35.23 150.72Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M30.1 111.62C33.5 102.88 32.13 96.77 26.01 93.3C25.57 93.05 25.1 92.87 24.6 92.72C21.51 95.85 18.28 101.44 16.04 103.38C12.29 106.63 15.79 115.13 15.54 118.63C15.33 121.52 27.33 135.58 37.44 146.88L47.16 129.95L30.1 111.62Z" />
            <path id="&lt;Path&gt;" class="shp17" d="M95.5 86.12C96.05 83.84 93.29 81.21 89.33 80.25C85.37 79.3 81.72 80.37 81.16 82.65C80.61 84.93 83.37 87.56 87.33 88.52C91.29 89.47 94.95 88.4 95.5 86.12Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M92.55 84.15C92.96 82.49 90.95 80.58 88.06 79.88C85.18 79.18 82.51 79.96 82.11 81.62C81.71 83.28 83.72 85.2 86.6 85.9C89.49 86.59 92.15 85.81 92.55 84.15Z" />
            <path id="&lt;Path&gt;" class="shp18" d="M73.91 65.94C74.4 61.82 70.98 58.49 66.29 58.49C61.6 58.49 57.4 61.82 56.91 65.94C56.43 70.05 59.85 73.38 64.54 73.38C69.23 73.38 73.43 70.05 73.91 65.94Z" />
            <path id="&lt;Path&gt;" class="shp19" d="M68.4 62.13C68.58 60.63 67.33 59.41 65.61 59.41C63.89 59.41 62.36 60.63 62.18 62.13C62 63.64 63.25 64.86 64.97 64.86C66.69 64.86 68.22 63.64 68.4 62.13Z" />
            <path id="&lt;Path&gt;" class="shp18" d="M117.89 69.31C119.35 65.94 117.25 62.23 113.21 61.03C109.17 59.82 104.72 61.57 103.26 64.94C101.8 68.31 103.9 72.01 107.94 73.22C111.97 74.43 116.43 72.67 117.89 69.31Z" />
            <path id="&lt;Path&gt;" class="shp19" d="M112.65 64.38C112.83 62.88 111.58 61.66 109.86 61.66C108.14 61.66 106.61 62.88 106.43 64.38C106.25 65.89 107.5 67.11 109.22 67.11C110.94 67.11 112.47 65.89 112.65 64.38Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M184.38 120.25C193.6 116.95 196.68 122.1 195.43 127.78C194.19 133.46 181.31 142.66 174.69 136.26C167.53 129.34 175.91 123.28 184.38 120.25Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M176 129.78C180.57 128.14 182.1 130.69 181.48 133.51C180.86 136.32 174.47 140.9 171.19 137.72C167.64 134.29 171.79 131.29 176 129.78Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M147.04 107.07C147.89 104.08 142.99 100.07 136.08 98.1C129.17 96.13 122.89 96.96 122.04 99.95C121.19 102.94 126.09 106.95 133 108.92C139.91 110.89 146.19 110.06 147.04 107.07Z" />
            <path id="&lt;Path&gt;" class="shp11" d="M65.21 94.94C64.12 92.03 57.78 91.72 51.06 94.24C44.34 96.77 39.78 101.17 40.87 104.08C41.96 106.99 48.3 107.3 55.02 104.77C61.74 102.25 66.3 97.84 65.21 94.94Z" />
            <path id="&lt;Path&gt;" class="shp15" d="M112.04 96.63C112.04 95.6 111.14 94.76 110.04 94.76C108.94 94.76 108.04 95.6 108.04 96.63C108.04 97.67 108.94 98.51 110.04 98.51C111.14 98.51 112.04 97.67 112.04 96.63Z" />
            <path id="&lt;Path&gt;" class="shp15" d="M72.79 95.63C72.79 94.6 71.89 93.76 70.79 93.76C69.69 93.76 68.79 94.6 68.79 95.63C68.79 96.67 69.69 97.51 70.79 97.51C71.89 97.51 72.79 96.67 72.79 95.63Z" />
            <path id="&lt;Path&gt;" class="shp20" d="M97.04 30.63C110.09 8.27 124.44 -1.34 142.29 4.13C120.04 -9.37 105.29 12.63 97.04 30.63Z" />
            <path id="&lt;Path&gt;" class="shp14" d="M134.66 60.69C135.3 59.54 132.6 54.84 127.47 52C122.33 49.17 116.69 49.27 116.06 50.42C115.43 51.57 120.03 53.33 125.17 56.16C130.31 59 134.03 61.83 134.66 60.69Z" />
            <path id="&lt;Path&gt;" class="shp20" d="M70.92 49.08C70.45 47.85 65.12 46.89 59.64 49C54.17 51.11 50.63 55.49 51.1 56.72C51.57 57.94 55.88 55.55 61.35 53.44C66.83 51.33 71.4 50.3 70.92 49.08Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M52.04 129.13L43.29 125.13L29.54 144.88L34.29 154.63L52.04 129.13Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M28.38 103.61C29.9 101.36 30.07 98.61 28.77 97.47C27.47 96.34 25.19 97.24 23.68 99.5C22.16 101.75 21.99 104.5 23.29 105.63C24.59 106.77 26.87 105.87 28.38 103.61Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M9.29 111.51L9.29 111.01C8.46 116.18 11.91 120.18 19.66 123.01L19.66 117.26C17.41 119.09 13.96 117.18 9.29 111.51Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M16.91 109.2C15.32 105.56 12.76 103.17 11.17 103.86C9.59 104.54 9.59 108.05 11.17 111.69C12.76 115.33 15.32 117.73 16.91 117.04C18.49 116.35 18.49 112.84 16.91 109.2Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M120.54 345.55L134.54 340.72C134.76 335.16 128.98 334.83 117.21 339.72L120.54 345.55Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M116.37 338.22L130.37 333.38C130.6 327.83 124.82 327.49 113.04 332.38L116.37 338.22Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M111.71 330.55L125.71 325.72C125.93 320.16 120.15 319.83 108.37 324.72L111.71 330.55Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M180.87 321.82L169.36 327.44C165.09 323.88 168.11 320.01 178.4 315.83L180.87 321.82Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M177.75 314.3L166.24 319.92C161.98 316.36 164.99 312.49 175.28 308.31L177.75 314.3Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M174.67 306.25L163.16 311.87C158.89 308.31 161.91 304.43 172.2 300.25L174.67 306.25Z" />
          </g>
        </g>
      </g>
    </svg>
    <audio ref="audio" autoplay loop src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session10/super-hero-theme.mp3"/>
    <audio @loadeddata="playSoundText" src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session10/Session10_Page9.mp3" ref="voice"/>
    <div class="page-number" id="page-light">240</div>
  </div>
</template>

<script>
import ImageComponent from "@/components/imageComponent/ImageComponent.vue";
import anime from "animejs";

export default {
  name: 'Session10Page9',
  components: {ImageComponent},
  methods: {
    animateSvg() {
      let vw = document.querySelector('.interactive-container').clientWidth;
      let vh = window.innerHeight;
      anime({
        targets: '#female',
        translateX: [
          { value: 1.1 * vw, duration: 3000 },
          { value: -1.1 * vw, duration: 6000 },
        ],
        scaleX: [
          { value: -1, duration: 1, delay: 3000 },
          { value: 1, duration: 1, delay: 6000 }
        ],
        scale: [
          { value: 2, duration: 3000 },
          { value: 0.5, duration: 6000 }
        ],
        loop: true,
        easing: 'linear',
      })
      anime({
        targets: '#male',
        translateX: [
          { value: -0.2 * vw, duration: 2000 },
          { value: -0.7 * vw, duration: 1},
          { value: 0.2 * vw, duration: 4001 },
          { value: 0.7 * vw, duration: 1},
        ],
        translateY: [
          { value: -1.1 * vh, duration: 2000 },
          { value: 1.1 * vh, duration: 4000 },
        ],
        scaleX: [
          { value: -1, duration: 1, delay: 2000 },
          { value: 1, duration: 1, delay: 4000 }
        ],
        scaleY: [
          { value: -1, duration: 1, delay: 2000 },
          { value: 1, duration: 1, delay: 4000 }
        ],
        loop: true,
        easing: 'linear',
      })
    },
    animateText() {
      let text = document.querySelector('.text-box').children;
      let animation = anime.timeline({duration: 500});
      animation
        .add({
          targets: '.kids',
          opacity: 1,
          duration: 1,
          delay: 1000
        })
        .add({
          targets: '.kids',
          scale: 100,
          duration: 1000,
        }, 1000)
        .add({
          targets: '.text-box',
          opacity: 1,
          easing: 'linear',
        }, 2500)
        .add({targets: text[0],opacity: 1, easing: 'linear'}, 2500)
        .add({targets: text[1],opacity: 1, easing: 'linear'}, 15300)
        .add({targets: text[2],opacity: 1, easing: 'linear'}, 20000)
    },
    setAudioVolumeLevel(level) {
      this.$refs.audio.volume = level
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 2500)
    },
    playSoundText() {
      this.playVoiceOver();
      this.animateText();
    }
  },
  mounted() {
    this.setAudioVolumeLevel(0.3);
    this.animateSvg();
  }
}
</script>

<style scoped>
.kids {
  position: absolute;
  width: 1%;
  bottom: 20vh;
  left: 65vh;
  opacity: 0;
}
.text-box {
  position: absolute;
  background-color: rgba(255,255,255,0.8);
  text-align: center;
  width: 84%;
  left: 8%;
  top: 1vh;
  font-size: 4vh;
  padding: 2vh;
  opacity: 0;
}
.text-box p {
  margin-bottom: 1vh;
  opacity: 0;
}
.text-box p:last-child {
  margin-bottom: 0;
}
.superhero {
  position: absolute;
  height: auto;
  width: 10%;
}
#female {
  top: 30%;
  left: -15%;
}
#male {
  bottom: 0;
  right: 20%;
}
tspan { white-space:pre }
.shp0 { fill: #a06733 }
.shp1 { fill: #fabe82 }
.shp2 { fill: #5e4882 }
.shp3 { fill: #443366 }
.shp4 { fill: #529fce }
.shp5 { fill: #317d9b }
.shp6 { fill: #b6e7ff }
.shp7 { fill: #9ad7ef }
.shp8 { fill: #fff16c }
.shp9 { fill: #ead74e }
.shp10 { opacity: 0.502;mix-blend-mode: multiply;fill: #66493d }
.shp11 { fill: #efa16c }
.shp12 { opacity: 0.302;mix-blend-mode: multiply;fill: url(#grd1) }
.shp13 { opacity: 0.502;fill: #49386b }
.shp14 { fill: #6b5791 }
.shp15 { fill: #3f2b1a }
.shp16 { fill: #ffecde }
.shp17 { fill: #efa460 }
.shp18 { fill: #8e615c }
.shp19 { fill: #c69b9b }
.shp20 { fill: #8776b2 }
tspan { white-space:pre }
.shp0-fsuperhero { fill: #60452c }
.shp1-fsuperhero { fill: #472e1a }
.shp2-fsuperhero { fill: #f4d540 }
.shp3-fsuperhero { fill: #665922 }
.shp4-fsuperhero { fill: #a09332 }
.shp5-fsuperhero { fill: #d6c145 }
.shp6-fsuperhero { fill: #c1aa38 }
.shp7-fsuperhero { fill: #d6b52d }
.shp8-fsuperhero { fill: #877821 }
.shp9-fsuperhero { fill: #ffc673 }
.shp10-fsuperhero { fill: #dda152 }
.shp11-fsuperhero { opacity: 0.502;mix-blend-mode: multiply;fill: #66493d }
.shp12-fsuperhero { fill: #665918 }
.shp13-fsuperhero { fill: #897a25 }
.shp14-fsuperhero { opacity: 0.302;mix-blend-mode: multiply;fill: url(#grd1) }
.shp15-fsuperhero { fill: #756a25 }
.shp16-fsuperhero { fill: #ffecde }
.shp17-fsuperhero { fill: #3a291e }
.shp18-fsuperhero { fill: #705241 }
.shp19-fsuperhero { fill: #ffede3 }
.shp20-fsuperhero { fill: #fcaf63 }
</style>
