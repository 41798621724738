import { render, staticRenderFns } from "./Session2Page9.vue?vue&type=template&id=ec2b7a6e&scoped=true&"
import script from "./Session2Page9.vue?vue&type=script&lang=js&"
export * from "./Session2Page9.vue?vue&type=script&lang=js&"
import style0 from "./Session2Page9.vue?vue&type=style&index=0&id=ec2b7a6e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec2b7a6e",
  null
  
)

export default component.exports