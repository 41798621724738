<template>
  <div class="interactive-container">
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1703 1289" width="1703" height="1289">
      <title>Landscape</title>
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="M53 705.74C200.25 668.19 346.2 665.84 490.83 698.7C707.78 747.99 948.1 911.95 1192.01 882.78C1354.61 863.34 1473.6 887.3 1548.97 954.67L1553 1189L53 1189L53 705.74Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp2">
          <path d="M1553 764.77C1405.75 731.81 1259.8 729.74 1115.17 758.59C898.22 801.86 657.9 945.79 413.99 920.19C251.39 903.12 132.4 924.15 57.03 983.29L53 1189L1553 1189L1553 764.77Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp3">
          <path d="M1553 820.29C1451.58 807.24 1340.3 832.56 1219.16 896.25C1087.99 965.2 891.69 1006.29 734.13 962.69C630.88 934.12 358.19 767.37 57.03 858.72L53 1189L1553 1189L1553 820.29Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp4">
          <path d="M364.12 952.66C371.82 968.77 367.85 986.71 355.25 992.74C342.66 998.77 326.21 990.6 318.51 974.49C310.82 958.39 314.79 940.44 327.38 934.42C339.98 928.39 356.42 936.56 364.12 952.66Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp5">
          <path d="M316.07 1004.21C323.76 1020.31 319.79 1038.26 307.2 1044.29C294.6 1050.32 278.16 1042.15 270.46 1026.04C262.76 1009.93 266.73 991.99 279.33 985.96C291.92 979.93 308.37 988.1 316.07 1004.21Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp6">
          <path d="M251.52 1049.65C269.31 1048.25 284.61 1058.41 285.7 1072.34C286.79 1086.27 273.26 1098.69 255.47 1100.09C237.69 1101.48 222.39 1091.32 221.3 1077.39C220.2 1063.46 233.74 1051.04 251.52 1049.65Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp7">
          <path d="M493.43 1055.31C511.2 1056.95 524.56 1069.55 523.28 1083.46C522.01 1097.37 506.57 1107.33 488.81 1105.7C471.04 1104.06 457.68 1091.46 458.96 1077.55C460.23 1063.63 475.67 1053.68 493.43 1055.31Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp8">
          <path d="M458.45 1005.75C471.81 993.91 490.14 992.8 499.39 1003.26C508.64 1013.72 505.32 1031.79 491.96 1043.63C478.61 1055.46 460.28 1056.58 451.02 1046.12C441.77 1035.66 445.09 1017.58 458.45 1005.75Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp9">
          <path d="M387.13 962.28C394.16 945.87 410.26 937.03 423.09 942.53C435.92 948.04 440.62 965.8 433.59 982.21C426.56 998.62 410.46 1007.46 397.63 1001.96C384.8 996.45 380.1 978.68 387.13 962.28Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp10">
          <path d="M1069.92 982.35C1063.39 997.52 1067.71 1013.98 1079.58 1019.09C1091.45 1024.21 1106.36 1016.06 1112.9 1000.88C1119.43 985.71 1115.11 969.25 1103.24 964.14C1091.37 959.02 1076.46 967.17 1069.92 982.35Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp11">
          <path d="M1116.24 1028.27C1109.71 1043.45 1114.03 1059.9 1125.9 1065.02C1137.76 1070.14 1152.68 1061.99 1159.22 1046.81C1165.75 1031.63 1161.43 1015.18 1149.56 1010.06C1137.69 1004.95 1122.78 1013.1 1116.24 1028.27Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp12">
          <path d="M1177.59 1067.95C1161.09 1067.31 1147.31 1077.26 1146.8 1090.18C1146.3 1103.11 1159.27 1114.11 1175.77 1114.75C1192.26 1115.39 1206.05 1105.44 1206.55 1092.52C1207.05 1079.6 1194.09 1068.6 1177.59 1067.95Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp13">
          <path d="M954.05 1081.98C937.68 1084.14 925.78 1096.28 927.47 1109.1C929.15 1121.92 943.79 1130.57 960.16 1128.41C976.53 1126.26 988.44 1114.12 986.75 1101.3C985.06 1088.47 970.43 1079.83 954.05 1081.98Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp14">
          <path d="M984.61 1034.87C971.83 1024.41 954.83 1024.04 946.65 1034.05C938.47 1044.07 942.21 1060.66 954.99 1071.12C967.77 1081.58 984.76 1081.95 992.94 1071.94C1001.12 1061.93 997.39 1045.33 984.61 1034.87Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp15">
          <path d="M1048.99 992.07C1041.9 977.15 1026.69 969.56 1015.02 975.12C1003.35 980.68 999.65 997.28 1006.75 1012.2C1013.84 1027.12 1029.05 1034.71 1040.72 1029.15C1052.39 1023.6 1056.09 1006.99 1048.99 992.07Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp16">
          <path d="M1430.01 995.84C1436.06 1009.91 1432.06 1025.15 1421.06 1029.9C1410.06 1034.64 1396.24 1027.08 1390.19 1013.02C1384.13 998.96 1388.14 983.71 1399.14 978.97C1410.13 974.23 1423.96 981.78 1430.01 995.84Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp17">
          <path d="M1387.09 1038.4C1393.14 1052.47 1389.14 1067.71 1378.14 1072.46C1367.14 1077.2 1353.32 1069.64 1347.27 1055.58C1341.21 1041.52 1345.22 1026.27 1356.21 1021.53C1367.21 1016.78 1381.03 1024.34 1387.09 1038.4Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp18">
          <path d="M1330.24 1075.17C1345.53 1074.58 1358.3 1083.8 1358.77 1095.77C1359.23 1107.75 1347.22 1117.94 1331.93 1118.54C1316.64 1119.14 1303.87 1109.91 1303.4 1097.94C1302.93 1085.96 1314.95 1075.77 1330.24 1075.17Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp19">
          <path d="M1537.38 1088.17C1552.55 1090.17 1563.58 1101.42 1562.02 1113.3C1560.46 1125.19 1546.89 1133.2 1531.72 1131.2C1516.55 1129.2 1505.52 1117.95 1507.08 1106.07C1508.65 1094.19 1522.21 1086.18 1537.38 1088.17Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp20">
          <path d="M1509.07 1044.52C1520.91 1034.82 1536.66 1034.48 1544.24 1043.76C1551.82 1053.04 1548.36 1068.42 1536.52 1078.11C1524.67 1087.8 1508.93 1088.14 1501.35 1078.87C1493.77 1069.59 1497.22 1054.21 1509.07 1044.52Z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp21">
          <path d="M1449.4 1004.86C1455.98 991.03 1470.08 984 1480.89 989.15C1491.7 994.3 1495.13 1009.68 1488.56 1023.51C1481.98 1037.34 1467.88 1044.37 1457.07 1039.22C1446.26 1034.07 1442.83 1018.68 1449.4 1004.86Z" />
        </clipPath>
      </defs>
      <g id="BACKGROUND">
        <path id="&lt;Path&gt;" class="shp0" d="M1553 1188.21L53.04 1188.21L53.04 188.21L1553 188.21L1553 1188.21Z" />
      </g>
      <g id="Landscape">
        <g id="&lt;Group&gt;">
          <g id="&lt;Clip Group&gt;">
            <path id="&lt;Path&gt;" class="shp-cloud" ref="cloud1" d="M731.66 487.24C717.98 487.24 704.94 490 693.07 494.99C683.15 477.52 664.41 465.72 642.89 465.68C643.39 462.48 643.65 459.2 643.65 455.85C643.65 423.59 619.63 396.95 588.5 392.8C583.91 348.64 546.58 314.21 501.2 314.21C458.51 314.21 422.95 344.69 415.07 385.07C390.15 389.56 369.88 407.36 361.86 430.85L337.12 431.31C337.16 430.21 337.21 429.11 337.21 428.01C337.21 377.3 296.11 336.2 245.4 336.2C194.7 336.2 153.6 377.3 153.6 428.01C153.6 436.77 154.86 445.23 157.15 453.26C156.59 453.25 156.03 453.22 155.47 453.22C131.47 453.22 110.71 467.09 100.76 487.26C45.98 487.6 1.67 532.11 1.67 586.98C1.67 642.06 46.32 686.71 101.41 686.71L731.66 686.71C786.75 686.71 831.4 642.06 831.4 586.98C831.4 531.89 786.75 487.24 731.66 487.24Z" />
            <path id="&lt;Path&gt;" class="shp-cloud" d="M1580.71 546.25C1567.03 546.25 1553.99 549.01 1542.12 554C1532.2 536.53 1513.45 524.73 1491.94 524.69C1492.44 521.49 1492.7 518.21 1492.7 514.86C1492.7 482.6 1468.67 455.96 1437.54 451.81C1432.95 407.65 1395.62 373.22 1350.25 373.22C1307.56 373.22 1272 403.7 1264.12 444.08C1239.2 448.57 1218.92 466.37 1210.9 489.86L1186.16 490.31C1186.2 489.22 1186.25 488.12 1186.25 487.01C1186.25 436.31 1145.15 395.21 1094.45 395.21C1043.75 395.21 1002.65 436.31 1002.65 487.01C1002.65 495.78 1003.9 504.24 1006.19 512.27C1005.63 512.25 1005.08 512.23 1004.51 512.23C980.51 512.23 959.76 526.1 949.81 546.27C895.02 546.61 850.72 591.12 850.72 645.98L850.72 804.74L1680.44 804.74L1680.44 645.98C1680.44 590.9 1635.79 546.25 1580.71 546.25Z" />
            <path id="&lt;Path&gt;" class="shp-cloud" ref="cloud2" d="M1129.42 526.19C1119.47 506.03 1098.72 492.15 1074.71 492.15C1074.15 492.15 1073.59 492.18 1073.03 492.19C1075.33 484.17 1076.58 475.7 1076.58 466.94C1076.58 416.24 1035.48 375.14 984.78 375.14C934.08 375.14 892.98 416.24 892.98 466.94C892.98 468.05 893.02 469.14 893.06 470.24L868.33 469.78C860.31 446.29 840.03 428.49 815.11 424.01C807.23 383.63 771.67 353.14 728.98 353.14C683.61 353.14 646.28 387.58 641.69 431.74C610.55 435.88 586.53 462.52 586.53 494.79C586.53 498.13 586.79 501.41 587.29 504.62C565.78 504.66 547.03 516.46 537.11 533.92C525.24 528.94 512.2 526.17 498.52 526.17C443.44 526.17 398.78 570.83 398.78 625.91C398.78 628.8 398.93 631.66 399.18 634.48L407.37 813.85L1228.51 813.85L1228.51 625.91C1228.51 571.04 1184.2 526.54 1129.42 526.19Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M885.57 720C885.57 804.8 816.82 873.55 732.01 873.55C647.2 873.55 578.45 804.8 578.45 720C578.45 635.19 647.2 566.44 732.01 566.44C816.82 566.44 885.57 635.19 885.57 720Z" />
            <path id="&lt;Path&gt;" class="shp3" d="M752.57 863.28C667.76 863.28 599.01 794.53 599.01 709.72C599.01 653.69 629.03 604.68 673.86 577.87C617.89 600.79 578.45 655.77 578.45 720C578.45 804.8 647.2 873.55 732.01 873.55C760.79 873.55 787.71 865.62 810.73 851.85C792.78 859.2 773.16 863.28 752.57 863.28Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp4" d="M1553 603.56C1447.04 577.88 1334.75 595.34 1216.13 655.93C1096.51 717.03 980.44 800.74 855.66 799.2C730.88 797.66 442.67 484.94 53 561.96L53 1189L1553 1189L1553 603.56Z" />
                  <path id="&lt;Path&gt;" class="shp5" d="M53 705.74C200.25 668.19 346.2 665.84 490.83 698.7C707.78 747.99 948.1 911.95 1192.01 882.78C1354.61 863.34 1473.6 887.3 1548.97 954.67L1553 1189L53 1189L53 705.74Z" />
                  <g id="&lt;Clip Group&gt;" clip-path="url(#cp1)" style="opacity: 0.502;mix-blend-mode: soft-light">
                    <path id="&lt;Path&gt;" class="shp6" d="M41.25 770.35C142.67 723.39 224.29 701 286.11 703.15C354.49 705.54 410.64 720.1 454.56 746.83C487.18 724.35 530.74 722.84 585.23 742.3C666.97 771.49 809.68 872.26 881.56 871.14L934.78 826.62L454.56 657.89L7.4 678.48L41.25 770.35Z" />
                  </g>
                  <path id="&lt;Path&gt;" class="shp7" d="M1553 764.77C1405.75 731.81 1259.8 729.74 1115.17 758.59C898.22 801.86 657.9 945.79 413.99 920.19C251.39 903.12 132.4 924.15 57.03 983.29L53 1189L1553 1189L1553 764.77Z" />
                  <g id="&lt;Clip Group&gt;" clip-path="url(#cp2)" style="opacity: 0.502;mix-blend-mode: soft-light">
                    <path id="&lt;Path&gt;" class="shp6" d="M1588.3 778.69C1458.35 756.8 1356.87 748.05 1283.87 752.44C1174.37 759.04 1098.38 785.18 1144.44 813.56C1051.74 805.46 989.83 821.37 958.69 861.28C884.9 955.91 768.57 1014.98 609.72 1038.52L526.35 895.91L1132.53 700.86L1619.77 717.3L1588.3 778.69Z" />
                  </g>
                  <path id="&lt;Path&gt;" class="shp8" d="M1553 820.29C1451.58 807.24 1340.3 832.56 1219.16 896.25C1087.99 965.2 891.69 1006.29 734.13 962.69C630.88 934.12 358.19 767.37 57.03 858.72L53 1189L1553 1189L1553 820.29Z" />
                  <g id="&lt;Clip Group&gt;" clip-path="url(#cp3)" style="opacity: 0.502;mix-blend-mode: soft-light">
                    <path id="&lt;Path&gt;" class="shp9" d="M22.79 907.34C82.14 868.37 160.37 851.51 257.49 856.76C398.72 864.39 463.11 928.72 454.56 970.1C499.57 926.96 554.75 917.61 620.12 942.04C718.16 978.69 814.35 1058.76 937.87 1048.82C1061.39 1038.88 1200.97 913.99 1285.33 899.04C1341.57 889.08 1363.35 899.66 1350.68 930.8C1412.6 856.38 1502.3 838.45 1619.77 877.01L1619.77 778.69L-3.08 792.65L22.79 907.34Z" />
                  </g>
                  <path id="&lt;Path&gt;" class="shp10" d="M1050.86 853.78C1050.86 856.51 1046.56 858.72 1041.25 858.72C1035.95 858.72 1031.65 856.51 1031.65 853.78C1031.65 851.05 1035.95 848.84 1041.25 848.84C1046.56 848.84 1050.86 851.05 1050.86 853.78Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1358.8 790.78C1358.8 793.51 1354.5 795.73 1349.2 795.73C1343.89 795.73 1339.59 793.51 1339.59 790.78C1339.59 788.05 1343.89 785.84 1349.2 785.84C1354.5 785.84 1358.8 788.05 1358.8 790.78Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1201.98 787.67C1201.98 790.4 1197.68 792.61 1192.38 792.61C1187.07 792.61 1182.77 790.4 1182.77 787.67C1182.77 784.94 1187.07 782.73 1192.38 782.73C1197.68 782.73 1201.98 784.94 1201.98 787.67Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M956.1 943.78C956.1 946.51 951.8 948.72 946.5 948.72C941.19 948.72 936.89 946.51 936.89 943.78C936.89 941.05 941.19 938.84 946.5 938.84C951.8 938.84 956.1 941.05 956.1 943.78Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M969.15 823.58C969.15 826.31 964.85 828.52 959.55 828.52C954.24 828.52 949.94 826.31 949.94 823.58C949.94 820.85 954.24 818.64 959.55 818.64C964.85 818.64 969.15 820.85 969.15 823.58Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M778.45 892.24C778.45 894.97 774.15 897.18 768.85 897.18C763.54 897.18 759.24 894.97 759.24 892.24C759.24 889.51 763.54 887.3 768.85 887.3C774.15 887.3 778.45 889.51 778.45 892.24Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M748.1 839.2C748.1 841.93 743.8 844.14 738.5 844.14C733.19 844.14 728.89 841.93 728.89 839.2C728.89 836.47 733.19 834.25 738.5 834.25C743.8 834.25 748.1 836.47 748.1 839.2Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M603.84 887.3C603.84 890.02 599.54 892.24 594.23 892.24C588.93 892.24 584.63 890.02 584.63 887.3C584.63 884.57 588.93 882.35 594.23 882.35C599.54 882.35 603.84 884.57 603.84 887.3Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M112.49 719.45C112.49 722.18 108.19 724.4 102.89 724.4C97.58 724.4 93.28 722.18 93.28 719.45C93.28 716.73 97.58 714.51 102.89 714.51C108.19 714.51 112.49 716.73 112.49 719.45Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M398.28 782.73C398.28 785.46 393.98 787.67 388.68 787.67C383.37 787.67 379.07 785.46 379.07 782.73C379.07 780 383.37 777.79 388.68 777.79C393.98 777.79 398.28 780 398.28 782.73Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M406.77 869.38C406.77 872.11 402.47 874.32 397.17 874.32C391.86 874.32 387.56 872.11 387.56 869.38C387.56 866.65 391.86 864.44 397.17 864.44C402.47 864.44 406.77 866.65 406.77 869.38Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1060.46 984.19C1060.46 986.92 1056.16 989.13 1050.86 989.13C1045.55 989.13 1041.25 986.92 1041.25 984.19C1041.25 981.46 1045.55 979.25 1050.86 979.25C1056.16 979.25 1060.46 981.46 1060.46 984.19Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1523.65 943.78C1523.65 946.51 1519.34 948.72 1514.04 948.72C1508.74 948.72 1504.43 946.51 1504.43 943.78C1504.43 941.05 1508.74 938.84 1514.04 938.84C1519.34 938.84 1523.65 941.05 1523.65 943.78Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M593.34 979.25C593.34 981.98 589.04 984.19 583.74 984.19C578.43 984.19 574.13 981.98 574.13 979.25C574.13 976.52 578.43 974.3 583.74 974.3C589.04 974.3 593.34 976.52 593.34 979.25Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M716.16 1048.78C716.16 1051.51 711.86 1053.72 706.55 1053.72C701.25 1053.72 696.95 1051.51 696.95 1048.78C696.95 1046.05 701.25 1043.84 706.55 1043.84C711.86 1043.84 716.16 1046.05 716.16 1048.78Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M164.96 900.45C164.96 903.18 160.66 905.39 155.35 905.39C150.05 905.39 145.75 903.18 145.75 900.45C145.75 897.72 150.05 895.51 155.35 895.51C160.66 895.51 164.96 897.72 164.96 900.45Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1395.58 869.38C1395.58 872.11 1391.28 874.32 1385.98 874.32C1380.67 874.32 1376.37 872.11 1376.37 869.38C1376.37 866.65 1380.67 864.44 1385.98 864.44C1391.28 864.44 1395.58 866.65 1395.58 869.38Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M437.83 707.72C437.83 710.45 433.53 712.66 428.23 712.66C422.92 712.66 418.62 710.45 418.62 707.72C418.62 704.99 422.92 702.78 428.23 702.78C433.53 702.78 437.83 704.99 437.83 707.72Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1058.14 842.32C1058.14 844.05 1055.42 845.45 1052.07 845.45C1048.71 845.45 1045.99 844.05 1045.99 842.32C1045.99 840.6 1048.71 839.2 1052.07 839.2C1055.42 839.2 1058.14 840.6 1058.14 842.32Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M774.92 845.45C774.92 847.18 772.2 848.58 768.85 848.58C765.49 848.58 762.77 847.18 762.77 845.45C762.77 843.72 765.49 842.32 768.85 842.32C772.2 842.32 774.92 843.72 774.92 845.45Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M579.47 853.78C579.47 855.5 576.75 856.9 573.4 856.9C570.04 856.9 567.32 855.5 567.32 853.78C567.32 852.05 570.04 850.65 573.4 850.65C576.75 850.65 579.47 852.05 579.47 853.78Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M354.83 804.27C354.83 806 352.11 807.4 348.76 807.4C345.4 807.4 342.68 806 342.68 804.27C342.68 802.55 345.4 801.15 348.76 801.15C352.11 801.15 354.83 802.55 354.83 804.27Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M155.35 740.84C155.35 742.57 152.63 743.97 149.28 743.97C145.92 743.97 143.2 742.57 143.2 740.84C143.2 739.11 145.92 737.72 149.28 737.72C152.63 737.72 155.35 739.11 155.35 740.84Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M133.26 784.54C133.26 786.27 130.54 787.67 127.19 787.67C123.83 787.67 121.11 786.27 121.11 784.54C121.11 782.82 123.83 781.42 127.19 781.42C130.54 781.42 133.26 782.82 133.26 784.54Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M649.54 787.67C649.54 789.4 646.82 790.8 643.47 790.8C640.11 790.8 637.39 789.4 637.39 787.67C637.39 785.94 640.11 784.54 643.47 784.54C646.82 784.54 649.54 785.94 649.54 787.67Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M466.9 717.06C466.9 718.79 464.18 720.19 460.83 720.19C457.47 720.19 454.75 718.79 454.75 717.06C454.75 715.33 457.47 713.93 460.83 713.93C464.18 713.93 466.9 715.33 466.9 717.06Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M434.31 884.24C434.31 885.97 431.59 887.37 428.23 887.37C424.87 887.37 422.15 885.97 422.15 884.24C422.15 882.51 424.87 881.11 428.23 881.11C431.59 881.11 434.31 882.51 434.31 884.24Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M127.19 914.93C127.19 916.66 124.47 918.06 121.11 918.06C117.75 918.06 115.03 916.66 115.03 914.93C115.03 913.2 117.75 911.81 121.11 911.81C124.47 911.81 127.19 913.2 127.19 914.93Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M209.95 921.18C209.95 922.91 207.23 924.31 203.88 924.31C200.52 924.31 197.8 922.91 197.8 921.18C197.8 919.46 200.52 918.06 203.88 918.06C207.23 918.06 209.95 919.46 209.95 921.18Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1294.12 921.18C1294.12 922.91 1291.4 924.31 1288.05 924.31C1284.69 924.31 1281.97 922.91 1281.97 921.18C1281.97 919.46 1284.69 918.06 1288.05 918.06C1291.4 918.06 1294.12 919.46 1294.12 921.18Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1100.19 970.96C1100.19 972.69 1097.47 974.09 1094.11 974.09C1090.76 974.09 1088.04 972.69 1088.04 970.96C1088.04 969.23 1090.76 967.83 1094.11 967.83C1097.47 967.83 1100.19 969.23 1100.19 970.96Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1423.4 856.9C1423.4 858.63 1420.68 860.03 1417.33 860.03C1413.97 860.03 1411.25 858.63 1411.25 856.9C1411.25 855.18 1413.97 853.78 1417.33 853.78C1420.68 853.78 1423.4 855.18 1423.4 856.9Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M1487.88 890.11C1487.88 891.83 1485.16 893.23 1481.8 893.23C1478.45 893.23 1475.73 891.83 1475.73 890.11C1475.73 888.38 1478.45 886.98 1481.8 886.98C1485.16 886.98 1487.88 888.38 1487.88 890.11Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M354.83 918.06C354.83 919.78 352.11 921.18 348.76 921.18C345.4 921.18 342.68 919.78 342.68 918.06C342.68 916.33 345.4 914.93 348.76 914.93C352.11 914.93 354.83 916.33 354.83 918.06Z" />
                  <path id="&lt;Path&gt;" class="shp7" d="M615.99 964.92C615.99 966.65 613.27 968.05 609.91 968.05C606.56 968.05 603.84 966.65 603.84 964.92C603.84 963.19 606.56 961.79 609.91 961.79C613.27 961.79 615.99 963.19 615.99 964.92Z" />
                  <path id="&lt;Path&gt;" class="shp11" d="M99.36 771.04C99.36 772.77 96.64 774.17 93.28 774.17C89.93 774.17 87.21 772.77 87.21 771.04C87.21 769.31 89.93 767.91 93.28 767.91C96.64 767.91 99.36 769.31 99.36 771.04Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1349.28 771.04C1349.28 772.77 1346.56 774.17 1343.21 774.17C1339.85 774.17 1337.13 772.77 1337.13 771.04C1337.13 769.31 1339.85 767.91 1343.21 767.91C1346.56 767.91 1349.28 769.31 1349.28 771.04Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1221.59 798.02C1221.59 799.75 1218.87 801.15 1215.51 801.15C1212.16 801.15 1209.44 799.75 1209.44 798.02C1209.44 796.3 1212.16 794.9 1215.51 794.9C1218.87 794.9 1221.59 796.3 1221.59 798.02Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1465.14 794.39C1465.14 796.12 1462.42 797.52 1459.06 797.52C1455.71 797.52 1452.99 796.12 1452.99 794.39C1452.99 792.67 1455.71 791.27 1459.06 791.27C1462.42 791.27 1465.14 792.67 1465.14 794.39Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M908.75 890.11C908.75 891.83 906.03 893.23 902.67 893.23C899.32 893.23 896.6 891.83 896.6 890.11C896.6 888.38 899.32 886.98 902.67 886.98C906.03 886.98 908.75 888.38 908.75 890.11Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M744.57 903.58C744.57 905.3 741.85 906.7 738.5 906.7C735.14 906.7 732.42 905.3 732.42 903.58C732.42 901.85 735.14 900.45 738.5 900.45C741.85 900.45 744.57 901.85 744.57 903.58Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp12" d="M1226.58 500.79C1222.69 497.77 1219.38 493.94 1221.31 490.53C1224.36 485.14 1234.15 491.69 1238.43 494.94C1239.11 494.68 1239.8 494.43 1240.49 494.19C1240.6 488.65 1241.75 475.33 1250.08 474.1C1259.62 472.69 1261.71 486.07 1262.1 489.85C1262.2 489.85 1262.31 489.84 1262.41 489.84C1263.12 486.78 1265.78 477.72 1271.77 479.86C1275.78 481.3 1275.35 486.26 1274.23 490.28C1277.51 490.67 1280.79 491.27 1284.07 492.12C1283.25 486.42 1283.17 479.1 1287.87 478.08C1293.09 476.95 1295.84 483.07 1296.11 496.44C1306.11 486.68 1313.83 484.08 1319.27 488.64C1325.01 493.44 1323.53 500.16 1314.83 508.82C1322.63 504.01 1327.49 503.96 1329.39 508.67C1331.03 512.71 1326.83 515.72 1323.01 517.55C1336.83 534.8 1343.43 558.14 1339.79 582.05C1345.31 580.52 1352.43 579.53 1354.04 584.01C1355.85 589.04 1350.15 592.57 1336.93 594.61C1347.93 603.23 1351.52 610.54 1347.73 616.54C1342.34 625.05 1330.05 616.89 1327.6 615.13C1327.57 615.18 1327.54 615.23 1327.51 615.28C1329.1 617.39 1334.99 625.93 1329.2 629.22C1325.81 631.15 1322.6 628.3 1320.3 625.14C1301.19 647.65 1271.43 658.69 1242.41 651.07C1201.21 640.24 1177.15 595.89 1188.68 552C1194.59 529.52 1208.7 511.55 1226.58 500.79Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1354.04 584.01C1352.43 579.53 1345.31 580.52 1339.79 582.05C1343.43 558.14 1336.84 534.8 1323.01 517.55C1326.83 515.72 1331.03 512.71 1329.39 508.67C1327.49 503.96 1322.63 504.01 1314.83 508.82C1323.53 500.16 1325.01 493.44 1319.27 488.64C1314.86 484.94 1308.45 487.52 1303.5 490.67C1304.19 491.16 1304.78 491.85 1305.19 492.84C1306.82 496.88 1302.62 499.89 1298.8 501.72C1312.63 518.97 1319.22 542.31 1315.58 566.22C1321.1 564.69 1328.22 563.7 1329.83 568.18C1331.64 573.21 1325.94 576.74 1312.73 578.78C1323.72 587.4 1327.31 594.71 1323.52 600.71C1318.13 609.22 1305.84 601.06 1303.39 599.3C1303.36 599.35 1303.33 599.4 1303.3 599.45C1304.89 601.56 1310.78 610.1 1305 613.39C1301.6 615.32 1298.39 612.47 1296.09 609.31C1276.98 631.82 1247.22 642.86 1218.21 635.24C1215.26 634.47 1212.41 633.5 1209.65 632.4C1218.57 641.16 1229.67 647.72 1242.41 651.07C1271.43 658.69 1301.19 647.65 1320.3 625.14C1322.6 628.3 1325.81 631.15 1329.2 629.22C1334.99 625.93 1329.1 617.39 1327.51 615.28C1327.54 615.23 1327.57 615.18 1327.6 615.13C1330.05 616.89 1342.34 625.05 1347.73 616.54C1351.52 610.54 1347.93 603.23 1336.93 594.61C1350.15 592.57 1355.85 589.04 1354.04 584.01Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M1256.01 650.33C1255.62 647.74 1252.3 647.12 1249.6 647.35C1249.57 647.18 1249.54 647.01 1249.51 646.83C1250.85 645.2 1252.13 643.45 1252.47 641.38C1252.84 639.08 1251.52 636.34 1249.2 636.03C1248.18 635.9 1247.29 636.23 1246.51 636.82C1239.26 619.79 1222.37 607.85 1202.69 607.85C1192.04 607.85 1182.21 611.35 1174.28 617.25C1173.88 616.41 1173.42 615.59 1172.88 614.83C1171.89 613.44 1170.41 612.1 1168.71 612.31C1167.26 612.5 1166.19 613.78 1165.54 615.1C1164.05 618.15 1164.04 621.89 1165.53 624.94C1164.16 623.54 1162.69 622.13 1160.86 621.45C1159.02 620.76 1156.7 620.98 1155.43 622.48C1153.94 624.22 1154.4 626.88 1155.27 629.01C1156.22 631.34 1157.57 633.52 1159.23 635.42C1155.75 634.35 1151.63 635.97 1149.82 639.13C1149.48 639.72 1149.2 640.4 1149.3 641.07C1149.51 642.58 1151.28 643.25 1152.78 643.49C1154.02 643.7 1155.26 643.79 1156.52 643.82C1155.58 647.55 1155.08 651.45 1155.08 655.46C1155.08 661.42 1156.18 667.11 1158.17 672.36C1157.23 672.94 1156.41 673.7 1155.84 674.64C1154.52 676.82 1154.71 679.92 1156.57 681.66C1158.28 683.25 1161.3 683.2 1162.85 681.53C1162.98 681.72 1163.1 681.92 1163.23 682.11C1163.2 683.42 1163.18 684.73 1163.16 686.03C1163.14 687.31 1163.15 688.69 1163.94 689.69C1165.22 691.31 1167.87 690.9 1169.37 689.46C1177.95 697.88 1189.71 703.08 1202.69 703.08C1228.99 703.08 1250.3 681.76 1250.3 655.46C1250.3 655.22 1250.29 654.98 1250.28 654.74C1251.37 654.72 1252.45 654.61 1253.45 654.19C1254.98 653.54 1256.26 651.97 1256.01 650.33Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1256.01 650.33C1255.62 647.74 1252.3 647.12 1249.6 647.35C1249.57 647.18 1249.54 647.01 1249.51 646.83C1250.85 645.2 1252.13 643.45 1252.47 641.38C1252.84 639.08 1251.52 636.34 1249.2 636.03C1248.18 635.9 1247.29 636.23 1246.51 636.82C1239.97 621.48 1225.61 610.3 1208.44 608.23C1219.12 613.17 1227.68 621.94 1232.3 632.8C1233.09 632.21 1233.98 631.88 1235 632.02C1237.31 632.32 1238.63 635.07 1238.26 637.37C1237.92 639.44 1236.64 641.18 1235.3 642.82C1235.33 642.99 1235.36 643.16 1235.39 643.33C1238.1 643.1 1241.41 643.72 1241.8 646.32C1242.05 647.96 1240.77 649.52 1239.24 650.17C1238.25 650.6 1237.17 650.71 1236.08 650.73C1236.08 650.97 1236.1 651.21 1236.1 651.45C1236.1 677.74 1214.78 699.06 1188.48 699.06C1186.55 699.06 1184.64 698.93 1182.77 698.71C1188.83 701.51 1195.58 703.08 1202.69 703.08C1228.99 703.08 1250.3 681.76 1250.3 655.46C1250.3 655.22 1250.29 654.98 1250.28 654.74C1251.37 654.72 1252.45 654.61 1253.45 654.19C1254.98 653.54 1256.26 651.97 1256.01 650.33Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M1385.85 663.79C1386.36 662.21 1385.23 660.44 1383.71 659.79C1382.31 659.19 1380.73 659.34 1379.26 659.75C1379.21 659.64 1379.18 659.52 1379.13 659.42C1381.13 656.42 1383.89 651.22 1381.75 649.87C1380.2 649.01 1377.69 650.64 1375.48 652.72C1373.08 649.15 1370.09 645.88 1366.48 643.13C1365.35 642.27 1364.19 641.48 1363 640.75C1363.77 639.81 1364.43 638.78 1364.94 637.67C1365.35 636.81 1365.66 635.81 1365.34 634.91C1364.83 633.51 1363.03 633.06 1361.55 633.23C1359.03 633.51 1356.7 635 1355.34 637.13C1355.21 637.08 1355.07 637.05 1354.93 637C1354.79 635.41 1355.23 633.78 1355.23 632.16C1355.23 630.3 1354.14 628.12 1352.28 628.11C1351.57 628.11 1350.9 628.45 1350.3 628.83C1348.11 630.25 1346.52 632.54 1345.9 635.07C1345.81 635.06 1345.72 635.05 1345.63 635.04C1345.24 633.9 1344.84 632.76 1344.44 631.62C1344.15 630.78 1343.83 629.9 1343.17 629.31C1341.34 627.66 1338.15 629.48 1337.62 631.88C1337.38 632.95 1337.49 634.03 1337.77 635.08C1326.79 636.19 1316.24 641.66 1308.98 651.18C1295.15 669.3 1298.47 695.08 1316.4 708.76C1333.29 721.65 1357.12 719.19 1371.38 703.69C1371.81 704.39 1372.32 705.05 1373.02 705.45C1375.18 706.67 1378.01 704.63 1378.53 702.21C1379.03 699.94 1378.17 697.64 1377.25 695.49C1377.28 695.43 1377.31 695.37 1377.35 695.32C1379 696.92 1381.88 697.05 1383.71 695.61C1385.63 694.09 1386.18 691.14 1385.07 688.95C1384.3 687.41 1382.79 686.32 1381.12 685.89C1382.79 679.44 1382.86 672.73 1381.4 666.35C1383.23 666.2 1385.3 665.52 1385.85 663.79Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M1354.79 630.07C1354.59 630.35 1354.4 630.64 1354.18 630.91C1354.52 631.12 1354.85 631.38 1355.19 631.6C1355.13 631.08 1355 630.55 1354.79 630.07Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M1385.85 663.79C1386.36 662.21 1385.23 660.44 1383.71 659.79C1382.31 659.19 1380.73 659.34 1379.26 659.75C1379.21 659.64 1379.18 659.52 1379.13 659.42C1381.13 656.42 1383.89 651.22 1381.75 649.87C1380.49 649.17 1378.59 650.13 1376.74 651.63C1377.13 652.35 1377.28 653.17 1377.03 653.94C1376.47 655.68 1374.4 656.36 1372.58 656.51C1374.03 662.89 1373.96 669.6 1372.29 676.05C1373.97 676.48 1375.47 677.56 1376.25 679.1C1377.36 681.29 1376.81 684.25 1374.88 685.77C1373.06 687.21 1370.17 687.08 1368.52 685.47C1368.49 685.53 1368.46 685.59 1368.43 685.65C1369.35 687.8 1370.2 690.1 1369.71 692.37C1369.18 694.79 1366.36 696.83 1364.2 695.61C1363.49 695.21 1362.99 694.55 1362.56 693.85C1348.29 709.34 1324.47 711.81 1307.57 698.92C1307.32 698.73 1307.1 698.5 1306.85 698.3C1309.34 702.22 1312.5 705.79 1316.4 708.76C1333.29 721.65 1357.12 719.19 1371.38 703.69C1371.81 704.39 1372.32 705.05 1373.02 705.45C1375.18 706.67 1378.01 704.63 1378.53 702.21C1379.03 699.94 1378.17 697.64 1377.25 695.49C1377.28 695.43 1377.31 695.37 1377.35 695.32C1379 696.92 1381.88 697.05 1383.71 695.61C1385.63 694.09 1386.18 691.14 1385.07 688.95C1384.3 687.41 1382.79 686.32 1381.12 685.89C1382.79 679.44 1382.86 672.73 1381.4 666.35C1383.23 666.2 1385.3 665.52 1385.85 663.79Z" />
                <path id="&lt;Path&gt;" class="shp12" d="M374.28 485.82C378.81 483.89 382.99 481.03 381.99 477.24C380.41 471.24 369.28 475.1 364.3 477.15C363.71 476.72 363.11 476.31 362.51 475.9C363.8 470.52 366.08 457.34 358.34 454.03C349.46 450.25 344.05 462.66 342.7 466.21C342.61 466.18 342.51 466.15 342.41 466.12C342.5 462.99 342.23 453.55 335.89 454.1C331.65 454.46 330.8 459.37 330.86 463.55C327.59 463.09 324.27 462.83 320.88 462.83C323.12 457.52 325.06 450.46 320.77 448.28C316.01 445.86 311.8 451.08 308.14 463.94C300.95 451.96 294.14 447.49 287.72 450.51C280.95 453.7 280.67 460.58 286.89 471.16C280.56 464.52 275.88 463.25 272.84 467.32C270.23 470.8 273.53 474.79 276.75 477.53C259 490.69 246.7 511.59 244.14 535.65C239.19 532.76 232.55 529.99 229.86 533.92C226.83 538.32 231.45 543.19 243.71 548.51C230.89 554.06 225.55 560.21 227.7 566.98C230.74 576.58 244.7 571.81 247.52 570.74C247.53 570.79 247.55 570.85 247.57 570.9C245.5 572.54 237.63 579.3 242.39 583.95C245.18 586.68 249.01 584.75 252.04 582.27C264.8 608.9 290.78 627.14 320.77 627.14C363.38 627.14 397.92 590.36 397.92 544.98C397.92 521.74 388.84 500.77 374.28 485.82Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M347.74 621.57C317.74 621.57 291.76 603.33 279 576.7C275.97 579.18 272.15 581.11 269.35 578.38C264.59 573.73 272.46 566.97 274.53 565.33C274.52 565.28 274.5 565.22 274.48 565.17C271.67 566.24 257.71 571.01 254.66 561.41C252.51 554.64 257.85 548.49 270.67 542.94C258.41 537.62 253.79 532.75 256.82 528.35C259.52 524.42 266.15 527.19 271.1 530.08C273.66 506.02 285.97 485.12 303.72 471.96C300.49 469.22 297.2 465.23 299.8 461.75C301.31 459.72 303.37 459.48 305.44 460.04C301.63 455.04 294.4 447.37 287.72 450.51C280.95 453.7 280.67 460.58 286.89 471.16C280.56 464.52 275.88 463.25 272.84 467.32C270.23 470.8 273.53 474.79 276.75 477.53C259 490.69 246.7 511.59 244.14 535.65C239.19 532.76 232.55 529.99 229.86 533.92C226.83 538.32 231.45 543.19 243.71 548.51C230.89 554.06 225.55 560.21 227.7 566.98C230.74 576.58 244.7 571.81 247.52 570.74C247.53 570.79 247.55 570.85 247.57 570.9C245.5 572.54 237.63 579.3 242.39 583.95C245.18 586.68 249.01 584.75 252.04 582.27C264.8 608.9 290.78 627.14 320.77 627.14C330.65 627.14 340.08 625.15 348.76 621.55C348.42 621.55 348.08 621.57 347.74 621.57Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M454.52 615.97C459.26 607.78 461.65 598.11 460.81 587.96C459.04 566.47 443.39 549.45 423.4 544.98C423.4 544.98 427.82 539.58 423.4 536.81C419.87 534.59 415.29 541.12 413.58 543.84C413.29 543.84 413 543.85 412.72 543.85C412.13 540.54 409.94 531.09 404.13 531.97C399.46 532.68 400.99 540.07 402.55 544.97C394.13 546.82 386.6 550.84 380.52 556.38C379.37 552.06 377.43 548.35 374.14 549.3C368.77 550.85 371.4 560.26 373.09 564.96C372.83 565.35 372.57 565.74 372.32 566.13C369.08 563.67 362.38 559.59 359.29 565.27C355.12 572.9 367.34 576.52 367.34 576.52C367.34 576.52 367.34 576.52 367.34 576.52C367.34 576.52 356.48 578.92 356.5 585.28C356.5 587.96 360.43 588.38 364.98 587.97C364.77 590.56 364.76 593.2 364.98 595.87C367.16 622.34 390.39 642.02 416.85 639.83C426.48 639.04 435.21 635.45 442.31 629.94C443.38 633.04 445.35 636.46 448.83 636.07C453 635.61 453 631.53 448.83 623.84C454.39 627.25 458.32 627.25 460.63 623.84C462.88 620.52 458.14 617.61 454.52 615.97Z" />
                <path id="&lt;Path&gt;" class="shp13" d="M435.89 633.22C409.42 635.41 386.2 615.72 384.02 589.26C383.79 586.59 383.81 583.95 384.02 581.36C379.47 581.77 375.54 581.34 375.53 578.67C375.52 572.31 386.38 569.91 386.38 569.91C386.38 569.91 386.38 569.91 386.38 569.91C386.38 569.91 374.16 566.29 378.32 558.65C378.93 557.54 379.68 556.81 380.52 556.36C379.37 552.05 377.42 548.36 374.14 549.3C368.77 550.85 371.4 560.26 373.09 564.96C372.83 565.35 372.57 565.74 372.32 566.13C369.08 563.67 362.38 559.59 359.29 565.27C355.12 572.9 367.34 576.52 367.34 576.52C367.34 576.52 367.34 576.52 367.34 576.52C367.34 576.52 356.48 578.92 356.5 585.28C356.5 587.96 360.43 588.38 364.98 587.97C364.77 590.56 364.76 593.2 364.98 595.87C367.16 622.34 390.39 642.02 416.85 639.83C424.55 639.2 431.66 636.75 437.83 632.99C437.19 633.07 436.54 633.17 435.89 633.22Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M338.25 838.03L324.12 528.07L334.11 527.43L355.04 840.4L338.25 838.03Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M346.2 721.8L333.45 716.01L395.13 580.44L404.13 584.53L346.2 721.8Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M374.14 645.88L374.14 629.8L429.94 604.57L434.12 611.96L374.14 645.88Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M328.27 597.55L295.58 547.73L303.28 542.05L335.14 583.57L328.27 597.55Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1259.11 876.81L1235.88 887.73L1269.27 567.26L1276.15 567.74L1259.11 876.81Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1261.9 759.3L1251.35 750.09L1333.4 656.14L1339.59 661.54L1261.9 759.3Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1309.24 689.65L1319.87 674.53L1354.75 677.28L1355.52 685.07L1309.24 689.65Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1269.27 639.28L1234.12 572.04L1241.12 568.39L1273.28 616.74L1269.27 639.28Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1249.04 774.17L1198.49 668.65L1206.65 664.74L1261.66 768.11L1249.04 774.17Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1222.42 703.08L1215.51 695.86L1223.09 656.28L1230.66 655.87L1222.42 703.08Z" />
                <path id="&lt;Path&gt;" class="shp16" d="" />
                <path id="&lt;Path&gt;" class="shp15" d="" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp17" d="M802.19 1085.62L802.19 1085.59C801.87 1085.59 801.56 1085.6 801.24 1085.6C800.92 1085.6 800.61 1085.59 800.29 1085.59L800.29 1085.62C720 1086.33 664.34 1120.79 633.33 1189L800.29 1189L802.19 1189L969.15 1189C938.14 1120.79 882.49 1086.33 802.19 1085.62Z" />
                <path id="&lt;Path&gt;" class="shp17" d="M1150.4 1085.62L1150.4 1085.59C1150.08 1085.59 1149.77 1085.6 1149.45 1085.6C1149.13 1085.6 1148.82 1085.59 1148.5 1085.59L1148.5 1085.62C1068.21 1086.33 1012.55 1120.79 981.54 1189L1148.5 1189L1150.4 1189L1317.36 1189C1286.35 1120.79 1230.7 1086.33 1150.4 1085.62Z" />
                <path id="&lt;Path&gt;" class="shp17" d="M461.78 1085.62L461.78 1085.59C461.46 1085.59 461.15 1085.6 460.83 1085.6C460.51 1085.6 460.2 1085.59 459.88 1085.59L459.88 1085.62C379.58 1086.33 323.93 1120.79 292.92 1189L459.88 1189L461.78 1189L628.74 1189C597.73 1120.79 542.07 1086.33 461.78 1085.62Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp18" d="M388.94 1070.7C388.94 1073.76 384.4 1076.25 378.8 1076.25C373.2 1076.25 368.66 1073.76 368.66 1070.7C368.66 1067.64 373.2 1065.16 378.8 1065.16C384.4 1065.16 388.94 1067.64 388.94 1070.7Z" />
                  <path id="&lt;Path&gt;" class="shp18" d="M891.02 1119.37C891.02 1122.43 886.48 1124.92 880.88 1124.92C875.28 1124.92 870.74 1122.43 870.74 1119.37C870.74 1116.31 875.28 1113.83 880.88 1113.83C886.48 1113.83 891.02 1116.31 891.02 1119.37Z" />
                  <path id="&lt;Path&gt;" class="shp18" d="M768.85 1108.71C768.85 1111.77 764.31 1114.25 758.71 1114.25C753.11 1114.25 748.57 1111.77 748.57 1108.71C748.57 1105.65 753.11 1103.17 758.71 1103.17C764.31 1103.17 768.85 1105.65 768.85 1108.71Z" />
                  <path id="&lt;Path&gt;" class="shp18" d="M1137.51 1125.18C1137.51 1128.24 1132.97 1130.72 1127.37 1130.72C1121.77 1130.72 1117.23 1128.24 1117.23 1125.18C1117.23 1122.12 1121.77 1119.64 1127.37 1119.64C1132.97 1119.64 1137.51 1122.12 1137.51 1125.18Z" />
                  <path id="&lt;Path&gt;" class="shp18" d="M415.78 1084.72C415.78 1087.51 413.52 1089.77 410.73 1089.77C407.94 1089.77 405.68 1087.51 405.68 1084.72C405.68 1081.93 407.94 1079.67 410.73 1079.67C413.52 1079.67 415.78 1081.93 415.78 1084.72Z" />
                  <path id="&lt;Path&gt;" class="shp18" d="M1146.41 1102.33C1146.41 1105.12 1144.15 1107.38 1141.36 1107.38C1138.57 1107.38 1136.31 1105.12 1136.31 1102.33C1136.31 1099.54 1138.57 1097.28 1141.36 1097.28C1144.15 1097.28 1146.41 1099.54 1146.41 1102.33Z" />
                  <path id="&lt;Path&gt;" class="shp18" d="M773.27 1130.15C773.27 1132.94 771.01 1135.2 768.22 1135.2C765.43 1135.2 763.17 1132.94 763.17 1130.15C763.17 1127.36 765.43 1125.1 768.22 1125.1C771.01 1125.1 773.27 1127.36 773.27 1130.15Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp17" d="M125.45 1048.81L125.45 1048.78C125.13 1048.78 124.81 1048.79 124.49 1048.8C124.18 1048.79 123.86 1048.78 123.54 1048.78L123.54 1048.81C96.39 1049.14 73 1056.46 53.04 1067.59L53.04 1189L123.54 1189L125.45 1189L292.4 1189C261.39 1096.51 205.74 1049.79 125.45 1048.81Z" />
                <path id="&lt;Path&gt;" class="shp17" d="M1553 1065.58C1533.87 1055.59 1511.71 1049.12 1486.23 1048.81L1486.23 1048.78C1485.91 1048.78 1485.59 1048.79 1485.27 1048.8C1484.96 1048.79 1484.64 1048.78 1484.32 1048.78L1484.32 1048.81C1404.03 1049.79 1348.38 1096.51 1317.36 1189L1484.32 1189L1486.23 1189L1553 1189L1553 1065.58Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp19" d="M218.09 470.18C218.32 467.17 216.14 464.24 213.28 463.25C212.62 463.02 211.93 462.9 211.23 462.85C212.92 457.35 214.08 451.57 214.58 445.56C215.78 431.38 213.31 417.82 208.03 405.99C208.63 405.86 209.23 405.71 209.81 405.5C211.13 405.03 212.44 404.31 213.15 403.11C214.45 400.89 213.13 397.88 210.98 396.47C208.83 395.05 205.35 394.39 202.79 394.19C203.75 392.27 204.72 390.26 204.74 388.11C204.76 385.96 203.5 383.64 201.4 383.18C199.56 382.78 197.72 383.88 196.32 385.14C195.92 385.49 195.55 385.89 195.19 386.28C184.25 374.26 169.47 366.32 152.72 364.91C138.25 363.68 124.43 367.52 112.68 375.03C112.2 371.14 110.55 367.16 107.56 367.38C103.5 367.67 102.13 377.17 103.88 381.52C101.88 379.75 99.09 377.84 96.55 377.03C94 376.22 90.94 376.51 89.06 378.42C87.23 380.27 86.93 383.24 87.66 385.74C88.4 388.24 89.99 390.38 91.58 392.45C88.69 392.14 85.55 393.54 83.43 395.53C81.31 397.53 80.63 401.17 82.5 403.4C83.41 404.5 84.67 405.05 86.06 405.3C81.84 413.99 79.09 423.69 78.21 434.06C74.72 475.43 102.42 511.54 140.08 514.72C160.35 516.43 179.36 508.24 193.15 493.87C193.97 495.56 195.16 497.08 196.65 498.23C198.17 499.4 200.2 500.22 202 499.55C204.35 498.67 205.2 495.72 205.01 493.22C204.82 490.73 203.94 488.26 204.2 485.77C206.54 486.41 208.05 486.96 210.38 486.27C212.7 485.58 214.79 483.67 215.11 481.27C215.43 478.87 213.48 476.23 211.06 476.33C214.06 476.71 217.85 473.2 218.09 470.18Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M158.81 512.04C121.16 508.86 93.46 472.75 96.95 431.38C97.82 421.02 100.57 411.31 104.79 402.63C103.4 402.37 102.14 401.82 101.23 400.73C99.37 398.49 100.04 394.85 102.16 392.86C104.29 390.86 107.42 389.47 110.32 389.77C108.73 387.71 107.13 385.56 106.4 383.06C105.67 380.56 105.97 377.6 107.8 375.74C109.02 374.5 110.74 373.95 112.5 373.94C111.87 370.43 110.27 367.19 107.56 367.38C103.5 367.67 102.13 377.17 103.88 381.52C101.88 379.75 99.09 377.84 96.55 377.03C94 376.22 90.94 376.51 89.06 378.42C87.23 380.27 86.93 383.24 87.66 385.74C88.4 388.24 89.99 390.38 91.58 392.45C88.69 392.14 85.55 393.54 83.43 395.53C81.31 397.53 80.63 401.17 82.5 403.4C83.41 404.5 84.67 405.05 86.06 405.3C81.84 413.99 79.09 423.69 78.21 434.06C74.72 475.43 102.42 511.54 140.08 514.72C148.17 515.4 156.06 514.48 163.51 512.24C161.95 512.22 160.39 512.17 158.81 512.04Z" />
              <path id="&lt;Path&gt;" class="shp20" d="M186.4 514.91C179.14 514.91 172.55 517.75 167.64 522.36C167.31 521.8 166.94 521.26 166.49 520.78C164.92 519.13 162.21 518.3 160.3 519.54C158.66 520.61 158.11 522.84 158.48 524.76C158.85 526.67 159.95 528.36 161.03 529.98C158.95 529 156.37 528.93 154.4 530.12C152.43 531.31 151.27 533.85 151.98 536.04C152.7 538.23 155.44 539.61 157.52 538.61C154.95 540.15 153.28 543.08 153.26 546.08C153.26 547.17 153.48 548.3 154.13 549.17C155.43 550.92 158.12 551.08 160.09 550.12C163.44 561.49 173.94 569.8 186.4 569.8C201.56 569.8 213.85 557.51 213.85 542.36C213.85 527.2 201.56 514.91 186.4 514.91Z" />
              <path id="&lt;Path&gt;" class="shp20" d="M1528.32 641.43C1527.92 639.9 1526.36 638.89 1524.79 638.67C1524.19 638.58 1523.6 638.6 1523 638.66C1523.31 637.02 1523.49 635.33 1523.49 633.6C1523.49 624.83 1519.36 617.04 1512.95 612.01C1513.86 610.93 1514.66 609.76 1515.28 608.49C1515.92 607.18 1516.38 605.6 1515.75 604.29C1514.96 602.64 1512.79 602.15 1510.99 602.44C1508.54 602.84 1506.17 604.58 1505.71 607.02C1504.9 605.99 1504.72 604.61 1504.18 603.42C1503.64 602.22 1502.36 601.1 1501.13 601.57C1500.23 601.92 1499.82 602.96 1499.61 603.9C1499.42 604.71 1499.34 605.54 1499.31 606.37C1498.23 606.24 1497.15 606.15 1496.04 606.15C1480.88 606.15 1468.59 618.44 1468.59 633.6C1468.59 648.76 1480.88 661.05 1496.04 661.05C1501.42 661.05 1506.42 659.48 1510.65 656.8C1510.83 657.4 1511.05 657.98 1511.38 658.51C1512.18 659.8 1513.71 660.76 1515.2 660.44C1516.59 660.14 1517.57 658.79 1517.88 657.39C1518.18 655.99 1517.94 654.54 1517.7 653.13C1519.44 654.04 1521.83 653.47 1522.97 651.87C1524.11 650.26 1523.87 647.82 1522.43 646.47C1523.92 646.48 1525.48 646.2 1526.69 645.33C1527.9 644.45 1528.69 642.88 1528.32 641.43Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M1522.23 551.69C1521.84 549.22 1518.63 548.04 1516.29 548.92C1517.7 548.04 1518.95 546.89 1519.93 545.54C1520.48 544.79 1520.96 543.84 1520.7 542.95C1520.22 541.36 1518 541.29 1516.39 541.71C1516.06 541.8 1515.75 541.91 1515.42 542.01C1515.92 538.29 1516.19 534.49 1516.19 530.62C1516.19 510.54 1509.21 492.26 1497.78 478.52C1500.28 477.63 1502.75 476.05 1502.88 473.5C1502.91 472.87 1502.77 472.2 1502.32 471.75C1501.79 471.21 1500.95 471.1 1500.19 471.09C1497.92 471.06 1495.66 471.54 1493.6 472.5C1494.1 469.96 1496.46 467.67 1495.68 465.21C1495.06 463.27 1492.63 462.41 1490.64 462.84C1488.65 463.27 1487.01 464.64 1485.46 465.96C1485.66 463.37 1485.32 460.74 1484.46 458.28C1484.06 457.14 1483.22 455.92 1482.01 456C1480.25 456.12 1478.88 458.67 1477.93 461.88C1468.32 456.46 1457.43 453.39 1445.89 453.39C1430.58 453.39 1416.42 458.78 1404.88 467.9C1404.48 466.16 1403.79 464.49 1402.79 463C1401.24 460.68 1398.24 458.71 1395.78 460.04C1393.94 461.03 1393.36 463.44 1393.56 465.52C1393.75 467.61 1394.5 469.63 1394.54 471.72C1393.24 470.5 1391.54 469.62 1389.76 469.66C1387.99 469.69 1386.19 470.81 1385.69 472.52C1385.29 473.85 1385.71 475.28 1386.23 476.57C1386.93 478.33 1387.82 480.01 1388.87 481.59C1386.66 480.93 1384.27 480.87 1382.03 481.41C1379.72 481.96 1377.23 483.79 1377.64 486.12C1377.96 487.95 1379.9 489 1381.67 489.56C1383.03 489.99 1384.43 490.25 1385.85 490.43C1379.34 502.13 1375.58 515.89 1375.58 530.62C1375.58 573.27 1407.06 607.85 1445.89 607.85C1472.32 607.85 1495.34 591.81 1507.35 568.11C1508.84 569.52 1510.93 570.32 1512.96 570.04C1515.27 569.72 1517.36 567.87 1517.71 565.57C1518.06 563.27 1516.44 560.76 1514.14 560.37C1516.31 559.4 1518.32 558.04 1520.02 556.38C1521.31 555.12 1522.51 553.46 1522.23 551.69Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M1522.23 551.69C1521.84 549.22 1518.63 548.04 1516.29 548.92C1517.7 548.04 1518.95 546.89 1519.93 545.54C1520.48 544.79 1520.96 543.84 1520.7 542.95C1520.22 541.36 1518 541.29 1516.39 541.71C1516.06 541.8 1515.75 541.91 1515.42 542.01C1515.92 538.29 1516.19 534.49 1516.19 530.62C1516.19 510.54 1509.21 492.26 1497.78 478.52C1500.28 477.63 1502.75 476.05 1502.88 473.5C1502.91 472.87 1502.77 472.2 1502.32 471.75C1501.79 471.21 1500.95 471.1 1500.19 471.09C1497.92 471.06 1495.66 471.54 1493.6 472.5C1494.1 469.96 1496.46 467.67 1495.68 465.21C1495.06 463.27 1492.63 462.41 1490.64 462.84C1488.65 463.27 1487.01 464.64 1485.46 465.96C1485.66 463.37 1485.32 460.74 1484.46 458.28C1484.06 457.14 1483.22 455.92 1482.01 456C1480.36 456.11 1479.05 458.39 1478.11 461.32C1478.67 461.27 1479.23 461.24 1479.79 461.25C1480.56 461.26 1481.39 461.36 1481.93 461.91C1482.37 462.36 1482.52 463.03 1482.49 463.66C1482.36 466.21 1479.88 467.78 1477.39 468.68C1488.82 482.41 1495.8 500.7 1495.8 520.78C1495.8 524.65 1495.53 528.45 1495.03 532.17C1495.35 532.07 1495.67 531.96 1496 531.87C1497.6 531.44 1499.83 531.52 1500.3 533.11C1500.57 534 1500.08 534.94 1499.53 535.7C1498.55 537.05 1497.31 538.2 1495.9 539.08C1498.24 538.2 1501.44 539.38 1501.83 541.84C1502.11 543.62 1500.92 545.28 1499.63 546.54C1497.92 548.2 1495.92 549.56 1493.75 550.53C1496.04 550.92 1497.67 553.42 1497.32 555.73C1496.96 558.03 1494.88 559.88 1492.57 560.2C1490.54 560.48 1488.45 559.68 1486.95 558.27C1474.95 581.97 1451.93 598.01 1425.49 598.01C1419.4 598.01 1413.49 597.16 1407.85 595.56C1418.82 603.32 1431.87 607.85 1445.89 607.85C1472.32 607.85 1495.34 591.81 1507.35 568.11C1508.84 569.52 1510.93 570.32 1512.96 570.04C1515.27 569.72 1517.36 567.87 1517.71 565.57C1518.06 563.27 1516.44 560.76 1514.14 560.37C1516.31 559.4 1518.32 558.04 1520.02 556.38C1521.31 555.12 1522.51 553.46 1522.23 551.69Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M185.75 543.1L180.53 538.19L142.8 577.64L154.93 434.43L144.97 433.55L140.07 485.85L115.15 441.18L108.3 445.83L138.44 503.32L120.71 692.58L133.26 690.33L141.48 593.33L185.75 543.1Z" />
              <path id="&lt;Path&gt;" class="shp21" d="M1504.49 626L1499.95 621.86L1461.68 663.76L1450.15 571.62L1479.16 517.26L1472.84 513.89L1448.12 555.41L1442.03 506.79L1434.1 507.96L1462.45 750.27L1472.67 751.54L1463.3 676.69L1504.49 626Z" />
            </g>
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp22" d="M477.86 1082.42L478.62 1073.81L426.58 1069.17L458.13 1045L452.88 1038.14L377.91 1095.56L403.62 994.86L395.25 992.72L383.04 1040.52L356.38 982.18L348.52 985.77L379.64 1053.85L359.22 1133.84L306.25 1035.31L298.64 1039.4L314.1 1068.17L272.13 1068.17L272.13 1076.81L318.75 1076.81L356.06 1146.22L337.66 1218.28L346.03 1220.42L374.46 1109.09L416.43 1076.94L477.86 1082.42Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M364.12 952.66C371.82 968.77 367.85 986.71 355.25 992.74C342.66 998.77 326.21 990.6 318.51 974.49C310.82 958.39 314.79 940.44 327.38 934.42C339.98 928.39 356.42 936.56 364.12 952.66Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M316.07 1004.21C323.76 1020.31 319.79 1038.26 307.2 1044.29C294.6 1050.32 278.16 1042.15 270.46 1026.04C262.76 1009.93 266.73 991.99 279.33 985.96C291.92 979.93 308.37 988.1 316.07 1004.21Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M251.52 1049.65C269.31 1048.25 284.61 1058.41 285.7 1072.34C286.79 1086.27 273.26 1098.69 255.47 1100.09C237.69 1101.48 222.39 1091.32 221.3 1077.39C220.2 1063.46 233.74 1051.04 251.52 1049.65Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M493.43 1055.31C511.2 1056.95 524.56 1069.55 523.28 1083.46C522.01 1097.37 506.57 1107.33 488.81 1105.7C471.04 1104.06 457.68 1091.46 458.96 1077.55C460.23 1063.63 475.67 1053.68 493.43 1055.31Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M458.45 1005.75C471.81 993.91 490.14 992.8 499.39 1003.26C508.64 1013.72 505.32 1031.79 491.96 1043.63C478.61 1055.46 460.28 1056.58 451.02 1046.12C441.77 1035.66 445.09 1017.58 458.45 1005.75Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M387.13 962.28C394.16 945.87 410.26 937.03 423.09 942.53C435.92 948.04 440.62 965.8 433.59 982.21C426.56 998.62 410.46 1007.46 397.63 1001.96C384.8 996.45 380.1 978.68 387.13 962.28Z" />
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp4)">
                      <path id="&lt;Path&gt;" class="shp23" d="M327.54 927.57C340.53 955.24 352.78 983.25 364.27 1011.57" />
                      <path id="&lt;Path&gt;" class="shp23" d="M316.62 981.79C327.97 981.47 339.31 980.46 350.54 978.8C358.2 968.14 364.49 956.2 369.44 944.04" />
                      <path id="&lt;Path&gt;" class="shp23" d="M309.45 964.18C320.45 962.98 331.45 961.78 342.45 960.58C346.84 950.97 351 940.99 354.82 931.14" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp5)">
                      <path id="&lt;Path&gt;" class="shp23" d="M276.57 980.44C290.39 1006.91 303.45 1033.78 315.74 1060.99" />
                      <path id="&lt;Path&gt;" class="shp23" d="M268.44 1034.78C279.79 1034.09 291.13 1033.41 302.47 1032.72C308.75 1021.75 314.52 1010.4 319.97 999" />
                      <path id="&lt;Path&gt;" class="shp23" d="M261.63 1017.98C272.67 1017.6 283.69 1016.69 294.64 1015.26C298.93 1005.47 303 995.6 306.78 985.61" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp6)">
                      <path id="&lt;Path&gt;" class="shp23" d="M215.95 1075.72C243.39 1075.24 270.81 1074.1 298.2 1072.31" />
                      <path id="&lt;Path&gt;" class="shp23" d="M259.24 1104.08C264.02 1094.07 268.33 1083.85 272.15 1073.44C266.64 1062.14 259.34 1051.57 251.39 1041.83" />
                      <path id="&lt;Path&gt;" class="shp23" d="M234.89 1100.42C239.78 1091.81 244.4 1083.05 248.73 1074.15C242.44 1065.77 235.39 1058.01 228.31 1050.29" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp7)">
                      <path id="&lt;Path&gt;" class="shp23" d="M454.1 1075.39C481.45 1078.5 508.71 1082.46 535.82 1087.25" />
                      <path id="&lt;Path&gt;" class="shp23" d="M526.12 1070.35C520.96 1074.67 515.98 1079.2 511.21 1083.95C515.38 1092.3 520.63 1099.68 526.68 1106.79" />
                      <path id="&lt;Path&gt;" class="shp23" d="M511.53 1054.41C504.55 1063.57 496.94 1072.25 488.76 1080.36C493.71 1092.27 499.26 1103.8 505.3 1115.2" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp8)">
                      <path id="&lt;Path&gt;" class="shp23" d="M446.88 1048.16C469.83 1028.64 494.2 1010.81 519.75 994.85" />
                      <path id="&lt;Path&gt;" class="shp23" d="M481.68 989.73C481.25 1000.28 480.83 1010.83 480.4 1021.38C492.45 1021.39 504.62 1021.22 516.67 1021.23" />
                      <path id="&lt;Path&gt;" class="shp23" d="M463.06 998.25C464.9 1009.92 466.01 1021.7 466.39 1033.51C481.52 1033.98 496.86 1034.59 511.96 1035.78" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp9)">
                      <path id="&lt;Path&gt;" class="shp23" d="M395.72 1009.98C404.02 980.3 412.32 950.61 420.62 920.93" />
                      <path id="&lt;Path&gt;" class="shp23" d="M381.94 965.05C387.83 974.29 394.18 983.25 400.96 991.87C416 988.52 430.88 985.59 445.93 982.24" />
                      <path id="&lt;Path&gt;" class="shp23" d="M393.46 944.8C397.88 952.16 402.6 959.36 407.6 966.35C417.73 959.66 428.65 954 439.4 948.34" />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp22" d="M969.45 1106.49L968.42 1098.55L1016.39 1092.37L986.33 1071.17L990.94 1064.63L1062.36 1115.01L1034.92 1022.81L1042.59 1020.53L1055.61 1064.29L1078.15 1009.37L1085.55 1012.4L1059.25 1076.5L1081.04 1149.74L1126.45 1056.68L1133.64 1060.19L1120.38 1087.36L1159.2 1085.84L1159.51 1093.83L1116.4 1095.52L1084.41 1161.07L1104.05 1227.05L1096.38 1229.33L1066.05 1127.4L1026.06 1099.19L969.45 1106.49Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1069.92 982.35C1063.39 997.52 1067.71 1013.98 1079.58 1019.09C1091.45 1024.21 1106.36 1016.06 1112.9 1000.88C1119.43 985.71 1115.11 969.25 1103.24 964.14C1091.37 959.02 1076.46 967.17 1069.92 982.35Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1116.24 1028.27C1109.71 1043.45 1114.03 1059.9 1125.9 1065.02C1137.76 1070.14 1152.68 1061.99 1159.22 1046.81C1165.75 1031.63 1161.43 1015.18 1149.56 1010.06C1137.69 1004.95 1122.78 1013.1 1116.24 1028.27Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1177.59 1067.95C1161.09 1067.31 1147.31 1077.26 1146.8 1090.18C1146.3 1103.11 1159.27 1114.11 1175.77 1114.75C1192.26 1115.39 1206.05 1105.44 1206.55 1092.52C1207.05 1079.6 1194.09 1068.6 1177.59 1067.95Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M954.05 1081.98C937.68 1084.14 925.78 1096.28 927.47 1109.1C929.15 1121.92 943.79 1130.57 960.16 1128.41C976.53 1126.26 988.44 1114.12 986.75 1101.3C985.06 1088.47 970.43 1079.83 954.05 1081.98Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M984.61 1034.87C971.83 1024.41 954.83 1024.04 946.65 1034.05C938.47 1044.07 942.21 1060.66 954.99 1071.12C967.77 1081.58 984.76 1081.95 992.94 1071.94C1001.12 1061.93 997.39 1045.33 984.61 1034.87Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1048.99 992.07C1041.9 977.15 1026.69 969.56 1015.02 975.12C1003.35 980.68 999.65 997.28 1006.75 1012.2C1013.84 1027.12 1029.05 1034.71 1040.72 1029.15C1052.39 1023.6 1056.09 1006.99 1048.99 992.07Z" />
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp10)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1102.84 957.81C1091.83 983.87 1081.53 1010.22 1071.93 1036.83" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1114.91 1007.56C1104.4 1007.67 1093.88 1007.16 1083.44 1006.02C1075.96 996.44 1069.71 985.63 1064.69 974.56" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1120.91 991.01C1110.69 990.31 1100.47 989.6 1090.25 988.89C1085.84 980.16 1081.64 971.07 1077.74 962.1" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp11)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1151.91 1004.86C1140.09 1029.84 1128.98 1055.16 1118.6 1080.78" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1161.4 1054.82C1150.88 1054.6 1140.36 1054.37 1129.85 1054.15C1123.65 1044.24 1117.89 1033.94 1112.44 1023.6" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1167.09 1039.03C1156.86 1039.08 1146.64 1038.64 1136.46 1037.72C1132.14 1028.82 1128.01 1019.84 1124.15 1010.74" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp12)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1211.43 1090.77C1186.04 1091.33 1160.63 1091.27 1135.24 1090.61" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1172.43 1118.58C1167.64 1109.5 1163.28 1100.2 1159.37 1090.71C1164.06 1080.06 1170.43 1070.02 1177.42 1060.72" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1194.82 1114.31C1189.98 1106.53 1185.39 1098.59 1181.06 1090.51C1186.58 1082.54 1192.82 1075.1 1199.08 1067.7" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp13)">
                      <path id="&lt;Path&gt;" class="shp23" d="M991.16 1099.12C965.98 1103 940.91 1107.65 916.01 1113.06" />
                      <path id="&lt;Path&gt;" class="shp23" d="M924.37 1097.08C929.3 1100.88 934.07 1104.9 938.66 1109.11C935.1 1116.99 930.51 1124 925.17 1130.8" />
                      <path id="&lt;Path&gt;" class="shp23" d="M937.28 1081.8C944.07 1090.02 951.43 1097.77 959.28 1104.98C955.14 1116.18 950.42 1127.04 945.26 1137.8" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp14)">
                      <path id="&lt;Path&gt;" class="shp23" d="M996.85 1073.68C974.92 1056.46 951.73 1040.85 927.52 1027.02" />
                      <path id="&lt;Path&gt;" class="shp23" d="M962.54 1020.9C963.32 1030.64 964.1 1040.39 964.87 1050.13C953.73 1050.58 942.47 1050.86 931.32 1051.31" />
                      <path id="&lt;Path&gt;" class="shp23" d="M980.07 1028.11C978.79 1038.97 978.19 1049.9 978.28 1060.84C964.3 1061.82 950.13 1062.94 936.21 1064.59" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp15)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1042.78 1036.51C1034.03 1009.35 1025.27 982.2 1016.51 955.05" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1053.89 994.45C1048.78 1003.21 1043.24 1011.73 1037.28 1019.95C1023.24 1017.39 1009.37 1015.22 995.33 1012.67" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1042.51 976.14C1038.68 983.11 1034.58 989.94 1030.21 996.59C1020.59 990.76 1010.29 985.93 1000.15 981.08" />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp22" d="M1523.12 1110.88L1524.07 1103.53L1479.62 1097.8L1507.48 1078.15L1503.2 1072.09L1437.02 1118.78L1462.44 1033.34L1455.34 1031.22L1443.27 1071.78L1422.39 1020.88L1415.53 1023.7L1439.9 1083.09L1419.71 1150.96L1377.63 1064.73L1370.97 1067.98L1383.25 1093.16L1347.28 1091.74L1346.99 1099.15L1386.94 1100.72L1416.58 1161.46L1398.39 1222.61L1405.49 1224.72L1433.6 1130.26L1470.66 1104.12L1523.12 1110.88Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1430.01 995.84C1436.06 1009.91 1432.06 1025.15 1421.06 1029.9C1410.06 1034.64 1396.24 1027.08 1390.19 1013.02C1384.13 998.96 1388.14 983.71 1399.14 978.97C1410.13 974.23 1423.96 981.78 1430.01 995.84Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1387.09 1038.4C1393.14 1052.47 1389.14 1067.71 1378.14 1072.46C1367.14 1077.2 1353.32 1069.64 1347.27 1055.58C1341.21 1041.52 1345.22 1026.27 1356.21 1021.53C1367.21 1016.78 1381.03 1024.34 1387.09 1038.4Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1330.24 1075.17C1345.53 1074.58 1358.3 1083.8 1358.77 1095.77C1359.23 1107.75 1347.22 1117.94 1331.93 1118.54C1316.64 1119.14 1303.87 1109.91 1303.4 1097.94C1302.93 1085.96 1314.95 1075.77 1330.24 1075.17Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1537.38 1088.17C1552.55 1090.17 1563.58 1101.42 1562.02 1113.3C1560.46 1125.19 1546.89 1133.2 1531.72 1131.2C1516.55 1129.2 1505.52 1117.95 1507.08 1106.07C1508.65 1094.19 1522.21 1086.18 1537.38 1088.17Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1509.07 1044.52C1520.91 1034.82 1536.66 1034.48 1544.24 1043.76C1551.82 1053.04 1548.36 1068.42 1536.52 1078.11C1524.67 1087.8 1508.93 1088.14 1501.35 1078.87C1493.77 1069.59 1497.22 1054.21 1509.07 1044.52Z" />
                    <path id="&lt;Path&gt;" class="shp18" d="M1449.4 1004.86C1455.98 991.03 1470.08 984 1480.89 989.15C1491.7 994.3 1495.13 1009.68 1488.56 1023.51C1481.98 1037.34 1467.88 1044.37 1457.07 1039.22C1446.26 1034.07 1442.83 1018.68 1449.4 1004.86Z" />
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp16)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1399.5 973.11C1409.71 997.25 1419.26 1021.68 1428.15 1046.33" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1388.32 1019.21C1398.06 1019.31 1407.81 1018.84 1417.49 1017.78C1424.42 1008.91 1430.2 998.89 1434.85 988.63" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1382.76 1003.88C1392.23 1003.22 1401.7 1002.56 1411.17 1001.9C1415.26 993.81 1419.15 985.4 1422.77 977.08" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp17)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1354.04 1016.7C1364.99 1039.86 1375.28 1063.32 1384.9 1087.06" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1345.24 1063C1354.99 1062.8 1364.73 1062.59 1374.48 1062.38C1380.23 1053.19 1385.56 1043.66 1390.61 1034.07" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1339.97 1048.37C1349.44 1048.42 1358.92 1048.01 1368.35 1047.15C1372.36 1038.91 1376.18 1030.59 1379.76 1022.15" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp18)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1298.88 1096.32C1322.41 1096.83 1345.95 1096.78 1369.48 1096.17" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1335.02 1122.08C1339.46 1113.67 1343.5 1105.05 1347.12 1096.26C1342.78 1086.39 1336.88 1077.09 1330.39 1068.47" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1314.27 1118.13C1318.76 1110.92 1323.01 1103.56 1327.02 1096.08C1321.91 1088.69 1316.13 1081.8 1310.32 1074.94" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp19)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1503 1104.05C1526.34 1107.65 1549.56 1111.95 1572.64 1116.97" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1564.89 1102.17C1560.32 1105.69 1555.9 1109.41 1551.65 1113.32C1554.95 1120.62 1559.2 1127.11 1564.15 1133.41" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1552.92 1088.01C1546.63 1095.62 1539.82 1102.81 1532.54 1109.49C1536.37 1119.86 1540.75 1129.93 1545.53 1139.9" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp20)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1497.73 1080.48C1518.05 1064.52 1539.54 1050.06 1561.97 1037.24" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1529.51 1031.57C1528.8 1040.6 1528.08 1049.63 1527.36 1058.65C1537.68 1059.07 1548.12 1059.33 1558.45 1059.75" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1513.27 1038.25C1514.46 1048.31 1515.01 1058.45 1514.94 1068.58C1527.89 1069.49 1541.02 1070.53 1553.92 1072.06" />
                    </g>
                    <g id="&lt;Clip Group&gt;" clip-path="url(#cp21)">
                      <path id="&lt;Path&gt;" class="shp23" d="M1455.16 1046.03C1463.27 1020.87 1471.39 995.71 1479.5 970.55" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1444.86 1007.06C1449.6 1015.18 1454.74 1023.07 1460.26 1030.69C1473.27 1028.32 1486.12 1026.31 1499.13 1023.95" />
                      <path id="&lt;Path&gt;" class="shp23" d="M1455.42 990.09C1458.96 996.55 1462.76 1002.88 1466.81 1009.04C1475.72 1003.64 1485.27 999.16 1494.67 994.67" />
                    </g>
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp24" d="M661.21 1201.1C643.43 1107.01 665.5 1032.95 727.4 978.9L679.29 1204.94L661.21 1201.1Z" />
                    <path id="&lt;Path&gt;" class="shp25" d="M697.37 1208.79C751.92 1130.1 761.93 1053.47 727.4 978.9L679.29 1204.94L697.37 1208.79Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp24" d="M988.03 1224.83C1031.52 1161.13 1039.14 1099.35 1010.9 1039.48L973.44 1221.83L988.03 1224.83Z" />
                    <path id="&lt;Path&gt;" class="shp25" d="M958.86 1218.84C944 1143.16 961.35 1083.37 1010.9 1039.48L973.44 1221.83L958.86 1218.84Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp24" d="M226.95 1234.98C268.48 1174.15 275.76 1115.15 248.79 1057.98L213.02 1232.12L226.95 1234.98Z" />
                    <path id="&lt;Path&gt;" class="shp25" d="M199.09 1229.25C184.9 1156.98 201.47 1099.89 248.79 1057.98L213.02 1232.12L199.09 1229.25Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp24" d="M1218.18 1197.76C1164.2 1118.69 1154.73 1041.99 1189.79 967.66L1236.29 1194.04L1218.18 1197.76Z" />
                    <path id="&lt;Path&gt;" class="shp25" d="M1254.4 1190.32C1272.84 1096.37 1251.31 1022.15 1189.79 967.66L1236.29 1194.04L1254.4 1190.32Z" />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                </g>
                <g id="&lt;Group&gt;">
                </g>
                <g id="&lt;Group&gt;">
                </g>
                <g id="&lt;Group&gt;">
                </g>
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp26" d="M363.73 1278L356.49 1280.52L284.63 1073.56L291.87 1071.04L363.73 1278Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M341.65 1228.44C297.41 1196.95 270.28 1186.25 260.27 1196.33C250.25 1206.41 279.72 1223.92 348.68 1248.86L341.65 1228.44Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M327.98 1188.15C283.74 1156.66 256.61 1145.95 246.6 1156.03C236.58 1166.11 266.05 1183.62 335.01 1208.57L327.98 1188.15Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M316.23 1150.63C271.99 1119.14 244.87 1108.44 234.85 1118.51C224.83 1128.59 254.31 1146.1 323.26 1171.05L316.23 1150.63Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M301.5 1109.31C257.26 1077.82 230.13 1067.12 220.12 1077.19C210.1 1087.27 239.57 1104.78 308.53 1129.73L301.5 1109.31Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M288.1 1069.31C243.86 1037.83 216.74 1027.12 206.72 1037.2C196.7 1047.28 226.18 1064.79 295.14 1089.73L288.1 1069.31Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M280.32 1074.77C249.06 1018.7 239.86 988.57 252.7 984.4C262.64 981.17 277.72 1010.15 297.94 1071.35L280.32 1074.77Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M341.25 1228.04C356.59 1175.95 371.32 1150.78 385.42 1152.51C399.52 1154.25 387.16 1186.23 348.34 1248.44L341.25 1228.04Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M327.58 1187.74C342.92 1135.66 357.64 1110.48 371.75 1112.22C385.85 1113.96 373.49 1145.93 334.66 1208.15L327.58 1187.74Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M315.83 1150.23C331.17 1098.14 345.9 1072.96 360 1074.7C374.1 1076.44 361.74 1108.42 322.92 1170.63L315.83 1150.23Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M301.1 1108.91C316.44 1056.82 331.16 1031.64 345.27 1033.38C359.37 1035.12 347.01 1067.1 308.18 1129.31L301.1 1108.91Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M287.7 1068.91C303.05 1016.82 317.77 991.65 331.87 993.39C345.97 995.13 333.61 1027.1 294.79 1089.31L287.7 1068.91Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp26" d="M1645.24 1035.01L1650.86 1027.78L1444.88 874.31L1439.26 881.54L1645.24 1035.01Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1597.79 992.8C1579.26 932.11 1577.61 897.93 1592.83 890.25C1608.06 882.58 1616.5 921.78 1618.13 1007.88L1597.79 992.8Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1557.56 963.28C1539.03 902.59 1537.38 868.41 1552.61 860.73C1567.84 853.05 1576.27 892.26 1577.91 978.35L1557.56 963.28Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1519.72 936.89C1501.2 876.21 1499.54 842.02 1514.77 834.35C1530 826.67 1538.44 865.88 1540.07 951.97L1519.72 936.89Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1478.75 905.82C1460.22 845.13 1458.57 810.95 1473.8 803.27C1489.03 795.6 1497.46 834.8 1499.1 920.9L1478.75 905.82Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1438.75 876.71C1420.22 816.03 1418.57 781.84 1433.8 774.17C1449.03 766.49 1457.46 805.7 1459.1 891.79L1438.75 876.71Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1447.91 869.92C1396.63 815.12 1366.17 794.18 1356.51 807.11C1349.04 817.12 1375.96 844.23 1437.27 888.46L1447.91 869.92Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1597.49 992.21C1532.79 991.06 1498.71 998.69 1495.25 1015.11C1491.79 1031.54 1532.64 1028.94 1617.79 1007.34L1597.49 992.21Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1557.26 962.69C1492.56 961.54 1458.48 969.17 1455.02 985.59C1451.56 1002.01 1492.41 999.42 1577.57 977.82L1557.26 962.69Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1519.43 936.3C1454.73 935.15 1420.65 942.79 1417.19 959.21C1413.73 975.63 1454.57 973.04 1539.73 951.43L1519.43 936.3Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1478.45 905.23C1413.75 904.08 1379.67 911.71 1376.21 928.14C1372.75 944.56 1413.6 941.96 1498.76 920.36L1478.45 905.23Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1438.45 876.12C1373.75 874.97 1339.67 882.61 1336.21 899.03C1332.75 915.45 1373.6 912.86 1458.76 891.25L1438.45 876.12Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp26" d="M1575.83 1225.15L1581.57 1219.99L1433.44 1061.98L1427.7 1067.15L1575.83 1225.15Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1542.72 1183.06C1536.37 1129.94 1540.08 1101.32 1553.86 1097.22C1567.64 1093.11 1568.81 1126.9 1557.36 1198.6L1542.72 1183.06Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1513.72 1152.58C1507.37 1099.45 1511.09 1070.84 1524.87 1066.73C1538.65 1062.63 1539.82 1096.42 1528.37 1168.11L1513.72 1152.58Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1486.25 1125.05C1479.9 1071.93 1483.61 1043.31 1497.39 1039.21C1511.17 1035.1 1512.34 1068.89 1500.89 1140.59L1486.25 1125.05Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1456.86 1093.17C1450.51 1040.05 1454.22 1011.43 1468.01 1007.32C1481.79 1003.22 1482.95 1037.01 1471.51 1108.71L1456.86 1093.17Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1428 1063.06C1421.65 1009.94 1425.36 981.33 1439.14 977.22C1452.92 973.11 1454.09 1006.91 1442.64 1078.6L1428 1063.06Z" />
                    <path id="&lt;Path&gt;" class="shp26" d="M1436.61 1058.78C1402.2 1005.68 1380.03 983.77 1370.1 993.07C1362.41 1000.26 1380.72 1026.77 1425.02 1072.59L1436.61 1058.78Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1542.56 1182.53C1489.03 1171.95 1459.61 1173.22 1454.3 1186.33C1448.99 1199.44 1483.27 1203.37 1557.16 1198.1L1542.56 1182.53Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1513.56 1152.04C1460.04 1141.46 1430.62 1142.73 1425.3 1155.84C1419.99 1168.96 1454.28 1172.88 1528.17 1167.62L1513.56 1152.04Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1486.09 1124.52C1432.56 1113.94 1403.14 1115.21 1397.83 1128.32C1392.51 1141.43 1426.8 1145.36 1500.69 1140.09L1486.09 1124.52Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1456.7 1092.63C1403.18 1082.05 1373.76 1083.32 1368.44 1096.44C1363.13 1109.55 1397.42 1113.48 1471.3 1108.21L1456.7 1092.63Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M1427.84 1062.53C1374.31 1051.95 1344.89 1053.22 1339.58 1066.33C1334.26 1079.45 1368.55 1083.37 1442.44 1078.1L1427.84 1062.53Z" />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp28" d="M112.02 1111.15C101.59 1157.9 99.51 1216.9 105.8 1288.16" />
                  <path id="&lt;Path&gt;" class="shp2" d="M129.83 1109.09L123.14 1106.41L125.83 1099.72C127.59 1095.34 125.46 1090.36 121.08 1088.6C116.7 1086.84 111.72 1088.97 109.96 1093.35L107.28 1100.03L100.59 1097.35C96.21 1095.59 91.23 1097.72 89.48 1102.1C87.72 1106.48 89.84 1111.45 94.22 1113.21L100.91 1115.9L98.22 1122.58C96.46 1126.97 98.59 1131.94 102.97 1133.7C107.35 1135.46 112.33 1133.34 114.09 1128.96L116.77 1122.27L123.46 1124.96C127.84 1126.72 132.81 1124.59 134.57 1120.21C136.33 1115.83 134.21 1110.85 129.83 1109.09Z" />
                  <path id="&lt;Path&gt;" class="shp29" d="M118.25 1111.15C118.25 1114.59 115.46 1117.38 112.02 1117.38C108.59 1117.38 105.8 1114.59 105.8 1111.15C105.8 1107.71 108.59 1104.93 112.02 1104.93C115.46 1104.93 118.25 1107.71 118.25 1111.15Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp22" d="M1697.78 158.18C1672.48 46.28 1559.32 -21.66 1445.03 6.43C1386.49 20.82 1339.01 57.62 1310.02 105.12C1302.45 94.43 1292.78 85.26 1283.84 90.22C1269.71 98.07 1285.97 124.89 1294.12 136.72C1293.38 138.54 1292.67 140.37 1291.98 142.21C1277.62 142.15 1243.16 144.35 1239.43 166.71C1235.17 192.32 1269.56 198.88 1279.36 200.22C1279.34 200.5 1279.32 200.77 1279.3 201.04C1271.26 202.8 1247.79 209.37 1252.92 225.62C1256.36 236.51 1269.18 235.72 1279.66 233C1280.45 241.85 1281.83 250.73 1283.82 259.61C1269.12 257.02 1250.18 256.32 1247.25 268.92C1242.39 289.79 1293.45 292.38 1294.16 292.41C1294.17 292.42 1294.17 292.43 1294.17 292.44C1293.68 292.94 1255.46 332 1272.5 354.36C1284.53 370.16 1302.03 366.62 1324.99 343.76C1312.04 364.48 1311.6 377.57 1323.66 383.04C1333.97 387.7 1342.06 376.63 1347.05 366.47C1390.74 404.92 1450.69 424.3 1512.78 416.14C1508.46 430.92 1505.48 450.04 1516.95 454.69C1536.25 462.51 1545.46 409.41 1545.49 409.28C1545.49 409.28 1545.49 409.28 1545.49 409.28C1545.57 409.38 1577.87 454.61 1601.51 439.88C1623.78 426.01 1603.74 392.5 1599.23 385.54C1599.36 385.46 1599.49 385.38 1599.62 385.3C1605.05 389.77 1626.66 406.14 1635.53 390.79C1640.74 381.78 1633.64 372.94 1625.62 366.52C1685.11 316.52 1715.59 236.96 1697.78 158.18Z" />
                <path id="&lt;Path&gt;" class="shp30" d="M1582.92 370.49C1582.92 370.49 1582.92 370.49 1582.92 370.49C1582.9 370.63 1573.69 423.72 1554.38 415.9C1542.91 411.26 1545.89 392.14 1550.22 377.35C1488.12 385.52 1421.11 377.15 1377.42 338.69C1373.41 345.51 1367.97 347.36 1361.1 344.25C1349.04 338.79 1349.48 325.7 1362.42 304.97C1339.47 327.84 1321.97 331.38 1309.93 315.58C1292.9 293.21 1331.12 254.15 1331.61 253.65C1331.6 253.64 1331.6 253.64 1331.6 253.63C1330.89 253.59 1279.83 251 1284.68 230.13C1287.62 217.53 1306.56 218.24 1321.25 220.82C1319.26 211.95 1317.88 203.06 1317.09 194.21C1306.61 196.94 1293.79 197.73 1290.36 186.83C1285.23 170.58 1308.69 164.01 1316.73 162.26C1316.75 161.99 1316.77 161.71 1316.79 161.44C1309.26 160.41 1287.18 156.27 1279.33 142.77C1263.24 144.27 1242.29 149.55 1239.43 166.71C1235.17 192.32 1269.56 198.88 1279.36 200.22C1279.34 200.5 1279.32 200.77 1279.3 201.04C1271.26 202.8 1247.79 209.37 1252.92 225.62C1256.36 236.51 1269.18 235.72 1279.66 233C1280.45 241.85 1281.83 250.73 1283.82 259.61C1269.12 257.02 1250.18 256.32 1247.25 268.92C1242.39 289.79 1293.45 292.38 1294.16 292.41C1294.17 292.42 1294.17 292.43 1294.17 292.44C1293.68 292.94 1255.46 332 1272.5 354.36C1284.53 370.16 1302.03 366.62 1324.99 343.76C1312.04 364.48 1311.6 377.57 1323.66 383.04C1333.97 387.7 1342.06 376.63 1347.05 366.47C1390.74 404.92 1450.69 424.3 1512.78 416.14C1508.46 430.92 1505.48 450.04 1516.95 454.69C1536.25 462.51 1545.46 409.41 1545.49 409.28C1545.49 409.28 1545.49 409.28 1545.49 409.28C1545.57 409.38 1577.87 454.61 1601.51 439.88C1619.45 428.7 1609.93 404.79 1603.2 392.28C1591.6 382.63 1582.96 370.55 1582.92 370.49Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M1387.11 166.21C1351.45 211.05 1345.19 254.54 1368.35 296.68L1399.07 168.32L1387.11 166.21Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M1411.03 170.43C1423.22 223.7 1408.99 265.79 1368.35 296.68L1399.07 168.32L1411.03 170.43Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M1577.86 266.11C1554.51 323.53 1516.53 356.07 1463.91 363.74L1569.38 257.67L1577.86 266.11Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M1560.9 249.23C1503.64 272.88 1471.31 311.05 1463.91 363.74L1569.38 257.67L1560.9 249.23Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp31" d="M1536.46 174.77C1517.13 222.28 1485.68 249.21 1442.11 255.55L1529.44 167.79L1536.46 174.77Z" />
                  <path id="&lt;Path&gt;" class="shp25" d="M1522.42 160.8C1475.01 180.37 1448.24 211.95 1442.11 255.55L1529.44 167.79L1522.42 160.8Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M1343.53 1200.63C1377.79 1154.42 1385.26 1108.6 1365.96 1063.17L1332.74 1197.97L1343.53 1200.63Z" />
                <path id="&lt;Path&gt;" class="shp25" d="M1321.96 1195.32C1313.09 1138.48 1327.75 1094.43 1365.96 1063.17L1332.74 1197.97L1321.96 1195.32Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M1359.97 1215.3C1397.52 1192.79 1416.13 1162.74 1415.79 1125.17L1353.16 1210.29L1359.97 1215.3Z" />
                <path id="&lt;Path&gt;" class="shp25" d="M1346.35 1205.28C1356.67 1162.73 1379.82 1136.02 1415.79 1125.17L1353.16 1210.29L1346.35 1205.28Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M542.06 1237.32C512.74 1165.98 518.11 1103.96 558.16 1051.26L556.95 1237.41L542.06 1237.32Z" />
                <path id="&lt;Path&gt;" class="shp25" d="M571.84 1237.51C602.09 1166.56 597.53 1104.48 558.16 1051.26L556.95 1237.41L571.84 1237.51Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp24" d="M539.33 1239.86C490.02 1201.81 468.73 1156.27 475.46 1103.24L549.78 1233.92L539.33 1239.86Z" />
                <path id="&lt;Path&gt;" class="shp25" d="M560.23 1227.97C552.74 1166.14 524.48 1124.57 475.46 1103.24L549.78 1233.92L560.23 1227.97Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp26" d="M1137.94 1226.36L1143.87 1228.84L1210 1061.04L1204.07 1058.56L1137.94 1226.36Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M1157.82 1186.44C1195.54 1162.72 1218.4 1155.32 1226.4 1164.23C1234.4 1173.14 1209.38 1186.06 1151.34 1203L1157.82 1186.44Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M1170.43 1153.75C1208.15 1130.04 1231.01 1122.63 1239.01 1131.54C1247.01 1140.45 1221.99 1153.37 1163.95 1170.31L1170.43 1153.75Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M1181.35 1123.26C1219.07 1099.55 1241.93 1092.15 1249.93 1101.05C1257.94 1109.96 1232.92 1122.88 1174.88 1139.82L1181.35 1123.26Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M1194.88 1089.78C1232.59 1066.07 1255.45 1058.66 1263.46 1067.57C1271.46 1076.48 1246.44 1089.4 1188.4 1106.34L1194.88 1089.78Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M1207.25 1057.33C1244.97 1033.61 1267.83 1026.21 1275.83 1035.12C1283.83 1044.03 1258.81 1056.95 1200.77 1073.89L1207.25 1057.33Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M1213.54 1062.28C1241.25 1017.46 1249.83 992.97 1239.29 988.8C1231.14 985.58 1217.71 1008.81 1199.02 1058.48L1213.54 1062.28Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M1158.17 1186.13C1147.05 1142.07 1135.61 1120.38 1123.85 1121.05C1112.08 1121.73 1121.35 1148.93 1151.65 1202.67L1158.17 1186.13Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M1170.78 1153.44C1159.66 1109.38 1148.22 1087.69 1136.46 1088.37C1124.69 1089.04 1133.96 1116.24 1164.26 1169.98L1170.78 1153.44Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M1181.7 1122.95C1170.59 1078.89 1159.15 1057.2 1147.38 1057.88C1135.62 1058.55 1144.88 1085.76 1175.18 1139.49L1181.7 1122.95Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M1195.22 1089.47C1184.11 1045.41 1172.67 1023.72 1160.9 1024.4C1149.14 1025.07 1158.41 1052.28 1188.7 1106.01L1195.22 1089.47Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M1207.59 1057.01C1196.48 1012.96 1185.04 991.27 1173.28 991.94C1161.51 992.62 1170.78 1019.82 1201.07 1073.56L1207.59 1057.01Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp26" d="M41.42 1186.82L33.91 1183.55L127.55 968.89L135.05 972.17L41.42 1186.82Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M60.02 1131.88C48.11 1075.08 34.56 1047 19.39 1047.65C4.21 1048.3 14.66 1083.42 50.73 1153.02L60.02 1131.88Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M78.5 1090.32C66.59 1033.51 53.05 1005.44 37.87 1006.09C22.7 1006.74 33.14 1041.86 69.21 1111.46L78.5 1090.32Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M96.48 1052.34C84.57 995.53 71.03 967.45 55.85 968.1C40.68 968.76 51.13 1003.88 87.19 1073.48L96.48 1052.34Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M114.87 1009.21C102.96 952.4 89.42 924.32 74.24 924.97C59.07 925.62 69.52 960.75 105.59 1030.34L114.87 1009.21Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M133.36 968.08C121.44 911.27 107.9 883.19 92.73 883.84C77.55 884.5 88 919.62 124.07 989.22L133.36 968.08Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M123.29 966.73C139.4 900.02 154.01 869.69 167.13 875.72C177.28 880.39 168.1 914.09 139.6 976.82L123.29 966.73Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M59.99 1131.28C109.82 1101.5 139.63 1092.4 149.45 1103.99C159.26 1115.58 126.37 1131.73 50.76 1152.44L59.99 1131.28Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M78.48 1089.72C128.3 1059.93 158.12 1050.84 167.93 1062.43C177.74 1074.02 144.85 1090.17 69.25 1110.88L78.48 1089.72Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M96.46 1051.73C146.28 1021.95 176.1 1012.86 185.91 1024.45C195.72 1036.04 162.83 1052.19 87.23 1072.9L96.46 1051.73Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M114.85 1008.6C164.67 978.82 194.49 969.72 204.3 981.31C214.12 992.9 181.22 1009.05 105.62 1029.76L114.85 1008.6Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M133.33 967.47C183.15 937.69 212.97 928.6 222.79 940.19C232.6 951.78 199.7 967.93 124.1 988.64L133.33 967.47Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp26" d="M6.07 928.34L0.05 923.41L141.05 751.31L147.07 756.24L6.07 928.34Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M37.25 882.91C41.02 827.9 35.88 798.73 21.84 795.41C7.8 792.09 8.29 826.9 23.31 899.84L37.25 882.91Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M64.87 849.68C68.64 794.67 63.5 765.5 49.46 762.18C35.42 758.86 35.91 793.67 50.93 866.61L64.87 849.68Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M91.11 819.59C94.88 764.58 89.74 735.41 75.7 732.09C61.66 728.77 62.15 763.58 77.17 836.52L91.11 819.59Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M119.06 784.9C122.83 729.88 117.69 700.71 103.64 697.4C89.6 694.08 90.09 728.89 105.11 801.83L119.06 784.9Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M146.57 752.06C150.33 697.05 145.2 667.88 131.15 664.56C117.11 661.24 117.6 696.05 132.62 768.99L146.57 752.06Z" />
                <path id="&lt;Path&gt;" class="shp26" d="M137.71 748.23C169.65 691.39 190.84 667.43 201.28 676.33C209.35 683.22 192.27 711.66 150.02 761.66L137.71 748.23Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M37.39 882.35C90.62 867.97 120.23 867.35 126.22 880.48C132.2 893.61 97.96 899.89 23.49 899.32L37.39 882.35Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M65.01 849.12C118.25 834.74 147.86 834.12 153.84 847.25C159.82 860.38 125.58 866.66 51.11 866.09L65.01 849.12Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M91.25 819.03C144.49 804.65 174.1 804.03 180.08 817.16C186.06 830.29 151.82 836.57 77.35 836L91.25 819.03Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M119.19 784.34C172.43 769.96 202.04 769.34 208.02 782.47C214.01 795.6 179.76 801.88 105.29 801.3L119.19 784.34Z" />
                <path id="&lt;Path&gt;" class="shp27" d="M146.7 751.5C199.94 737.13 229.55 736.5 235.53 749.63C241.51 762.76 207.27 769.04 132.8 768.47L146.7 751.5Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

    <!--  Flowers' Kingdom  -->
    <!--  Yellow Flowers  -->
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 47" width="47" height="47">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M41.15 21.05L34.47 18.36L37.15 11.68C38.91 7.3 36.79 2.32 32.41 0.56C28.03 -1.2 23.05 0.92 21.29 5.31L18.6 11.99L11.92 9.31C7.54 7.55 2.56 9.67 0.8 14.05C-0.96 18.43 1.17 23.41 5.55 25.17L12.23 27.85L9.55 34.54C7.79 38.92 9.91 43.9 14.29 45.66C18.67 47.42 23.65 45.29 25.41 40.91L28.1 34.23L34.78 36.91C39.16 38.67 44.14 36.55 45.9 32.17C47.66 27.78 45.54 22.81 41.15 21.05Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M28.53 23.11C28.53 25.97 26.21 28.29 23.35 28.29C20.49 28.29 18.17 25.97 18.17 23.11C18.17 20.25 20.49 17.93 23.35 17.93C26.21 17.93 28.53 20.25 28.53 23.11Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" width="43" height="43">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M32.44 32.91L30.64 26.8L36.75 25C40.75 23.82 43.04 19.62 41.86 15.62C40.68 11.62 36.48 9.33 32.48 10.51L26.37 12.31L24.57 6.2C23.39 2.2 19.19 -0.09 15.19 1.09C11.18 2.27 8.9 6.47 10.08 10.47L11.88 16.58L5.77 18.38C1.77 19.56 -0.52 23.76 0.66 27.77C1.84 31.77 6.04 34.06 10.04 32.88L16.15 31.08L17.95 37.18C19.13 41.19 23.33 43.47 27.33 42.29C31.34 41.11 33.62 36.91 32.44 32.91Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M27.58 21.69C27.58 25.18 24.75 28.01 21.26 28.01C17.77 28.01 14.94 25.18 14.94 21.69C14.94 18.2 17.77 15.37 21.26 15.37C24.75 15.37 27.58 18.2 27.58 21.69Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M33.03 28.61L30.32 22.99L35.94 20.28C39.62 18.51 41.16 14.09 39.39 10.41C37.61 6.73 33.19 5.18 29.51 6.96L23.89 9.67L21.18 4.05C19.41 0.37 14.99 -1.18 11.31 0.6C7.63 2.37 6.08 6.8 7.86 10.48L10.57 16.09L4.95 18.8C1.27 20.58 -0.27 25 1.5 28.68C3.28 32.36 7.7 33.9 11.38 32.13L16.99 29.42L19.7 35.04C21.48 38.72 25.9 40.26 29.58 38.49C33.26 36.71 34.81 32.29 33.03 28.61Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M25.42 19.93C25.2 22.68 22.8 24.73 20.06 24.52C17.31 24.3 15.26 21.9 15.47 19.15C15.69 16.41 18.09 14.36 20.83 14.57C23.58 14.78 25.63 17.18 25.42 19.93Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 46" width="47" height="46">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M41.4 22.54L34.94 19.34L38.14 12.88C40.23 8.65 38.5 3.52 34.27 1.43C30.04 -0.67 24.91 1.07 22.82 5.3L19.62 11.75L13.16 8.55C8.93 6.46 3.81 8.19 1.71 12.42C-0.38 16.65 1.35 21.78 5.58 23.88L12.03 27.07L8.84 33.53C6.74 37.76 8.47 42.89 12.7 44.98C16.93 47.08 22.06 45.35 24.16 41.12L27.35 34.66L33.81 37.86C38.04 39.95 43.17 38.22 45.26 33.99C47.36 29.76 45.63 24.63 41.4 22.54Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M29.7 23.69C29.43 27.12 26.43 29.68 23 29.41C19.57 29.15 17.01 26.15 17.28 22.72C17.55 19.29 20.54 16.73 23.97 17C27.4 17.27 29.96 20.26 29.7 23.69Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 42" width="41" height="42">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M31.96 31.42L29.93 25.53L35.83 23.5C39.69 22.17 41.75 17.96 40.42 14.1C39.09 10.24 34.89 8.18 31.02 9.51L25.12 11.53L23.1 5.64C21.77 1.77 17.56 -0.28 13.7 1.04C9.83 2.37 7.78 6.58 9.1 10.44L11.13 16.34L5.23 18.37C1.37 19.69 -0.69 23.9 0.64 27.77C1.97 31.63 6.18 33.69 10.04 32.36L15.94 30.33L17.96 36.23C19.29 40.09 23.5 42.15 27.36 40.82C31.23 39.5 33.28 35.29 31.96 31.42Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M25.42 21.91C24.88 24.61 22.26 26.36 19.56 25.82C16.86 25.29 15.1 22.66 15.64 19.96C16.18 17.26 18.8 15.5 21.5 16.04C24.21 16.58 25.96 19.2 25.42 21.91Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 43" width="42" height="43">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M32.1 32.47L30.3 26.36L36.4 24.56C40.41 23.38 42.69 19.18 41.51 15.18C40.33 11.17 36.13 8.89 32.13 10.07L26.02 11.87L24.22 5.76C23.04 1.76 18.84 -0.53 14.84 0.65C10.84 1.83 8.55 6.03 9.73 10.03L11.53 16.14L5.42 17.94C1.42 19.12 -0.87 23.32 0.31 27.33C1.49 31.33 5.69 33.62 9.69 32.44L15.8 30.63L17.6 36.74C18.78 40.75 22.98 43.03 26.99 41.85C30.99 40.67 33.28 36.47 32.1 32.47Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M26.38 21.25C26.38 24.27 23.93 26.72 20.91 26.72C17.89 26.72 15.44 24.27 15.44 21.25C15.44 18.23 17.89 15.78 20.91 15.78C23.93 15.78 26.38 18.23 26.38 21.25Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 47" width="47" height="47">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M41.56 21.48L34.87 18.79L37.56 12.11C39.31 7.73 37.19 2.75 32.81 0.99C28.43 -0.77 23.45 1.35 21.69 5.74L19.01 12.42L12.32 9.74C7.94 7.98 2.96 10.1 1.2 14.48C-0.56 18.86 1.57 23.84 5.95 25.6L12.63 28.29L9.95 34.97C8.19 39.35 10.31 44.33 14.7 46.09C19.08 47.85 24.05 45.72 25.81 41.34L28.5 34.66L35.18 37.34C39.56 39.1 44.54 36.98 46.3 32.6C48.06 28.21 45.94 23.24 41.56 21.48Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M28.73 23.54C28.73 26.29 26.5 28.52 23.75 28.52C21 28.52 18.77 26.29 18.77 23.54C18.77 20.79 21 18.56 23.75 18.56C26.5 18.56 28.73 20.79 28.73 23.54Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="yellow-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 46" width="47" height="46">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp-petal" d="M41.4 22.54L34.94 19.34L38.14 12.88C40.23 8.65 38.5 3.52 34.27 1.43C30.04 -0.67 24.91 1.07 22.82 5.3L19.62 11.75L13.16 8.55C8.93 6.46 3.81 8.19 1.71 12.42C-0.38 16.65 1.35 21.78 5.58 23.88L12.03 27.07L8.84 33.53C6.74 37.76 8.47 42.89 12.7 44.98C16.93 47.08 22.06 45.35 24.16 41.12L27.35 34.66L33.81 37.86C38.04 39.95 43.17 38.22 45.26 33.99C47.36 29.76 45.63 24.63 41.4 22.54Z" />
                  <path id="&lt;Path&gt;" class="shp-pistil" d="M29.7 23.69C29.43 27.12 26.43 29.68 23 29.41C19.57 29.15 17.01 26.15 17.28 22.72C17.55 19.29 20.54 16.73 23.97 17C27.4 17.27 29.96 20.26 29.7 23.69Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

    <!--  Blue Flowers  -->
    <svg class="blue-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 98" width="100" height="98">
      <title>BlueFlower</title>
      <g id="BlueFlower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-blue" d="M73.71 50.55C81.39 49.11 92.48 50.94 97.6 45.42C103.43 39.12 93.8 30.28 81 35.32C93.8 30.28 94.83 17.25 86.27 16.62C77.71 15.99 69.98 27.65 62.51 30.59C62.49 30.59 62.47 30.6 62.44 30.61C62.71 22.61 70.66 11.41 67.07 3.71C63.45 -4.08 51.63 1.5 51.45 15.26C51.63 1.5 39.95 -4.38 36.13 3.31C32.61 10.4 39 20.77 39.95 28.63C33.18 24.36 27.4 12.08 18.98 11.4C10.42 10.7 9.43 23.74 21.3 30.69C9.43 23.74 -1.45 30.98 3.34 38.1C7.54 44.34 18.75 44.25 26.12 46.85C18.44 48.29 7.35 46.46 2.23 51.99C-3.6 58.28 6.03 67.12 18.83 62.08C6.03 67.12 5 80.15 13.56 80.78C22.13 81.42 29.85 69.75 37.32 66.82C37.34 66.81 37.36 66.8 37.39 66.79C37.12 74.8 29.17 85.99 32.76 93.69C36.38 101.48 48.2 95.9 48.38 82.15C48.2 95.9 59.88 101.78 63.7 94.09C67.22 87.01 60.83 76.64 59.88 68.77C66.65 73.04 72.43 85.32 80.85 86.01C89.41 86.7 90.4 73.67 78.53 66.72C90.4 73.67 101.28 66.43 96.49 59.3C92.29 53.06 81.08 53.15 73.71 50.55Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M50.95 19.67L47.72 76.97" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M75.83 37.22L22.87 61.13" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M25.03 33.28L73.67 65.08" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M61.07 49.57C60.59 55.73 55.21 60.33 49.05 59.85C42.89 59.38 38.28 54 38.76 47.84C39.24 41.68 44.62 37.07 50.78 37.55C56.94 38.03 61.55 43.41 61.07 49.57Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="blue-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 113" width="112" height="113">
      <title>BlueFlower</title>
      <g id="BlueFlower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-blue" d="M91.65 57.57C110.53 49.72 112.46 44.48 111.1 40.67C110.16 38.05 102.32 39.22 94.19 41.55C101.45 37.21 107.67 32.29 106.39 29.81C104.47 26.07 98.97 23.9 78.51 33.5C78.62 33.12 78.74 32.75 78.86 32.35C85.37 9.66 82.25 4.5 78.18 3.19C75.53 2.35 71.7 9.29 68.62 17.16C69.58 8.76 69.44 0.83 66.7 0.34C63 -0.32 58.56 2.01 54.24 18.28C48.2 1.78 43.46 -0.19 39.78 0.85C37.1 1.6 37.74 9.51 39.53 17.77C35.68 10.24 31.18 3.71 28.63 4.82C24.81 6.47 22.26 11.7 30.21 32.29C11.88 24.07 6.8 26.24 4.95 29.81C3.68 32.29 9.89 37.21 17.15 41.55C9.02 39.22 1.18 38.05 0.25 40.67C-1.11 44.48 0.82 49.72 19.7 57.57C1.36 65.7 -0.42 70.92 1 74.67C1.99 77.28 9.81 75.95 17.89 73.45C10.72 77.94 4.61 82.99 5.93 85.44C7.79 88.87 12.7 90.89 29.61 83.14C21.02 99.57 22.77 104.57 26.1 106.61C28.47 108.06 33.84 102.22 38.7 95.3C35.78 103.24 34.04 110.98 36.58 112.1C40.27 113.73 45.6 112.21 54.74 94.19C61.75 112.07 66.77 114.03 70.53 112.81C73.18 111.96 72.25 104.08 70.16 95.89C74.28 103.27 79.02 109.63 81.53 108.43C85.22 106.67 87.54 101.46 79.34 82.02C98.19 91.08 103.47 89.03 105.42 85.44C106.74 82.99 100.63 77.94 93.46 73.45C101.54 75.95 109.36 77.28 110.34 74.67C111.77 70.92 109.99 65.7 91.65 57.57Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M38.25 23.06L74.49 89.72" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M46.81 23.06L62.82 96.71" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M35.24 86.09L74.49 23.06" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M62.82 15.44L46.81 93.79" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M23.09 74.96L88.68 37.74" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M20.93 66.69L93.67 47.52" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M15.49 47.52L95.45 66.69" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M26.43 37.74L90.41 80.22" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M73.1 56.67C73.1 66.3 65.3 74.1 55.67 74.1C46.05 74.1 38.25 66.3 38.25 56.67C38.25 47.05 46.05 39.25 55.67 39.25C65.3 39.25 73.1 47.05 73.1 56.67Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="blue-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83 83" width="83" height="83">
      <title>BlueFlower</title>
      <g id="BlueFlower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-blue" d="M61.72 42.7C68.15 41.26 77.52 42.49 81.66 37.7C86.39 32.23 78.03 25.08 67.42 29.68C78.03 25.08 78.53 14.1 71.31 13.81C64.09 13.52 57.93 23.55 51.74 26.23C51.72 26.24 51.7 26.25 51.68 26.26C51.68 19.52 58.04 9.88 54.8 3.51C51.53 -2.93 41.75 2.1 42 13.66C41.75 2.1 31.77 -2.51 28.77 4.06C26.01 10.12 31.69 18.65 32.71 25.24C26.9 21.84 21.68 11.69 14.59 11.35C7.37 11.02 6.91 22 17.09 27.51C6.91 22 -2.03 28.4 2.21 34.26C5.92 39.38 15.34 38.98 21.61 40.96C15.19 42.39 5.81 41.17 1.67 45.96C-3.05 51.43 5.3 58.58 15.92 53.97C5.3 58.58 4.81 69.56 12.03 69.85C19.24 70.13 25.41 60.11 31.6 57.42C31.62 57.41 31.64 57.4 31.65 57.4C31.66 64.14 25.3 73.78 28.54 80.15C31.81 86.59 41.59 81.56 41.34 69.99C41.59 81.56 51.57 86.17 54.56 79.6C57.32 73.54 51.65 65 50.62 58.42C56.44 61.81 61.66 71.97 68.75 72.3C75.96 72.64 76.42 61.65 66.24 56.15C76.42 61.65 85.36 55.25 81.13 49.4C77.42 44.28 68 44.68 61.72 42.7Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M41.71 17.39L40.64 65.66" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M63.12 31.43L19.28 53.06" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M20.3 29.58L62.11 54.91" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M51.07 42.24C50.85 47.43 46.45 51.46 41.26 51.23C36.07 51.01 32.04 46.61 32.27 41.42C32.49 36.23 36.88 32.2 42.07 32.43C47.27 32.65 51.29 37.04 51.07 42.24Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="blue-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 96" width="95" height="96">
      <title>BlueFlower</title>
      <g id="BlueFlower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-blue" d="M77.59 48.48C93.59 41.83 95.22 37.39 94.07 34.16C93.28 31.94 86.63 32.93 79.75 34.91C85.9 31.23 91.16 27.06 90.08 24.96C88.45 21.8 83.8 19.95 66.46 28.09C66.56 27.77 66.66 27.45 66.75 27.12C72.27 7.89 69.63 3.52 66.19 2.41C63.94 1.69 60.69 7.57 58.08 14.25C58.89 7.13 58.77 0.41 56.45 0C53.32 -0.56 49.56 1.41 45.9 15.19C40.78 1.21 36.76 -0.46 33.65 0.42C31.38 1.06 31.92 7.76 33.43 14.76C30.18 8.38 26.37 2.85 24.2 3.79C20.96 5.19 18.8 9.62 25.54 27.06C10.01 20.1 5.7 21.93 4.15 24.96C3.07 27.06 8.33 31.23 14.48 34.91C7.59 32.93 0.95 31.94 0.16 34.16C-1 37.39 0.64 41.83 16.64 48.48C1.1 55.36 -0.41 59.79 0.8 62.97C1.63 65.18 8.26 64.05 15.1 61.94C9.03 65.74 3.85 70.02 4.97 72.09C6.54 75 10.71 76.71 25.04 70.14C17.75 84.06 19.24 88.3 22.06 90.02C24.07 91.26 28.62 86.31 32.73 80.45C30.26 87.17 28.78 93.73 30.94 94.68C34.06 96.06 38.58 94.78 46.32 79.5C52.26 94.65 56.52 96.31 59.7 95.28C61.95 94.56 61.16 87.89 59.39 80.94C62.88 87.2 66.89 92.59 69.02 91.57C72.15 90.07 74.11 85.67 67.16 69.19C83.14 76.87 87.61 75.13 89.26 72.09C90.38 70.02 85.2 65.74 79.13 61.94C85.97 64.05 92.59 65.18 93.43 62.97C94.64 59.79 93.13 55.36 77.59 48.48Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M29.61 14.16L64.41 73.2" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M39.7 11.78L56.1 75.88" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M28.86 71.15L62.32 13.02" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M51.55 6.51L41.56 75.74" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M21.55 61.48L75.6 25.83" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M15.34 54.09L80.4 34.5" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M9.83 36.95L82.62 51.75" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M19.4 27.78L78.49 64.12" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M61.88 43.72C61.88 51.87 55.27 58.48 47.11 58.48C38.96 58.48 32.35 51.87 32.35 43.72C32.35 35.57 38.96 28.96 47.11 28.96C55.27 28.96 61.88 35.57 61.88 43.72Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="blue-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 88" width="85" height="88">
      <title>BlueFlower</title>
      <g id="BlueFlower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-blue" d="M15.71 42.42C2.29 35.23 1.2 31.1 2.45 28.26C3.31 26.31 9.02 27.69 14.87 29.99C9.8 26.21 5.53 22.05 6.63 20.23C8.29 17.48 12.48 16.16 26.96 24.8C26.9 24.5 26.84 24.2 26.78 23.89C23.44 6.09 26.07 2.33 29.16 1.58C31.17 1.1 33.55 6.66 35.31 12.89C35.15 6.39 35.77 0.32 37.82 0.11C40.59 -0.16 43.72 1.9 45.85 14.63C51.37 2.36 55 1.15 57.65 2.17C59.57 2.92 58.59 8.94 56.74 15.16C60.06 9.63 63.8 4.9 65.62 5.91C68.33 7.42 69.87 11.58 62.67 26.87C76.73 21.71 80.34 23.69 81.46 26.54C82.24 28.52 77.34 31.91 71.71 34.78C77.85 33.5 83.72 33.1 84.24 35.16C84.99 38.17 83.23 42.06 68.79 46.9C81.8 54.28 82.77 58.39 81.48 61.18C80.58 63.12 74.9 61.61 69.1 59.2C74.1 63.08 78.28 67.33 77.15 69.13C75.55 71.64 71.8 72.88 59.82 65.89C65.1 79.02 63.48 82.75 60.9 84.1C59.05 85.07 55.47 80.26 52.33 74.65C53.97 80.91 54.76 86.95 52.81 87.66C49.98 88.68 46.15 87.18 40.57 72.8C34.24 86.07 30.41 87.26 27.71 86.09C25.82 85.27 27.01 79.29 29.08 73.14C25.57 78.54 21.66 83.12 19.89 82.05C17.27 80.46 15.9 76.33 23.21 61.94C8.71 67.71 4.95 65.81 3.75 62.93C2.93 60.97 7.77 57.48 13.35 54.49C7.23 55.9 1.37 56.43 0.81 54.37C0 51.41 1.65 47.51 15.71 42.42Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M59.81 18.52L24.99 69.38" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M51.2 15.63L32.03 72.42" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M56.11 70.14L31.41 15.08" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M41.29 9.98L44.7 73.36" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M63.21 61.93L18.87 25.7" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M69.18 55.69L14.02 33.19" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M75.29 40.6L10.78 48.63" />
                      <path id="&lt;Path&gt;" class="shp-petal-blue-stroke" d="M67.66 31.59L13.43 60.14" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M29.45 42.9C28.83 50.27 34.08 56.74 41.18 57.34C48.28 57.94 54.54 52.44 55.16 45.07C55.79 37.69 50.54 31.22 43.44 30.62C36.34 30.02 30.08 35.52 29.45 42.9Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

    <!--  Green Flowers  -->
    <svg class="green-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 103" width="104" height="103">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-green" d="M75.24 59.79C83.34 60.44 93.96 65.3 100.6 61.16C108.17 56.44 100.92 44.95 86.71 46.51C100.92 44.95 105.51 32.17 97.1 29.19C88.68 26.22 77.75 35.8 69.46 36.71C69.44 36.71 69.41 36.72 69.39 36.72C71.84 28.77 82.87 19.71 81.38 11.01C79.87 2.21 66.49 4.58 62.56 18.32C66.49 4.58 56.39 -4.5 50.46 2.16C44.99 8.3 48.57 20.45 47.38 28.59C41.75 22.46 39.31 8.57 31.06 5.59C22.67 2.55 18.11 15.35 28.12 25.56C18.11 15.35 5.22 19.64 8.09 28.09C10.59 35.49 21.86 38.46 28.54 43.08C20.44 42.43 9.82 37.57 3.18 41.71C-4.39 46.43 2.86 57.92 17.06 56.37C2.86 57.92 -1.74 70.71 6.68 73.68C15.09 76.65 26.02 67.07 34.31 66.16C34.34 66.16 34.36 66.16 34.39 66.15C31.93 74.11 20.9 83.16 22.4 91.87C23.91 100.66 37.28 98.29 41.22 84.55C37.28 98.29 47.38 107.38 53.32 100.71C58.78 94.57 55.2 82.43 56.4 74.28C62.02 80.41 64.47 94.31 72.72 97.29C81.11 100.32 85.66 87.52 75.66 77.31C85.66 87.52 98.55 83.23 95.69 74.78C93.18 67.38 81.92 64.41 75.24 59.79Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M60.86 22.61L41.97 79.19" />
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M81 47L21.37 56.52" />
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M31.14 29.18L71.23 74.34" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M62.83 55.35C60.67 61.4 54.02 64.54 47.97 62.38C41.93 60.22 38.78 53.57 40.94 47.52C43.1 41.48 49.75 38.33 55.8 40.49C61.85 42.65 65 49.3 62.83 55.35Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="green-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-green" d="M46.88 30.15C51.41 28.25 58.46 27.91 60.87 23.84C63.62 19.2 56.54 15.05 49.34 19.83C56.54 15.05 55.45 6.91 50.11 7.65C44.77 8.39 41.56 16.58 37.36 19.37C37.35 19.38 37.34 19.39 37.33 19.39C36.43 14.44 39.84 6.51 36.62 2.26C33.36 -2.04 26.84 2.94 28.55 11.41C26.84 2.94 18.89 0.87 17.56 6.1C16.33 10.92 21.63 16.44 23.25 21.14C18.53 19.42 13.35 12.64 8.1 13.33C2.75 14.03 3.86 22.17 12.07 24.87C3.86 22.17 -1.86 28.05 2.02 31.8C5.42 35.07 12.29 33.54 17.17 34.16C12.63 36.06 5.58 36.4 3.17 40.47C0.42 45.11 7.5 49.26 14.7 44.48C7.5 49.26 8.59 57.4 13.93 56.66C19.27 55.92 22.48 47.73 26.68 44.94C26.69 44.94 26.7 44.93 26.71 44.92C27.61 49.87 24.2 57.8 27.42 62.05C30.68 66.36 37.2 61.37 35.49 52.9C37.2 61.37 45.15 63.44 46.48 58.21C47.71 53.4 42.41 47.87 40.79 43.17C45.51 44.9 50.69 51.67 55.94 50.98C61.29 50.28 60.18 42.14 51.97 39.44C60.18 42.14 65.91 36.26 62.02 32.52C58.62 29.24 51.75 30.78 46.88 30.15Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M28.83 14.19L34.41 49.81" />
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M46.42 21.69L17.05 43.36" />
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M14.7 25.97L48.77 39.08" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M38.98 31.21C39.5 35.06 36.81 38.6 32.96 39.12C29.12 39.64 25.58 36.94 25.06 33.1C24.54 29.25 27.23 25.71 31.08 25.19C34.93 24.67 38.46 27.37 38.98 31.21Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <svg class="green-flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 79" width="78" height="79">
      <title>Flower</title>
      <g id="Flower">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Clip Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp-petal-green" d="M57.46 37.13C63.09 34.83 71.81 34.5 74.84 29.5C78.3 23.8 69.59 18.58 60.64 24.4C69.59 18.58 68.34 8.5 61.73 9.36C55.12 10.21 51.06 20.28 45.84 23.68C45.82 23.69 45.8 23.7 45.79 23.71C44.75 17.58 49.05 7.83 45.12 2.53C41.15 -2.83 33.02 3.26 35.03 13.75C33.02 3.26 23.23 0.6 21.52 7.05C19.94 12.98 26.42 19.88 28.37 25.71C22.55 23.52 16.24 15.08 9.73 15.87C3.11 16.67 4.39 26.74 14.5 30.18C4.39 26.74 -2.76 33.95 2 38.62C6.16 42.71 14.68 40.9 20.69 41.73C15.07 44.02 6.35 44.36 3.32 49.36C-0.14 55.06 8.56 60.28 17.52 54.45C8.56 60.28 9.81 70.35 16.42 69.5C23.04 68.64 27.1 58.57 32.32 55.17C32.34 55.16 32.35 55.15 32.37 55.14C33.41 61.27 29.11 71.03 33.04 76.33C37.01 81.68 45.13 75.6 43.12 65.11C45.13 75.6 54.93 78.25 56.64 71.81C58.22 65.87 51.73 58.98 49.79 53.14C55.6 55.34 61.92 63.77 68.42 62.98C75.04 62.18 73.76 52.11 63.65 48.67C73.76 52.11 80.91 44.9 76.16 40.23C71.99 36.14 63.48 37.96 57.46 37.13Z" />
                    <g id="&lt;Group&gt;">
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M35.34 17.18L41.82 61.28" />
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M57 26.66L20.44 53.1" />
                      <path id="&lt;Path&gt;" class="shp-petal-green-stroke" d="M17.74 31.58L59.7 48.18" />
                    </g>
                    <path id="&lt;Path&gt;" class="shp-pistil" d="M47.7 38.35C48.29 43.11 44.92 47.45 40.16 48.05C35.4 48.64 31.05 45.27 30.46 40.51C29.86 35.75 33.24 31.4 38 30.81C42.76 30.21 47.1 33.59 47.7 38.35Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

    <!--  Main content  -->
    <div class="text-box">
      Draw a picture of your peaceful, quiet place.
    </div>
    <drawing-canvas class="canvas" v-on:updateCanvas="updateCanvas" :data="canvasData" :canvasStyle="canvasStyle"/>
    <audio ref="audio" autoplay loop src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session1/530415__klankbeeld__forest-summer-roond-020-200619-0186.mp3"/>
    <audio
      @loadeddata="playSoundText"
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session4/Session4_Page11.mp3" ref="voice"/>
  </div>
</template>

<script>
import anime from "animejs";
import DrawingCanvas from "@/components/drawingCanvas/DrawingCanvas";

export default {
  name: "Session4Page11",
  components: {DrawingCanvas},
  data() {
    return {
      canvasStyle: {
        width: 0.98,
        height: 0.8,
        isPicture: false,
      },
      canvasData: null,
    }
  },
  methods: {
    animateSvg() {
      let vw = window.innerWidth;
      const yellowFlowers = document.getElementsByClassName('yellow-flower');
      yellowFlowers.forEach(flower => {
        flower.style.bottom = (Math.floor(Math.random() * 10) + 2).toString() + 'vh';
        flower.style.left = (Math.floor(Math.random() * 95) + 2).toString() + 'vw';
      })

      const blueFlowers = document.getElementsByClassName('blue-flower');
      blueFlowers.forEach(flower => {
        flower.style.bottom = (Math.floor(Math.random() * 10) + 7).toString() + 'vh';
        flower.style.left = Math.floor(Math.random() * 100).toString() + 'vw';
        flower.style.height = (Math.floor(Math.random() * 5) + 5).toString() + 'vh';
      })

      const greenFlowers = document.getElementsByClassName('green-flower');
      greenFlowers.forEach(flower => {
        flower.style.bottom = Math.floor(Math.random() * 15).toString() + 'vh';
        flower.style.left = Math.floor(Math.random() * 100).toString() + 'vw';
      })
      anime({
        targets: this.$refs.cloud1,
        translateX: vw,
        duration: 7000,
        loop: true,
        easing: 'linear',
        direction: 'alternate'
      });
      anime({
        targets: this.$refs.cloud2,
        translateX: -vw,
        duration: 6000,
        loop: true,
        easing: 'linear',
        direction: 'alternate'
      })
      anime({
        targets: ".yellow-flower",
        scale: 13,
        duration: 2000,
        delay: 1000,
        easing: 'linear'
      })
      anime({
        targets: '.blue-flower',
        rotate: '360deg',
        duration: 3000,
        easing: 'steps(10)',
        loop: true
      })
      anime({
        targets: '.green-flower',
        rotate: '180deg',
        duration: 6000,
        direction: 'alternate',
        easing: 'easeInOutSine',
        loop: true
      })
    },
    animateText() {},
    setAudioVolumeLevel(level) {
      this.$refs.audio.volume = level
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 500)
    },
    init() {
      this.canvasData = this.$store.getters.getPage97Data;
    },
    updateCanvas(canvasData) {
      this.$store.commit('setPage97Data', canvasData);
    },
    playSoundText() {
      this.playVoiceOver();
      this.animateText();
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.animateSvg();
    this.setAudioVolumeLevel(0.4);
  },
}
</script>

<style scoped>
.text-box {
  position: absolute;
  width: 98%;
  left: 1%;
  top: 1vh;
  background-color: #000000;
  color: #ffffff;
  font-size: 5vh;
  z-index: 30;
  padding: 2vh;
  text-align: center;
  opacity: 1;
  line-height: 4.5vh;
}
.landscape {
  position: absolute;
  width: 120%;
  left: -4%;
  top: -17.65vh;
  height: auto;
}
.canvas {
  position: absolute;
  top: 1vh;
  left: 1%;
  opacity: 1;
  z-index: 20;
}
.yellow-flower {
  position: absolute;
  width: 0.3%;
  height: auto;
  z-index: 10;
}
.blue-flower {
  position: absolute;
  z-index: 20;
}
.green-flower {
  position: absolute;
  z-index: 5;
}
tspan { white-space:pre }
.shp-pistil { fill: #ffffff }
.shp-petal { fill: #ffe464 }
.shp-petal-blue { fill: #88e3ff }
.shp-petal-blue-stroke { fill: none;stroke: #daf2f0;stroke-width: 2 }
.shp-petal-green { fill: #007060 }
.shp-petal-green-stroke { fill: none;stroke: #24a094;stroke-width: 2 }
.shp0 { fill: #d2fcf8 }
.shp-cloud { mix-blend-mode: hard-light;fill: #ffffff }
.shp2 { fill: #ffe464 }
.shp3 { opacity: 0.502;mix-blend-mode: soft-light;fill: #231f20 }
.shp4 { fill: #007a68 }
.shp5 { fill: #17a68f }
.shp6 { fill: #ffdc4a }
.shp7 { fill: #9ec95c }
.shp8 { fill: #80c157 }
.shp9 { fill: #231f20 }
.shp10 { fill: #60a831 }
.shp11 { fill: #007e6a }
.shp12 { fill: #c3c44a }
.shp13 { opacity: 0.102;mix-blend-mode: multiply;fill: #231f20 }
.shp14 { fill: #68bb5a }
.shp15 { fill: #8b5e3c }
.shp16 { fill: #8dc63f }
.shp17 { fill: #c1c44c }
.shp18 { fill: #a5a232 }
.shp19 { fill: #aaa73c }
.shp20 { fill: #5aa54a }
.shp21 { fill: #754c29 }
.shp22 { fill: #006838 }
.shp23 { fill: none;stroke: #006838;stroke-width: 2 }
.shp24 { fill: #007662 }
.shp25 { fill: #009483 }
.shp26 { fill: #234749 }
.shp27 { fill: #002620 }
.shp28 { fill: none;stroke: #004f45;stroke-width: 6 }
.shp29 { fill: #ffffff }
.shp30 { opacity: 0.2;mix-blend-mode: soft-light;fill: #ffdc4a }
.shp31 { fill: #15776e }
</style>