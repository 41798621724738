<template>
  <div class="interactive-container">
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1828 1098" width="1828" height="1098">
      <title>Landscape</title>
      <defs>
        <linearGradient id="grd1" gradientUnits="userSpaceOnUse"  x1="163.558" y1="700.849" x2="163.558" y2="-797.454">
          <stop offset="0.11" stop-color="#ccf0f5"  />
          <stop offset="1" stop-color="#94e0e9"  />
        </linearGradient>
        <linearGradient id="grd2" gradientUnits="userSpaceOnUse"  x1="1702.609" y1="289.862" x2="1080.669" y2="574.973">
          <stop offset="0" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd3" gradientUnits="userSpaceOnUse"  x1="636.327" y1="33.057" x2="1630.267" y2="473.837">
          <stop offset="0" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd4" gradientUnits="userSpaceOnUse"  x1="1532.329" y1="446.461" x2="705.83" y2="666.861">
          <stop offset="0" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd5" gradientUnits="userSpaceOnUse"  x1="294.23" y1="187.867" x2="920.537" y2="351.599">
          <stop offset="0" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd6" gradientUnits="userSpaceOnUse"  x1="918.149" y1="134.203" x2="918.149" y2="240.325">
          <stop offset="0.013" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd7" gradientUnits="userSpaceOnUse"  x1="790.15" y1="-4.521" x2="790.15" y2="83.074">
          <stop offset="0.013" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd8" gradientUnits="userSpaceOnUse"  x1="1068.688" y1="85.474" x2="1068.688" y2="128.55">
          <stop offset="0.013" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd9" gradientUnits="userSpaceOnUse"  x1="1136.825" y1="409.139" x2="1136.825" y2="461.658">
          <stop offset="0.013" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd10" gradientUnits="userSpaceOnUse"  x1="1648.825" y1="51.394" x2="1648.825" y2="87.094">
          <stop offset="0.013" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd11" gradientUnits="userSpaceOnUse"  x1="1397.012" y1="67.754" x2="1397.012" y2="154.275">
          <stop offset="0.013" stop-color="#e7f8fb"  />
          <stop offset="1" stop-color="#e7f8fb" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd12" gradientUnits="userSpaceOnUse"  x1="383.145" y1="870.897" x2="692.563" y2="870.897">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd13" gradientUnits="userSpaceOnUse"  x1="946.041" y1="960.931" x2="1255.458" y2="960.931">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd14" gradientUnits="userSpaceOnUse"  x1="961.704" y1="750.672" x2="1301.203" y2="750.672">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd15" gradientUnits="userSpaceOnUse"  x1="942.337" y1="554.923" x2="933.714" y2="684.266">
          <stop offset="0.009" stop-color="#aee2ca"  />
          <stop offset="1" stop-color="#80d4ba"  />
        </linearGradient>
        <linearGradient id="grd16" gradientUnits="userSpaceOnUse"  x1="845.04" y1="1032.761" x2="840.402" y2="868.804">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd17" gradientUnits="userSpaceOnUse"  x1="859.205" y1="757.497" x2="857.677" y2="703.487">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd18" gradientUnits="userSpaceOnUse"  x1="1074.611" y1="947.767" x2="1070.747" y2="811.208">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd19" gradientUnits="userSpaceOnUse"  x1="1241.611" y1="1047.434" x2="1237.747" y2="910.875">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd20" gradientUnits="userSpaceOnUse"  x1="889.254" y1="825.061" x2="1146.895" y2="825.061">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd21" gradientUnits="userSpaceOnUse"  x1="696.246" y1="721.425" x2="909.198" y2="721.425">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd22" gradientUnits="userSpaceOnUse"  x1="0" y1="0" x2="0" y2="0">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd23" gradientUnits="userSpaceOnUse"  x1="1594.825" y1="835.385" x2="1594.825" y2="713.334">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd24" gradientUnits="userSpaceOnUse"  x1="1476.123" y1="806.302" x2="1476.123" y2="697.798">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd25" gradientUnits="userSpaceOnUse"  x1="1530.916" y1="835.89" x2="1530.916" y2="727.386">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd26" gradientUnits="userSpaceOnUse"  x1="1372.222" y1="705.443" x2="1372.222" y2="616.55">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd27" gradientUnits="userSpaceOnUse"  x1="1238.877" y1="691.518" x2="1238.877" y2="612.49">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd28" gradientUnits="userSpaceOnUse"  x1="1178.34" y1="721.788" x2="1178.34" y2="642.76">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd29" gradientUnits="userSpaceOnUse"  x1="191.445" y1="757.954" x2="191.445" y2="642.471">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd30" gradientUnits="userSpaceOnUse"  x1="510.348" y1="818.954" x2="510.348" y2="703.471">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="grd31" gradientUnits="userSpaceOnUse"  x1="1567.661" y1="1161.874" x2="1567.661" y2="905.431">
          <stop offset="0" stop-color="#1d302f"  />
          <stop offset="0.857" stop-color="#1d302f" stop-opacity="0.14" />
          <stop offset="1" stop-color="#1d302f" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g id="Landscape">
        <g id="&lt;Clip Group&gt;">
          <path id="&lt;Path&gt;" class="shp0" d="M1665.86 1014.71L167.56 1014.71L167.56 13.01L1665.86 13.01L1665.86 1014.71Z" />
          <g id="&lt;Group&gt;" class="clouds">
            <path id="&lt;Path&gt;" class="shp1" d="M1723.32 249.84C1716.87 247.52 1710.52 244.77 1704.22 242.13C1671.03 228.18 1636.25 216.48 1600.24 212.94C1564.37 209.41 1528.25 216.66 1492.71 220.9C1468.21 223.82 1444.91 232 1420.53 235.67C1395.21 239.48 1369.54 240.44 1343.97 239.84C1292.01 238.63 1240.5 230.98 1188.81 226.28C1120.92 220.11 1052.54 219.32 984.53 223.92C979.39 224.26 971.85 221.57 973.92 216.86C974.86 214.72 977.46 213.95 979.74 213.47C1048.06 199.18 1121.59 200.76 1191.01 202.2C1259.48 203.62 1328.5 210.25 1396.32 197.25C1403.55 195.86 1411.04 192.83 1414.88 186.56C1418.73 180.29 1416.8 170.33 1409.8 168.08C1405.45 166.67 1398.48 166.5 1399.01 161.96C1399.25 159.92 1401.25 158.61 1403.05 157.64C1420.15 148.35 1438.82 141.98 1458.03 138.86C1462.69 138.1 1467.72 137.59 1471.93 139.74C1476.14 141.88 1478.75 147.72 1475.95 151.52C1473.53 154.79 1468.09 155.59 1467.1 159.53C1466.41 162.29 1468.6 165.12 1471.26 166.14C1473.91 167.16 1476.88 166.83 1479.69 166.45C1505.06 163.02 1528.56 154.48 1553.28 148.59C1580.12 142.19 1608.84 139.14 1636.35 141.5C1678.73 145.13 1718.73 169.78 1756.92 186.87C1770.47 192.93 1786.92 201 1790.86 216.72C1796.59 239.56 1771.74 255.52 1751.8 255.57C1742.02 255.59 1732.57 253.16 1723.32 249.84Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M385.74 226.11C399.37 222.02 413.91 220.3 428.18 219.35C448.28 218 468.5 217.84 488.33 213.89C508.08 209.96 527.94 206.6 548.18 206.9C550.24 206.93 552.43 207.04 554.12 208.21C557.73 210.68 556.9 216.66 553.65 219.59C550.4 222.51 545.78 223.2 541.45 223.76C521.44 226.37 500.86 230.74 480.66 230.74C472.45 231.05 461.82 232.79 460.41 240.88C459.59 245.62 462.93 250.31 467.17 252.57C471.42 254.84 476.39 255.21 481.2 255.48C518.9 257.61 556.7 256.15 594.33 253.53C643.89 250.08 692.3 241.01 741.42 252.57C786.98 263.28 831.24 283.52 878.82 283.15C909.74 282.92 939.78 273.7 969.99 267.04C1000.19 260.37 1032.29 256.32 1061.61 266.15C1081.24 272.73 1098.86 284.9 1120.01 286.25C1141.65 287.64 1162.98 279.62 1184.62 283.07C1186.58 283.38 1188.64 283.84 1190.09 285.21C1195.16 289.99 1187.13 294.28 1183.36 295.2C1152.31 302.82 1120.19 298.32 1088.97 294.27C1056.36 290.04 1024.17 287.98 991.4 291.95C949.34 297.06 912.1 321.83 868.53 312.86C843.13 307.62 818.06 300.89 793.27 293.29C715.65 269.51 633.56 276.84 554.66 289.4C533.07 292.84 512.24 298.94 490.79 302.56C472.63 305.62 444.57 317.61 426.78 307.84C424.61 306.65 422.85 304.37 423.02 301.9C423.19 299.59 424.94 297.71 426.83 296.39C431.05 293.43 436.26 292.3 440.92 290.11C445.59 287.92 450.1 283.95 450.28 278.8C450.4 275.05 448.11 271.53 445.08 269.32C442.04 267.12 438.35 266.03 434.69 265.21C414.58 260.7 394.79 264.33 374.58 263.51C369.42 263.3 364.09 262.75 359.62 260.17C355.15 257.59 351.77 252.48 352.7 247.4C353.56 242.74 357.63 239.41 361.64 236.87C369.14 232.11 377.26 228.66 385.74 226.11Z" />
            <path id="&lt;Path&gt;" class="shp3" d="M1714.41 296.81C1672.56 299.98 1631.24 309.49 1589.57 314.54C1516.51 323.39 1442.89 325.34 1369.83 334.07C1364.62 334.69 1358.38 334.92 1355.22 330.73C1348.56 321.93 1362.92 314.11 1360.49 304.81C1359.12 299.59 1353.15 297.18 1347.86 296.08C1324.21 291.13 1304.04 300.56 1282.33 307.61C1270.83 311.35 1257.91 315.75 1245.93 317.35C1232.39 319.15 1220.34 318.61 1207.3 324.09C1186.05 333.01 1231.94 331.47 1234.67 331.52C1238.8 331.59 1244.12 333.24 1244.16 337.37C1244.2 340.87 1240.31 342.91 1237 344.07C1195.75 358.57 1151.75 364.78 1108.1 364.34C1086.56 364.11 1067.5 362.11 1046.69 357.18C1024.72 351.97 1001.78 348.54 979.36 353.04C962.47 356.43 946.77 364.13 930.15 368.64C907.62 374.76 884.72 373.02 861.97 376.69C859.61 377.07 857.11 377.59 855.43 379.3C852.72 382.04 853.4 386.89 855.93 389.8C858.45 392.71 862.28 394.1 865.99 395.14C887.89 401.29 912.05 398.94 932.35 388.68C941.08 384.27 949.11 378.46 958.2 374.88C971.34 369.7 981.98 373.41 994.74 377.13C1079.37 401.75 1165.43 393.24 1250.21 374.25C1255.69 373.02 1274.74 372.71 1267.84 384.43C1265.72 388.02 1261.63 389.85 1258.39 392.46C1255.14 395.07 1252.59 399.81 1254.91 403.27C1256.01 404.92 1257.93 405.79 1259.79 406.44C1270.28 410.1 1281.74 409.1 1292.81 408.15C1344.53 403.74 1395.53 407.31 1447.24 405.96C1482.28 405.04 1517.2 401 1552.28 401.29C1594.19 401.64 1636.27 401.48 1678.31 398.46C1705.41 396.51 1735.12 396.08 1759.62 382.84C1787.05 368 1801.13 328.54 1772.28 306.57C1759.37 296.73 1741.95 295.54 1725.73 296.16C1721.95 296.3 1718.18 296.52 1714.41 296.81Z" />
            <path id="&lt;Path&gt;" class="shp4" d="M156.01 74.31C187.7 76.56 219.01 83.6 250.57 87.27C305.9 93.7 361.63 94.9 416.97 101.24C420.91 101.69 425.64 101.84 428.02 98.66C433.02 91.97 422.12 86.11 423.93 79.06C424.95 75.1 429.46 73.26 433.46 72.4C451.34 68.57 466.64 75.64 483.1 80.89C491.82 83.68 501.61 86.97 510.69 88.13C520.94 89.45 530.06 88.99 539.95 93.09C556.07 99.77 521.33 98.77 519.26 98.81C516.14 98.88 512.12 100.15 512.1 103.28C512.09 105.93 515.04 107.46 517.55 108.32C548.83 119.14 582.16 123.69 615.19 123.19C631.5 122.94 645.91 121.35 661.64 117.54C678.26 113.52 695.61 110.84 712.6 114.17C725.39 116.67 737.31 122.44 749.9 125.79C766.98 130.34 784.3 128.94 801.54 131.63C803.33 131.91 805.22 132.3 806.5 133.58C808.56 135.65 808.07 139.32 806.16 141.53C804.26 143.74 801.37 144.81 798.56 145.62C782.01 150.35 763.71 148.66 748.31 140.97C741.69 137.66 735.59 133.29 728.69 130.61C718.73 126.74 710.69 129.6 701.05 132.45C637.07 151.4 571.9 145.28 507.66 131.21C503.51 130.3 489.09 130.14 494.36 138.98C495.97 141.69 499.07 143.06 501.54 145.03C504 146.99 505.95 150.57 504.21 153.2C503.39 154.45 501.94 155.12 500.53 155.62C492.6 158.42 483.92 157.71 475.54 157.03C436.37 153.89 397.78 156.78 358.64 155.94C332.11 155.37 305.67 152.44 279.11 152.79C247.39 153.21 215.54 153.25 183.71 151.12C163.19 149.74 140.7 149.53 122.11 139.59C101.29 128.46 90.49 98.64 112.24 81.91C121.98 74.42 135.16 73.45 147.44 73.86C150.3 73.95 153.16 74.11 156.01 74.31Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M645.52 216.43C673.4 219.14 689.01 218.47 692.35 214.4C697.37 208.29 674.37 203.82 682.32 198.53C690.26 193.24 717.02 194.46 717.02 190.8C717.02 187.13 712.84 168.82 725.39 165.97C737.93 163.13 741.28 175.74 749.22 173.3C757.17 170.86 756.33 139.93 773.89 140.75C791.45 141.56 790.2 175.33 813.62 174.93C837.03 174.52 848.74 175.33 843.3 187.54C837.87 199.75 839.12 205.04 842.89 206.26C846.65 207.48 883.86 198.53 878.01 211.95C872.15 225.38 876.75 226.2 893.48 227.01C910.21 227.82 916.9 237.59 913.55 239.22C910.21 240.84 627.12 246.54 600.36 239.62C573.6 232.71 612.07 229.45 621.69 228.23C631.31 227.01 618.34 211.95 645.52 216.43Z" />
            <path id="&lt;Path&gt;" class="shp6" d="M564.46 63.35C587.47 65.59 600.36 65.03 603.12 61.67C607.26 56.63 588.28 52.94 594.84 48.57C601.39 44.21 623.48 45.21 623.48 42.19C623.48 39.17 620.03 24.05 630.39 21.7C640.74 19.35 643.5 29.76 650.06 27.75C656.62 25.73 655.93 0.21 670.42 0.88C684.92 1.55 683.88 29.43 703.21 29.09C722.54 28.76 732.2 29.43 727.71 39.5C723.23 49.58 724.26 53.95 727.37 54.95C730.47 55.96 761.19 48.57 756.36 59.66C751.53 70.74 755.32 71.41 769.13 72.08C782.94 72.76 788.46 80.82 785.7 82.16C782.94 83.5 549.28 88.2 527.19 82.5C505.1 76.79 536.85 74.1 544.79 73.09C552.73 72.08 542.03 59.66 564.46 63.35Z" />
            <path id="&lt;Path&gt;" class="shp7" d="M896.47 137.64C1003.38 139.37 1059.25 139.02 1064.08 136.61C1071.32 132.99 1029.42 129.88 1018.04 128.33C1006.66 126.78 1006.14 104.02 993.73 104.02C981.31 104.02 968.38 116.43 963.72 111.78C959.06 107.12 960.1 73.49 939.41 71.42C918.71 69.36 926.47 90.05 910.44 95.74C894.4 101.43 873.71 98.33 880.95 105.57C888.19 112.81 891.81 126.78 878.36 127.3C864.91 127.81 866.46 138.68 896.47 137.64Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M927.68 472.74C1058.03 474.85 1126.15 474.42 1132.03 471.48C1140.86 467.07 1089.78 463.28 1075.9 461.39C1062.03 459.5 1061.39 431.75 1046.26 431.75C1031.12 431.75 1015.35 446.88 1009.68 441.21C1004 435.53 1005.26 394.53 980.03 392.01C954.8 389.49 964.26 414.72 944.71 421.65C925.16 428.59 899.93 424.81 908.76 433.64C917.59 442.47 922.01 459.5 905.61 460.13C889.21 460.76 891.1 474 927.68 472.74Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M1505.45 94.63C1594.06 96.06 1640.36 95.77 1644.36 93.77C1650.36 90.77 1615.64 88.2 1606.2 86.91C1596.77 85.63 1596.34 66.76 1586.05 66.76C1575.76 66.76 1565.05 77.05 1561.19 73.19C1557.33 69.33 1558.19 41.47 1541.04 39.75C1523.89 38.04 1530.32 55.19 1517.03 59.9C1503.74 64.62 1486.59 62.05 1492.59 68.05C1498.59 74.05 1501.59 85.63 1490.45 86.05C1479.3 86.48 1480.59 95.49 1505.45 94.63Z" />
            <path id="&lt;Path&gt;" class="shp10" d="M1105.36 96.35C1106.88 88.77 1115.41 84.78 1130.96 84.41C1154.28 83.84 1149.16 61.08 1158.83 57.67C1168.51 54.26 1181.02 69.05 1191.83 57.67C1202.64 46.29 1204.34 26.95 1218 26.38C1231.65 25.81 1225.39 44.58 1239.04 46.86C1252.7 49.14 1275.45 44.02 1275.45 54.82C1275.45 65.63 1260.09 76.44 1265.21 81.56C1270.33 86.68 1319.25 87.25 1319.25 100.9C1319.25 114.55 1344.85 112.28 1364.19 112.28C1383.53 112.28 1395.48 132.19 1392.64 132.76C1389.79 133.33 1071.23 132.76 1070.09 126.5C1068.95 120.24 1071.8 110.57 1089.43 112.28C1101.19 113.42 1106.5 108.11 1105.36 96.35Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp11" d="M1665.25 229.54C1641.7 345.58 1591.67 398.97 1515.16 389.72C1400.38 375.85 1410.47 302.69 1315.88 300.17C1221.28 297.65 1178.4 520.89 1003.08 529.72C827.76 538.55 822.72 310.26 764.7 279.99C706.68 249.72 699.11 330.44 549.02 327.92C398.93 325.4 448.12 128.64 345.96 104.67C277.85 88.7 217.73 103.83 165.6 150.08L165.6 645.55L1667.8 645.55L1665.25 229.54Z" />
              <path id="&lt;Path&gt;" class="shp12" d="M1665.25 229.54C1641.7 345.58 1591.67 398.97 1515.16 389.72C1400.38 375.85 1410.47 302.69 1315.88 300.17C1221.28 297.65 1178.4 520.89 1003.08 529.72C827.76 538.55 822.72 310.26 764.7 279.99C706.68 249.72 699.11 330.44 549.02 327.92C398.93 325.4 448.12 128.64 345.96 104.67C277.85 88.7 217.73 103.83 165.6 150.08L165.6 158.65C203.91 143.32 295.74 111.56 342.17 137.97C381.91 160.57 376.07 186.28 324.64 215.11C371.39 203.42 399.05 213.55 407.63 245.5C420.48 293.42 494.11 360.04 552.55 367.05C591.51 371.72 612.94 383.41 616.84 402.11C623.07 357.7 640.6 332.38 669.43 326.14C698.26 319.91 724.36 308.61 747.74 292.25C757.09 303.16 759.04 312.9 753.58 321.47C745.4 334.32 722.03 331.99 724.36 341.34C726.7 350.69 767.61 336.66 786.31 368.22C805.01 399.77 872.8 557.56 1016.56 554.05C1160.31 550.55 1250.31 360.04 1269.01 355.36C1281.48 352.24 1290.05 354.19 1294.72 361.2C1291.61 333.93 1300.57 320.69 1321.6 321.47C1353.16 322.64 1418.61 414.97 1523.8 405.62C1575.98 400.98 1663.41 307.51 1664.02 256.77L1665.25 229.54Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp13" d="M165.6 645.55L169.2 457.04C219.06 478.08 265.43 480.81 308.28 465.23C372.56 441.85 372.56 363.54 446.2 357.7C519.83 351.85 587.62 444.19 629.69 431.33C671.77 418.47 679.95 388.09 708 393.93C736.05 399.77 779.29 593.79 862.28 605.48C945.26 617.17 968.64 610.15 1037.59 619.5C1106.55 628.85 1208.23 632.36 1251.48 606.65C1294.72 580.93 1367.19 454.71 1439.65 476.91C1487.96 491.72 1564.32 476.13 1668.73 430.16L1667.8 645.55L165.6 645.55Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M1409.76 585.33L1418.42 474.67L1433.09 472L1419.09 468.67L1433.09 410.67L1463.09 476.67L1443.76 484.67L1463.09 482.67L1468.28 544.9L1453.09 555.33L1469.71 562.06L1477.09 650.67L1405.76 650.67L1409.76 585.33Z" />
              <path id="&lt;Path&gt;" class="shp14" d="M1631.77 605.86L1605.06 595.01L1631.77 599.83L1623.95 570.89L1609.95 570.89L1608.97 563.25L1624.93 563.65L1591.4 447.86L1591.38 446.67L1591.22 447.23L1591.06 446.67L1591.03 447.86L1557.51 563.65L1573.47 563.25L1572.49 570.89L1558.48 570.89L1550.67 599.83L1577.37 595.01L1550.67 605.86L1549.04 619.93L1588.12 619.93L1594.31 619.93L1633.4 619.93L1631.77 605.86Z" />
              <path id="&lt;Path&gt;" class="shp14" d="M1320.78 637.89L1294.07 627.04L1320.78 631.86L1312.96 602.92L1298.96 602.92L1297.98 595.28L1313.94 595.68L1280.41 479.89L1280.39 478.7L1280.23 479.26L1280.07 478.7L1280.05 479.89L1246.52 595.68L1262.48 595.28L1261.5 602.92L1247.49 602.92L1239.68 631.86L1266.39 627.04L1239.68 637.89L1238.05 651.96L1277.13 651.96L1283.32 651.96L1322.41 651.96L1320.78 637.89Z" />
              <path id="&lt;Path&gt;" class="shp14" d="M1420.95 636.73L1394.24 625.87L1420.95 630.7L1413.13 601.75L1399.13 601.75L1398.15 594.12L1414.11 594.52L1380.58 478.73L1380.56 477.53L1380.4 478.09L1380.23 477.53L1380.21 478.73L1346.69 594.52L1362.65 594.12L1361.67 601.75L1347.66 601.75L1339.85 630.7L1366.55 625.87L1339.85 636.73L1338.22 650.8L1377.3 650.8L1383.49 650.8L1422.58 650.8L1420.95 636.73Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1586.42 650.33L1557.76 473L1546.42 527.67L1553.09 527L1554.42 535.67L1545.76 536.33L1536.42 595L1554.42 594.33L1534.42 599.67L1529.09 652.33L1586.42 650.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1457.09 650.33L1485.76 473L1497.09 527.67L1490.42 527L1489.09 535.67L1497.76 536.33L1507.09 595L1489.09 594.33L1509.09 599.67L1514.42 652.33L1457.09 650.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1299.27 650.33L1327.93 473L1339.27 527.67L1332.6 527L1331.27 535.67L1339.93 536.33L1349.27 595L1331.27 594.33L1351.27 599.67L1356.6 652.33L1299.27 650.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M1603.76 576.33L1612.42 465.67L1627.09 463L1613.09 459.67L1627.09 401.67L1657.09 467.67L1637.76 475.67L1657.09 473.67L1662.28 535.9L1647.09 546.33L1663.71 553.06L1671.09 641.67L1599.76 641.67L1603.76 576.33Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M1636.73 643.52L1614.44 634.46L1636.73 638.49L1630.21 614.33L1618.52 614.33L1617.7 607.96L1631.02 608.29L1603.04 511.65L1603.02 510.65L1602.88 511.12L1602.75 510.65L1602.73 511.65L1574.75 608.29L1588.07 607.96L1587.25 614.33L1575.56 614.33L1569.04 638.49L1591.33 634.46L1569.04 643.52L1567.68 655.27L1600.3 655.27L1605.47 655.27L1638.09 655.27L1636.73 643.52Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M1555.4 643.52L1533.11 634.46L1555.4 638.49L1548.87 614.33L1537.18 614.33L1536.37 607.96L1549.69 608.29L1521.7 511.65L1521.69 510.65L1521.55 511.12L1521.42 510.65L1521.4 511.65L1493.41 608.29L1506.73 607.96L1505.92 614.33L1494.23 614.33L1487.7 638.49L1510 634.46L1487.7 643.52L1486.35 655.27L1518.97 655.27L1524.13 655.27L1556.76 655.27L1555.4 643.52Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M1442.42 641.64L1423.19 633.83L1442.42 637.3L1436.79 616.47L1426.71 616.47L1426.01 610.97L1437.5 611.26L1413.36 527.91L1413.35 527.05L1413.23 527.46L1413.11 527.05L1413.1 527.91L1388.96 611.26L1400.45 610.97L1399.75 616.47L1389.67 616.47L1384.04 637.3L1403.26 633.83L1384.04 641.64L1382.87 651.77L1411 651.77L1415.46 651.77L1443.59 651.77L1442.42 641.64Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp14" d="M1119.67 585.33L1111 474.67L1096.34 472L1110.34 468.67L1096.34 410.67L1066.34 476.67L1085.67 484.67L1066.34 482.67L1061.15 544.9L1076.34 555.33L1059.72 562.06L1052.34 650.67L1123.67 650.67L1119.67 585.33Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M897.66 605.86L924.37 595.01L897.66 599.83L905.48 570.89L919.48 570.89L920.46 563.25L904.5 563.65L938.03 447.86L938.05 446.67L938.21 447.23L938.37 446.67L938.39 447.86L971.92 563.65L955.96 563.25L956.94 570.89L970.95 570.89L978.76 599.83L952.05 595.01L978.76 605.86L980.39 619.93L941.31 619.93L935.12 619.93L896.03 619.93L897.66 605.86Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M1208.65 637.89L1235.36 627.04L1208.65 631.86L1216.47 602.92L1230.47 602.92L1231.45 595.28L1215.49 595.68L1249.02 479.89L1249.04 478.7L1249.2 479.26L1249.36 478.7L1249.38 479.89L1282.91 595.68L1266.95 595.28L1267.93 602.92L1281.94 602.92L1289.75 631.86L1263.04 627.04L1289.75 637.89L1291.38 651.96L1252.29 651.96L1246.11 651.96L1207.02 651.96L1208.65 637.89Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M1108.48 636.73L1135.19 625.87L1108.48 630.7L1116.3 601.75L1130.3 601.75L1131.28 594.12L1115.32 594.52L1148.85 478.73L1148.87 477.53L1149.03 478.09L1149.19 477.53L1149.21 478.73L1182.74 594.52L1166.78 594.12L1167.76 601.75L1181.77 601.75L1189.58 630.7L1162.87 625.87L1189.58 636.73L1191.21 650.8L1152.13 650.8L1145.94 650.8L1106.85 650.8L1108.48 636.73Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M943 650.33L971.67 473L983 527.67L976.34 527L975 535.67L983.67 536.33L993 595L975 594.33L995 599.67L1000.34 652.33L943 650.33Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M1072.34 650.33L1043.67 473L1032.34 527.67L1039 527L1040.34 535.67L1031.67 536.33L1022.34 595L1040.34 594.33L1020.34 599.67L1015 652.33L1072.34 650.33Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M925.67 576.33L917 465.67L902.34 463L916.34 459.67L902.34 401.67L872.34 467.67L891.67 475.67L872.34 473.67L867.15 535.9L882.34 546.33L865.72 553.06L858.34 641.67L929.67 641.67L925.67 576.33Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M892.7 643.52L914.99 634.46L892.7 638.49L899.22 614.33L910.91 614.33L911.73 607.96L898.41 608.29L926.39 511.65L926.41 510.65L926.54 511.12L926.68 510.65L926.7 511.65L954.68 608.29L941.36 607.96L942.18 614.33L953.87 614.33L960.39 638.49L938.1 634.46L960.39 643.52L961.75 655.27L929.13 655.27L923.96 655.27L891.34 655.27L892.7 643.52Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M974.03 643.52L996.32 634.46L974.03 638.49L980.56 614.33L992.25 614.33L993.06 607.96L979.74 608.29L1007.72 511.65L1007.74 510.65L1007.88 511.12L1008.01 510.65L1008.03 511.65L1036.02 608.29L1022.69 607.96L1023.51 614.33L1035.2 614.33L1041.72 638.49L1019.43 634.46L1041.72 643.52L1043.08 655.27L1010.46 655.27L1005.29 655.27L972.67 655.27L974.03 643.52Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M1087.01 641.64L1106.24 633.83L1087.01 637.3L1092.64 616.47L1102.72 616.47L1103.42 610.97L1091.93 611.26L1116.07 527.91L1116.08 527.05L1116.2 527.46L1116.32 527.05L1116.33 527.91L1140.47 611.26L1128.98 610.97L1129.68 616.47L1139.76 616.47L1145.39 637.3L1126.16 633.83L1145.39 641.64L1146.56 651.77L1118.43 651.77L1113.97 651.77L1085.84 651.77L1087.01 641.64Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp14" d="M552 585.33L560.67 474.67L575.33 472L561.33 468.67L575.33 410.67L605.33 476.67L586 484.67L605.33 482.67L610.52 544.9L595.33 555.33L611.95 562.06L619.33 650.67L548 650.67L552 585.33Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M774.01 605.86L747.3 595.01L774.01 599.83L766.2 570.89L752.19 570.89L751.21 563.25L767.17 563.65L733.64 447.86L733.62 446.67L733.46 447.23L733.3 446.67L733.28 447.86L699.75 563.65L715.71 563.25L714.73 570.89L700.73 570.89L692.91 599.83L719.62 595.01L692.91 605.86L691.28 619.93L730.37 619.93L736.56 619.93L775.64 619.93L774.01 605.86Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M463.02 637.89L436.32 627.04L463.02 631.86L455.21 602.92L441.2 602.92L440.22 595.28L456.18 595.68L422.66 479.89L422.64 478.7L422.47 479.26L422.31 478.7L422.29 479.89L388.76 595.68L404.72 595.28L403.74 602.92L389.74 602.92L381.92 631.86L408.63 627.04L381.92 637.89L380.29 651.96L419.38 651.96L425.57 651.96L464.65 651.96L463.02 637.89Z" />
                <path id="&lt;Path&gt;" class="shp14" d="M563.19 636.73L536.48 625.87L563.19 630.7L555.38 601.75L541.37 601.75L540.39 594.12L556.35 594.52L522.82 478.73L522.8 477.53L522.64 478.09L522.48 477.53L522.46 478.73L488.93 594.52L504.89 594.12L503.91 601.75L489.91 601.75L482.09 630.7L508.8 625.87L482.09 636.73L480.46 650.8L519.55 650.8L525.74 650.8L564.82 650.8L563.19 636.73Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M728.67 650.33L700 473L688.67 527.67L695.33 527L696.67 535.67L688 536.33L678.67 595L696.67 594.33L676.67 599.67L671.33 652.33L728.67 650.33Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M599.33 650.33L628 473L639.33 527.67L632.67 527L631.33 535.67L640 536.33L649.33 595L631.33 594.33L651.33 599.67L656.67 652.33L599.33 650.33Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M441.51 650.33L470.18 473L481.51 527.67L474.84 527L473.51 535.67L482.18 536.33L491.51 595L473.51 594.33L493.51 599.67L498.84 652.33L441.51 650.33Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M746 587.49L754.67 476.82L769.33 474.16L755.33 470.82L769.33 412.82L799.33 478.82L780 486.82L799.33 484.82L804.52 547.06L789.33 557.49L805.95 564.22L813.33 652.82L742 652.82L746 587.49Z" />
                <path id="&lt;Path&gt;" class="shp15" d="M853.22 602.76L860.02 515.87L871.54 513.77L860.55 511.16L871.54 465.62L895.1 517.44L879.92 523.72L895.1 522.15L899.17 571.02L887.24 579.21L900.29 584.49L906.09 654.06L850.08 654.06L853.22 602.76Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M778.98 643.52L756.68 634.46L778.98 638.49L772.45 614.33L760.76 614.33L759.94 607.96L773.27 608.29L745.28 511.65L745.26 510.65L745.13 511.12L744.99 510.65L744.98 511.65L716.99 608.29L730.31 607.96L729.5 614.33L717.81 614.33L711.28 638.49L733.57 634.46L711.28 643.52L709.92 655.27L742.55 655.27L747.71 655.27L780.33 655.27L778.98 643.52Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M865.75 643.52L843.46 634.46L865.75 638.49L859.23 614.33L847.54 614.33L846.72 607.96L860.04 608.29L832.06 511.65L832.04 510.65L831.91 511.12L831.77 510.65L831.75 511.65L803.77 608.29L817.09 607.96L816.27 614.33L804.58 614.33L798.06 638.49L820.35 634.46L798.06 643.52L796.7 655.27L829.32 655.27L834.49 655.27L867.11 655.27L865.75 643.52Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M697.64 643.52L675.35 634.46L697.64 638.49L691.12 614.33L679.43 614.33L678.61 607.96L691.93 608.29L663.95 511.65L663.93 510.65L663.8 511.12L663.66 510.65L663.64 511.65L635.66 608.29L648.98 607.96L648.16 614.33L636.47 614.33L629.95 638.49L652.24 634.46L629.95 643.52L628.59 655.27L661.21 655.27L666.38 655.27L699 655.27L697.64 643.52Z" />
                <path id="&lt;Path&gt;" class="shp16" d="M584.66 641.64L565.44 633.83L584.66 637.3L579.04 616.47L568.95 616.47L568.25 610.97L579.74 611.26L555.6 527.91L555.59 527.05L555.47 527.46L555.36 527.05L555.34 527.91L531.21 611.26L542.69 610.97L541.99 616.47L531.91 616.47L526.28 637.3L545.51 633.83L526.28 641.64L525.11 651.77L553.25 651.77L557.7 651.77L585.83 651.77L584.66 641.64Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M261.91 585.33L253.25 474.67L238.58 472L252.58 468.67L238.58 410.67L208.58 476.67L227.91 484.67L208.58 482.67L203.4 544.9L218.58 555.33L201.97 562.06L194.58 650.67L265.91 650.67L261.91 585.33Z" />
              <path id="&lt;Path&gt;" class="shp14" d="M39.9 605.86L66.61 595.01L39.9 599.83L47.72 570.89L61.73 570.89L62.7 563.25L46.74 563.65L80.27 447.86L80.29 446.67L80.45 447.23L80.62 446.67L80.64 447.86L114.17 563.65L98.21 563.25L99.18 570.89L113.19 570.89L121.01 599.83L94.3 595.01L121.01 605.86L122.63 619.93L83.55 619.93L77.36 619.93L38.27 619.93L39.9 605.86Z" />
              <path id="&lt;Path&gt;" class="shp14" d="M350.89 637.89L377.6 627.04L350.89 631.86L358.71 602.92L372.72 602.92L373.69 595.28L357.73 595.68L391.26 479.89L391.28 478.7L391.44 479.26L391.61 478.7L391.63 479.89L425.16 595.68L409.2 595.28L410.17 602.92L424.18 602.92L432 631.86L405.29 627.04L432 637.89L433.62 651.96L394.54 651.96L388.35 651.96L349.26 651.96L350.89 637.89Z" />
              <path id="&lt;Path&gt;" class="shp14" d="M250.72 636.73L277.43 625.87L250.72 630.7L258.54 601.75L272.55 601.75L273.52 594.12L257.56 594.52L291.09 478.73L291.11 477.53L291.27 478.09L291.44 477.53L291.46 478.73L324.99 594.52L309.03 594.12L310 601.75L324.01 601.75L331.83 630.7L305.12 625.87L331.83 636.73L333.46 650.8L294.37 650.8L288.18 650.8L249.09 650.8L250.72 636.73Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M85.25 650.33L113.91 473L125.25 527.67L118.58 527L117.25 535.67L125.91 536.33L135.25 595L117.25 594.33L137.25 599.67L142.58 652.33L85.25 650.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M214.58 650.33L185.91 473L174.58 527.67L181.25 527L182.58 535.67L173.91 536.33L164.58 595L182.58 594.33L162.58 599.67L157.25 652.33L214.58 650.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M372.41 650.33L343.74 473L332.41 527.67L339.07 527L340.41 535.67L331.74 536.33L322.41 595L340.41 594.33L320.41 599.67L315.07 652.33L372.41 650.33Z" />
              <path id="&lt;Path&gt;" class="shp15" d="M67.91 576.33L59.25 465.67L44.58 463L58.58 459.67L44.58 401.67L14.58 467.67L33.91 475.67L14.58 473.67L9.4 535.9L24.58 546.33L7.97 553.06L0.58 641.67L71.91 641.67L67.91 576.33Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M34.94 643.52L57.23 634.46L34.94 638.49L41.47 614.33L53.16 614.33L53.97 607.96L40.65 608.29L68.63 511.65L68.65 510.65L68.79 511.12L68.92 510.65L68.94 511.65L96.93 608.29L83.6 607.96L84.42 614.33L96.11 614.33L102.63 638.49L80.34 634.46L102.63 643.52L103.99 655.27L71.37 655.27L66.21 655.27L33.58 655.27L34.94 643.52Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M116.27 643.52L138.57 634.46L116.27 638.49L122.8 614.33L134.49 614.33L135.3 607.96L121.98 608.29L149.97 511.65L149.99 510.65L150.12 511.12L150.26 510.65L150.27 511.65L178.26 608.29L164.94 607.96L165.75 614.33L177.44 614.33L183.97 638.49L161.68 634.46L183.97 643.52L185.33 655.27L152.7 655.27L147.54 655.27L114.91 655.27L116.27 643.52Z" />
              <path id="&lt;Path&gt;" class="shp16" d="M229.25 641.64L248.48 633.83L229.25 637.3L234.88 616.47L244.96 616.47L245.67 610.97L234.18 611.26L258.31 527.91L258.33 527.05L258.44 527.46L258.56 527.05L258.58 527.91L282.71 611.26L271.22 610.97L271.92 616.47L282.01 616.47L287.63 637.3L268.41 633.83L287.63 641.64L288.81 651.77L260.67 651.77L256.22 651.77L228.08 651.77L229.25 641.64Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp17" d="M167.2 1010.64L1669.92 1010.64L1669.92 648.1L167.2 648.1L167.2 1010.64Z" />
          <path id="&lt;Path&gt;" class="shp18" d="M656.72 657.73C583.75 659.05 510.49 660.39 437.66 667.65C431.91 668.22 424.49 669.93 424.55 673.27C424.59 675.13 427.2 676.5 429.9 677.36C437.46 679.79 446.73 679.97 455.65 680.1C518.15 681.02 580.77 681.94 643.67 679.36C676.32 678.02 709.87 675.59 740 666.74C764.95 659.42 745.4 656.18 730.32 656.4C705.8 656.77 681.25 657.29 656.72 657.73Z" />
          <path id="&lt;Path&gt;" class="shp19" d="M613 769C530.33 745 444.67 722.67 356 702C267.33 681.33 193 665.33 133 654L133 806C463 838 644.33 841.33 677 816C709.67 790.67 688.33 775 613 769Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp20" d="M278.92 761.18C278.01 775.13 348.99 791.15 437.46 796.96C525.93 802.78 598.4 796.18 599.31 782.23C600.23 768.28 529.25 752.26 440.78 746.45C352.3 740.64 279.84 747.24 278.92 761.18Z" />
            <path id="&lt;Path&gt;" class="shp19" d="M307.25 758.61C306.49 770.11 365.05 783.33 438.04 788.12C511.03 792.92 570.82 787.48 571.57 775.97C572.33 764.46 513.77 751.24 440.78 746.45C367.79 741.65 308 747.1 307.25 758.61Z" />
            <path id="&lt;Path&gt;" class="shp21" d="M327.19 761.67C326.52 771.82 382.08 777.62 443.9 781.68C505.72 785.74 556.37 780.81 557.04 770.66C557.7 760.51 508.13 748.99 446.31 744.93C384.5 740.87 327.85 751.52 327.19 761.67Z" />
            <path id="&lt;Path&gt;" class="shp22" d="M410.4 601.6L415.3 601.6C422.18 601.6 427.75 596.03 427.75 589.15L427.75 570.32L397.96 570.32L397.96 589.15C397.96 596.03 403.53 601.6 410.4 601.6Z" />
            <path id="&lt;Path&gt;" class="shp23" d="M489.61 588.1L407.78 588.1L470.04 677.93L548.76 677.93L489.61 588.1Z" />
            <path id="&lt;Path&gt;" class="shp24" d="M358.71 756.6L487.12 756.6L487.12 675.04L358.71 675.04L358.71 756.6Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M414.07 747.02L358.71 747.02L358.71 736.51L414.07 736.51L414.07 747.02Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M414.07 726L358.71 726L358.71 715.49L414.07 715.49L414.07 726Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M414.07 704.98L358.71 704.98L358.71 694.47L414.07 694.47L414.07 704.98Z" />
            <path id="&lt;Path&gt;" class="shp26" d="M413.85 756.6L542.26 756.6L542.26 675.71L413.85 651.03L413.85 756.6Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M553.66 764.91L352.64 764.91L352.64 751.61L553.66 751.61L553.66 764.91Z" />
            <path id="&lt;Path&gt;" class="shp26" d="M413.12 677.49L484.72 588.1L542.26 675.71L413.12 677.49Z" />
            <path id="&lt;Path&gt;" class="shp23" d="M394.44 588.1L489.61 588.1L414.01 677.93L335.29 677.93L394.44 588.1Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M371.68 751.35L391.7 751.35L391.7 706.02L371.68 706.02L371.68 751.35Z" />
            <path id="&lt;Path&gt;" class="shp21" d="M498 773L533.73 773C528.55 763.86 522.74 758.76 516.33 757.7C506.7 756.12 505.65 766.41 499.19 769.04C494.88 770.8 493.34 772.08 494.57 772.87C496.51 772.96 497.65 773 498 773Z" />
            <path id="&lt;Path&gt;" class="shp21" d="M340.74 769.51L376.47 769.51C371.29 760.37 365.49 755.27 359.07 754.22C349.44 752.64 348.39 762.92 341.93 765.56C337.62 767.32 336.08 768.59 337.31 769.38C339.25 769.47 340.39 769.51 340.74 769.51Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M542.32 747.4L413.36 747.4L413.36 737.25L542.32 737.25L542.32 747.4Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M542.32 726.2L413.36 726.2L413.36 716.05L542.32 716.05L542.32 726.2Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M542.32 706.5L413.36 706.5L413.36 696.35L542.32 696.35L542.32 706.5Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M542.32 688.88L413.36 688.88L413.36 678.73L542.32 678.73L542.32 688.88Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M467.92 731.49L500.01 731.49L500.01 677.53L467.92 677.53L467.92 731.49Z" />
            <path id="&lt;Path&gt;" class="shp28" d="M473.49 725.45L494.45 725.45L494.45 681.6L473.49 681.6L473.49 725.45Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M413.36 716.05C437.74 736.05 462.76 746.15 488.44 746.35C514.11 746.55 532.07 739.83 542.32 726.2L542.47 751.43L413.51 752.32L413.36 716.05Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M553.66 739.83L352.64 739.83L352.64 734.28L553.66 734.28L553.66 739.83Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M530.53 752.62L534.41 752.62L534.41 737.1L530.53 737.1L530.53 752.62Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M496.2 752.62L500.08 752.62L500.08 737.1L496.2 737.1L496.2 752.62Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M461.87 752.62L465.75 752.62L465.75 737.1L461.87 737.1L461.87 752.62Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M427.54 752.62L431.42 752.62L431.42 737.1L427.54 737.1L427.54 752.62Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M393.21 752.62L397.09 752.62L397.09 737.1L393.21 737.1L393.21 752.62Z" />
            <path id="&lt;Path&gt;" class="shp27" d="M358.87 752.62L362.76 752.62L362.76 737.1L358.87 737.1L358.87 752.62Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M358.71 678.06L358.71 684.69L421.37 684.69L489.59 603.21L542.26 675.71L486.86 591.13L413.18 678.06L358.71 678.06Z" />
            <path id="&lt;Path&gt;" class="shp30" d="M472.34 653.9L450.38 653.9L450.38 651.46L472.34 651.46L472.34 653.9Z" />
            <path id="&lt;Path&gt;" class="shp30" d="M534.06 717.71L512.09 717.71L512.09 715.27L534.06 715.27L534.06 717.71Z" />
            <path id="&lt;Path&gt;" class="shp30" d="M505.82 636.46L483.85 636.46L483.85 634.02L505.82 634.02L505.82 636.46Z" />
            <path id="&lt;Path&gt;" class="shp30" d="M447.94 688.42L425.97 688.42L425.97 685.98L447.94 685.98L447.94 688.42Z" />
            <path id="&lt;Path&gt;" class="shp31" d="M394.93 573L430.15 573L430.15 567.77L394.93 567.77L394.93 573Z" />
            <path id="&lt;Path&gt;" class="shp31" d="M352.88 661.59C353.2 661.8 353.62 661.72 353.84 661.41L397 598.39C397.22 598.08 397.14 597.65 396.83 597.44C396.51 597.22 396.08 597.3 395.87 597.62L352.71 660.63C352.49 660.95 352.57 661.37 352.88 661.59Z" />
            <path id="&lt;Path&gt;" class="shp31" d="M370.67 661.59C370.98 661.8 371.41 661.72 371.62 661.41L414.79 598.39C415 598.08 414.92 597.65 414.61 597.44C414.3 597.22 413.87 597.3 413.65 597.62L370.49 660.63C370.27 660.95 370.35 661.37 370.67 661.59Z" />
            <path id="&lt;Path&gt;" class="shp31" d="M388.45 661.59C388.76 661.8 389.19 661.72 389.41 661.41L432.57 598.39C432.78 598.08 432.7 597.65 432.39 597.44C432.08 597.22 431.65 597.3 431.44 597.62L388.27 660.63C388.06 660.95 388.14 661.37 388.45 661.59Z" />
            <path id="&lt;Path&gt;" class="shp31" d="M406.23 661.59C406.55 661.8 406.97 661.72 407.19 661.41L450.35 598.39C450.57 598.08 450.49 597.65 450.17 597.44C449.86 597.22 449.43 597.3 449.22 597.62L406.05 660.63C405.84 660.95 405.92 661.37 406.23 661.59Z" />
            <path id="&lt;Path&gt;" class="shp32" d="M365.23 770.16C363.07 765.45 363.86 760.41 367.62 755.03C373.27 746.95 377.73 745.76 386.36 747.25C394.99 748.74 396.48 757.67 401.24 758.26C404.41 758.66 406 762.92 406 771.06L365.23 770.16Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp21" d="M366.22 770.06C367.57 769.49 368.32 768.05 367.99 766.62C366.14 758.57 359.13 732.84 342.51 719.9C322.66 704.44 329.51 728.69 343.92 742.92L348.14 741.86L346.03 744.67L362.56 768.98C363.36 770.17 364.9 770.62 366.22 770.06Z" />
              <path id="&lt;Path&gt;" class="shp21" d="M365.06 770.06C363.71 769.49 362.96 768.05 363.29 766.62C365.14 758.57 372.16 732.84 388.77 719.9C408.62 704.44 401.77 728.69 387.36 742.92L383.15 741.86L385.25 744.67L368.73 768.98C367.92 770.17 366.38 770.62 365.06 770.06Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp21" d="M398.07 766.52C399.59 758.51 404.73 753.78 413.51 752.32C426.67 750.13 432.24 760.25 439.92 761.99C445.03 763.15 449.21 767.45 452.47 774.89L395.11 773.25C393.72 773.21 393.06 771.54 394.04 770.56L398.07 766.52Z" />
            <path id="&lt;Path&gt;" class="shp29" d="M421.72 623.76L401.68 655.08L363.69 655.08L383.38 630.22L421.72 623.76Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp24" d="M370.34 648.2L404.62 648.2L387.28 613.92L370.34 648.2Z" />
              <path id="&lt;Path&gt;" class="shp28" d="M378.57 643.17L396.38 643.17L387.37 625.36L378.57 643.17Z" />
              <path id="&lt;Path&gt;" class="shp33" d="M404.62 648.2L421.72 623.76L387.28 613.92L404.62 648.2Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp20" d="M133 654L348 700C204 689.33 142.67 686.67 164 692C184.07 697.02 227.35 707.94 256.83 715.38C260.13 716.21 259.39 721.09 256 720.9C212.49 718.48 140.58 716.71 159 733C185 756 338 810 367 813C367.29 813.03 367.58 813.06 367.87 813.09C375.01 813.87 374.19 824.52 367.01 824.23C305.99 821.73 205.72 816.17 140 805L133 654Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp34" d="M653.71 798.16C655.3 802.18 653.06 805.58 644.67 808.16C598.71 822.3 240.17 810.04 166.25 807.34L166.25 819.89C178.76 820.37 607.18 836.74 657.71 821.19C674.08 816.16 667.12 808.03 653.71 798.16Z" />
            <path id="&lt;Path&gt;" class="shp34" d="M810.37 649.06C810.36 649.06 810.35 649.06 810.34 649.06C795.46 653.4 803.04 659.18 819.65 663.21C827.05 661.16 841.41 657.58 850.04 655.96C867.67 652.64 885.46 650.38 903.33 649.06L810.37 649.06Z" />
            <path id="&lt;Path&gt;" class="shp34" d="M850.96 677.47C822.37 683.25 731.72 678.62 630.21 710.86C578.21 727.38 588.84 746.17 609.9 763.14C600.49 749.93 604.28 736.27 643.25 723.9C755.01 688.39 853.62 697.6 870.71 688.39C877.77 684.59 865.53 681.01 850.96 677.47Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp35" d="M941.31 647.63L1297.08 647.63C1300.49 647.63 1300.77 652.65 1297.38 653.02C1204.36 663.26 1032.13 686.43 1141.57 708.12C1287.52 737.04 1608.34 738.36 1547.85 747.56C1487.37 756.77 1370.35 777.8 1415.06 792.27C1444.86 801.91 1528.57 820.32 1666.19 847.49L1666.19 1013.16L165.96 1013.16L165.96 819.88C459.61 831.27 623.52 831.71 657.71 821.19C708.99 805.42 531.48 759.4 643.25 723.9C755.01 688.39 853.62 697.6 870.71 688.39C887.81 679.19 791.82 671.3 823.38 662.1C831.27 659.8 840.55 657.74 850.04 655.96C880.13 650.29 910.7 647.63 941.31 647.63Z" />
          <path id="&lt;Path&gt;" class="shp36" d="M742 874.28C629.28 876.86 257.43 889.24 343.07 873.76C684.18 872.39 817.15 872.56 742 874.28Z" />
          <path id="&lt;Path&gt;" class="shp37" d="M1304.9 964.31C1192.17 966.89 820.32 979.28 905.97 963.8C1247.07 962.42 1380.05 962.59 1304.9 964.31Z" />
          <path id="&lt;Path&gt;" class="shp38" d="M1355.39 754.08C1243.2 756.65 883.85 768.56 932.38 755.4C937.13 754.12 942.03 753.54 946.94 753.52C1004.56 753.29 1465.2 751.57 1355.39 754.08Z" />
          <path id="&lt;Path&gt;" class="shp39" d="M1547.85 747.56C1608.34 738.36 1287.52 737.04 1141.57 708.12C1032.13 686.43 1195.35 663.84 1288.37 653.61C1291.76 653.23 1300.49 647.63 1297.08 647.63L1251.89 647.63C1163.77 653.55 984.47 672.26 1009.55 687.7C1042.23 707.81 1100.94 706.43 1083.71 714.09C1072.4 719.12 903.97 720.38 966.82 731.69C1029.66 743 1406.74 740.49 1421.82 746.77C1436.9 753.06 1147.81 771.91 1184.26 775.68C1210.11 778.35 1304.18 775.97 1349.58 774.36C1290.84 778.19 1150.23 790.12 1181.75 793.28C1219.46 797.05 1446.96 807.1 1431.88 807.1C1416.79 807.1 1267.22 824.7 1337.61 839.78C1407.99 854.87 1666.92 858.64 1622.93 864.92C1578.93 871.2 1521.14 892.19 1575.17 893.01C1611.2 893.56 1641.54 890.44 1666.19 883.68L1666.19 847.49C1528.57 820.32 1444.86 801.91 1415.06 792.27C1370.35 777.8 1487.37 756.77 1547.85 747.56Z" />
          <path id="&lt;Path&gt;" class="shp40" d="M657.71 821.19C610.05 835.86 226.16 822.13 172.24 820.12L173.8 842.39C518.97 853.02 700.96 847.7 719.78 826.44C748 794.54 598.31 783.5 654.75 746.69C711.19 709.88 895.23 719.7 924.67 697.61C954.12 675.53 860.87 685.34 869.46 669.39C875.19 658.76 886.48 651.98 903.33 649.06C885.46 650.38 867.67 652.64 850.04 655.96C840.55 657.74 831.27 659.8 823.38 662.1C791.82 671.3 887.81 679.19 870.71 688.39C853.62 697.6 755.01 688.39 643.25 723.9C531.48 759.4 708.99 805.42 657.71 821.19Z" />
          <path id="&lt;Path&gt;" class="shp17" d="M1297.81 647.82C1298.57 649.34 1290.58 653.36 1288.37 653.61C1195.35 663.84 1032.13 686.43 1141.57 708.12C1287.52 737.04 1608.34 738.36 1547.85 747.56C1487.37 756.77 1370.35 777.8 1415.06 792.27C1444.86 801.91 1528.57 820.32 1666.19 847.49L1666.19 1011.59L1668.97 1011.59L1668.97 649.06L1297.81 647.82Z" />
          <path id="&lt;Path&gt;" class="shp17" d="M1335.29 649.06L1297.45 653.02C1297.43 653.02 1297.4 653.02 1297.38 653.02C1204.36 663.26 1032.13 686.43 1141.57 708.12C1287.52 737.04 1608.34 738.36 1547.85 747.56C1487.37 756.77 1370.35 777.8 1415.06 792.27C1444.86 801.91 1528.57 820.32 1666.19 847.49L1668.97 649.06L1335.29 649.06Z" />
          <path id="&lt;Path&gt;" class="shp18" d="M1483.66 651.88C1306.07 656.52 1230.62 660.3 1257.32 663.2C1297.37 667.55 1417.07 669.73 1394.43 672.34C1371.8 674.95 1300.41 675.38 1319.56 678C1338.72 680.61 1516.74 689.31 1488.02 691.92C1459.29 694.54 1299.11 698.89 1356.13 705.85C1394.14 710.5 1455.81 716.74 1541.12 724.57L1552.87 653.19L1483.66 651.88Z" />
          <path id="&lt;Path&gt;" class="shp41" d="M1556.95 738.74C1559.69 725.4 1294.88 729.68 1148.93 700.76C1124.02 695.82 1112.95 690.14 1112.97 685.22C1097.13 692.81 1104.08 700.69 1141.57 708.12C1256.87 730.97 1494.3 738.56 1553.67 744.45C1558.78 743.63 1556.21 742.29 1556.95 738.74Z" />
          <path id="&lt;Path&gt;" class="shp20" d="M1412.5 778.02C1510.43 738.95 1594.88 719.92 1665.85 720.94L1666.19 847.49C1481.26 812.88 1396.7 789.72 1412.5 778.02Z" />
          <path id="&lt;Path&gt;" class="shp19" d="M1421.41 781.09C1476.88 757.51 1531.18 741.9 1584.32 734.26C1664.04 722.8 1690.94 732.27 1647.1 743.73C1603.25 755.19 1458.28 781.09 1483.19 778.1C1508.1 775.11 1651.58 764.15 1611.72 776.11C1571.87 788.07 1549.95 810.99 1584.82 822.94C1608.07 830.91 1635.19 839.1 1666.19 847.49C1487.39 813.52 1405.8 791.39 1421.41 781.09Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp34" d="M1422.42 784.91C1415.72 782.74 1412.67 780.42 1412.5 778.02C1402.79 783.03 1401.69 787.94 1415.06 792.27C1444.86 801.91 1528.57 820.32 1666.19 847.49L1666.31 838.69C1629.41 831.34 1462.12 797.75 1422.42 784.91Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp42" d="M844.77 876.37C844.77 881.87 868.49 886.33 897.75 886.33C927.01 886.33 950.72 881.87 950.72 876.37C950.72 870.87 927.01 866.41 897.75 866.41C868.49 866.41 844.77 870.87 844.77 876.37Z" />
            <path id="&lt;Path&gt;" class="shp43" d="M858.16 877.92L939.75 877.92C941.44 877.92 942.03 875.69 940.56 874.84C932.89 870.44 918.74 861.18 904.99 846.24C886.29 825.91 858.59 850.64 853.77 872.56C853.17 875.32 855.33 877.92 858.16 877.92Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp44" d="M861.8 705.9C861.8 707.71 869.61 709.18 879.25 709.18C888.89 709.18 896.7 707.71 896.7 705.9C896.7 704.09 888.89 702.62 879.25 702.62C869.61 702.62 861.8 704.09 861.8 705.9Z" />
            <path id="&lt;Path&gt;" class="shp43" d="M866.21 706.41L893.08 706.41C893.64 706.41 893.83 705.68 893.35 705.4C890.83 703.95 886.16 700.9 881.63 695.98C875.48 689.28 866.35 697.43 864.76 704.65C864.56 705.56 865.28 706.41 866.21 706.41Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp45" d="M914.3 857.06L900.05 864.55L879.28 864.07L901.98 867.45L914.3 857.06Z" />
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp46" d="M1075.06 817.49C1075.06 822.07 1094.81 825.79 1119.18 825.79C1143.55 825.79 1163.3 822.07 1163.3 817.49C1163.3 812.91 1143.55 809.2 1119.18 809.2C1094.81 809.2 1075.06 812.91 1075.06 817.49Z" />
              <path id="&lt;Path&gt;" class="shp43" d="M1086.43 816.84C1085.14 816.82 1084.52 815.21 1085.45 814.28C1091.37 808.4 1107.16 794.36 1120.84 796.64C1138.08 799.52 1146.41 807.58 1150.86 809.3C1151.11 809.4 1151.34 809.51 1151.56 809.64C1155.42 811.87 1153.4 817.92 1148.94 817.84L1086.43 816.84Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp45" d="M1118.6 804.66L1137.68 802.73C1127.69 805.63 1121.33 806.27 1118.6 804.66Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp47" d="M1242.06 917.16C1242.06 921.74 1261.81 925.45 1286.18 925.45C1310.55 925.45 1330.3 921.74 1330.3 917.16C1330.3 912.58 1310.55 908.86 1286.18 908.86C1261.81 908.86 1242.06 912.58 1242.06 917.16Z" />
              <path id="&lt;Path&gt;" class="shp43" d="M1253.43 916.51C1252.14 916.49 1251.52 914.87 1252.45 913.95C1258.37 908.07 1275.4 902.46 1287.84 896.31C1306.67 887 1313.41 907.24 1317.86 908.97C1318.11 909.07 1318.34 909.18 1318.56 909.3C1322.42 911.54 1320.4 917.58 1315.94 917.51L1253.43 916.51Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp45" d="M1285.6 904.33L1304.68 902.39C1294.69 905.29 1288.33 905.94 1285.6 904.33Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp48" d="M1030.42 829.02C1033.8 828.96 1037.39 828.95 1040.3 830.68C1019.68 842.45 991.06 833.25 971.9 847.28C977.71 851.03 985.08 851.05 992 850.91C1044.07 849.9 1097 847.08 1147.44 860.05C1148.5 861.96 1146.04 863.87 1143.98 864.57C1133.93 867.93 1123.1 867.73 1112.51 867.73C1082.4 867.72 1052.29 869.63 1022.43 873.43C1018.1 873.98 1012.36 876.62 1013.73 880.77C1014.14 882 1015.16 882.94 1016.2 883.72C1024.05 889.54 1034.59 889.47 1044.33 890.29C1059.69 891.59 1074.82 895.74 1088.69 902.47C1093.41 904.75 1098.55 908.17 1098.94 913.4C1099.49 920.76 1090.66 924.84 1083.41 926.18C1066.19 929.35 1048.55 929.2 1031.04 929.03C1006.37 928.8 981.7 928.58 957.03 928.35C936.15 928.15 914.76 927.86 895.28 920.37C892.32 919.23 888.94 917.18 889.1 914.02C889.3 910.29 894.03 908.91 897.74 908.54C921.92 906.1 946.12 903.82 970.33 901.72C971.48 900.34 970.75 898.1 969.3 897.05C967.85 896 965.97 895.83 964.18 895.71C955.47 895.14 946.72 895.06 937.99 895.47C936.59 895.53 935.03 895.56 933.98 894.63C932.4 893.24 933.06 890.43 934.72 889.14C936.39 887.85 938.63 887.64 940.73 887.48C951.25 886.7 961.77 885.91 972.28 885.13C975.94 884.85 980.95 882.54 979.45 879.19C978.77 877.65 976.95 877.04 975.33 876.59C969.28 874.9 963.23 873.21 957.18 871.52C958.87 867.32 964.5 866.83 969.03 866.86C985.46 866.96 1001.91 864.87 1017.8 860.68C989.59 861.43 961.08 862.16 933.44 856.46C929.06 855.55 923.65 853.14 924 848.68C924.35 844.35 929.76 842.73 934.07 842.2C943.19 841.06 952.31 839.93 961.43 838.79C947.93 840.03 934.16 838.15 921.49 833.33C937.7 827.66 956.89 830.27 973.81 829.98C992.68 829.66 1011.55 829.34 1030.42 829.02Z" />
          <path id="&lt;Path&gt;" class="shp49" d="M745.91 757.99C877.29 761.46 931.92 762.76 909.81 761.89C876.64 760.59 742 770.34 771.27 772.3C800.54 774.25 944.93 784 923.46 784.65C902 785.3 678.92 787.9 693.88 784.65C708.83 781.4 760.22 780.75 745.26 776.85C730.3 772.95 650.3 758.64 682.82 753.43C715.34 748.23 752.41 749.53 733.55 746.28C714.69 743.03 684.12 739.78 702.33 736.52C720.54 733.27 937.12 723.52 903.95 725.47C870.78 727.42 768.67 737.82 794.04 743.03C810.95 746.5 794.9 751.48 745.91 757.99Z" />
          <path id="&lt;Path&gt;" class="shp50" d="" />
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1645.88 790.3C1645.88 793.18 1652.01 795.51 1659.58 795.51C1667.14 795.51 1673.27 793.18 1673.27 790.3C1673.27 787.43 1667.14 785.1 1659.58 785.1C1652.01 785.1 1645.88 787.43 1645.88 790.3Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp51" d="M1718.52 745.14L1679.88 732.42L1718.52 738.07L1707.21 704.14L1686.95 704.14L1685.53 695.19L1708.62 695.66L1660.12 559.93L1660.09 558.53L1659.85 559.19L1659.62 558.53L1659.59 559.93L1611.08 695.66L1634.17 695.19L1632.75 704.14L1612.49 704.14L1601.18 738.07L1639.82 732.42L1601.18 745.14L1598.83 761.64L1655.37 761.64L1664.33 761.64L1720.88 761.64L1718.52 745.14Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp52" d="M1659.29 574.08C1659.05 592.15 1658.94 610.21 1658.88 628.28L1658.86 682.47L1659.18 736.66L1659.25 750.21C1659.32 754.72 1659.12 759.24 1659.86 763.76C1661.43 772.79 1662.8 781.82 1659.29 790.85C1659.06 791.45 1658.38 791.75 1657.78 791.52C1657.46 791.4 1657.23 791.15 1657.11 790.85C1653.6 781.82 1654.97 772.79 1656.54 763.76C1657.28 759.24 1657.09 754.72 1657.15 750.21L1657.23 736.66L1657.54 682.47L1657.53 628.28C1657.46 610.21 1657.36 592.15 1657.11 574.08C1657.1 573.48 1657.58 572.99 1658.19 572.98C1658.79 572.97 1659.28 573.45 1659.29 574.05C1659.29 574.06 1659.29 574.08 1659.29 574.08Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp53" d="M1658.2 680.58L1626.63 666.45" />
                <path id="&lt;Path&gt;" class="shp53" d="M1660.09 633.46L1675.64 620.74" />
                <path id="&lt;Path&gt;" class="shp53" d="M1659.14 742.32L1675.64 730.53" />
                <path id="&lt;Path&gt;" class="shp54" d="M1601.18 745.14C1615.95 751.43 1634.64 754.1 1657.26 753.15C1679.88 752.21 1700.3 749.54 1718.52 745.14L1720.88 761.64L1598.83 761.64L1601.18 745.14Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1521.95 766.23C1521.95 768.78 1527.41 770.85 1534.13 770.85C1540.86 770.85 1546.31 768.78 1546.31 766.23C1546.31 763.67 1540.86 761.6 1534.13 761.6C1527.41 761.6 1521.95 763.67 1521.95 766.23Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp51" d="M1586.53 726.08L1552.18 714.76L1586.53 719.79L1576.48 689.63L1558.46 689.63L1557.21 681.67L1577.74 682.09L1534.61 561.42L1534.59 560.18L1534.38 560.76L1534.17 560.18L1534.14 561.42L1491.02 682.09L1511.54 681.67L1510.29 689.63L1492.27 689.63L1482.22 719.79L1516.57 714.76L1482.22 726.08L1480.12 740.74L1530.4 740.74L1538.36 740.74L1588.63 740.74L1586.53 726.08Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp52" d="M1533.88 574C1533.46 606.12 1533.46 638.24 1533.49 670.36L1533.78 718.53L1533.85 730.58C1533.9 734.59 1533.73 738.61 1534.39 742.62C1535.78 750.65 1537 758.68 1533.88 766.71C1533.67 767.25 1533.07 767.51 1532.53 767.3C1532.25 767.2 1532.04 766.97 1531.94 766.71C1528.82 758.68 1530.04 750.65 1531.43 742.62C1532.09 738.61 1531.92 734.59 1531.97 730.58L1532.04 718.53L1532.33 670.36C1532.36 638.24 1532.36 606.12 1531.94 574C1531.93 573.47 1532.36 573.03 1532.9 573.02C1533.43 573.01 1533.87 573.44 1533.88 573.98C1533.88 573.98 1533.88 573.99 1533.88 574Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp55" d="M1532.91 668.68L1504.84 656.11" />
                <path id="&lt;Path&gt;" class="shp55" d="M1534.59 626.79L1548.41 615.48" />
                <path id="&lt;Path&gt;" class="shp55" d="M1533.75 723.56L1548.41 713.09" />
                <path id="&lt;Path&gt;" class="shp56" d="M1482.22 726.08C1495.34 731.66 1511.96 734.04 1532.07 733.2C1552.18 732.36 1570.33 729.99 1586.53 726.08L1588.63 740.74L1480.12 740.74L1482.22 726.08Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1576.75 795.81C1576.75 798.37 1582.2 800.44 1588.92 800.44C1595.65 800.44 1601.1 798.37 1601.1 795.81C1601.1 793.26 1595.65 791.19 1588.92 791.19C1582.2 791.19 1576.75 793.26 1576.75 795.81Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1641.33 755.66L1606.97 744.35L1641.33 749.38L1631.27 719.22L1613.26 719.22L1612 711.26L1632.53 711.68L1589.4 591.01L1589.38 589.76L1589.17 590.35L1588.96 589.76L1588.93 591.01L1545.81 711.68L1566.34 711.26L1565.08 719.22L1547.06 719.22L1537.01 749.38L1571.36 744.35L1537.01 755.66L1534.92 770.33L1585.19 770.33L1593.15 770.33L1643.42 770.33L1641.33 755.66Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp57" d="M1588.67 603.59C1588.25 635.71 1588.26 667.83 1588.29 699.94L1588.57 748.12L1588.64 760.17C1588.69 764.18 1588.52 768.2 1589.18 772.21C1590.58 780.24 1591.79 788.27 1588.67 796.3C1588.46 796.84 1587.86 797.1 1587.33 796.89C1587.04 796.78 1586.84 796.56 1586.73 796.3C1583.61 788.27 1584.83 780.24 1586.22 772.21C1586.88 768.2 1586.71 764.18 1586.77 760.17L1586.84 748.12L1587.12 699.94C1587.15 667.83 1587.15 635.71 1586.73 603.59C1586.73 603.05 1587.15 602.61 1587.69 602.61C1588.22 602.6 1588.66 603.03 1588.67 603.56C1588.67 603.57 1588.67 603.58 1588.67 603.59Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp58" d="M1587.7 698.27L1559.63 685.7" />
                <path id="&lt;Path&gt;" class="shp58" d="M1589.38 656.38L1603.2 645.06" />
                <path id="&lt;Path&gt;" class="shp58" d="M1588.54 753.15L1603.2 742.68" />
                <path id="&lt;Path&gt;" class="shp59" d="M1537.01 755.66C1550.14 761.25 1566.76 763.62 1586.86 762.79C1606.97 761.95 1625.13 759.57 1641.33 755.66L1643.42 770.33L1534.92 770.33L1537.01 755.66Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1410.49 672.61C1410.49 674.7 1414.96 676.4 1420.47 676.4C1425.98 676.4 1430.45 674.7 1430.45 672.61C1430.45 670.52 1425.98 668.82 1420.47 668.82C1414.96 668.82 1410.49 670.52 1410.49 672.61Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp51" d="M1463.4 639.72L1435.26 630.45L1463.4 634.57L1455.16 609.86L1440.4 609.86L1439.37 603.34L1456.19 603.68L1420.86 504.82L1420.84 503.8L1420.67 504.28L1420.5 503.8L1420.48 504.82L1385.15 603.68L1401.96 603.34L1400.93 609.86L1386.18 609.86L1377.94 634.57L1406.08 630.45L1377.94 639.72L1376.22 651.73L1417.41 651.73L1423.93 651.73L1465.12 651.73L1463.4 639.72Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp52" d="M1420.26 515.13C1419.92 541.44 1419.92 567.76 1419.95 594.07L1420.18 633.54L1420.24 643.41C1420.28 646.7 1420.14 649.98 1420.68 653.27C1421.82 659.85 1422.82 666.43 1420.26 673.01C1420.09 673.45 1419.6 673.66 1419.16 673.49C1418.93 673.4 1418.76 673.22 1418.67 673.01C1416.12 666.43 1417.11 659.85 1418.26 653.27C1418.8 649.98 1418.66 646.7 1418.7 643.41L1418.76 633.54L1418.99 594.07C1419.01 567.76 1419.02 541.44 1418.67 515.13C1418.67 514.69 1419.02 514.33 1419.46 514.32C1419.9 514.32 1420.26 514.67 1420.26 515.11C1420.26 515.11 1420.26 515.12 1420.26 515.13Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp60" d="M1419.47 592.7L1396.47 582.4" />
                <path id="&lt;Path&gt;" class="shp60" d="M1420.84 558.37L1432.17 549.11" />
                <path id="&lt;Path&gt;" class="shp60" d="M1420.15 637.66L1432.17 629.08" />
                <path id="&lt;Path&gt;" class="shp61" d="M1377.94 639.72C1388.69 644.29 1402.31 646.24 1418.78 645.55C1435.26 644.87 1450.13 642.92 1463.4 639.72L1465.12 651.73L1376.22 651.73L1377.94 639.72Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1273.34 662.33C1273.34 664.19 1277.31 665.7 1282.21 665.7C1287.11 665.7 1291.08 664.19 1291.08 662.33C1291.08 660.47 1287.11 658.96 1282.21 658.96C1277.31 658.96 1273.34 660.47 1273.34 662.33Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp51" d="M1320.38 633.09L1295.36 624.85L1320.38 628.51L1313.06 606.54L1299.94 606.54L1299.02 600.74L1313.97 601.05L1282.56 513.16L1282.54 512.25L1282.39 512.68L1282.24 512.25L1282.22 513.16L1250.81 601.05L1265.76 600.74L1264.85 606.54L1251.73 606.54L1244.4 628.51L1269.42 624.85L1244.4 633.09L1242.88 643.77L1279.49 643.77L1285.29 643.77L1321.91 643.77L1320.38 633.09Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp52" d="M1282.03 522.32C1281.72 545.72 1281.73 569.11 1281.75 592.5L1281.95 627.59L1282 636.37C1282.04 639.29 1281.92 642.21 1282.4 645.14C1283.42 650.99 1284.3 656.83 1282.03 662.68C1281.88 663.07 1281.44 663.27 1281.05 663.11C1280.84 663.03 1280.69 662.87 1280.62 662.68C1278.34 656.83 1279.23 650.99 1280.25 645.14C1280.72 642.21 1280.6 639.29 1280.64 636.37L1280.69 627.59L1280.9 592.5C1280.92 569.11 1280.92 545.72 1280.62 522.32C1280.61 521.93 1280.92 521.61 1281.31 521.61C1281.7 521.6 1282.02 521.92 1282.03 522.31C1282.03 522.31 1282.03 522.32 1282.03 522.32Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp62" d="M1281.32 591.28L1260.88 582.13" />
                <path id="&lt;Path&gt;" class="shp62" d="M1282.54 560.77L1292.61 552.53" />
                <path id="&lt;Path&gt;" class="shp62" d="M1281.93 631.25L1292.61 623.63" />
                <path id="&lt;Path&gt;" class="shp63" d="M1244.4 633.09C1253.96 637.15 1266.07 638.88 1280.71 638.27C1295.36 637.66 1308.58 635.93 1320.38 633.09L1321.91 643.77L1242.88 643.77L1244.4 633.09Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1212.81 692.6C1212.81 694.46 1216.78 695.97 1221.68 695.97C1226.58 695.97 1230.55 694.46 1230.55 692.6C1230.55 690.74 1226.58 689.23 1221.68 689.23C1216.78 689.23 1212.81 690.74 1212.81 692.6Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1259.84 663.36L1234.82 655.12L1259.84 658.78L1252.52 636.81L1239.4 636.81L1238.48 631.01L1253.44 631.32L1222.03 543.43L1222.01 542.53L1221.85 542.95L1221.7 542.53L1221.68 543.43L1190.27 631.32L1205.22 631.01L1204.31 636.81L1191.19 636.81L1183.87 658.78L1208.89 655.12L1183.87 663.36L1182.34 674.04L1218.96 674.04L1224.75 674.04L1261.37 674.04L1259.84 663.36Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp57" d="M1221.49 552.59C1221.19 575.99 1221.19 599.38 1221.21 622.77L1221.42 657.86L1221.47 666.64C1221.51 669.56 1221.38 672.48 1221.86 675.41C1222.88 681.26 1223.77 687.11 1221.49 692.95C1221.34 693.34 1220.9 693.54 1220.51 693.38C1220.31 693.31 1220.16 693.14 1220.08 692.95C1217.81 687.11 1218.69 681.26 1219.71 675.41C1220.19 672.48 1220.06 669.56 1220.1 666.64L1220.16 657.86L1220.36 622.77C1220.38 599.38 1220.39 575.99 1220.08 552.59C1220.08 552.2 1220.39 551.88 1220.78 551.88C1221.17 551.87 1221.49 552.19 1221.49 552.58C1221.49 552.58 1221.49 552.59 1221.49 552.59Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp64" d="M1220.79 621.55L1200.34 612.4" />
                <path id="&lt;Path&gt;" class="shp64" d="M1222.01 591.04L1232.08 582.8" />
                <path id="&lt;Path&gt;" class="shp64" d="M1221.4 661.53L1232.08 653.9" />
                <path id="&lt;Path&gt;" class="shp65" d="M1183.87 663.36C1193.43 667.42 1205.53 669.15 1220.18 668.54C1234.82 667.93 1248.04 666.2 1259.84 663.36L1261.37 674.04L1182.34 674.04L1183.87 663.36Z" />
              </g>
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M1264.72 707.28C1261.68 686.04 1264.09 674.87 1271.94 673.76C1280.18 672.59 1284.53 683.77 1285 707.28L1264.72 707.28Z" />
                <path id="&lt;Path&gt;" class="shp66" d="M1276.36 706.57C1272.79 691.08 1271.31 681.88 1271.9 678.98" />
                <path id="&lt;Path&gt;" class="shp66" d="M1275.91 704.21C1276.6 702.15 1277.98 700.82 1280.04 700.23" />
                <path id="&lt;Path&gt;" class="shp66" d="M1274.66 699.79C1273.28 698.21 1271.58 697.5 1269.57 697.65" />
                <path id="&lt;Path&gt;" class="shp66" d="M1273.93 693.77C1274.56 691.14 1275.74 689.63 1277.46 689.24" />
                <path id="&lt;Path&gt;" class="shp66" d="M1272.33 686.67C1272.97 684.04 1274.15 682.52 1275.87 682.13" />
                <path id="&lt;Path&gt;" class="shp66" d="M1267.65 691.16C1271.2 691.03 1273.29 692.04 1273.93 694.21" />
                <path id="&lt;Path&gt;" class="shp66" d="M1266.05 683.61C1269.6 683.49 1271.69 684.5 1272.33 686.67" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1301.47 707.28C1304.51 690.98 1302.1 682.28 1294.24 681.17C1286 680 1281.65 688.71 1281.19 707.28L1301.47 707.28Z" />
                <path id="&lt;Path&gt;" class="shp66" d="M1289.83 706.57C1293.23 694.18 1294.63 686.53 1294.04 683.63" />
                <path id="&lt;Path&gt;" class="shp66" d="M1290.27 704.21C1289.59 702.15 1288.21 700.82 1286.14 700.23" />
                <path id="&lt;Path&gt;" class="shp66" d="M1291.53 699.79C1292.91 698.21 1294.6 697.5 1296.62 697.65" />
                <path id="&lt;Path&gt;" class="shp66" d="M1292.86 693.07C1291.82 690.91 1290.45 689.63 1288.73 689.24" />
                <path id="&lt;Path&gt;" class="shp66" d="M1298.54 691.16C1295.19 691.89 1293.2 693.34 1292.56 695.51" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M1264.31 703.52C1262.83 701.3 1260.51 704.65 1260.51 707.32L1270.15 707.32C1270.15 704.65 1265.47 705.27 1264.31 703.52Z" />
              <path id="&lt;Path&gt;" class="shp43" d="M1302.96 704.74C1303.97 703.24 1305.54 705.51 1305.54 707.32L1299 707.32C1299 705.51 1302.17 705.93 1302.96 704.74Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M1460.31 786.32C1455.72 754.23 1459.36 737.34 1471.24 735.66C1483.69 733.9 1490.26 750.79 1490.97 786.32L1460.31 786.32Z" />
                <path id="&lt;Path&gt;" class="shp67" d="M1477.9 785.26C1472.52 761.84 1470.27 747.94 1471.17 743.55" />
                <path id="&lt;Path&gt;" class="shp67" d="M1477.23 781.69C1478.27 778.57 1480.35 776.56 1483.48 775.67" />
                <path id="&lt;Path&gt;" class="shp67" d="M1475.34 775C1473.26 772.62 1470.69 771.54 1467.64 771.76" />
                <path id="&lt;Path&gt;" class="shp67" d="M1474.23 765.91C1475.19 761.93 1476.97 759.65 1479.57 759.05" />
                <path id="&lt;Path&gt;" class="shp67" d="M1471.82 755.17C1472.78 751.19 1474.56 748.91 1477.16 748.31" />
                <path id="&lt;Path&gt;" class="shp67" d="M1464.74 761.95C1470.1 761.76 1473.27 763.29 1474.23 766.57" />
                <path id="&lt;Path&gt;" class="shp67" d="M1462.33 750.55C1467.69 750.36 1470.86 751.9 1471.82 755.17" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1515.86 786.32C1520.46 761.69 1516.82 748.54 1504.94 746.86C1492.49 745.1 1485.91 758.25 1485.21 786.32L1515.86 786.32Z" />
                <path id="&lt;Path&gt;" class="shp67" d="M1498.27 785.26C1503.4 766.52 1505.52 754.96 1504.63 750.58" />
                <path id="&lt;Path&gt;" class="shp67" d="M1498.94 781.69C1497.9 778.57 1495.82 776.56 1492.7 775.67" />
                <path id="&lt;Path&gt;" class="shp67" d="M1500.84 775C1502.92 772.62 1505.48 771.54 1508.53 771.76" />
                <path id="&lt;Path&gt;" class="shp67" d="M1502.84 764.85C1501.28 761.58 1499.2 759.65 1496.6 759.05" />
                <path id="&lt;Path&gt;" class="shp67" d="M1511.43 761.95C1506.37 763.07 1503.36 765.26 1502.4 768.53" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M1459.69 780.64C1457.46 777.29 1453.95 782.35 1453.95 786.38L1468.52 786.38C1468.52 782.35 1461.45 783.29 1459.69 780.64Z" />
              <path id="&lt;Path&gt;" class="shp43" d="M1518.12 782.48C1519.64 780.21 1522.02 783.65 1522.02 786.38L1512.13 786.38C1512.13 783.65 1516.92 784.28 1518.12 782.48Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp43" d="M1347.63 678.8C1347.63 680.5 1351.26 679.66 1355.73 679.66C1360.2 679.66 1363.82 680.5 1363.82 678.8C1363.82 677.11 1360.2 675.74 1355.73 675.74C1351.26 675.74 1347.63 677.11 1347.63 678.8Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp26" d="" />
          <path id="&lt;Path&gt;" class="shp18" d="M171 755C193.67 768.33 212.33 776 227 778C207.67 785.33 189 777.67 171 755Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp18" d="M239.97 715.3C239.97 718.02 245.77 720.22 252.93 720.22C260.09 720.22 265.89 718.02 265.89 715.3C265.89 712.58 260.09 710.37 252.93 710.37C245.77 710.37 239.97 712.58 239.97 715.3Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp51" d="M308.7 672.57L272.14 660.53L308.7 665.88L298 633.78L278.82 633.78L277.49 625.3L299.33 625.75L253.44 497.32L253.41 496L253.19 496.62L252.96 496L252.94 497.32L207.04 625.75L228.89 625.3L227.55 633.78L208.38 633.78L197.67 665.88L234.24 660.53L197.67 672.57L195.44 688.17L248.95 688.17L257.42 688.17L310.93 688.17L308.7 672.57Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp52" d="M252.66 510.71C252.21 544.9 252.21 579.08 252.25 613.27L252.55 664.54L252.62 677.36C252.68 681.63 252.5 685.91 253.2 690.18C254.68 698.73 255.98 707.27 252.66 715.82C252.44 716.39 251.79 716.67 251.22 716.45C250.93 716.33 250.7 716.1 250.59 715.82C247.27 707.27 248.57 698.73 250.05 690.18C250.75 685.91 250.57 681.63 250.63 677.36L250.7 664.54L251 613.27C251.04 579.08 251.04 544.9 250.59 510.71C250.59 510.14 251.04 509.68 251.61 509.67C252.18 509.66 252.65 510.12 252.66 510.69C252.66 510.69 252.66 510.71 252.66 510.71Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp68" d="M251.63 611.48L221.75 598.11" />
              <path id="&lt;Path&gt;" class="shp68" d="M253.41 566.89L268.12 554.86" />
              <path id="&lt;Path&gt;" class="shp68" d="M252.52 669.89L268.12 658.75" />
              <path id="&lt;Path&gt;" class="shp69" d="M197.67 672.57C211.65 678.51 229.33 681.04 250.73 680.15C272.14 679.26 291.46 676.73 308.7 672.57L310.93 688.17L195.44 688.17L197.67 672.57Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp18" d="M558.87 776.3C558.87 779.02 564.67 781.22 571.83 781.22C578.99 781.22 584.79 779.02 584.79 776.3C584.79 773.58 578.99 771.37 571.83 771.37C564.67 771.37 558.87 773.58 558.87 776.3Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp21" d="M627.6 733.57L591.04 721.53L627.6 726.88L616.9 694.78L597.73 694.78L596.39 686.3L618.24 686.75L572.34 558.32L572.31 557L572.09 557.62L571.87 557L571.84 558.32L525.94 686.75L547.79 686.3L546.45 694.78L527.28 694.78L516.58 726.88L553.14 721.53L516.58 733.57L514.35 749.17L567.85 749.17L576.32 749.17L629.83 749.17L627.6 733.57Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp57" d="M571.56 571.71C571.11 605.9 571.12 640.08 571.15 674.27L571.45 725.54L571.52 738.36C571.58 742.63 571.4 746.91 572.1 751.18C573.59 759.73 574.88 768.27 571.56 776.82C571.34 777.39 570.7 777.67 570.13 777.45C569.83 777.33 569.61 777.1 569.5 776.82C566.17 768.27 567.47 759.73 568.96 751.18C569.65 746.91 569.47 742.63 569.53 738.36L569.61 725.54L569.91 674.27C569.94 640.08 569.94 605.9 569.5 571.71C569.49 571.14 569.95 570.68 570.51 570.67C571.08 570.66 571.55 571.12 571.56 571.69C571.56 571.69 571.56 571.71 571.56 571.71Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp70" d="M570.53 672.48L540.65 659.11" />
              <path id="&lt;Path&gt;" class="shp70" d="M572.31 627.89L587.03 615.86" />
              <path id="&lt;Path&gt;" class="shp70" d="M571.42 730.89L587.03 719.75" />
              <path id="&lt;Path&gt;" class="shp71" d="M516.58 733.57C530.55 739.51 548.23 742.04 569.64 741.15C591.04 740.26 610.36 737.73 627.6 733.57L629.83 749.17L514.35 749.17L516.58 733.57Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp32" d="M458.7 793.7C453.67 758.63 457.65 740.18 470.63 738.34C484.24 736.42 491.42 754.87 492.19 793.7L458.7 793.7Z" />
              <path id="&lt;Path&gt;" class="shp72" d="M477.92 792.54C472.03 766.95 469.58 751.76 470.56 746.96" />
              <path id="&lt;Path&gt;" class="shp72" d="M477.19 788.64C478.32 785.23 480.6 783.04 484.01 782.06" />
              <path id="&lt;Path&gt;" class="shp72" d="M475.11 781.33C472.84 778.73 470.04 777.55 466.71 777.8" />
              <path id="&lt;Path&gt;" class="shp72" d="M473.91 771.4C474.96 767.05 476.9 764.55 479.74 763.9" />
              <path id="&lt;Path&gt;" class="shp72" d="M471.27 759.66C472.32 755.32 474.27 752.82 477.11 752.17" />
              <path id="&lt;Path&gt;" class="shp72" d="M463.54 767.07C469.4 766.86 472.85 768.54 473.91 772.11" />
              <path id="&lt;Path&gt;" class="shp72" d="M460.9 754.62C466.76 754.4 470.22 756.08 471.27 759.66" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp21" d="M519.4 793.7C524.42 766.79 520.44 752.41 507.46 750.58C493.86 748.65 486.67 763.03 485.9 793.7L519.4 793.7Z" />
              <path id="&lt;Path&gt;" class="shp72" d="M500.18 792.54C505.78 772.07 508.1 759.44 507.12 754.64" />
              <path id="&lt;Path&gt;" class="shp72" d="M500.91 788.64C499.77 785.23 497.5 783.04 494.08 782.06" />
              <path id="&lt;Path&gt;" class="shp72" d="M502.98 781.33C505.25 778.73 508.06 777.55 511.39 777.8" />
              <path id="&lt;Path&gt;" class="shp72" d="M505.17 770.24C503.47 766.67 501.19 764.55 498.35 763.9" />
              <path id="&lt;Path&gt;" class="shp72" d="M514.55 767.07C509.03 768.29 505.74 770.69 504.68 774.26" />
            </g>
            <path id="&lt;Path&gt;" class="shp43" d="M458.01 787.49C455.58 783.84 451.74 789.37 451.74 793.76L467.66 793.76C467.66 789.37 459.94 790.39 458.01 787.49Z" />
            <path id="&lt;Path&gt;" class="shp43" d="M521.87 789.51C523.52 787.02 526.12 790.78 526.12 793.76L515.32 793.76C515.32 790.78 520.56 791.47 521.87 789.51Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp73" d="M532.61 1003.72C537.1 923 546.07 866.19 559.52 833.31" />
                <path id="&lt;Path&gt;" class="shp73" d="M540.89 918.61C537.22 907.94 530.89 900.61 521.89 896.61" />
                <path id="&lt;Path&gt;" class="shp73" d="M549.39 869.61C557.39 864.61 564.72 862.78 571.39 864.11" />
                <path id="&lt;Path&gt;" class="shp73" d="M554.89 844.11C550.55 833.11 546.22 826.78 541.89 825.11" />
                <path id="&lt;Path&gt;" class="shp57" d="M568.66 863.09C573.81 862.95 577.63 863.3 580.14 864.14C582.64 864.97 584.45 866.36 585.57 868.31C576.8 869.84 571.16 868.1 568.66 863.09Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M524.81 899.43C520.29 896.96 517.16 894.72 515.42 892.74C513.68 890.75 512.82 888.64 512.84 886.39C521.18 889.5 525.17 893.84 524.81 899.43Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M544.01 828.24C539.5 825.76 536.37 823.53 534.63 821.55C532.89 819.56 532.03 817.45 532.05 815.2C540.39 818.31 544.38 822.65 544.01 828.24Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp73" d="M624.92 1021.48C636.32 941.44 638.64 883.98 631.88 849.1" />
                <path id="&lt;Path&gt;" class="shp73" d="M633.46 936.4C639.15 926.65 646.79 920.7 656.4 918.54" />
                <path id="&lt;Path&gt;" class="shp73" d="M634.72 886.68C627.85 880.21 621.02 876.98 614.22 876.98" />
                <path id="&lt;Path&gt;" class="shp73" d="M634.31 860.6C640.72 850.66 646.2 845.3 650.78 844.51" />
                <path id="&lt;Path&gt;" class="shp57" d="M617.1 876.52C612.07 875.37 608.25 874.96 605.63 875.29C603.01 875.62 600.97 876.63 599.49 878.33C607.79 881.54 613.66 880.94 617.1 876.52Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M652.99 920.74C657.9 919.19 661.41 917.61 663.5 916.01C665.6 914.4 666.86 912.5 667.27 910.29C658.49 911.7 653.72 915.18 652.99 920.74Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M648.08 847.16C653 845.62 656.5 844.04 658.6 842.43C660.7 840.83 661.95 838.92 662.37 836.72C653.58 838.13 648.82 841.61 648.08 847.16Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp73" d="M678.22 1018.34C689.5 938.28 703.23 882.43 719.41 850.8" />
                <path id="&lt;Path&gt;" class="shp73" d="M693.64 934.23C690.89 923.29 685.2 915.45 676.57 910.7" />
                <path id="&lt;Path&gt;" class="shp73" d="M706.25 886.12C714.64 881.81 722.1 880.6 728.63 882.49" />
                <path id="&lt;Path&gt;" class="shp73" d="M713.88 861.17C710.49 849.85 706.7 843.17 702.53 841.15" />
                <path id="&lt;Path&gt;" class="shp57" d="M726 881.25C731.14 881.54 734.92 882.21 737.35 883.26C739.77 884.3 741.46 885.84 742.41 887.88C733.54 888.66 728.07 886.45 726 881.25Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M679.23 913.76C674.94 910.91 672.01 908.42 670.45 906.3C668.88 904.17 668.2 901.99 668.41 899.76C676.46 903.56 680.07 908.22 679.23 913.76Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M704.38 844.44C700.09 841.59 697.16 839.11 695.59 836.98C694.02 834.85 693.35 832.68 693.56 830.44C701.61 834.24 705.22 838.91 704.38 844.44Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp73" d="M760.45 1063.62C777.03 984.49 783.08 927.3 778.6 892.05" />
                <path id="&lt;Path&gt;" class="shp73" d="M774.51 979.27C780.81 969.91 788.83 964.47 798.56 962.94" />
                <path id="&lt;Path&gt;" class="shp73" d="M778.99 929.74C772.56 922.84 765.95 919.17 759.16 918.73" />
                <path id="&lt;Path&gt;" class="shp73" d="M780.28 903.68C787.32 894.18 793.14 889.19 797.76 888.7" />
                <path id="&lt;Path&gt;" class="shp57" d="M762.07 918.45C757.13 916.98 753.34 916.33 750.71 916.48C748.07 916.64 745.96 917.52 744.38 919.11C752.45 922.86 758.35 922.64 762.07 918.45Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M795.01 964.91C800.01 963.69 803.61 962.34 805.81 960.87C808 959.41 809.38 957.59 809.94 955.41C801.08 956.25 796.1 959.42 795.01 964.91Z" />
                <path id="&lt;Path&gt;" class="shp57" d="M794.9 891.17C799.9 889.95 803.5 888.6 805.7 887.14C807.89 885.67 809.27 883.85 809.83 881.68C800.97 882.51 795.99 885.68 794.9 891.17Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M556 1037.1C542.26 963.48 553.15 924.16 588.65 919.14C625.87 913.87 645.53 953.19 647.64 1037.1L556 1037.1Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M608.58 1033.93C593.25 977.93 586.91 943.37 589.58 930.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M606.58 1023.26C609.69 1013.93 615.91 1007.93 625.25 1005.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M600.91 1003.26C594.69 996.15 587.02 992.93 577.91 993.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M594.91 972.93C599.58 963.15 605.8 957.37 613.58 955.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M569.25 964.26C584.36 967.59 593.36 974.15 596.25 983.93" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M722.05 1037.1C735.79 963.48 724.91 924.16 689.4 919.14C652.18 913.87 632.52 953.19 630.41 1037.1L722.05 1037.1Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M669.47 1033.93C684.81 977.93 691.14 943.37 688.47 930.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M671.47 1023.26C668.36 1013.93 662.14 1007.93 652.81 1005.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M677.14 1003.26C683.36 996.15 691.03 992.93 700.14 993.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M683.14 972.93C678.47 963.15 672.25 957.37 664.47 955.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M708.81 964.26C693.69 967.59 684.69 974.15 681.81 983.93" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M554.14 1020.12C547.47 1010.12 536.98 1025.25 536.98 1037.27L580.53 1037.27C580.53 1025.25 559.41 1028.04 554.14 1020.12Z" />
              <path id="&lt;Path&gt;" class="shp75" d="M728.81 1025.63C733.34 1018.84 740.45 1029.11 740.45 1037.27L710.89 1037.27C710.89 1029.11 725.23 1031 728.81 1025.63Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M697.53 1037.1C683.79 941.17 694.67 890.69 730.18 885.66C767.4 880.4 787.06 930.88 789.16 1037.1L697.53 1037.1Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M750.1 1033.93C734.01 963.92 727.3 922.36 729.97 909.25" />
                <path id="&lt;Path&gt;" class="shp74" d="M748.1 1023.26C751.22 1013.93 757.44 1007.93 766.77 1005.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M742.44 1003.26C736.22 996.15 728.55 992.93 719.44 993.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M739.14 976.09C742.01 964.2 747.33 957.37 755.1 955.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M731.93 943.98C734.8 932.1 740.12 925.27 747.9 923.49" />
                <path id="&lt;Path&gt;" class="shp74" d="M710.77 964.26C726.8 963.68 736.25 968.27 739.14 978.05" />
                <path id="&lt;Path&gt;" class="shp74" d="M703.56 930.19C719.59 929.6 729.04 934.2 731.93 943.98" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M863.57 1037.1C877.31 963.48 866.43 924.16 830.92 919.14C793.71 913.87 774.04 953.19 771.94 1037.1L863.57 1037.1Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M811 1033.93C826.33 977.93 832.66 943.37 830 930.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M813 1023.26C809.88 1013.93 803.66 1007.93 794.33 1005.26" />
                <path id="&lt;Path&gt;" class="shp74" d="M818.66 1003.26C824.88 996.15 832.55 992.93 841.66 993.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M824.66 972.93C820 963.15 813.77 957.37 806 955.59" />
                <path id="&lt;Path&gt;" class="shp74" d="M850.33 964.26C835.22 967.59 826.22 974.15 823.33 983.93" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M695.66 1020.12C688.99 1010.12 678.51 1025.25 678.51 1037.27L722.05 1037.27C722.05 1025.25 700.94 1028.04 695.66 1020.12Z" />
              <path id="&lt;Path&gt;" class="shp43" d="M870.33 1025.63C874.86 1018.84 881.98 1029.11 881.98 1037.27L852.42 1037.27C852.42 1029.11 866.75 1031 870.33 1025.63Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M424.25 1037.13C412.42 954.56 421.79 911.11 452.35 906.79C484.38 902.26 501.31 945.7 503.12 1037.13L424.25 1037.13Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M469.5 1034.39C455.65 974.14 449.88 938.38 452.17 927.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M467.78 1025.21C470.46 1017.18 475.81 1012.02 483.85 1009.72" />
                <path id="&lt;Path&gt;" class="shp73" d="M462.9 1008C457.55 1001.88 450.95 999.11 443.11 999.68" />
                <path id="&lt;Path&gt;" class="shp73" d="M460.07 984.61C462.53 974.38 467.11 968.5 473.81 966.97" />
                <path id="&lt;Path&gt;" class="shp73" d="M453.86 956.98C456.33 946.75 460.91 940.87 467.6 939.34" />
                <path id="&lt;Path&gt;" class="shp73" d="M435.65 974.43C449.44 973.93 457.58 977.89 460.07 986.3" />
                <path id="&lt;Path&gt;" class="shp73" d="M429.45 945.11C443.24 944.61 451.38 948.56 453.86 956.98" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M567.16 1037.13C578.99 973.77 569.62 939.92 539.06 935.6C507.03 931.06 490.11 964.91 488.29 1037.13L567.16 1037.13Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M521.91 1034.39C535.11 986.2 540.56 956.46 538.26 945.17" />
                <path id="&lt;Path&gt;" class="shp73" d="M523.63 1025.21C520.95 1017.18 515.6 1012.02 507.57 1009.72" />
                <path id="&lt;Path&gt;" class="shp73" d="M528.51 1008C533.86 1001.88 540.46 999.11 548.3 999.68" />
                <path id="&lt;Path&gt;" class="shp73" d="M533.67 981.89C529.66 973.48 524.3 968.5 517.61 966.97" />
                <path id="&lt;Path&gt;" class="shp73" d="M555.76 974.43C542.76 977.3 535.01 982.94 532.52 991.36" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M422.64 1022.51C416.9 1013.9 407.88 1026.93 407.88 1037.27L445.36 1037.27C445.36 1026.93 427.19 1029.32 422.64 1022.51Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M993 1069.51C979.26 995.89 990.15 956.57 1025.65 951.54C1062.87 946.28 1082.53 985.6 1084.64 1069.51L993 1069.51Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M1045.58 1066.33C1030.25 1010.33 1023.91 975.78 1026.58 962.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1043.58 1055.67C1046.69 1046.33 1052.91 1040.33 1062.25 1037.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1037.91 1035.67C1031.69 1028.55 1024.02 1025.33 1014.91 1026" />
                <path id="&lt;Path&gt;" class="shp74" d="M1031.91 1005.33C1036.58 995.56 1042.8 989.78 1050.58 988" />
                <path id="&lt;Path&gt;" class="shp74" d="M1006.25 996.67C1021.36 1000 1030.36 1006.56 1033.25 1016.33" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1159.05 1069.51C1172.79 995.89 1161.91 956.57 1126.4 951.54C1089.18 946.28 1069.52 985.6 1067.41 1069.51L1159.05 1069.51Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M1106.47 1066.33C1121.81 1010.33 1128.14 975.78 1125.47 962.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1108.47 1055.67C1105.36 1046.33 1099.14 1040.33 1089.81 1037.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1114.14 1035.67C1120.36 1028.56 1128.03 1025.33 1137.14 1026" />
                <path id="&lt;Path&gt;" class="shp74" d="M1120.14 1005.33C1115.47 995.56 1109.25 989.78 1101.47 988" />
                <path id="&lt;Path&gt;" class="shp74" d="M1145.81 996.67C1130.69 1000 1121.69 1006.56 1118.81 1016.33" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M991.14 1052.53C984.47 1042.52 973.98 1057.66 973.98 1069.68L1017.53 1069.68C1017.53 1057.66 996.41 1060.44 991.14 1052.53Z" />
              <path id="&lt;Path&gt;" class="shp75" d="M1165.81 1058.04C1170.34 1051.25 1177.45 1061.52 1177.45 1069.68L1147.89 1069.68C1147.89 1061.52 1162.23 1063.41 1165.81 1058.04Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M1134.53 1069.51C1120.79 973.58 1131.67 923.1 1167.18 918.07C1204.4 912.8 1224.06 963.28 1226.16 1069.51L1134.53 1069.51Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M1187.1 1066.33C1171.01 996.33 1164.3 954.77 1166.97 941.66" />
                <path id="&lt;Path&gt;" class="shp74" d="M1185.1 1055.67C1188.22 1046.33 1194.44 1040.33 1203.77 1037.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1179.44 1035.67C1173.22 1028.55 1165.55 1025.33 1156.44 1026" />
                <path id="&lt;Path&gt;" class="shp74" d="M1176.14 1008.49C1179.01 996.61 1184.33 989.78 1192.1 988" />
                <path id="&lt;Path&gt;" class="shp74" d="M1168.93 976.39C1171.8 964.5 1177.12 957.67 1184.9 955.89" />
                <path id="&lt;Path&gt;" class="shp74" d="M1147.77 996.67C1163.8 996.08 1173.25 1000.68 1176.14 1010.46" />
                <path id="&lt;Path&gt;" class="shp74" d="M1140.56 962.59C1156.59 962.01 1166.04 966.61 1168.93 976.39" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1300.57 1069.51C1314.31 995.89 1303.43 956.57 1267.92 951.54C1230.71 946.28 1211.04 985.6 1208.94 1069.51L1300.57 1069.51Z" />
                <path id="&lt;Path&gt;" class="shp74" d="M1248 1066.33C1263.33 1010.33 1269.66 975.78 1267 962.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1250 1055.67C1246.88 1046.33 1240.66 1040.33 1231.33 1037.67" />
                <path id="&lt;Path&gt;" class="shp74" d="M1255.66 1035.67C1261.88 1028.56 1269.55 1025.33 1278.66 1026" />
                <path id="&lt;Path&gt;" class="shp74" d="M1261.66 1005.33C1257 995.56 1250.77 989.78 1243 988" />
                <path id="&lt;Path&gt;" class="shp74" d="M1287.33 996.67C1272.22 1000 1263.22 1006.56 1260.33 1016.33" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M1132.66 1052.53C1125.99 1042.52 1115.51 1057.66 1115.51 1069.68L1159.05 1069.68C1159.05 1057.66 1137.94 1060.44 1132.66 1052.53Z" />
              <path id="&lt;Path&gt;" class="shp43" d="M1307.33 1058.04C1311.86 1051.25 1318.98 1061.52 1318.98 1069.68L1289.42 1069.68C1289.42 1061.52 1303.75 1063.41 1307.33 1058.04Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M861.25 1069.53C849.42 986.97 858.79 943.52 889.35 939.2C921.38 934.66 938.31 978.11 940.12 1069.53L861.25 1069.53Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M906.5 1066.8C892.65 1006.55 886.88 970.78 889.17 959.5" />
                <path id="&lt;Path&gt;" class="shp73" d="M904.78 1057.62C907.46 1049.59 912.81 1044.42 920.85 1042.13" />
                <path id="&lt;Path&gt;" class="shp73" d="M899.9 1040.41C894.55 1034.29 887.95 1031.51 880.11 1032.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M897.07 1017.02C899.53 1006.79 904.11 1000.91 910.81 999.38" />
                <path id="&lt;Path&gt;" class="shp73" d="M890.86 989.38C893.33 979.16 897.91 973.28 904.6 971.75" />
                <path id="&lt;Path&gt;" class="shp73" d="M872.65 1006.84C886.44 1006.34 894.58 1010.29 897.07 1018.71" />
                <path id="&lt;Path&gt;" class="shp73" d="M866.45 977.52C880.24 977.01 888.38 980.97 890.86 989.38" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1004.16 1069.53C1015.99 1006.17 1006.62 972.33 976.06 968C944.03 963.47 927.11 997.31 925.29 1069.53L1004.16 1069.53Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M958.91 1066.8C972.11 1018.6 977.56 988.86 975.26 977.58" />
                <path id="&lt;Path&gt;" class="shp73" d="M960.63 1057.62C957.95 1049.59 952.6 1044.42 944.57 1042.13" />
                <path id="&lt;Path&gt;" class="shp73" d="M965.51 1040.41C970.86 1034.29 977.46 1031.51 985.3 1032.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M970.67 1014.3C966.66 1005.88 961.3 1000.91 954.61 999.38" />
                <path id="&lt;Path&gt;" class="shp73" d="M992.76 1006.84C979.76 1009.71 972.01 1015.35 969.52 1023.77" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M859.64 1054.92C853.9 1046.31 844.88 1059.33 844.88 1069.68L882.36 1069.68C882.36 1059.33 864.19 1061.73 859.64 1054.92Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M1448.25 1069.53C1436.42 986.97 1445.79 943.52 1476.35 939.2C1508.38 934.66 1525.31 978.11 1527.12 1069.53L1448.25 1069.53Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M1493.5 1066.8C1479.65 1006.55 1473.88 970.78 1476.17 959.5" />
                <path id="&lt;Path&gt;" class="shp73" d="M1491.78 1057.62C1494.46 1049.59 1499.81 1044.42 1507.85 1042.13" />
                <path id="&lt;Path&gt;" class="shp73" d="M1486.9 1040.41C1481.55 1034.29 1474.95 1031.51 1467.11 1032.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M1484.07 1017.02C1486.53 1006.79 1491.11 1000.91 1497.81 999.38" />
                <path id="&lt;Path&gt;" class="shp73" d="M1477.86 989.38C1480.33 979.16 1484.91 973.28 1491.6 971.75" />
                <path id="&lt;Path&gt;" class="shp73" d="M1459.65 1006.84C1473.44 1006.34 1481.58 1010.29 1484.07 1018.71" />
                <path id="&lt;Path&gt;" class="shp73" d="M1453.45 977.52C1467.24 977.01 1475.38 980.97 1477.86 989.38" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1591.16 1069.53C1602.99 1006.17 1593.62 972.33 1563.06 968C1531.03 963.47 1514.11 997.31 1512.29 1069.53L1591.16 1069.53Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M1545.91 1066.8C1559.11 1018.6 1564.56 988.86 1562.26 977.58" />
                <path id="&lt;Path&gt;" class="shp73" d="M1547.63 1057.62C1544.95 1049.59 1539.6 1044.42 1531.57 1042.13" />
                <path id="&lt;Path&gt;" class="shp73" d="M1552.51 1040.41C1557.86 1034.29 1564.46 1031.51 1572.3 1032.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M1557.67 1014.3C1553.66 1005.88 1548.3 1000.91 1541.61 999.38" />
                <path id="&lt;Path&gt;" class="shp73" d="M1579.76 1006.84C1566.76 1009.71 1559.01 1015.35 1556.52 1023.77" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M1446.64 1054.92C1440.9 1046.31 1431.88 1059.33 1431.88 1069.68L1469.36 1069.68C1469.36 1059.33 1451.19 1061.73 1446.64 1054.92Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp32" d="M1407.99 1042.23C1401.67 1004.14 1374.15 956.29 1325.44 898.67C1368.73 923.63 1396.25 971.49 1407.99 1042.23Z" />
              <path id="&lt;Path&gt;" class="shp32" d="M1401.47 1046.67C1404.03 1008.14 1388.19 955.26 1353.95 888.03C1390.38 922.23 1406.22 975.11 1401.47 1046.67Z" />
              <path id="&lt;Path&gt;" class="shp32" d="M1405.74 1042.61C1399.41 1004.52 1409.99 950.34 1437.47 880.07C1404.57 917.69 1393.99 971.86 1405.74 1042.61Z" />
              <path id="&lt;Path&gt;" class="shp32" d="M1413.35 1044.7C1398.47 1009.06 1396.38 953.9 1407.05 879.21C1383.63 923.35 1385.73 978.52 1413.35 1044.7Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M1309.25 1069.53C1297.42 986.97 1306.79 943.52 1337.35 939.2C1369.38 934.66 1386.31 978.11 1388.12 1069.53L1309.25 1069.53Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M1354.5 1066.8C1340.65 1006.55 1334.88 970.78 1337.17 959.5" />
                <path id="&lt;Path&gt;" class="shp73" d="M1352.78 1057.62C1355.46 1049.59 1360.81 1044.42 1368.85 1042.13" />
                <path id="&lt;Path&gt;" class="shp73" d="M1347.9 1040.41C1342.55 1034.29 1335.95 1031.51 1328.11 1032.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M1345.07 1017.02C1347.53 1006.79 1352.11 1000.91 1358.81 999.38" />
                <path id="&lt;Path&gt;" class="shp73" d="M1338.86 989.38C1341.33 979.16 1345.91 973.28 1352.6 971.75" />
                <path id="&lt;Path&gt;" class="shp73" d="M1320.65 1006.84C1334.44 1006.34 1342.58 1010.29 1345.07 1018.71" />
                <path id="&lt;Path&gt;" class="shp73" d="M1314.45 977.52C1328.24 977.01 1336.38 980.97 1338.86 989.38" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M1452.16 1069.53C1463.99 1006.17 1454.62 972.33 1424.06 968C1392.03 963.47 1375.11 997.31 1373.29 1069.53L1452.16 1069.53Z" />
                <path id="&lt;Path&gt;" class="shp73" d="M1406.91 1066.8C1420.11 1018.6 1425.56 988.86 1423.26 977.58" />
                <path id="&lt;Path&gt;" class="shp73" d="M1408.63 1057.62C1405.95 1049.59 1400.6 1044.42 1392.57 1042.13" />
                <path id="&lt;Path&gt;" class="shp73" d="M1413.51 1040.41C1418.86 1034.29 1425.46 1031.51 1433.3 1032.09" />
                <path id="&lt;Path&gt;" class="shp73" d="M1418.67 1014.3C1414.66 1005.88 1409.3 1000.91 1402.61 999.38" />
                <path id="&lt;Path&gt;" class="shp73" d="M1440.76 1006.84C1427.76 1009.71 1420.01 1015.35 1417.52 1023.77" />
              </g>
              <path id="&lt;Path&gt;" class="shp43" d="M1307.64 1054.92C1301.9 1046.31 1292.88 1059.33 1292.88 1069.68L1330.36 1069.68C1330.36 1059.33 1312.19 1061.73 1307.64 1054.92Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp18" d="M1670.52 1067.16C1670.52 1073.2 1683.41 1078.09 1699.31 1078.09C1715.2 1078.09 1728.09 1073.2 1728.09 1067.16C1728.09 1061.12 1715.2 1056.22 1699.31 1056.22C1683.41 1056.22 1670.52 1061.12 1670.52 1067.16Z" />
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp76" d="M1823.15 972.26L1741.96 945.53L1823.15 957.41L1799.39 886.12L1756.81 886.12L1753.84 867.31L1802.36 868.3L1700.44 583.12L1700.38 580.17L1699.88 581.56L1699.39 580.17L1699.33 583.12L1597.4 868.3L1645.92 867.31L1642.95 886.12L1600.37 886.12L1576.61 957.41L1657.8 945.53L1576.61 972.26L1571.66 1006.92L1690.48 1006.92L1709.29 1006.92L1828.1 1006.92L1823.15 972.26Z" />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp57" d="M1698.71 612.85C1698.42 631.83 1698.27 650.8 1698.15 669.78C1698.04 688.76 1697.79 707.74 1697.83 726.71L1697.78 840.58C1697.92 878.53 1698.06 916.49 1698.4 954.44L1698.61 982.91C1698.75 992.4 1698.38 1001.89 1699.89 1011.37C1703.27 1030.35 1706.06 1049.33 1698.71 1068.31C1698.22 1069.57 1696.79 1070.2 1695.53 1069.71C1694.86 1069.45 1694.37 1068.92 1694.13 1068.31C1686.77 1049.33 1689.56 1030.35 1692.94 1011.37C1694.45 1001.89 1694.08 992.4 1694.22 982.91L1694.44 954.44C1694.77 916.49 1694.91 878.53 1695.05 840.58L1695 726.71C1695.04 707.74 1694.79 688.76 1694.68 669.78C1694.56 650.8 1694.41 631.83 1694.13 612.85C1694.11 611.58 1695.12 610.54 1696.38 610.52C1697.65 610.51 1698.69 611.52 1698.71 612.78C1698.71 612.8 1698.71 612.83 1698.71 612.85Z" />
                </g>
                <path id="&lt;Path&gt;" class="shp77" d="M1696.42 836.62L1630.08 806.91" />
                <path id="&lt;Path&gt;" class="shp77" d="M1700.38 737.6L1733.05 710.87" />
                <path id="&lt;Path&gt;" class="shp77" d="M1698.4 966.32L1733.05 941.57" />
                <path id="&lt;Path&gt;" class="shp78" d="M1576.61 972.26C1607.63 985.47 1646.91 991.08 1694.44 989.1C1741.96 987.12 1784.87 981.51 1823.15 972.26L1828.1 1006.92L1571.66 1006.92L1576.61 972.26Z" />
              </g>
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M1664.19 1088.6C1689.75 1059.51 1710.79 1030.99 1727.29 1003.03C1743.79 975.06 1751.82 938.03 1751.35 891.93L1719.26 943.47L1730.39 956.62L1715.21 946.81L1692.99 993.48L1705.26 1004.05L1690.66 997.58L1685.85 1007.27L1696.88 1018.32L1683.76 1011.99L1654.89 1084.53L1664.19 1088.6Z" />
                <path id="&lt;Path&gt;" class="shp32" d="M1655.18 1091.08C1629.3 942.46 1592.56 851.19 1544.97 817.29L1548.54 861.81L1569.19 868.18L1548.64 867.16L1548.93 883.21L1573.97 885.54L1550.69 890.55L1575.17 893.01L1550.5 897.17L1581.73 967.26L1607.91 969.31L1582.8 974.17L1602.66 1007L1622.65 1008.16L1604.16 1013.21L1609.26 1026.91L1627.74 1021.86L1607.79 1033.24C1630.01 1070.88 1645.81 1090.16 1655.18 1091.08Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1652.71 1088.84C1585.1 963.85 1525.09 893.25 1472.67 877.04L1489.22 915.72L1509.45 915.3L1490.91 920.46L1495.99 934.7L1518.91 929.38L1499.76 940.73L1522.22 935.7L1501.57 946.69L1550.35 1000.03L1574.19 994.13L1553.37 1005.88L1580.85 1029.32L1598.93 1024.44L1584.05 1034.41L1592.68 1045.14L1607.57 1035.17L1593.29 1051.21C1624.3 1078.24 1644.11 1090.79 1652.71 1088.84Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M1665.26 1088.78C1679.58 1043.12 1689.06 1000.35 1693.7 960.49C1698.35 920.63 1688.87 874.77 1665.26 822.91L1654.81 897.21L1673.97 906.5L1651.91 903.02L1650.16 966.88L1669.32 972.68L1649.58 972.68L1649 986.03L1667 993L1649 992.42L1652.71 1088.84L1665.26 1088.78Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp79" d="M1684.42 1098.07L1623.46 1098.07C1623.46 1089.42 1630.48 1082.4 1639.14 1082.4L1668.74 1082.4C1677.4 1082.4 1684.42 1089.42 1684.42 1098.07Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M386.52 1068.41C356.64 1043.77 331.33 1018.96 310.59 993.98C289.84 969 276.02 933.72 269.13 888.14L309.02 933.91L300.13 948.66L313.55 936.56L342.93 979.09L332.5 991.48L345.88 982.77L352.17 991.56L343.04 1004.23L354.99 995.89L395.05 1062.91L386.52 1068.41Z" />
                <path id="&lt;Path&gt;" class="shp32" d="M395.8 1069.42C397.68 918.57 419.4 822.62 460.98 781.56L464.55 826.08L445.18 835.66L465.31 831.38L467.58 847.28L443.23 853.56L467 854.8L443.23 861.13L468.25 861.3L448.58 935.48L423.07 941.68L448.63 942.47L434.26 978.05L414.71 982.37L433.77 984.42L430.92 998.75L411.86 996.71L433.37 1004.76C417.44 1045.46 404.91 1067.01 395.8 1069.42Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M397.89 1066.82C444.72 932.65 492.71 853.39 541.87 829.03L531.71 869.85L511.67 872.67L530.79 874.81L528.05 889.67L504.57 888.07L525.28 896.22L502.31 894.83L524.44 902.4L484.79 962.83L460.32 960.8L482.74 969.08L459.34 996.6L440.72 994.67L457 1002.14L450.18 1014.1L433.9 1006.63L450.56 1020.2C424.24 1051.83 406.69 1067.37 397.89 1066.82Z" />
                <path id="&lt;Path&gt;" class="shp21" d="M385.49 1068.76C364.08 1025.96 347.9 985.25 336.97 946.64C326.03 908.02 328.08 861.24 343.12 806.28L365.28 877.97L347.85 890.19L369.07 883.24L380.97 946L362.98 954.79L382.46 951.64L385.17 964.73L368.51 974.48L386.18 971.03L397.89 1066.82L385.49 1068.76Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp79" d="M368.06 1080.98L428.24 1071.27C426.86 1062.72 418.81 1056.91 410.26 1058.29L381.04 1063.01C372.49 1064.39 366.68 1072.44 368.06 1080.98Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp32" d="M193.6 1071.06C238.86 1053.39 278.74 1033.74 313.26 1012.09C347.78 990.45 378.07 953.81 404.14 902.17L338.66 941.1L343.48 962.17L332.22 942.49L280.55 981.67L288.12 1000.53L275.58 984.9L264.64 992.92L270.55 1011.6L259.58 996.98L185.58 1061.16L193.6 1071.06Z" />
                <path id="&lt;Path&gt;" class="shp32" d="M182.13 1068.63C238.99 888.09 250.68 765.2 217.21 699.97L195.51 751.64L214.84 770.64L192.53 757.66L183.59 775.72L210.16 792.75L181.34 784.91L207.2 801.77L177.3 792.17L171.68 888.3L199.67 905.68L168.89 896.61L172.09 944.65L193.69 957.46L170.18 952.43L167.95 970.64L191.47 975.67L162.68 976.84C165.73 1031.6 172.21 1062.19 182.13 1068.63Z" />
                <path id="&lt;Path&gt;" class="shp76" d="M180.67 1064.71C177.41 886.44 151.25 773.15 102.19 724.86L98.31 777.5L121.1 788.71L97.46 783.77L94.91 802.57L123.52 809.85L95.64 811.46L123.57 818.8L94.22 819.15L117.81 906.71L147.78 913.89L117.81 914.97L134.92 956.94L157.87 961.94L135.54 964.46L138.98 981.39L161.32 978.87L136.15 988.51C155.12 1036.52 169.96 1061.92 180.67 1064.71Z" />
                <path id="&lt;Path&gt;" class="shp76" d="M194.68 1071.88C236.97 1029.25 272.2 987.07 300.36 945.32C328.53 903.58 344.41 847.01 348.01 775.6L293.51 852.38L309.5 873.78L286.93 857.18L248.16 927.33L266.16 944.85L244.17 933.47L235.82 948.01L251.86 966.15L232.14 955.12L180.67 1064.71L194.68 1071.88Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp79" d="M210.67 1093.28L142.74 1058.13C147.74 1048.48 159.6 1044.71 169.25 1049.7L202.24 1066.78C211.89 1071.77 215.66 1083.64 210.67 1093.28Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp39" d="M1090.33 830.98C1098.53 832.28 1106.91 832.09 1115.08 833.48C1116.11 833.66 1117.42 834.28 1117.16 835.28C1117 835.94 1116.23 836.22 1115.58 836.39C1108.3 838.32 1100.75 840.27 1093.32 839.05C1089.73 838.46 1086.3 837.14 1082.8 836.09C1078.7 834.86 1074.32 833.9 1071.04 831.14C1068.37 828.9 1065.91 823.72 1068.49 820.67C1071.9 822.65 1074.55 825.73 1078.31 827.48C1082.1 829.25 1086.21 830.32 1090.33 830.98Z" />
          <path id="&lt;Path&gt;" class="shp39" d="M840.92 880.67C848.56 886.34 857.56 889.29 867.91 889.54C883.45 889.91 902.68 891.02 891.58 891.76C880.49 892.5 851.64 892.13 839.07 887.32C830.69 884.12 831.3 881.9 840.92 880.67Z" />
          <path id="&lt;Path&gt;" class="shp39" d="M744.29 805.75C746.71 806.16 749.13 806.54 751.57 806.73C774.22 808.57 866.12 816.22 850.53 818.17C832.78 820.39 726.28 819.28 738.48 818.17C750.69 817.06 799.13 820.02 793.58 816.69C788.04 813.36 730.72 808.18 736.26 806.34C739.43 805.28 742.36 805.43 744.29 805.75Z" />
        </g>
      </g>
    </svg>
    <svg class="smoke" id="smoke-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117 80" width="117" height="80">
      <title>Cloud</title>
      <g id="Cloud">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp-smoke" d="M108.72 37.06C108.78 36.4 108.81 35.73 108.81 35.06C108.81 22.43 98.58 12.2 85.95 12.2C79.74 12.2 74.11 14.68 69.99 18.71C68.07 8.25 58.91 0.31 47.89 0.31C39.3 0.31 31.84 5.13 28.06 12.21C12.77 12.34 0.41 24.78 0.41 40.1C0.41 55.51 12.9 67.99 28.31 67.99C33.48 67.99 38.32 66.58 42.47 64.13C45.34 73.1 53.75 79.6 63.68 79.6C73.59 79.6 81.98 73.13 84.87 64.19C86.57 65.03 88.48 65.51 90.5 65.51C92.31 65.51 94.03 65.12 95.6 64.44C97.33 65.12 99.21 65.51 101.18 65.51C109.59 65.51 116.41 58.7 116.41 50.29C116.41 44.62 113.31 39.69 108.72 37.06Z" />
        </g>
      </g>
    </svg>
    <svg class="smoke" id="smoke-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 51" width="67" height="51">
      <title>Cloud</title>
      <g id="Cloud">
        <g id="&lt;Group&gt;">
          <path id="&lt;Path&gt;" class="shp-smoke" d="M55.54 17.24C55.49 17.24 55.44 17.23 55.4 17.22C55.39 12.65 52.09 8.63 47.43 7.87C45.2 7.5 43.02 7.95 41.2 8.98C40.19 4.5 36.57 0.87 31.76 0.08C25.64 -0.92 19.87 3.06 18.57 9.05C14.01 9.19 10.04 12.52 9.28 17.19C9.13 18.11 9.13 19.02 9.23 19.9C4.9 21.68 1.56 25.59 0.75 30.55C-0.49 38.15 4.66 45.32 12.26 46.56C15.65 47.11 18.95 46.39 21.68 44.75C23.86 47.57 27.07 49.61 30.87 50.23C37.27 51.28 43.37 47.98 46.18 42.5C47.65 43.4 49.31 44.04 51.11 44.33C58.59 45.55 65.65 40.48 66.87 33C68.09 25.52 63.02 18.46 55.54 17.24Z" />
        </g>
      </g>
    </svg>
    <!--  Main content  -->
    <div class="main-content">
      <h1 class="requires">Draw a picture of your dream house.</h1>
      <div class="text-box">
        Talk with your friends about your picture. Listen to others.
      </div>
    </div>
    <drawing-canvas class="canvas" v-on:updateCanvas="updateCanvas" :data="canvasData"  :canvasStyle="canvasStyle"/>
    <audio ref="audio" autoplay loop src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session1/Water-Stream-Sound-Effect-Amplified.mp3"/>
    <audio
      @loadeddata="playSoundText"
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session1/Session1_Page36.mp3" ref="voice"/>
  </div>
</template>

<script>
import anime from "animejs";
import DrawingCanvas from "@/components/drawingCanvas/DrawingCanvas";

export default {
  name: "Session1Page25",
  components: {DrawingCanvas},
  data() {
    return {
      canvasStyle: {
        width: 0.98,
        height: 0.64,
        isPicture: false,
      },
      canvasData: null,
    }
  },
  methods: {
    animateSvg() {
      let vw = document.querySelector('.interactive-container').clientWidth;
      let vh = window.innerHeight;
      let clouds = document.getElementsByClassName('clouds')[0].children;
      clouds.forEach(cloud => {
        let plusOrMinus = Math.random() < 0.5 ? -1 : 1;
        anime({
          targets: cloud,
          translateX: (Math.floor(Math.random() * 0.3 * vw) + 0.2*vw) * plusOrMinus,
          duration: (Math.floor(Math.random() * 1000) + 5000),
          loop: true,
          direction: 'alternate',
          easing: 'linear'
        })
      })

      anime({
        targets: "#smoke-1",
        translateY: -0.4*vh,
        opacity: 0.1,
        duration: 2000,
        easing: 'linear',
        loop: true
      })
      anime({
        targets: "#smoke-2",
        translateY: -0.2*vh,
        opacity: 0.1,
        duration: 2000,
        easing: 'linear',
        loop: true,
        delay: 100
      })
    },
    animateText() {
      let mainContentAnimation = anime.timeline({
        easing: 'linear',
        duration: 1,
      });
      mainContentAnimation
        .add({targets: '.requires', opacity: 1}, 2000)
        .add({targets: '.text-box', opacity: 0.90}, 4000)
        .add({targets: '.canvas', opacity: 1}, 4500)
    },
    setAudioVolumeLevel(level) {
      this.$refs.audio.volume = level
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 2000)
    },
    init() {
      this.canvasData = this.$store.getters.getPage31Data;
    },
    updateCanvas(canvasData) {
      this.$store.commit('setPage31Data', canvasData);
    },
    playSoundText() {
      this.playVoiceOver();
      this.animateText();
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.animateSvg();
    this.setAudioVolumeLevel(0.5);
  }
}
</script>

<style scoped>
.canvas {
  position: absolute;
  top: 16.5vh;
  left: 1.3vh;
  z-index: 100;
  opacity: 0;
}
.main-content {
  position: absolute;
  left: 1.3vh;
  top: 1vh;
  width: 130.4vh;
  z-index: 50;
}
.main-content h1 {
  background-color: #000000;
  color: #ffffff;
  font-size: 3.5vh;
  width: 100%;
  height: auto;
  padding: 1.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.landscape {
  position: absolute;
  width: 130%;
  height: auto;
  left: -12%;
  top: -2vh;
}
.smoke {
  position: absolute;
  height: 6vh;
  width: auto;
  left: 14%;
  top: 47vh;
}
.text-box {
  background-color: #ffffff;
  padding: 1vh;
  height: auto;
  opacity: 0;
  font-size: 3vh;
  text-align: center;
}
tspan { white-space:pre }
.shp-smoke { fill: #ffffff }
.shp0 { fill: url(#grd1) }
.shp1 { opacity: 0.702;fill: url(#grd2) }
.shp2 { opacity: 0.702;fill: url(#grd3) }
.shp3 { opacity: 0.702;fill: url(#grd4) }
.shp4 { opacity: 0.702;fill: url(#grd5) }
.shp5 { fill: url(#grd6) }
.shp6 { fill: url(#grd7) }
.shp7 { fill: url(#grd8) }
.shp8 { fill: url(#grd9) }
.shp9 { fill: url(#grd10) }
.shp10 { fill: url(#grd11) }
.shp11 { fill: #80bdbd }
.shp12 { fill: #90ccca }
.shp13 { fill: #6fa1a2 }
.shp14 { fill: #3f605d }
.shp15 { fill: #4f7472 }
.shp16 { fill: #5b8585 }
.shp17 { fill: #8fa441 }
.shp18 { fill: #9dac44 }
.shp19 { fill: #bed156 }
.shp20 { fill: #adc44f }
.shp21 { fill: #6a824c }
.shp22 { fill: #e08072 }
.shp23 { fill: #bc5741 }
.shp24 { fill: #614e39 }
.shp25 { opacity: 0.4;fill: #513f30 }
.shp26 { fill: #725d43 }
.shp27 { fill: #513f30 }
.shp28 { fill: #dbba62 }
.shp29 { opacity: 0.149;fill: #4b2535 }
.shp30 { fill: #a37f55 }
.shp31 { fill: #df685d }
.shp32 { fill: #596e42 }
.shp33 { fill: #987956 }
.shp34 { fill: #bbdd78 }
.shp35 { fill: #98e0c8 }
.shp36 { opacity: 0.149;fill: url(#grd12) }
.shp37 { opacity: 0.149;fill: url(#grd13) }
.shp38 { opacity: 0.149;fill: url(#grd14) }
.shp39 { fill: #fbf4e5 }
.shp40 { opacity: 0.349;fill: url(#grd15) }
.shp41 { fill: #a8bd4f }
.shp42 { fill: url(#grd16) }
.shp43 { fill: #8b716f }
.shp44 { fill: url(#grd17) }
.shp45 { fill: #6e5553 }
.shp46 { fill: url(#grd18) }
.shp47 { fill: url(#grd19) }
.shp48 { opacity: 0.149;fill: url(#grd20) }
.shp49 { opacity: 0.149;fill: url(#grd21) }
.shp50 { fill: url(#grd22) }
.shp51 { fill: #455836 }
.shp52 { fill: #353f35 }
.shp53 { fill: none;stroke: #353f35;stroke-linecap:round;stroke-width: 2.179 }
.shp54 { opacity: 0.2;fill: url(#grd23) }
.shp55 { fill: none;stroke: #353f35;stroke-linecap:round;stroke-width: 1.938 }
.shp56 { opacity: 0.2;fill: url(#grd24) }
.shp57 { fill: #4b5b37 }
.shp58 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 1.938 }
.shp59 { opacity: 0.2;fill: url(#grd25) }
.shp60 { fill: none;stroke: #353f35;stroke-linecap:round;stroke-width: 1.587 }
.shp61 { opacity: 0.2;fill: url(#grd26) }
.shp62 { fill: none;stroke: #353f35;stroke-linecap:round;stroke-width: 1.411 }
.shp63 { opacity: 0.2;fill: url(#grd27) }
.shp64 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 1.411 }
.shp65 { opacity: 0.2;fill: url(#grd28) }
.shp66 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 0.622 }
.shp67 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 0.94 }
.shp68 { fill: none;stroke: #353f35;stroke-linecap:round;stroke-width: 2.062 }
.shp69 { opacity: 0.2;fill: url(#grd29) }
.shp70 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 2.062 }
.shp71 { opacity: 0.2;fill: url(#grd30) }
.shp72 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 1.027 }
.shp73 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 2.419 }
.shp74 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 2.81 }
.shp75 { fill: #a15b51 }
.shp76 { fill: #86a55f }
.shp77 { fill: none;stroke: #4b5b37;stroke-linecap:round;stroke-width: 4.579 }
.shp78 { opacity: 0.2;fill: url(#grd31) }
.shp79 { fill: #284321 }
</style>