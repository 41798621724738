<template>
  <div class="interactive-container">
    <ImageComponent
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/images/max/session1/background-stars.jpg"
      srcPlaceholder="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/images/min/session1/background-stars.jpg"
      class="session-background"
    />
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1538 1004" width="1538" height="1004">
      <title>Starry Starry Night</title>
      <defs>
        <linearGradient id="grd326" gradientUnits="userSpaceOnUse"  x1="917.183" y1="357.651" x2="975.703" y2="322.918">
          <stop offset="0" stop-color="#ffffff"  />
          <stop offset="1" stop-color="#1a237e"  />
        </linearGradient>
        <linearGradient id="grd327" gradientUnits="userSpaceOnUse"  x1="1142.134" y1="138.784" x2="1166.029" y2="124.602">
          <stop offset="0" stop-color="#ffffff"  />
          <stop offset="1" stop-color="#1a237e"  />
        </linearGradient>
        <linearGradient id="grd328" gradientUnits="userSpaceOnUse"  x1="471.229" y1="202.215" x2="489.859" y2="191.728">
          <stop offset="0" stop-color="#ffffff"  />
          <stop offset="1" stop-color="#1a237e"  />
        </linearGradient>
      </defs>
      <g id="BACKGROUND 2">
        <g id="&lt;Clip Group&gt;">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Mesh&gt;" class="shp325" d="M882.95 380.33C882.91 381.17 882.36 382.42 885.83 380.91C893.66 377.91 884.83 384.43 919.29 362.49C919.33 362.47 919.39 362.43 919.43 362.41C917.14 358.84 917.24 358.97 917.03 358.64C915.83 359.42 909.23 363.63 905.22 366.09C901.17 368.58 887.11 377.65 882.96 380.3C882.96 380.31 882.96 380.32 882.95 380.33ZM882.96 380.3C887.11 377.65 901.17 368.58 905.22 366.09C909.23 363.63 915.83 359.42 917.03 358.64C916.84 358.35 916.45 357.76 914.83 355.21C914.79 355.24 914.75 355.27 914.7 355.3C884.75 374.4 889.85 372.19 885.89 376.24C882.54 379.13 883.04 379.44 882.96 380.3ZM919.43 362.41C954.25 340.73 966.43 332.15 976.2 323.52C979.04 321.02 980.23 319.5 979.46 319.18C979.45 319.17 979.44 319.17 979.43 319.17C952.94 335.82 947.27 339.19 939.37 344.24C931.56 349.21 925.83 352.82 917.03 358.64C917.24 358.97 917.14 358.84 919.43 362.41ZM917.03 358.64C925.83 352.82 931.56 349.21 939.37 344.24C947.27 339.19 952.94 335.82 979.43 319.17C978.63 318.88 975.98 319.78 971.94 321.6C957.35 328.32 944.87 336.12 914.83 355.21C916.45 357.76 916.84 358.35 917.03 358.64Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Mesh&gt;" class="shp326" d="M1128.16 148.04C1128.14 148.39 1127.92 148.9 1129.33 148.28C1132.53 147.06 1128.92 149.72 1142.99 140.76C1143.01 140.75 1143.03 140.74 1143.05 140.73C1142.12 139.27 1142.16 139.32 1142.07 139.19C1141.58 139.51 1138.89 141.22 1137.25 142.23C1135.6 143.24 1129.85 146.95 1128.16 148.03C1128.16 148.03 1128.16 148.04 1128.16 148.04ZM1128.16 148.03C1129.85 146.95 1135.6 143.24 1137.25 142.23C1138.89 141.22 1141.58 139.51 1142.07 139.19C1142 139.07 1141.84 138.83 1141.17 137.79C1141.16 137.8 1141.14 137.81 1141.12 137.82C1128.89 145.62 1130.97 144.72 1129.36 146.37C1127.99 147.55 1128.19 147.68 1128.16 148.03ZM1143.05 140.73C1157.27 131.87 1162.24 128.37 1166.23 124.85C1167.39 123.83 1167.88 123.2 1167.56 123.08C1167.56 123.07 1167.55 123.07 1167.55 123.07C1156.73 129.87 1154.42 131.25 1151.19 133.31C1148 135.34 1145.67 136.81 1142.07 139.19C1142.16 139.32 1142.12 139.27 1143.05 140.73ZM1142.07 139.19C1145.67 136.81 1148 135.34 1151.19 133.31C1154.42 131.25 1156.73 129.87 1167.55 123.07C1167.22 122.95 1166.14 123.32 1164.49 124.06C1158.54 126.81 1153.44 129.99 1141.17 137.79C1141.84 138.83 1142 139.07 1142.07 139.19Z" />
            </g>
            <g id="&lt;Group&gt;" style="opacity: 0.722">
              <path id="&lt;Mesh&gt;" class="shp327" d="M460.44 209.52C460.51 209.92 460.43 210.46 461.59 210.1C464.26 209.48 461.58 211.68 472.42 204.78C472.43 204.77 472.45 204.76 472.46 204.75C471.22 202.8 471.27 202.88 471.16 202.7C470.78 202.95 468.71 204.27 467.44 205.03C466.15 205.8 461.74 208.67 460.43 209.5C460.43 209.51 460.44 209.51 460.44 209.52ZM460.43 209.5C461.74 208.67 466.15 205.8 467.44 205.03C468.71 204.27 470.78 202.95 471.16 202.7C471.06 202.54 470.85 202.21 469.96 200.83C469.95 200.84 469.94 200.85 469.92 200.86C460.5 206.87 462.21 206.34 461.13 207.87C460.15 208.9 460.37 209.1 460.43 209.5ZM472.46 204.75C483.47 198.01 487.22 195.18 490.04 192.07C490.86 191.17 491.16 190.56 490.83 190.33C490.83 190.33 490.82 190.33 490.82 190.32C482.46 195.53 480.65 196.55 478.17 198.14C475.71 199.7 473.9 200.83 471.16 202.7C471.27 202.88 471.22 202.8 472.46 204.75ZM471.16 202.7C473.9 200.83 475.71 199.7 478.17 198.14C480.65 196.55 482.46 195.53 490.82 190.32C490.48 190.1 489.57 190.26 488.22 190.71C483.36 192.4 479.42 194.83 469.96 200.83C470.85 202.21 471.06 202.54 471.16 202.7Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="star-container">
      <svg class="star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1320 1258" width="1320" height="1258">
        <title>Star</title>
        <g id="Object">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp0-star" d="M678.99 12.07L866.54 392.07C869.62 398.32 875.59 402.66 882.48 403.66L1301.84 464.6C1319.21 467.12 1326.15 488.47 1313.58 500.73L1010.13 796.52C1005.14 801.38 1002.86 808.39 1004.04 815.26L1075.67 1232.93C1078.64 1250.23 1060.48 1263.42 1044.94 1255.26L669.86 1058.06C663.69 1054.82 656.31 1054.82 650.14 1058.06L275.06 1255.26C259.52 1263.42 241.36 1250.23 244.33 1232.93L315.96 815.26C317.14 808.39 314.86 801.38 309.87 796.52L6.42 500.73C-6.15 488.47 0.79 467.12 18.16 464.6L437.51 403.66C444.41 402.66 450.38 398.32 453.46 392.07L641.01 12.07C648.77 -3.67 671.22 -3.67 678.99 12.07Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp1-star" d="M660 99.85L492.87 423.56C484.11 440.54 467.28 451.87 448.25 453.61L85.38 486.73L468.76 504.66C493.11 505.8 515.39 491.04 523.85 468.17L660 99.85Z" />
            </g>
            <path id="&lt;Path&gt;" class="shp2-star" d="M252.57 1184.87L244.33 1232.93C241.36 1250.23 259.52 1263.43 275.06 1255.25L650.14 1058.06C656.31 1054.82 663.69 1054.82 669.86 1058.06L1044.94 1255.25C1060.48 1263.43 1078.64 1250.23 1075.67 1232.93L1004.04 815.26C1002.86 808.39 1005.14 801.38 1010.13 796.51L1313.58 500.73C1326.15 488.47 1319.21 467.12 1301.84 464.6L1161.38 444.19C934.2 851.89 586.33 1066.55 252.57 1184.87Z" />
          </g>
        </g>
      </svg>
      <p class="para">Look out for this
        <br>Bright Star to give
        <br>you Fun Friends
        <br>skills each week.</p>
    </div>
    <audio ref="audio" autoplay loop src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session4/night-time.mp3"/>
    <audio
      @loadeddata="playSoundText"
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session1/43Animated_Book_Page42.mp3" ref="voice"/>
    <div class="page-number" id="page-light">55</div>
  </div>
</template>

<script>
import anime from "animejs";
import ImageComponent from "@/components/imageComponent/ImageComponent.vue";
export default {
  name: "Session1Page36",
  components: {ImageComponent},
  methods: {
    animateSvg() {
      let vh = window.innerHeight;
      let vw = document.querySelector('.interactive-container').clientWidth;
      anime({
        targets: ".shp325",
        translateX: -4 * vw,
        translateY: 3.2 * vh,
        duration: 3200,
        opacity: 0,
        easing: 'linear',
        loop: true,
      })
      anime({
        targets: ".shp326",
        translateX: -8 * vw,
        translateY: 6.4 * vh,
        duration: 5000,
        opacity: 0,
        easing: 'linear',
        loop: true,
      })
      anime({
        targets: ".shp327",
        translateX: -8 * vw,
        translateY: 6.4 * vh,
        duration: 32000,
        opacity: 0,
        easing: 'linear',
        loop: true,
      })
    },
    animateText() {
      let animation = anime.timeline();
      animation
        .add({
          targets: ".star",
          scale: 70,
          duration: 1000,
          delay: 1000,
        })
        .add({
          targets: ".para",
          opacity: 1,
          duration: 1000,
          easing: 'linear'
        })
    },
    setAudioVolumeLevel(level) {
      this.$refs.audio.volume = level
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 2000)
    },
    playSoundText() {
      this.playVoiceOver();
      this.animateText();
      this.animateSvg();
    }
  },
  mounted() {
    this.setAudioVolumeLevel(0.4);
  }
}
</script>

<style scoped>
@media screen and (max-width: 930px) {
  .para {
    margin-top: 6vh;
    margin-right: 3%;
  }
}
.landscape {
  position: absolute;
  width: 110%;
  top: -1vh;
  left: -2%;
  height: auto;
}
.star-container {
  position: absolute;
  top: 10vh;
  left: 20%;
  width: 57%;
  height: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.star {
  position: absolute;
  width: auto;
  height: 1vh;
}
.para {
  width: 100%;
  position: absolute;
  font-weight: bold;
  font-size: 4.8vh;
  height: auto;
  margin-top: 3%;
  text-align: center;
  opacity: 0;
}
tspan { white-space:pre }
.shp0-star { fill: #f3cc30 }
.shp1-star { fill: #f7db5e }
.shp2-star { fill: #edbd31 }
.shp0 { fill: url(#grd1) }
.shp1 { fill: url(#grd2) }
.shp2 { fill: url(#grd3) }
.shp3 { fill: url(#grd4) }
.shp4 { fill: url(#grd5) }
.shp5 { fill: url(#grd6) }
.shp6 { fill: url(#grd7) }
.shp7 { fill: url(#grd8) }
.shp8 { fill: url(#grd9) }
.shp9 { fill: url(#grd10) }
.shp10 { fill: url(#grd11) }
.shp11 { fill: url(#grd12) }
.shp12 { fill: url(#grd13) }
.shp13 { fill: url(#grd14) }
.shp14 { fill: url(#grd15) }
.shp15 { fill: url(#grd16) }
.shp16 { fill: url(#grd17) }
.shp17 { fill: url(#grd18) }
.shp18 { fill: url(#grd19) }
.shp19 { fill: url(#grd20) }
.shp20 { fill: url(#grd21) }
.shp21 { fill: url(#grd22) }
.shp22 { fill: url(#grd23) }
.shp23 { fill: url(#grd24) }
.shp24 { fill: url(#grd25) }
.shp25 { fill: url(#grd26) }
.shp26 { fill: url(#grd27) }
.shp27 { fill: url(#grd28) }
.shp28 { fill: url(#grd29) }
.shp29 { fill: url(#grd30) }
.shp30 { fill: url(#grd31) }
.shp31 { fill: url(#grd32) }
.shp32 { fill: url(#grd33) }
.shp33 { fill: url(#grd34) }
.shp34 { fill: url(#grd35) }
.shp35 { fill: url(#grd36) }
.shp36 { fill: url(#grd37) }
.shp37 { fill: url(#grd38) }
.shp38 { fill: url(#grd39) }
.shp39 { fill: url(#grd40) }
.shp40 { fill: url(#grd41) }
.shp41 { fill: url(#grd42) }
.shp42 { fill: url(#grd43) }
.shp43 { fill: url(#grd44) }
.shp44 { fill: url(#grd45) }
.shp45 { fill: url(#grd46) }
.shp46 { fill: url(#grd47) }
.shp47 { fill: url(#grd48) }
.shp48 { fill: url(#grd49) }
.shp49 { fill: url(#grd50) }
.shp50 { fill: url(#grd51) }
.shp51 { fill: url(#grd52) }
.shp52 { fill: url(#grd53) }
.shp53 { fill: url(#grd54) }
.shp54 { fill: url(#grd55) }
.shp55 { fill: url(#grd56) }
.shp56 { fill: url(#grd57) }
.shp57 { fill: url(#grd58) }
.shp58 { fill: url(#grd59) }
.shp59 { fill: url(#grd60) }
.shp60 { fill: url(#grd61) }
.shp61 { fill: url(#grd62) }
.shp62 { fill: url(#grd63) }
.shp63 { fill: url(#grd64) }
.shp64 { fill: url(#grd65) }
.shp65 { fill: url(#grd66) }
.shp66 { fill: url(#grd67) }
.shp67 { fill: url(#grd68) }
.shp68 { fill: url(#grd69) }
.shp69 { fill: url(#grd70) }
.shp70 { fill: url(#grd71) }
.shp71 { fill: url(#grd72) }
.shp72 { fill: url(#grd73) }
.shp73 { fill: url(#grd74) }
.shp74 { fill: url(#grd75) }
.shp75 { fill: url(#grd76) }
.shp76 { fill: url(#grd77) }
.shp77 { fill: url(#grd78) }
.shp78 { fill: url(#grd79) }
.shp79 { fill: url(#grd80) }
.shp80 { fill: url(#grd81) }
.shp81 { fill: url(#grd82) }
.shp82 { fill: url(#grd83) }
.shp83 { fill: url(#grd84) }
.shp84 { fill: url(#grd85) }
.shp85 { fill: url(#grd86) }
.shp86 { fill: url(#grd87) }
.shp87 { fill: url(#grd88) }
.shp88 { fill: url(#grd89) }
.shp89 { fill: url(#grd90) }
.shp90 { fill: url(#grd91) }
.shp91 { fill: url(#grd92) }
.shp92 { fill: url(#grd93) }
.shp93 { fill: url(#grd94) }
.shp94 { fill: url(#grd95) }
.shp95 { fill: url(#grd96) }
.shp96 { fill: url(#grd97) }
.shp97 { fill: url(#grd98) }
.shp98 { fill: url(#grd99) }
.shp99 { fill: url(#grd100) }
.shp100 { fill: url(#grd101) }
.shp101 { fill: url(#grd102) }
.shp102 { fill: url(#grd103) }
.shp103 { fill: url(#grd104) }
.shp104 { fill: url(#grd105) }
.shp105 { fill: url(#grd106) }
.shp106 { fill: url(#grd107) }
.shp107 { fill: url(#grd108) }
.shp108 { fill: url(#grd109) }
.shp109 { fill: url(#grd110) }
.shp110 { fill: url(#grd111) }
.shp111 { fill: url(#grd112) }
.shp112 { fill: url(#grd113) }
.shp113 { fill: url(#grd114) }
.shp114 { fill: url(#grd115) }
.shp115 { fill: url(#grd116) }
.shp116 { fill: url(#grd117) }
.shp117 { fill: url(#grd118) }
.shp118 { fill: url(#grd119) }
.shp119 { fill: url(#grd120) }
.shp120 { fill: url(#grd121) }
.shp121 { fill: url(#grd122) }
.shp122 { fill: url(#grd123) }
.shp123 { fill: url(#grd124) }
.shp124 { fill: url(#grd125) }
.shp125 { fill: url(#grd126) }
.shp126 { fill: url(#grd127) }
.shp127 { fill: url(#grd128) }
.shp128 { fill: url(#grd129) }
.shp129 { fill: url(#grd130) }
.shp130 { fill: url(#grd131) }
.shp131 { fill: url(#grd132) }
.shp132 { fill: url(#grd133) }
.shp133 { fill: url(#grd134) }
.shp134 { fill: url(#grd135) }
.shp135 { fill: url(#grd136) }
.shp136 { fill: url(#grd137) }
.shp137 { fill: url(#grd138) }
.shp138 { fill: url(#grd139) }
.shp139 { fill: url(#grd140) }
.shp140 { fill: url(#grd141) }
.shp141 { fill: url(#grd142) }
.shp142 { fill: url(#grd143) }
.shp143 { fill: url(#grd144) }
.shp144 { fill: url(#grd145) }
.shp145 { fill: url(#grd146) }
.shp146 { fill: url(#grd147) }
.shp147 { fill: url(#grd148) }
.shp148 { fill: url(#grd149) }
.shp149 { fill: url(#grd150) }
.shp150 { fill: url(#grd151) }
.shp151 { fill: url(#grd152) }
.shp152 { fill: url(#grd153) }
.shp153 { fill: url(#grd154) }
.shp154 { fill: url(#grd155) }
.shp155 { fill: url(#grd156) }
.shp156 { fill: url(#grd157) }
.shp157 { fill: url(#grd158) }
.shp158 { fill: url(#grd159) }
.shp159 { fill: url(#grd160) }
.shp160 { fill: url(#grd161) }
.shp161 { fill: url(#grd162) }
.shp162 { fill: url(#grd163) }
.shp163 { fill: url(#grd164) }
.shp164 { fill: url(#grd165) }
.shp165 { fill: url(#grd166) }
.shp166 { fill: url(#grd167) }
.shp167 { fill: url(#grd168) }
.shp168 { fill: url(#grd169) }
.shp169 { fill: url(#grd170) }
.shp170 { fill: url(#grd171) }
.shp171 { fill: url(#grd172) }
.shp172 { fill: url(#grd173) }
.shp173 { fill: url(#grd174) }
.shp174 { fill: url(#grd175) }
.shp175 { fill: url(#grd176) }
.shp176 { fill: url(#grd177) }
.shp177 { opacity: 0.412;fill: url(#grd178) }
.shp178 { opacity: 0.412;fill: url(#grd179) }
.shp179 { opacity: 0.412;fill: url(#grd180) }
.shp180 { opacity: 0.412;fill: url(#grd181) }
.shp181 { opacity: 0.412;fill: url(#grd182) }
.shp182 { opacity: 0.412;fill: url(#grd183) }
.shp183 { opacity: 0.412;fill: url(#grd184) }
.shp184 { opacity: 0.412;fill: url(#grd185) }
.shp185 { opacity: 0.412;fill: url(#grd186) }
.shp186 { opacity: 0.412;fill: url(#grd187) }
.shp187 { opacity: 0.412;fill: url(#grd188) }
.shp188 { opacity: 0.412;fill: url(#grd189) }
.shp189 { opacity: 0.412;fill: url(#grd190) }
.shp190 { opacity: 0.412;fill: url(#grd191) }
.shp191 { opacity: 0.412;fill: url(#grd192) }
.shp192 { opacity: 0.412;fill: url(#grd193) }
.shp193 { opacity: 0.412;fill: url(#grd194) }
.shp194 { opacity: 0.412;fill: url(#grd195) }
.shp195 { opacity: 0.412;fill: url(#grd196) }
.shp196 { opacity: 0.412;fill: url(#grd197) }
.shp197 { opacity: 0.412;fill: url(#grd198) }
.shp198 { opacity: 0.412;fill: url(#grd199) }
.shp199 { opacity: 0.412;fill: url(#grd200) }
.shp200 { opacity: 0.412;fill: url(#grd201) }
.shp201 { opacity: 0.412;fill: url(#grd202) }
.shp202 { opacity: 0.412;fill: url(#grd203) }
.shp203 { opacity: 0.412;fill: url(#grd204) }
.shp204 { opacity: 0.412;fill: url(#grd205) }
.shp205 { opacity: 0.412;fill: url(#grd206) }
.shp206 { opacity: 0.412;fill: url(#grd207) }
.shp207 { opacity: 0.412;fill: url(#grd208) }
.shp208 { opacity: 0.412;fill: url(#grd209) }
.shp209 { opacity: 0.412;fill: url(#grd210) }
.shp210 { opacity: 0.412;fill: url(#grd211) }
.shp211 { opacity: 0.412;fill: url(#grd212) }
.shp212 { opacity: 0.412;fill: url(#grd213) }
.shp213 { opacity: 0.412;fill: url(#grd214) }
.shp214 { opacity: 0.412;fill: url(#grd215) }
.shp215 { opacity: 0.412;fill: url(#grd216) }
.shp216 { opacity: 0.412;fill: url(#grd217) }
.shp217 { fill: url(#grd218) }
.shp218 { fill: url(#grd219) }
.shp219 { fill: url(#grd220) }
.shp220 { fill: url(#grd221) }
.shp221 { fill: url(#grd222) }
.shp222 { fill: url(#grd223) }
.shp223 { fill: url(#grd224) }
.shp224 { fill: url(#grd225) }
.shp225 { fill: url(#grd226) }
.shp226 { fill: url(#grd227) }
.shp227 { fill: url(#grd228) }
.shp228 { fill: url(#grd229) }
.shp229 { fill: url(#grd230) }
.shp230 { fill: url(#grd231) }
.shp231 { fill: url(#grd232) }
.shp232 { fill: url(#grd233) }
.shp233 { fill: url(#grd234) }
.shp234 { fill: url(#grd235) }
.shp235 { fill: url(#grd236) }
.shp236 { fill: url(#grd237) }
.shp237 { fill: url(#grd238) }
.shp238 { fill: url(#grd239) }
.shp239 { fill: url(#grd240) }
.shp240 { fill: url(#grd241) }
.shp241 { fill: url(#grd242) }
.shp242 { fill: url(#grd243) }
.shp243 { fill: url(#grd244) }
.shp244 { fill: url(#grd245) }
.shp245 { fill: url(#grd246) }
.shp246 { fill: url(#grd247) }
.shp247 { fill: url(#grd248) }
.shp248 { fill: url(#grd249) }
.shp249 { fill: url(#grd250) }
.shp250 { fill: url(#grd251) }
.shp251 { fill: url(#grd252) }
.shp252 { fill: url(#grd253) }
.shp253 { fill: url(#grd254) }
.shp254 { fill: url(#grd255) }
.shp255 { fill: url(#grd256) }
.shp256 { fill: url(#grd257) }
.shp257 { fill: url(#grd258) }
.shp258 { fill: url(#grd259) }
.shp259 { fill: url(#grd260) }
.shp260 { fill: url(#grd261) }
.shp261 { fill: url(#grd262) }
.shp262 { fill: url(#grd263) }
.shp263 { fill: url(#grd264) }
.shp264 { fill: url(#grd265) }
.shp265 { fill: url(#grd266) }
.shp266 { fill: url(#grd267) }
.shp267 { fill: url(#grd268) }
.shp268 { fill: url(#grd269) }
.shp269 { fill: url(#grd270) }
.shp270 { fill: url(#grd271) }
.shp271 { fill: url(#grd272) }
.shp272 { fill: url(#grd273) }
.shp273 { fill: url(#grd274) }
.shp274 { fill: url(#grd275) }
.shp275 { fill: url(#grd276) }
.shp276 { fill: url(#grd277) }
.shp277 { fill: url(#grd278) }
.shp278 { fill: url(#grd279) }
.shp279 { fill: url(#grd280) }
.shp280 { fill: url(#grd281) }
.shp281 { fill: url(#grd282) }
.shp282 { fill: url(#grd283) }
.shp283 { fill: url(#grd284) }
.shp284 { fill: url(#grd285) }
.shp285 { fill: url(#grd286) }
.shp286 { fill: url(#grd287) }
.shp287 { mix-blend-mode: overlay;fill: url(#grd288) }
.shp288 { fill: url(#grd289) }
.shp289 { fill: url(#grd290) }
.shp290 { fill: url(#grd291) }
.shp291 { fill: url(#grd292) }
.shp292 { fill: url(#grd293) }
.shp293 { fill: url(#grd294) }
.shp294 { fill: url(#grd295) }
.shp295 { fill: url(#grd296) }
.shp296 { fill: url(#grd297) }
.shp297 { fill: url(#grd298) }
.shp298 { fill: url(#grd299) }
.shp299 { fill: url(#grd300) }
.shp300 { fill: url(#grd301) }
.shp301 { fill: url(#grd302) }
.shp302 { fill: url(#grd303) }
.shp303 { fill: url(#grd304) }
.shp304 { fill: url(#grd305) }
.shp305 { fill: url(#grd306) }
.shp306 { fill: url(#grd307) }
.shp307 { fill: url(#grd308) }
.shp308 { fill: url(#grd309) }
.shp309 { fill: url(#grd310) }
.shp310 { fill: url(#grd311) }
.shp311 { fill: url(#grd312) }
.shp312 { fill: url(#grd313) }
.shp313 { fill: url(#grd314) }
.shp314 { fill: url(#grd315) }
.shp315 { fill: url(#grd316) }
.shp316 { fill: url(#grd317) }
.shp317 { fill: url(#grd318) }
.shp318 { fill: url(#grd319) }
.shp319 { fill: url(#grd320) }
.shp320 { fill: url(#grd321) }
.shp321 { fill: url(#grd322) }
.shp322 { fill: url(#grd323) }
.shp323 { fill: url(#grd324) }
.shp324 { fill: url(#grd325) }
.shp325 { fill: url(#grd326) }
.shp326 { fill: url(#grd327) }
.shp327 { fill: url(#grd328) }
</style>