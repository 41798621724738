<template>
  <div class="interactive-container">
    <svg class="landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1000" width="1500" height="1000">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="M1500 1000L0 1000L0 761.67L1500 761.67L1500 1000Z" />
        </clipPath>
      </defs>
      <g id="OBJECT">
        <g id="&lt;Group&gt;">
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp0" d="M1500 54.2L1500 0L0 0L0 76.26C496.52 6.93 1001.65 -0.48 1500 54.2Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M1500 124.48L1500 54.2C1001.65 -0.48 496.52 6.93 0 76.26L0 89.11C499.35 47.19 1003.18 59.08 1500 124.48Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M0 239.48L0 314.85C268.69 350.42 531.25 431.81 772.97 554.42C1003.5 464.05 1252.51 420.92 1500 428.52L1500 270.29C1000.01 209.26 495.15 166.57 0 239.48Z" />
            <path id="&lt;Path&gt;" class="shp2" d="M1500 143.52L1500 124.48C1003.18 59.08 499.35 47.19 0 89.11L0 179.91C495.73 103.97 1001.17 91.7 1500 143.52Z" />
            <path id="&lt;Path&gt;" class="shp1" d="M1500 270.29L1500 143.52C1001.17 91.7 495.73 103.97 0 179.91L0 239.48C495.15 166.57 1000.01 209.26 1500 270.29Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp3" d="M1500 247L1442 204L1379.24 272L1331.28 247L1263.01 297L1038.07 148L922.07 266L870.87 242L754.56 345.97L746.07 516L1108.07 516L1122.6 495.46L1500 495.46L1500 247Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M1038.07 148L1081 215L1058.95 226L1108.07 239L1083 262.61L1263.01 297L1038.07 148Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M1331.28 247L1325.72 290L1339 285L1332.36 314.85L1379.24 272L1331.28 247Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M1442 204L1442 247L1455 247L1448.5 262.61L1466.89 256L1454.45 280.93L1490 286L1500 277.21L1500 247L1442 204Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M870.87 242L881.33 277.21L903 281.33L922.07 266L870.87 242Z" />
            </g>
            <path id="big-cloud" class="shp5" d="M0 227.03C13.21 225.36 26.83 226.9 39.27 231.64C57.68 238.66 73.34 252.64 82.39 270.14C104.25 262.7 130.6 275.02 136.55 297.33C153.1 287 173.56 283.1 192.75 286.6C211.95 290.11 229.71 300.99 241.54 316.51C289.59 286.63 346.56 274.8 402.04 285.91C457.52 297.03 508.34 329.93 541.17 376.01C546.22 364.57 553.99 353.51 565.84 349.53C577.7 345.54 590.4 345 602.91 344.77C625.74 344.35 648.8 344.85 671.04 350.07C693.27 355.29 714.81 365.57 730.37 382.28C745.02 360.34 773.07 349.02 799.43 347.79C825.78 346.55 852.43 358.48 869.06 378.96C888.45 368.83 915.31 377.26 925.43 396.64C935.08 376.02 955.68 354.78 977.05 346.92C998.42 339.06 1022.88 339.99 1043.59 349.45C1057.77 333.87 1081.12 327.36 1101.31 333.34C1121.5 339.32 1137.53 357.5 1140.93 378.28C1169.5 363.89 1206.36 371.76 1228.39 394.96C1234.22 380.98 1246.96 370.62 1261.63 366.87C1276.3 363.11 1292.64 366.63 1304.47 376.08C1308.07 367.73 1316.37 365.05 1325.43 364.15C1334.48 363.25 1343.88 367.6 1349.05 375.08C1360.98 358.59 1382.92 350 1402.88 354C1422.83 358.01 1439.75 374.4 1444.4 394.22C1459.24 377.18 1477.78 369.11 1500 370L1500 495.46L0 495.46C0 361.6 0 272.12 0 227.03Z" />
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp3" d="M173.15 351.91L485.82 129.13L960 511.73L897.21 568L173.15 568L173.15 351.91Z" />
              <path id="&lt;Path&gt;" class="shp4" d="M485.82 129.13L518.78 195.22L468.91 195.22L558.38 276.92L520.24 276.92L558.38 312.55L468.91 312.55L631.72 392.75L556.91 404.94L684.52 472.21L674.26 430.99L870.87 439.82L485.82 129.13Z" />
              <path id="&lt;Path&gt;" class="shp6" d="M619.99 568L485.82 386.81L439.57 364.53L373.56 276.92L309.14 336.31L222.48 253.14L108.07 340.77L108.07 568L619.99 568Z" />
              <path id="&lt;Path&gt;" class="shp5" d="M222.48 253.14L217.5 320.3L248.89 383.84L254.75 330.37L309.14 336.31L222.48 253.14Z" />
              <path id="&lt;Path&gt;" class="shp5" d="M373.56 276.92L373.56 306.61L389.7 330.37L373.56 340.77L398.5 366.02L350.1 383.84L388.23 392.75L391.17 383.84L419.77 396.46L427.1 387.55L485.82 415.03L468.91 386.81L485.82 386.81L439.57 364.53L373.56 276.92Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp7" d="M224.9 438.35C260.6 458.12 293.35 484.58 332.59 495.81C380 509.38 430.44 499.03 479.71 496.78C571.15 492.6 663.59 517.99 740.49 567.55C751.28 563.08 762.09 558.68 772.97 554.42C531.25 431.81 268.69 350.42 0 314.85L0 401.1C76.26 387.71 157.12 400.8 224.9 438.35Z" />
            <path id="&lt;Path&gt;" class="shp8" d="M479.71 496.78C430.44 499.03 380 509.38 332.59 495.81C293.35 484.58 260.6 458.12 224.9 438.35C157.12 400.8 76.26 387.71 0 401.1L0 550.31C225.69 491.04 470.04 506.58 687.75 590.37C705.2 582.47 722.8 574.89 740.49 567.55C663.59 517.99 571.15 492.6 479.71 496.78Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp9" d="M376.33 478.57C373.47 476.34 370.19 475.14 366.01 476.82C363.12 478 359.72 480.7 356.26 485.53C354.4 477.61 351.67 472.82 348.82 470.25C345.44 467.22 341.85 467.14 338.26 468.2C334.66 469.27 331.74 471.3 330.28 475.27C328.8 479.26 328.81 485.14 330.36 494.4C330.66 496.24 331 498.04 331.35 499.8C328.27 496.82 325.51 495.4 323.34 494.98C320.24 494.4 318.18 495.68 316.56 497.6C314.92 499.54 314.02 501.76 314.55 504.47C315.06 507.22 316.97 510.41 320.56 515.15C324.14 519.92 327.55 524.18 330.68 527.28C333.79 530.34 336.72 532.22 339.15 531.46C340.68 530.99 341.8 529.39 342.31 526.96C343.56 528.01 344.88 528.6 346.28 528.64C346.85 528.66 347.42 528.55 347.98 528.37C349.19 530.72 350.82 532.16 352.69 532.46C356.32 533.04 360.12 529.74 363.89 524.62C367.68 519.46 371.55 512.47 375.44 504.67C379.37 496.88 381.21 491.75 381.16 487.7C381.13 483.69 379.21 480.82 376.33 478.57Z" />
            <path id="&lt;Path&gt;" class="shp9" d="M648.26 552.76C645.85 549.96 642.85 548.17 638.78 548.36C637.84 548.4 636.85 548.56 635.79 548.81C646.38 535.57 644.92 528.11 639.31 522.17C633 515.45 623.37 513.5 605.36 532.2C605.04 532.54 604.75 532.88 604.43 533.23C603.24 529.72 600.72 527.96 597.63 527C591.25 525.03 584.91 527.19 579.59 544.39C579.54 544.55 579.5 544.71 579.45 544.87C577.58 542.97 575.35 542.67 573.16 543.07C568.35 543.93 565.39 547.22 566.5 559.31C567.51 571.38 570.61 581.52 575.41 581.89C577.09 582.03 578.78 580.73 580.2 578.41C580.61 578.7 581.05 578.95 581.52 579.1C583.25 579.63 585.2 579.09 587.24 577.71C587.35 577.82 587.44 577.95 587.56 578.05C589.47 579.56 592.12 579.93 595.28 579.37C595.3 579.68 595.31 579.98 595.38 580.28C596.25 583.82 601.11 586.15 608.52 586.19C615.89 586.25 625.76 583.81 635.24 578.3C644.71 572.76 649.1 567.34 650.57 563.1C652.04 558.8 650.66 555.55 648.26 552.76Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp10" d="M0 444.74C19.23 444.44 41.19 454.78 51.41 471.07C60.38 466.31 71.5 465.82 80.85 469.76C90.2 473.71 97.61 482.02 100.46 491.76C110.97 482.65 128.49 489.27 133.28 502.33C150.04 485.31 177.68 480.13 199.47 489.94C221.26 499.75 235.72 523.87 234.09 547.71L0 572L0 444.74Z" />
          <path id="&lt;Path&gt;" class="shp9" d="M772.97 554.42C744.26 565.67 715.84 577.66 687.75 590.37C733.76 608.07 778.56 628.84 821.77 652.58C1038.57 574.97 1269.8 537.91 1500 543.72L1500 428.52C1252.51 420.92 1003.5 464.05 772.97 554.42Z" />
          <path id="&lt;Path&gt;" class="shp11" d="M821.77 652.58C830.53 657.39 839.22 662.33 847.85 667.39C1062.14 622.92 1281.16 601.23 1500 603.18L1500 543.72C1269.8 537.91 1038.57 574.97 821.77 652.58Z" />
          <g id="cloud">
            <path id="&lt;Path&gt;" class="shp5" d="M434.73 188.67L94.6 188.67C112.89 171.41 140.32 166.74 163.15 177.26C164.6 168.23 170.59 159.75 178.63 155.4C186.68 151.06 196.76 150.68 205.1 154.42C211.77 136.49 231.34 124.29 250.37 126.2C269.41 128.11 286.16 143.96 289.13 162.87C296.69 156.26 307.58 154.75 317 158.24C326.43 161.73 333.62 170.63 335.06 180.57C364.68 165.46 407.18 170.04 434.73 188.67Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M1495.79 127.56L1155.66 127.56C1173.94 110.31 1201.37 105.64 1224.2 116.16C1225.65 107.13 1231.64 98.65 1239.69 94.3C1247.74 89.95 1257.81 89.58 1266.16 93.32C1272.82 75.39 1292.39 63.18 1311.43 65.1C1330.47 67.01 1347.22 82.86 1350.18 101.76C1357.75 95.15 1368.64 93.64 1378.06 97.13C1387.48 100.62 1394.68 109.53 1396.12 119.47C1425.74 104.35 1468.23 108.94 1495.79 127.56Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M923.28 122L733.27 122C729.75 101.18 745.48 76.61 765.98 71.56C786.48 66.51 809.78 79.45 816.34 99.52C823.4 90.16 836.56 85.89 847.78 89.33C859 92.77 867.51 103.67 868.12 115.39L923.28 122Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M1068.72 235.27L1299.33 235.27C1303.59 210 1284.51 180.18 1259.63 174.06C1234.74 167.93 1206.46 183.63 1198.51 208C1189.93 196.63 1173.97 191.45 1160.35 195.62C1146.73 199.8 1136.4 213.03 1135.67 227.25L1068.72 235.27Z" />
            <path id="&lt;Path&gt;" class="shp5" d="M613.89 115.42L414.4 115.42L451.78 106.75C453.32 100.94 457.09 95.3 462.55 92.76C468 90.23 474.58 90.25 480.02 92.82C486.46 78.17 502.03 69.28 517.97 70.59C533.91 71.9 548.37 84.11 552.34 99.6C556.89 94.15 565.83 91.92 572.68 93.79C579.54 95.67 585.04 101.77 586.18 108.79L613.89 115.42Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp12" d="M1128.35 590.39C1127.37 586.25 1124.69 583.8 1121.2 582.24C1117.72 580.68 1114.08 580.28 1110.25 582.96C1107.71 584.76 1105.05 588.04 1102.7 593.25C1099.43 584.73 1095.63 579.79 1092.05 577.33C1087.7 574.38 1083.6 574.86 1079.68 576.64C1075.74 578.43 1072.72 581.21 1071.68 585.94C1070.6 590.71 1071.5 597.37 1074.6 607.65C1074.79 608.28 1074.99 608.9 1075.19 609.52C1069.57 604.51 1065.64 605.14 1062.37 607.64C1058.1 610.9 1056.56 616.06 1065.34 627.55C1074.11 639.04 1084.68 645.71 1088.95 642.45C1089.47 642.06 1089.87 641.52 1090.16 640.88C1092.53 643.32 1094.98 644.69 1097.47 644.49C1099.65 644.31 1101.64 642.88 1103.3 640.42C1104.7 641.87 1106.25 642.7 1107.85 642.71C1111.7 642.74 1115.13 638.7 1118.14 632.64C1121.16 626.55 1123.87 618.43 1126.26 609.39C1128.67 600.37 1129.36 594.56 1128.35 590.39Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp13" d="M511.76 731.86C416.29 705.02 317.41 690.4 218.27 688.24C273.33 697.3 327.89 712.24 380.56 729.88C414.89 741.37 448.88 753.99 482.79 766.88C591.46 729.93 702.22 699.12 814.35 674.55C711.95 683.63 610.58 702.75 511.76 731.86Z" />
            <path id="&lt;Path&gt;" class="shp14" d="M814.35 674.55C825.51 672.11 836.67 669.71 847.85 667.39C839.22 662.33 830.53 657.39 821.77 652.58C778.56 628.84 733.76 608.07 687.75 590.37C470.04 506.58 225.69 491.04 0 550.31L0 690.84C71.44 674.57 145.29 676.23 218.27 688.24C317.41 690.4 416.29 705.02 511.76 731.86C610.58 702.75 711.95 683.63 814.35 674.55Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp15" d="M0.65 625.5C111.75 572.5 233.86 541 356.62 535.99C236.38 528.09 114.67 543.24 0 580.26L0.65 625.5Z" />
          <path id="&lt;Path&gt;" class="shp11" d="M814.71 678.19C813.65 672.09 808.96 666.77 803.05 664.95C797.13 663.13 790.26 664.89 785.96 669.34C788.51 664.48 791.14 659.27 790.72 653.79C790.3 648.31 785.44 642.79 780.04 643.83C777.07 644.39 774.76 646.7 772.86 649.05C768.14 654.87 764.66 661.69 762.72 668.92C764.68 664.95 760.65 660 756.23 659.75C751.8 659.51 747.81 662.56 745.19 666.14C739.27 674.19 738.51 685.7 743.29 694.47C766.32 687.67 790.91 681.31 814.71 678.19Z" />
          <path id="&lt;Path&gt;" class="shp11" d="M711.29 702.08C703.55 689.07 689.24 680.2 674.13 679.05C659.03 677.9 643.55 684.51 633.92 696.2C628.97 689.05 615.68 691.29 613.35 699.67C597.69 692.1 577.56 694.99 564.66 706.66C551.76 718.33 546.88 738.07 552.84 754.41C605.69 738.35 658.45 718.15 711.29 702.08Z" />
          <path id="&lt;Path&gt;" class="shp10" d="M1005.96 653.12C1005.54 635.31 991.2 618.84 973.62 615.97C956.05 613.1 937.21 624.15 931.14 640.9C921.48 631.1 903.25 632.04 894.64 642.77C883.34 632.32 865.73 629.32 851.6 635.45C837.48 641.58 827.64 656.49 827.55 671.89C885.33 668.48 948.19 656.52 1005.96 653.12Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp15" d="M1041.62 868.48C1204.28 857.95 1363.84 804.07 1500 714.53L1500 603.18C1281.16 601.23 1062.14 622.92 847.85 667.39C738.71 690.03 630.8 718.6 524.77 752.96C681.45 839.34 863.05 880.05 1041.62 868.48Z" />
            <path id="&lt;Path&gt;" class="shp13" d="M1041.62 868.48C863.05 880.05 681.45 839.34 524.77 752.96C510.74 757.5 496.74 762.14 482.79 766.88C575.72 802.21 667.98 839.53 764.85 861.18C1012.49 916.53 1276.93 863.46 1500 741.72L1500 714.53C1363.84 804.07 1204.28 857.95 1041.62 868.48Z" />
          </g>
          <path id="&lt;Path&gt;" class="shp16" d="M1199.88 648.56C1191.35 649.51 1182.48 650.76 1175.37 655.57C1168.25 660.38 1163.54 669.85 1166.85 677.77C1170.27 685.93 1180.39 689.35 1184.76 697.05C1189.86 706.04 1185.04 718.03 1176.85 724.34C1168.66 730.64 1158.06 732.66 1147.89 734.5C1124.59 738.71 1101.29 742.91 1077.99 747.12C1069.27 748.7 1060.27 750.39 1052.93 755.35C1045.59 760.31 1040.33 769.49 1042.71 778.02C1045.63 788.55 1057.84 793.08 1068.52 795.39C1132.42 809.23 1198.79 799.54 1263.44 789.8C1321.86 781.01 1382.01 771.61 1432.57 741.04C1449.87 730.58 1470.4 712.06 1466.39 689.55C1462.76 669.23 1438.91 656.14 1420.5 651.45C1405.77 647.7 1390.74 650.76 1375.79 650.8C1349.28 650.86 1322.79 645.87 1296.25 644.93C1264.1 643.78 1231.86 644.99 1199.88 648.56Z" />
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp17" d="M1378.12 710.1C1375.41 679.45 1367.58 648.92 1352.81 622.26C1345.48 621.57 1333.05 622.01 1325.72 621.32C1332.47 647.23 1335.23 674.17 1333.94 700.91C1346.67 709.6 1362.97 712.92 1378.12 710.1Z" />
              <path id="&lt;Path&gt;" class="shp18" d="M1333.94 700.91C1333.51 709.83 1332.62 718.73 1331.28 727.56C1336.5 734.97 1345.18 740.49 1354.19 741.44C1363.21 742.39 1372.58 739.28 1379.24 733.12C1379.17 725.48 1378.8 717.79 1378.12 710.1C1362.97 712.92 1346.67 709.6 1333.94 700.91Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1447.71 634.25C1432.95 642.41 1413.99 642.19 1398.83 634.81C1393.37 646.66 1382.81 656.05 1370.4 660.09C1358 664.12 1344.39 660.83 1333 654.47C1321.64 665.4 1305.15 670.77 1289.53 668.62C1273.91 666.47 1260.44 656.63 1252.46 643.04C1264.68 635.7 1273.93 623.99 1279.82 611C1266.98 623.51 1249.21 631.42 1231.32 630.42C1213.42 629.43 1195.88 618.73 1188.61 602.35C1181.34 585.96 1182.74 560.54 1197.32 550.12C1188.75 539.2 1184.66 524.87 1186.18 511.08C1187.39 500.13 1192.13 489.64 1199.4 481.38C1188.53 486.58 1179.42 495.52 1174.4 506.49C1167.17 522.28 1168.66 541.75 1178.21 556.27C1160.91 572.83 1155.86 600.84 1166.27 622.41C1176.69 643.97 1201.77 657.43 1225.49 654.18C1235.5 672.95 1254.16 686.87 1275 691.12C1295.85 695.38 1318.47 689.87 1335.03 676.52C1349.17 679.62 1368.16 682.28 1382.27 679.05C1396.39 675.81 1409.72 664.28 1410.04 649.81C1426.5 657.34 1447.42 653.72 1460.38 641.09C1470.83 630.9 1475.55 615.4 1473.27 601.05C1469.65 614.93 1460.28 627.29 1447.71 634.25Z" />
              <path id="&lt;Path&gt;" class="shp19" d="M1469.86 531.57C1477.39 516.15 1484.24 499.25 1481.58 482.3C1478.92 465.34 1463.09 449.39 1446.26 452.76C1448.14 429.61 1433.99 406.02 1412.69 396.77C1391.39 387.52 1364.49 393.29 1348.86 410.47C1344.52 399.24 1333.1 391.13 1321.07 390.72C1309.04 390.32 1295.48 394.31 1290.39 405.21C1275.11 395.64 1253.63 397.35 1240.05 409.23C1226.48 421.1 1221.93 442.16 1229.39 458.58C1219.88 456.24 1210.32 468 1214.56 476.83C1209.3 477.52 1204.18 479.09 1199.4 481.38C1192.13 489.64 1187.39 500.13 1186.18 511.08C1184.66 524.87 1188.75 539.2 1197.32 550.12C1182.74 560.54 1181.34 585.96 1188.61 602.35C1195.88 618.73 1213.42 629.43 1231.32 630.42C1249.21 631.42 1266.98 623.51 1279.82 611C1273.93 623.99 1264.68 635.7 1252.46 643.04C1260.44 656.63 1273.91 666.47 1289.53 668.62C1305.15 670.77 1321.64 665.4 1333 654.47C1344.39 660.83 1358 664.12 1370.4 660.09C1382.81 656.05 1393.37 646.66 1398.83 634.81C1413.99 642.19 1432.95 642.41 1447.71 634.25C1460.28 627.29 1469.65 614.93 1473.27 601.05C1472.72 597.6 1471.77 594.22 1470.4 590.99C1487.24 579.29 1485.17 545.22 1469.86 531.57Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1398.54 482.55C1396.29 478.14 1392.48 474.07 1387.72 471.45C1382.96 468.83 1377.48 467.81 1372.56 468.27C1369.28 468.56 1366.27 469.51 1363.76 470.85C1361.8 464.81 1358.03 458.92 1352.73 454.54C1346.48 449.38 1338.57 446.69 1331.28 446.53C1323.96 446.32 1317.3 448.65 1312.88 452.12C1318.52 452.43 1324.3 453.4 1329.86 455.31C1335.42 457.2 1340.72 460.02 1345.18 463.68C1349.62 467.37 1353.39 472.05 1356.3 477.14C1359.23 482.24 1361.27 487.74 1362.64 493.22C1365.21 488.22 1366.24 481.24 1364.65 474.09C1364.59 473.82 1364.5 473.56 1364.44 473.3C1371 473.74 1377.86 475.3 1383.81 478.58C1391.01 482.52 1396.84 488.94 1400.96 495.46C1401.53 491.64 1400.82 486.95 1398.54 482.55Z" />
              <path id="&lt;Path&gt;" class="shp13" d="M1250.34 482.53C1244.97 481.94 1238.83 483.36 1233.96 487.02C1229.08 490.67 1225.99 496.16 1225.04 501.48C1224.04 506.82 1225.19 511.97 1227.48 515.39C1228.03 511.3 1229.25 507.22 1231.22 503.49C1233.19 499.76 1235.9 496.4 1239.15 493.95C1242.41 491.52 1246.4 489.87 1250.53 489.03C1254.67 488.18 1258.92 488.15 1263.01 488.78C1260.36 485.62 1255.74 483.07 1250.34 482.53Z" />
            </g>
          </g>
          <path id="&lt;Path&gt;" class="shp12" d="M865.01 892.05C863.83 876.46 857.97 860.53 845.88 850.62C833.78 840.71 814.72 838.6 802.62 848.51C806.57 827.91 796.31 805.35 778.19 794.79C760.07 784.23 735.39 786.43 719.42 800.02C712.76 794.81 703.57 793 695.44 795.3C687.31 797.6 680.43 803.95 677.49 811.88C666.57 803.74 651.96 800.78 638.75 804.04C625.53 807.29 613.96 816.7 608.07 828.97C663.8 844.11 723.48 861.83 779.21 876.97C805.67 884.16 837.99 887.38 865.01 892.05Z" />
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp20" d="M544.22 941.46C705.91 969.57 869.91 984.32 1034.02 985.52C1194.64 986.69 1363.25 972.29 1500 889.84L1500 741.72C1276.93 863.46 1012.49 916.53 764.85 861.18C667.98 839.53 575.72 802.21 482.79 766.88C448.88 753.99 414.89 741.37 380.56 729.88C258.09 688.87 125.34 662.29 0 690.84L0 882.19C179.99 862.1 363.87 910.1 544.22 941.46Z" />
            <path id="&lt;Path&gt;" class="shp15" d="M1034.02 985.52C869.91 984.32 705.91 969.57 544.22 941.46C363.87 910.1 179.99 862.1 0 882.19L0 1000L1500 1000L1500 889.84C1363.25 972.29 1194.64 986.69 1034.02 985.52Z" />
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp21" d="M1233.9 920.68C1233.9 933.88 1219.62 933.67 1202.01 933.67C1184.39 933.67 1170.12 933.88 1170.12 920.68C1170.12 907.48 1184.39 896.78 1202.01 896.78C1219.62 896.78 1233.9 907.48 1233.9 920.68Z" />
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M270.27 762.92C273.58 753.83 280.97 745.22 289.89 741.46C296.43 738.71 303.79 738.07 310.73 739.48C303.4 730.73 290.45 724.9 275.65 724.9C252.77 724.9 234.21 738.8 234.21 755.96C234.21 767.71 242.93 771.28 255.77 772.36C259.05 767.42 264.39 763.82 270.27 762.92Z" />
                <path id="&lt;Path&gt;" class="shp22" d="M289.89 741.46C280.97 745.22 273.58 753.83 270.27 762.92C264.39 763.82 259.05 767.42 255.77 772.36C261.68 772.86 268.45 772.84 275.65 772.84C298.54 772.84 317.09 773.11 317.09 755.96C317.09 749.9 314.74 744.26 310.73 739.48C303.79 738.07 296.43 738.71 289.89 741.46Z" />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M294.29 767.93C288.88 764.7 282.39 763.33 276.14 764.04C275.83 765.29 275.65 766.57 275.65 767.88C275.65 779.59 288.32 779.41 303.95 779.41C304.33 779.41 304.71 779.41 305.09 779.41C302.62 774.7 298.86 770.66 294.29 767.93Z" />
                <path id="&lt;Path&gt;" class="shp23" d="M332.25 767.88C332.25 756.16 319.58 746.67 303.95 746.67C290.07 746.67 278.55 754.16 276.14 764.04C282.39 763.33 288.88 764.7 294.29 767.93C298.86 770.66 302.62 774.7 305.09 779.41C320.18 779.41 332.25 779.31 332.25 767.88Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp5" d="M296.23 782.58C296.23 790.24 287.94 790.12 277.71 790.12C267.48 790.12 259.19 790.24 259.19 782.58C259.19 774.91 267.48 768.69 277.71 768.69C287.94 768.69 296.23 774.91 296.23 782.58Z" />
            </g>
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="shp21" d="M514.56 857.87C521.58 853.62 529.72 851.28 537.92 851.01C532.06 838.79 516.51 830.03 498.19 830.03C474.86 830.03 455.94 844.21 455.94 861.7C455.94 878.61 473.63 878.91 495.88 878.91C499.86 870.27 506.42 862.81 514.56 857.87Z" />
                <path id="&lt;Path&gt;" class="shp22" d="M514.56 857.87C506.42 862.81 499.86 870.27 495.88 878.91C496.65 878.91 497.41 878.91 498.19 878.91C521.52 878.91 540.44 879.19 540.44 861.7C540.44 857.94 539.52 854.35 537.92 851.01C529.72 851.28 521.58 853.62 514.56 857.87Z" />
              </g>
              <path id="&lt;Path&gt;" class="shp23" d="M572.72 878.37C572.72 891.58 558.44 891.36 540.83 891.36C523.22 891.36 508.94 891.58 508.94 878.37C508.94 865.17 523.22 854.47 540.83 854.47C558.44 854.47 572.72 865.17 572.72 878.37Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M347.83 829.71C354.26 829.71 354.27 819.71 347.83 819.71C341.39 819.71 341.38 829.71 347.83 829.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M1373.88 829.71C1380.31 829.71 1380.33 819.71 1373.88 819.71C1367.45 819.71 1367.44 829.71 1373.88 829.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M303.83 851.71C310.26 851.71 310.27 841.71 303.83 841.71C297.39 841.71 297.38 851.71 303.83 851.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M435.83 834.71C442.26 834.71 442.27 824.71 435.83 824.71C429.39 824.71 429.38 834.71 435.83 834.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M538.83 917.71C545.26 917.71 545.27 907.71 538.83 907.71C532.39 907.71 532.38 917.71 538.83 917.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M1142 943.71C1148.43 943.71 1148.45 933.71 1142 933.71C1135.57 933.71 1135.55 943.71 1142 943.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M1308.05 911.71C1314.48 911.71 1314.49 901.71 1308.05 901.71C1301.61 901.71 1301.6 911.71 1308.05 911.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M1276 882.42C1282.43 882.42 1282.45 872.42 1276 872.42C1269.57 872.42 1269.55 882.42 1276 882.42Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M897.83 952.71C904.26 952.71 904.27 942.71 897.83 942.71C891.39 942.71 891.38 952.71 897.83 952.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M935.83 916.71C942.26 916.71 942.27 906.71 935.83 906.71C929.39 906.71 929.38 916.71 935.83 916.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp14" d="M145.83 744.71C152.26 744.71 152.27 734.71 145.83 734.71C139.39 734.71 139.38 744.71 145.83 744.71Z" />
            </g>
          </g>
          <g id="&lt;Group&gt;">
            <path id="&lt;Path&gt;" class="shp24" d="M1006.07 912.57C998.76 910.45 991.49 908.62 984.24 907.12C983.68 907.01 983.34 906.47 983.49 905.92C983.63 905.37 984.2 905.01 984.76 905.13C991.36 906.47 997.99 908.08 1004.61 909.96C1000.69 902.88 996.47 895.98 991.96 889.26C984.52 887.78 977.08 886.64 969.74 885.78C969.17 885.71 968.8 885.2 968.9 884.64C968.99 884.08 969.53 883.67 970.1 883.73C973.42 884.13 976.78 884.57 980.17 885.06C983.51 885.58 986.89 886.15 990.3 886.77C985.73 880.1 980.9 873.61 975.84 867.3C968.28 866.51 960.8 866 953.39 865.83C952.82 865.82 952.4 865.35 952.44 864.78C952.49 864.21 952.98 863.76 953.55 863.77C960.29 863.91 967.09 864.32 973.95 864.98C968.98 858.98 963.85 853.11 958.43 847.51C958.03 847.09 958.04 846.41 958.46 846C958.89 845.59 959.55 845.61 959.96 846.04C968.17 854.61 975.9 863.65 983.08 873.1C983.52 869.78 983.92 866.4 984.28 862.99C984.6 859.61 984.89 856.18 985.13 852.71C985.17 852.11 985.69 851.68 986.29 851.75C986.88 851.83 987.33 852.38 987.29 852.98C986.76 860.63 985.97 868.13 984.88 875.52C990.07 882.51 994.99 889.71 999.54 897.13C1001.09 890.55 1002.4 883.86 1003.52 877.03C1003.62 876.43 1004.17 876.05 1004.76 876.18C1005.36 876.3 1005.76 876.89 1005.65 877.49C1004.39 885.05 1002.89 892.45 1001.12 899.7C1006.62 908.82 1011.64 918.23 1016.17 927.87L1018.58 927.87C1017.05 924.15 1015.83 919.75 1014.99 914.74C1013.17 904.02 1013.19 890.59 1015.43 876.03C1012.68 871.01 1009.53 865.97 1005.86 861.03C993.01 843.77 981.66 836.2 973.03 834.08C964.31 831.99 958.1 835.26 952.98 840.47C947.83 845.72 944.76 851.96 945.92 860.06C947.05 868.23 952.39 878.14 963.14 892.57C973.7 906.83 984.22 919.21 993.88 927.87L1013.81 927.87C1011.38 922.7 1008.76 917.61 1006.07 912.57Z" />
            <path id="&lt;Path&gt;" class="shp10" d="M1005.65 877.49C1005.76 876.89 1005.36 876.3 1004.76 876.18C1004.17 876.05 1003.62 876.43 1003.52 877.03C1002.4 883.86 1001.09 890.55 999.54 897.13C994.99 889.71 990.07 882.51 984.88 875.52C985.97 868.13 986.76 860.63 987.29 852.98C987.33 852.38 986.88 851.83 986.29 851.75C985.69 851.68 985.17 852.11 985.13 852.71C984.89 856.18 984.6 859.61 984.28 862.99C983.92 866.4 983.52 869.78 983.08 873.1C975.9 863.65 968.17 854.61 959.96 846.04C959.55 845.61 958.89 845.59 958.46 846C958.04 846.41 958.03 847.09 958.43 847.51C963.85 853.11 968.98 858.98 973.95 864.98C967.09 864.32 960.29 863.91 953.55 863.77C952.98 863.76 952.49 864.21 952.44 864.78C952.4 865.35 952.82 865.82 953.39 865.83C960.8 866 968.28 866.51 975.84 867.3C980.9 873.61 985.73 880.1 990.3 886.77C986.89 886.15 983.51 885.58 980.17 885.06C976.78 884.57 973.42 884.13 970.1 883.73C969.53 883.67 968.99 884.08 968.9 884.64C968.8 885.2 969.17 885.71 969.74 885.78C977.08 886.64 984.52 887.78 991.96 889.26C996.47 895.98 1000.69 902.88 1004.61 909.96C997.99 908.08 991.36 906.47 984.76 905.13C984.2 905.01 983.63 905.37 983.49 905.92C983.34 906.47 983.68 907.01 984.24 907.12C991.49 908.62 998.76 910.45 1006.07 912.57C1008.76 917.61 1011.38 922.7 1013.81 927.87L1016.17 927.87C1011.64 918.23 1006.62 908.82 1001.12 899.7C1002.89 892.45 1004.39 885.05 1005.65 877.49Z" />
            <path id="&lt;Path&gt;" class="shp24" d="M1063.07 908.68C1060.79 913.56 1058.44 918.09 1056.02 922.09C1054.77 924.16 1053.49 926.09 1052.2 927.87L1058.81 927.87C1060.64 925.4 1062.48 922.93 1064.35 920.49C1063.86 916.59 1063.45 912.64 1063.07 908.68Z" />
            <path id="&lt;Path&gt;" class="shp24" d="M1100.26 910.32C1109.85 899.92 1114.57 892.43 1115.87 886.21C1117.19 880.04 1115.06 875.2 1111.35 871.06C1107.64 866.93 1103.05 864.26 1096.47 865.39C1090.71 866.4 1083.33 870.38 1074.35 879.23C1071.33 888.83 1067.99 897.76 1064.44 905.73C1064.81 910.08 1065.23 914.41 1065.76 918.69C1069.55 913.83 1073.46 909.07 1077.5 904.41C1077.08 898.65 1076.81 892.83 1076.67 886.96C1076.66 886.51 1077.02 886.12 1077.47 886.09C1077.92 886.07 1078.29 886.41 1078.3 886.87C1078.42 892.19 1078.65 897.46 1078.99 902.69C1083.05 898.05 1087.23 893.52 1091.51 889.08C1091.42 883.31 1091.45 877.47 1091.65 871.61C1091.67 871.16 1092.05 870.79 1092.5 870.79C1092.95 870.79 1093.3 871.16 1093.29 871.61C1093.1 876.92 1093.04 882.2 1093.1 887.44C1097.26 883.2 1101.49 879.04 1105.85 875C1106.17 874.7 1106.68 874.71 1106.98 875.04C1107.29 875.37 1107.27 875.88 1106.95 876.18C1100.4 882.28 1094.07 888.61 1087.96 895.15C1093.07 894.98 1098.13 894.92 1103.16 894.95C1103.59 894.96 1103.96 895.32 1103.97 895.75C1103.98 896.19 1103.64 896.54 1103.2 896.54C1097.65 896.5 1092.05 896.59 1086.42 896.82C1081.98 901.65 1077.66 906.59 1073.48 911.65C1078.56 911.18 1083.62 910.82 1088.64 910.56C1089.08 910.54 1089.46 910.88 1089.49 911.31C1089.53 911.75 1089.2 912.12 1088.77 912.14C1083.23 912.43 1077.65 912.85 1072.04 913.41C1068.19 918.13 1064.49 922.97 1060.86 927.87L1081.85 927.87C1087.81 923.03 1094.06 917.07 1100.26 910.32Z" />
            <path id="&lt;Path&gt;" class="shp10" d="M1064.35 920.49C1062.48 922.93 1060.64 925.4 1058.81 927.87L1060.86 927.87C1064.49 922.97 1068.19 918.13 1072.04 913.41C1077.65 912.85 1083.23 912.43 1088.77 912.14C1089.2 912.12 1089.53 911.75 1089.49 911.31C1089.46 910.88 1089.08 910.54 1088.64 910.56C1083.62 910.82 1078.56 911.18 1073.48 911.65C1077.66 906.59 1081.98 901.65 1086.42 896.82C1092.05 896.59 1097.65 896.5 1103.2 896.54C1103.64 896.54 1103.98 896.19 1103.97 895.75C1103.96 895.32 1103.59 894.96 1103.16 894.95C1098.13 894.92 1093.07 894.98 1087.96 895.15C1094.07 888.61 1100.4 882.28 1106.95 876.18C1107.27 875.88 1107.29 875.37 1106.98 875.04C1106.68 874.71 1106.17 874.7 1105.85 875C1101.49 879.04 1097.26 883.2 1093.1 887.44C1093.04 882.2 1093.1 876.92 1093.29 871.61C1093.3 871.16 1092.95 870.79 1092.5 870.79C1092.05 870.79 1091.67 871.16 1091.65 871.61C1091.45 877.47 1091.42 883.31 1091.51 889.08C1087.23 893.52 1083.05 898.05 1078.99 902.69C1078.65 897.46 1078.42 892.19 1078.3 886.87C1078.29 886.41 1077.92 886.07 1077.47 886.09C1077.02 886.12 1076.66 886.51 1076.67 886.96C1076.81 892.83 1077.08 898.65 1077.5 904.41C1073.46 909.07 1069.55 913.83 1065.76 918.69C1065.23 914.41 1064.81 910.08 1064.44 905.73C1063.99 906.75 1063.53 907.7 1063.07 908.68C1063.45 912.64 1063.86 916.59 1064.35 920.49Z" />
            <path id="&lt;Path&gt;" class="shp25" d="M1014.99 914.74C1015.83 919.75 1017.05 924.15 1018.58 927.87L1032.72 927.87C1033.74 921.46 1034.91 915.07 1036.13 908.69C1031.44 901.65 1026.86 894.44 1022.43 887.07C1022.1 886.5 1022.28 885.75 1022.83 885.39C1023.39 885.03 1024.12 885.2 1024.46 885.77C1028.45 892.46 1032.56 899 1036.8 905.41C1038.59 896.56 1040.61 887.76 1042.86 879.02C1038.54 871.73 1034.4 864.28 1030.38 856.67C1030.07 856.08 1030.29 855.34 1030.86 855.01C1031.44 854.68 1032.15 854.89 1032.46 855.48C1036.09 862.38 1039.83 869.15 1043.69 875.78C1045.99 867.05 1048.51 858.37 1051.22 849.76C1047.33 842.25 1043.58 834.57 1040.01 826.76C1039.74 826.15 1039.99 825.42 1040.59 825.13C1041.18 824.83 1041.88 825.09 1042.16 825.69C1045.38 832.78 1048.75 839.74 1052.24 846.58C1054.93 838.31 1057.76 830.08 1060.85 821.94C1061.07 821.33 1061.75 821.02 1062.36 821.25C1062.97 821.49 1063.28 822.17 1063.05 822.78C1058.44 835.04 1054.27 847.48 1050.53 860.04C1057.11 856.48 1063.72 853.09 1070.33 849.87C1070.9 849.59 1071.61 849.82 1071.91 850.39C1072.21 850.95 1071.99 851.64 1071.42 851.92C1064.12 855.49 1056.84 859.25 1049.61 863.24C1046.93 872.47 1044.49 881.76 1042.32 891.13C1048.67 887.19 1055.07 883.42 1061.48 879.81C1062.04 879.49 1062.76 879.68 1063.09 880.23C1063.42 880.78 1063.24 881.47 1062.69 881.79C1055.62 885.78 1048.57 889.97 1041.58 894.37C1039.07 905.47 1036.92 916.64 1035.11 927.87L1052.2 927.87C1053.49 926.09 1054.77 924.16 1056.02 922.09C1058.44 918.09 1060.79 913.56 1063.07 908.68C1063.53 907.7 1063.99 906.75 1064.44 905.73C1067.99 897.76 1071.33 888.83 1074.35 879.23C1075.11 876.83 1075.84 874.39 1076.56 871.91C1082.34 852.02 1083.65 839.12 1081.3 830.12C1079 821.16 1073.06 816.2 1065.49 813.19C1057.93 810.19 1050.18 809.68 1042.28 815.46C1034.44 821.23 1026.36 833.39 1019.9 855.61C1017.89 862.57 1016.46 869.41 1015.43 876.03C1013.19 890.59 1013.17 904.02 1014.99 914.74Z" />
            <path id="&lt;Path&gt;" class="shp10" d="M1062.69 881.79C1063.24 881.47 1063.42 880.78 1063.09 880.23C1062.76 879.68 1062.04 879.49 1061.48 879.81C1055.07 883.42 1048.67 887.19 1042.32 891.13C1044.49 881.76 1046.93 872.47 1049.61 863.24C1056.84 859.25 1064.12 855.49 1071.42 851.92C1071.99 851.64 1072.21 850.95 1071.91 850.39C1071.61 849.82 1070.9 849.59 1070.33 849.87C1063.72 853.09 1057.11 856.48 1050.53 860.04C1054.27 847.48 1058.44 835.04 1063.05 822.78C1063.28 822.17 1062.97 821.49 1062.36 821.25C1061.75 821.02 1061.07 821.33 1060.85 821.94C1057.76 830.08 1054.93 838.31 1052.24 846.58C1048.75 839.74 1045.38 832.78 1042.16 825.69C1041.88 825.09 1041.18 824.83 1040.59 825.13C1039.99 825.42 1039.74 826.15 1040.01 826.76C1043.58 834.57 1047.33 842.25 1051.22 849.76C1048.51 858.37 1045.99 867.05 1043.69 875.78C1039.83 869.15 1036.09 862.38 1032.46 855.48C1032.15 854.89 1031.44 854.68 1030.86 855.01C1030.29 855.34 1030.07 856.08 1030.38 856.67C1034.4 864.28 1038.54 871.73 1042.86 879.02C1040.61 887.76 1038.59 896.56 1036.8 905.41C1032.56 899 1028.45 892.46 1024.46 885.77C1024.12 885.2 1023.39 885.03 1022.83 885.39C1022.28 885.75 1022.1 886.5 1022.43 887.07C1026.86 894.44 1031.44 901.65 1036.13 908.69C1034.91 915.07 1033.74 921.46 1032.72 927.87L1035.11 927.87C1036.92 916.64 1039.07 905.47 1041.58 894.37C1048.57 889.97 1055.62 885.78 1062.69 881.79Z" />
          </g>
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M819.24 751.67C823.1 751.67 823.11 745.67 819.24 745.67C815.38 745.67 815.38 751.67 819.24 751.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M835.24 776.33C839.1 776.33 839.11 770.33 835.24 770.33C831.38 770.33 831.38 776.33 835.24 776.33Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M891.24 729.67C895.1 729.67 895.11 723.67 891.24 723.67C887.38 723.67 887.38 729.67 891.24 729.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M881.24 781.67C885.1 781.67 885.11 775.67 881.24 775.67C877.38 775.67 877.38 781.67 881.24 781.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M891.24 842.33C895.1 842.33 895.11 836.33 891.24 836.33C887.38 836.33 887.38 842.33 891.24 842.33Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M767.91 767.67C771.77 767.67 771.78 761.67 767.91 761.67C764.05 761.67 764.04 767.67 767.91 767.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1020.58 724.33C1024.44 724.33 1024.44 718.33 1020.58 718.33C1016.72 718.33 1016.71 724.33 1020.58 724.33Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M1142 655.58C1145.86 655.58 1145.87 649.58 1142 649.58C1138.14 649.58 1138.13 655.58 1142 655.58Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1085.24 813.67C1089.1 813.67 1089.11 807.67 1085.24 807.67C1081.38 807.67 1081.38 813.67 1085.24 813.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1109.91 765.67C1113.77 765.67 1113.78 759.67 1109.91 759.67C1106.05 759.67 1106.04 765.67 1109.91 765.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M978.58 800.33C982.44 800.33 982.44 794.33 978.58 794.33C974.72 794.33 974.71 800.33 978.58 800.33Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M1213.68 732.67C1217.54 732.67 1217.55 726.67 1213.68 726.67C1209.82 726.67 1209.81 732.67 1213.68 732.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1116.26 691.74C1120.12 691.74 1120.13 685.74 1116.26 685.74C1112.4 685.74 1112.4 691.74 1116.26 691.74Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M1160.88 827.71C1164.74 827.71 1164.75 821.71 1160.88 821.71C1157.02 821.71 1157.02 827.71 1160.88 827.71Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1263.01 749.67C1266.87 749.67 1266.87 743.67 1263.01 743.67C1259.15 743.67 1259.14 749.67 1263.01 749.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1308.05 719.67C1311.91 719.67 1311.91 713.67 1308.05 713.67C1304.18 713.67 1304.18 719.67 1308.05 719.67Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1466.89 681.92C1470.76 681.92 1470.76 675.92 1466.89 675.92C1463.03 675.92 1463.03 681.92 1466.89 681.92Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M1063.24 673C1067.1 673 1067.11 667 1063.24 667C1059.38 667 1059.38 673 1063.24 673Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp12" d="M991.24 699C995.1 699 995.11 693 991.24 693C987.38 693 987.38 699 991.24 699Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M947.24 696.33C951.1 696.33 951.11 690.33 947.24 690.33C943.38 690.33 943.38 696.33 947.24 696.33Z" />
            </g>
            <g id="&lt;Group&gt;">
              <path id="&lt;Path&gt;" class="shp13" d="M690.58 749.67C694.44 749.67 694.44 743.67 690.58 743.67C686.72 743.67 686.71 749.67 690.58 749.67Z" />
            </g>
          </g>
          <g id="&lt;Clip Group&gt;" clip-path="url(#cp1)">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp26" d="M185.77 948.84C175.7 956.78 166.03 964.27 156.8 971.03C147.53 977.74 138.94 983.91 130.73 988.74C114.53 998.45 100.31 1003.65 90.65 998.1C85.88 995.32 82.93 990.07 81.88 982.63C80.87 975.23 81.94 965.73 85.42 954.97C88.98 944.3 94.79 932.24 103.37 920.38C111.89 908.48 122.96 896.62 136.22 886.15C149.5 875.71 161.53 869.08 171.83 865.25C176.95 863.28 181.73 862.14 185.96 861.35C190.24 860.67 194.11 860.57 197.53 860.83C211.33 862.1 218.36 870.04 222.8 880.08C227.29 890.2 228.28 900.61 222.91 911.31C221.59 914.04 219.84 916.72 217.68 919.52C215.57 922.42 212.93 925.2 209.9 928.26C203.78 934.29 195.85 940.92 185.77 948.84Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M163.68 945.32C158.83 944.82 153.98 944.52 148.97 944.25C143.99 944 139.06 943.99 133.95 943.99C142.97 934.65 152.7 926 162.92 918C173.99 919.35 184.79 921.41 195.18 924.03C195.99 924.23 196.75 923.72 196.88 922.87C197.02 922.04 196.48 921.19 195.66 920.98C190.97 919.82 186.18 918.73 181.27 917.72C176.43 916.8 171.5 915.98 166.46 915.23C180.59 904.56 195.67 895.16 211.48 887.21C212.26 886.81 212.58 885.84 212.19 885.05C211.8 884.26 210.84 883.94 210.05 884.35C199.44 889.58 189.26 895.66 179.38 902.16C180.37 896.98 181.64 891.97 182.97 886.8C184.28 881.59 185.86 876.55 187.52 871.37C187.8 870.49 187.31 869.61 186.42 869.4C185.52 869.19 184.55 869.74 184.27 870.61C182.4 876.26 180.81 882.03 179.37 887.71C177.88 893.31 176.71 899.1 175.62 904.7C165.52 911.68 155.83 919.25 146.64 927.4C146.99 922.25 147.45 917 148.02 911.68C148.68 906.46 149.45 901.16 150.33 895.77C150.49 894.87 149.88 894.06 148.96 893.98C148.05 893.9 147.18 894.59 147.03 895.5C145.06 907.33 143.78 918.97 143.3 930.48C138.73 934.59 134.44 938.97 130.11 943.33C125.94 947.83 121.74 952.31 117.82 957.04C117.42 951.86 117.06 946.54 116.99 941.29C116.89 936 116.81 930.55 117.04 925.21C117.07 924.29 116.36 923.58 115.43 923.62C114.51 923.67 113.73 924.45 113.7 925.37C113.53 931.34 113.62 937.34 113.75 943.13C113.99 949.03 114.38 954.83 114.88 960.51C108.8 968.16 102.81 975.9 97.52 984.13C97.03 984.87 97.23 985.86 97.97 986.34C98.71 986.82 99.7 986.62 100.19 985.89C109.27 972.16 119.55 959.22 130.85 947.24C136.38 947.04 142.02 947.18 147.43 947.31C152.88 947.48 158.37 947.93 163.64 948.38C164.47 948.45 165.16 947.84 165.18 947.01C165.2 946.17 164.53 945.41 163.68 945.32Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp26" d="M111.28 851.92C94.05 872.58 76.7 890.05 61.13 901.58C45.6 913.05 31.66 918.49 22.21 913.26C12.87 908.11 9.99 892.85 14.63 872.23C19.23 851.69 31.67 825.96 51.49 802.19C71.36 778.46 88.02 768.18 100.66 765.56C113.41 762.98 122.38 767.96 129.7 775.78C137.05 783.63 141.34 792.88 139.19 804.79C137.07 816.79 128.54 831.3 111.28 851.92Z" />
                    <path id="&lt;Path&gt;" class="shp27" d="M89 853.38C79.39 854.36 69.69 855.59 59.96 857.11C67.38 846.85 75.2 836.87 83.33 827.15C94.16 826.18 104.94 825.58 115.6 825.29C116.44 825.26 117.06 824.57 117 823.72C116.95 822.88 116.22 822.22 115.38 822.24C110.57 822.38 105.71 822.57 100.81 822.8C95.96 823.08 91.07 823.41 86.14 823.79C97.33 810.63 109.11 797.97 121.44 785.86C122.05 785.26 122.06 784.27 121.46 783.66C120.86 783.04 119.88 783.03 119.26 783.64C111.04 791.65 103.14 799.99 95.42 808.49C94.92 798.37 94.69 788.14 94.78 777.83C94.78 776.95 94.08 776.27 93.2 776.3C92.33 776.33 91.61 777.07 91.6 777.95C91.53 789.33 91.84 800.63 92.47 811.77C84.57 820.68 76.92 829.82 69.56 839.2C68.98 834.19 68.44 829.13 67.95 824.02C67.5 818.95 67.11 813.83 66.75 808.66C66.7 807.79 65.95 807.15 65.07 807.24C64.2 807.34 63.55 808.13 63.61 809.01C64.37 820.39 65.43 831.61 66.87 842.69C59.59 852.12 52.61 861.79 45.97 871.68C44.04 861.73 42.39 851.64 41.01 841.42C40.9 840.55 40.1 839.97 39.24 840.12C38.37 840.27 37.76 841.1 37.87 841.97C38.64 847.62 39.54 853.25 40.44 858.78C41.4 864.36 42.42 869.88 43.5 875.33C38.31 883.28 33.18 891.26 28.39 899.45C27.94 900.19 28.19 901.14 28.93 901.58C29.67 902.02 30.62 901.77 31.06 901.04C39.26 887.22 48.05 873.75 57.39 860.67C68.13 858.9 78.84 857.49 89.43 856.4C90.26 856.3 90.84 855.56 90.73 854.74C90.61 853.91 89.84 853.3 89 853.38Z" />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp24" d="M42.02 896.12C37.32 909.22 32.55 921.61 27.69 932.98C22.78 944.34 18.02 954.72 12.92 963.56C2.9 981.18 -7.87 993.05 -19.58 992.58C-31.05 992.13 -41.36 978.59 -46 955.57C-48.22 944.13 -49.15 930.38 -47.76 915.38C-46.42 900.38 -42.99 884.08 -37.13 867.75C-31.24 851.43 -24.55 839.03 -17.84 829.93C-14.53 825.36 -11.08 821.66 -7.87 818.58C-4.58 815.55 -1.32 813.24 1.79 811.49C14.4 804.61 25.23 807.21 35.12 813.2C45.07 819.24 52.21 827.62 53.78 840.32C54.22 843.52 54.26 846.97 53.98 850.76C53.78 854.6 53.05 858.69 52.11 863.25C50.11 872.33 46.75 883.04 42.02 896.12Z" />
                    <path id="&lt;Path&gt;" class="shp10" d="M20.18 905.51C15.53 907.77 10.97 910.18 6.31 912.67C1.67 915.17 -2.82 917.83 -7.44 920.56C-4.23 907.27 -0.22 894.17 4.45 881.32C15.09 876.34 25.86 871.99 36.59 868.23C37.43 867.93 37.82 867.03 37.46 866.2C37.11 865.37 36.14 864.94 35.29 865.23C30.45 866.94 25.57 868.76 20.65 870.67C15.81 872.63 10.97 874.7 6.06 876.87C12.67 859.42 20.52 842.46 29.57 826.15C30.01 825.34 29.71 824.31 28.9 823.85C28.09 823.4 27.07 823.69 26.63 824.51C20.52 835.35 15.12 846.57 10.13 857.95C8.06 852.82 6.26 847.68 4.44 842.4C2.58 837.1 0.98 831.8 -0.61 826.36C-0.88 825.44 -1.83 824.95 -2.73 825.28C-3.64 825.61 -4.15 826.63 -3.88 827.56C-2.17 833.52 -0.22 839.45 1.8 845.25C3.76 851.02 6.01 856.76 8.25 862.34C3.28 874.25 -1.14 886.39 -4.94 898.73C-7.45 893.9 -9.92 888.96 -12.36 883.91C-14.68 878.9 -16.96 873.78 -19.19 868.56C-19.56 867.67 -20.56 867.29 -21.42 867.72C-22.27 868.16 -22.66 869.23 -22.28 870.12C-17.39 881.63 -12.09 892.69 -6.27 903.31C-8.14 909.49 -9.69 915.76 -11.27 922.01C-12.68 928.31 -14.12 934.6 -15.24 940.96C-18.31 936.44 -21.42 931.79 -24.28 927.07C-27.18 922.35 -30.13 917.5 -32.81 912.59C-33.27 911.75 -34.31 911.49 -35.12 912.01C-35.93 912.53 -36.21 913.64 -35.74 914.48C-32.69 919.92 -29.44 925.26 -26.24 930.44C-22.92 935.64 -19.54 940.72 -16.12 945.65C-16.87 950.73 -17.84 955.78 -18.41 960.89L-19.37 968.54L-20.1 976.21C-20.19 977.14 -19.51 977.96 -18.58 978.04C-17.66 978.12 -16.84 977.44 -16.75 976.52C-15.19 959.24 -12.43 942.07 -8.53 925.17C-3.59 922.05 1.57 919.13 6.56 916.3C11.59 913.49 16.77 910.86 21.81 908.33C22.6 907.93 22.89 906.98 22.46 906.21C22.02 905.44 21 905.12 20.18 905.51Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp24" d="M143.12 1007.6C123.17 1017.11 103.94 1023.46 88.43 1025.77C72.95 1028.07 61.13 1026.25 56.75 1018.93C52.39 1011.66 56.44 1000.18 67.14 987.77C77.8 975.39 95.19 962.2 116.6 952C138.02 941.83 152.75 939.41 162.66 941.31C172.6 943.22 177.8 949.45 180.98 957.33C184.16 965.22 184.7 973.29 179.42 981.51C174.14 989.77 163.09 998.12 143.12 1007.6Z" />
                    <path id="&lt;Path&gt;" class="shp10" d="M126.35 1001.31C119.05 998.76 111.67 996.29 104.22 993.92C113.14 989.29 122.15 984.83 131.23 980.52C139.36 983.4 147.41 986.42 155.36 989.52C155.99 989.77 156.68 989.45 156.91 988.82C157.15 988.19 156.83 987.48 156.2 987.23C149 984.41 141.72 981.67 134.37 979.02C146.77 973.19 159.3 967.63 171.95 962.36C172.58 962.1 172.88 961.38 172.62 960.75C172.35 960.12 171.63 959.82 171.01 960.09C162.61 963.56 154.29 967.2 146.01 970.94C148.85 963.44 151.8 955.94 154.88 948.46C155.14 947.82 154.83 947.11 154.19 946.86C153.55 946.61 152.82 946.93 152.55 947.56C149.16 955.82 145.93 964.1 142.84 972.38C134.27 976.3 125.75 980.35 117.3 984.53C119.87 976.94 122.56 969.35 125.36 961.75C125.6 961.11 125.27 960.4 124.62 960.18C123.97 959.95 123.25 960.3 123.01 960.94C119.93 969.33 116.98 977.7 114.18 986.09C105.75 990.3 97.39 994.65 89.1 999.14C91.39 991.46 93.81 983.78 96.35 976.1C96.56 975.44 96.21 974.75 95.55 974.55C94.89 974.34 94.18 974.71 93.97 975.36C91.17 983.85 88.53 992.33 86.03 1000.8C79.45 1004.42 72.89 1008.06 66.4 1011.84C65.81 1012.18 65.61 1012.93 65.95 1013.52C66.29 1014.11 67.05 1014.31 67.64 1013.97C78.67 1007.59 89.84 1001.44 101.14 995.53C109.37 998.12 117.53 1000.82 125.59 1003.62C126.23 1003.84 126.91 1003.5 127.12 1002.87C127.33 1002.23 126.99 1001.53 126.35 1001.31Z" />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path id="&lt;Path&gt;" class="shp25" d="M114.85 957.91C102.36 970.94 90.23 983.17 78.56 994.21C66.82 1005.2 55.86 1015.21 45.22 1023.22C40.03 1027.3 34.75 1030.79 29.91 1033.85C25.01 1036.88 20.11 1039.25 15.65 1041.04C6.6 1044.56 -1.81 1045.24 -8.74 1042.04C-15.67 1038.85 -20.46 1032.02 -22.94 1021.97C-24.25 1016.91 -24.7 1011.2 -24.64 1004.74C-24.64 998.25 -23.69 991.25 -22.21 983.62C-19.02 968.52 -13.03 951.22 -3.4 933.66C6.14 916.08 19.02 898.06 35 881.4C37 879.32 38.93 877.28 40.9 875.36C42.89 873.47 44.86 871.65 46.79 869.9C48.72 868.14 50.61 866.45 52.48 864.82C54.36 863.21 56.28 861.73 58.12 860.28C61.85 857.41 65.36 854.7 68.89 852.39C72.42 850.08 75.82 848.01 79.08 846.16C85.6 842.46 91.82 839.92 97.39 837.98C103.05 836.16 108.26 835.2 112.96 834.83C117.64 834.43 121.97 834.85 125.84 835.75C129.73 836.66 133.2 838.09 136.33 839.96C142.59 843.69 147.52 849.13 151.72 855.51C160.15 868.3 163.9 882.28 158.79 898.19C157.56 902.24 155.72 906.33 153.31 910.67C151 915.11 147.92 919.54 144.34 924.42C137.09 934.07 127.38 944.92 114.85 957.91Z" />
                    <path id="&lt;Path&gt;" class="shp10" d="M83.74 957.4C76.97 957.63 70.22 958.1 63.29 958.63C56.4 959.19 49.61 960.04 42.6 960.93C53.4 946.55 65.17 932.91 77.67 919.99C93.11 919.73 108.35 920.37 123.18 921.78C124.34 921.89 125.29 921.02 125.3 919.83C125.33 918.66 124.41 917.61 123.23 917.49C116.55 916.88 109.74 916.38 102.8 915.99C95.97 915.7 89.05 915.56 81.99 915.51C99.3 898.14 117.99 882.16 137.86 867.79C138.84 867.07 139.05 865.68 138.35 864.69C137.64 863.69 136.26 863.48 135.27 864.21C121.94 873.7 109.3 884.14 97.11 895.04C97.42 887.76 98.11 880.64 98.84 873.3C99.54 865.93 100.59 858.71 101.72 851.31C101.91 850.05 101.05 848.95 99.78 848.85C98.52 848.75 97.33 849.69 97.14 850.95C96.53 855.04 95.89 859.02 95.42 863.12C94.94 867.21 94.5 871.26 94.11 875.28C93.26 883.25 92.83 891.39 92.46 899.28C80.04 910.85 68.21 923.07 57.09 935.91C56.63 928.77 56.29 921.51 56.07 914.12C55.97 906.85 56 899.46 56.14 891.95C56.18 890.68 55.19 889.69 53.92 889.75C52.66 889.82 51.61 890.91 51.58 892.19C51.21 908.7 51.66 924.86 53.06 940.73C50.38 944.03 47.56 947.2 45 950.6L37.21 960.67L29.76 971C27.24 974.42 24.99 978.03 22.59 981.53C21.21 974.48 19.84 967.24 18.85 960.05C17.82 952.82 16.79 945.39 16.14 938.03C16.03 936.78 14.92 935.92 13.66 936.13C12.4 936.35 11.48 937.55 11.6 938.8C12.42 947 13.56 955.18 14.72 963.11C16.03 971.14 17.51 979.04 19.11 986.76L13.76 995.38L11.09 999.69L8.57 1004.09L3.53 1012.89L-1.22 1021.86C-1.79 1022.95 -1.37 1024.28 -0.29 1024.84C0.8 1025.4 2.14 1024.98 2.71 1023.91C13.26 1003.69 25.38 984.3 38.88 965.91C46.45 964.71 54.23 963.91 61.7 963.13C69.23 962.39 76.85 961.99 84.22 961.63C85.38 961.57 86.21 960.59 86.1 959.44C85.98 958.28 84.93 957.36 83.74 957.4Z" />
                  </g>
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M1419.18 919.25C1431.31 927.78 1441.89 937.27 1450.54 946.85C1459.22 956.41 1465.88 966.18 1470.53 975.11C1479.8 993.13 1480.7 1007.95 1473.27 1014.9C1465.74 1021.94 1451.58 1020.17 1434.56 1013.33C1417.47 1006.43 1397.34 994.6 1376.46 979.94C1366 972.61 1357.92 966.21 1351.81 960.32C1348.76 957.35 1346.18 954.57 1344.1 951.78C1341.99 949.04 1340.33 946.4 1339.09 943.78C1334.09 933.4 1335.63 923.8 1340.35 914.68C1345.05 905.6 1352.01 898.7 1364.43 897.82C1367.52 897.63 1370.96 897.78 1374.77 898.42C1378.54 899.11 1382.74 900.17 1387.28 901.85C1396.39 905.16 1407.04 910.73 1419.18 919.25Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1423.07 941.4C1424.62 950.91 1425.89 960.35 1426.85 969.73C1417.45 962.02 1407.72 954.71 1397.76 947.73C1395.94 937.46 1393.75 927.16 1391.2 916.8C1391 915.98 1390.17 915.45 1389.35 915.61C1388.53 915.78 1388.03 916.57 1388.23 917.39C1389.38 922.09 1390.47 926.75 1391.5 931.38C1392.5 936.05 1393.43 940.71 1394.31 945.32C1380.63 935.94 1366.5 927.22 1351.97 919.21C1351.26 918.81 1350.36 919.07 1349.96 919.79C1349.57 920.51 1349.83 921.42 1350.54 921.82C1360.11 927.03 1369.45 932.66 1378.64 938.51C1369.23 940.59 1359.99 942.94 1350.91 945.56C1350.14 945.78 1349.67 946.59 1349.86 947.38C1350.04 948.16 1350.81 948.61 1351.58 948.39C1361.58 945.51 1371.76 942.99 1382.16 940.78C1391.63 946.96 1400.9 953.44 1409.94 960.23C1405.15 960.94 1400.42 961.68 1395.74 962.47C1391.02 963.32 1386.36 964.21 1381.75 965.13C1380.97 965.29 1380.44 966.07 1380.56 966.86C1380.68 967.65 1381.41 968.17 1382.19 968.01C1392.36 965.94 1402.74 964.17 1413.26 962.78C1422.25 969.64 1430.99 976.83 1439.46 984.32C1429.85 984.93 1420.35 985.85 1410.98 987.05C1410.18 987.15 1409.59 987.88 1409.66 988.68C1409.72 989.48 1410.42 990.03 1411.21 989.93C1416.37 989.28 1421.54 988.76 1426.8 988.24C1432.01 987.79 1437.28 987.41 1442.6 987.08C1449.26 993.15 1455.88 999.26 1462.2 1005.67C1462.78 1006.25 1463.72 1006.24 1464.3 1005.67C1464.89 1005.09 1464.89 1004.15 1464.3 1003.57C1453.39 992.66 1441.97 982.26 1430.11 972.41C1429.13 962.03 1427.78 951.58 1426.09 941.04C1425.96 940.21 1425.17 939.61 1424.33 939.72C1423.5 939.82 1422.94 940.58 1423.07 941.4Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp24" d="M1498.38 887.74C1508 914.25 1514.05 939.35 1515.52 959.41C1516.96 979.42 1513.67 994.48 1503.87 999.56C1494.15 1004.6 1479.6 998.43 1464.52 983.32C1449.48 968.26 1434.11 944.14 1423.53 914.95C1412.99 885.75 1412 866.19 1415.87 853.67C1419.79 841.09 1428.6 835.37 1439.2 832.52C1449.83 829.65 1460.26 830.21 1470 838.12C1479.8 846.04 1488.81 861.22 1498.38 887.74Z" />
                  <path id="&lt;Path&gt;" class="shp10" d="M1488.19 908.42C1484.04 917.47 1480.01 926.67 1476.14 935.99C1471.14 924.01 1466.46 911.89 1462.05 899.68C1466.78 889.58 1471.71 879.65 1476.8 869.9C1477.2 869.13 1476.89 868.2 1476.11 867.82C1475.33 867.44 1474.37 867.76 1473.97 868.53L1467.13 881.88L1460.52 895.45C1454.59 878.76 1449.18 861.89 1444.29 844.86C1444.05 844.02 1443.16 843.53 1442.32 843.77C1441.47 844.01 1440.98 844.89 1441.23 845.74C1444.44 857.04 1447.96 868.26 1451.64 879.41C1442.38 874.76 1433.14 869.89 1423.96 864.81C1423.17 864.38 1422.21 864.67 1421.8 865.47C1421.39 866.26 1421.7 867.26 1422.48 867.69C1432.62 873.28 1442.84 878.61 1453.08 883.69C1456.99 895.24 1461.13 906.71 1465.52 918.09L1451.32 911.63L1437.16 904.85C1436.36 904.45 1435.41 904.79 1435.04 905.61C1434.67 906.42 1435.03 907.39 1435.83 907.79C1446.26 912.87 1456.69 917.71 1467.17 922.29C1471.62 933.64 1476.32 944.89 1481.28 956.03C1471.6 952.3 1461.95 948.35 1452.31 944.18C1451.49 943.83 1450.56 944.21 1450.23 945.04C1449.9 945.87 1450.29 946.84 1451.11 947.19C1456.43 949.48 1461.79 951.7 1467.09 953.87C1472.45 956.02 1477.79 958.12 1483.11 960.16C1487.14 968.99 1491.21 977.81 1495.53 986.5C1495.92 987.3 1496.87 987.62 1497.66 987.23C1498.45 986.84 1498.78 985.88 1498.39 985.1C1491.12 970.31 1484.28 955.32 1477.88 940.14C1482.09 929.81 1486.51 919.64 1491.08 909.64C1491.43 908.86 1491.08 907.95 1490.29 907.61C1489.5 907.26 1488.55 907.63 1488.19 908.42Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp12" d="M1373.88 878.78L1373.88 878.78C1383.21 890.59 1393.34 902.18 1403.63 913.89C1413.92 925.54 1424.37 937.37 1434.83 949.21C1445.21 961.12 1455.57 973.02 1465.78 984.75C1476.05 996.47 1486.23 1008.02 1496.72 1018.81L1496.72 1018.81L1496.72 1018.81C1499.72 1004.15 1498.81 986.96 1493.59 969.84C1488.43 952.72 1478.99 935.76 1466.46 921.46C1453.91 907.18 1438.32 895.61 1422.02 888.26C1405.73 880.86 1388.81 877.72 1373.88 878.78Z" />
                  <path id="&lt;Path&gt;" class="shp28" d="M1390.05 886.8C1408.5 897.98 1430.65 915.6 1449.16 936.64C1467.61 957.73 1482.19 981.98 1490.88 1001.73C1484.67 981.05 1470.92 955.5 1452.13 934.03C1433.29 912.6 1409.75 895.65 1390.05 886.8Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp26" d="M1484.57 868.61L1484.57 868.61C1475.33 889.09 1472.92 916.48 1477.56 942.78C1482.12 969.09 1493.67 994.04 1509.31 1010.17L1509.31 1010.17C1513.84 988.13 1512.95 962.4 1508.6 937.35C1504.2 912.32 1496.31 887.81 1484.57 868.61Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M1485.59 882.1C1485.92 898.27 1487.98 919.42 1491.63 940.32C1495.29 961.21 1500.52 981.81 1505.69 997.13C1502.42 981.3 1498.18 960.61 1494.54 939.81C1490.91 919.01 1487.88 898.11 1485.59 882.1Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp26" d="M1385.2 961.8C1399.2 972.43 1416.3 981.38 1433.8 989.27C1451.33 997.09 1469.39 1003.91 1486.64 1007.29C1483.16 999.25 1477.46 991.28 1470.07 984.28C1462.7 977.26 1453.66 971.22 1443.86 966.83C1434.07 962.44 1423.54 959.7 1413.4 958.86C1403.25 958 1393.51 959.05 1385.2 961.8Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M1395.56 963.82C1407.73 967.15 1423.36 972.39 1438.36 979.1C1453.35 985.84 1467.65 994.02 1478.24 1000.9L1478.24 1000.9L1478.24 1000.9C1468.57 992.78 1454.46 983.8 1439.3 977C1424.14 970.21 1408.05 965.64 1395.56 963.82Z" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp12" d="M660.58 964.57C671.77 979.73 687.95 993.69 705.5 1005.48C723.08 1017.21 742.15 1026.86 760.43 1031.43C753.93 1013.78 738.91 996.04 720.18 983.55C701.5 970.99 679.38 963.86 660.58 964.57Z" />
                  <path id="&lt;Path&gt;" class="shp28" d="M670.71 969.65L670.71 969.65C682.37 976.53 697.47 985.72 712.15 995.54C726.83 1005.38 741.07 1015.85 751.88 1024.01C742.14 1014.59 728.29 1003.38 713.53 993.49C698.76 983.61 683.12 975.07 670.71 969.65Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp26" d="M686.36 914.4L686.36 914.4C694.01 939.21 708.62 964.72 725.92 988.09C743.3 1011.41 763.5 1032.77 785.05 1047.25L785.05 1047.26L785.05 1047.25C784.9 1021.35 773.67 991.3 755.1 966.42C736.64 941.46 711.13 922.02 686.36 914.4Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M697.21 925.62C709.22 939.88 724.63 958.74 739.14 978.27C753.65 997.81 767.25 1018.01 777.44 1033.63C769.23 1016.88 756.47 995.9 741.88 976.24C727.27 956.59 710.87 938.32 697.21 925.62Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp12" d="M799.46 877.8L799.46 877.79L799.46 877.79C790.64 888.09 783.23 901.05 777.79 915.55C772.34 930.03 768.87 946.05 767.66 962.24C766.46 978.43 767.53 994.78 770.78 1009.91C774.02 1025.05 779.44 1038.96 786.63 1050.45C797.46 1025.54 803.29 995.37 805.59 965.05C807.8 934.73 806.49 904.03 799.46 877.8Z" />
                  <path id="&lt;Path&gt;" class="shp28" d="M796.1 893.77C791.07 912.63 786.76 937.96 784.85 963.51C782.96 989.06 783.48 1014.76 785.67 1034.15C785.86 1014.64 786.53 989.17 788.4 963.78C790.3 938.39 793.4 913.1 796.1 893.77Z" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="shp26" d="M859.14 914.48C837.63 925.64 817.32 947.31 803.82 972.79C790.23 998.23 783.61 1027.19 786.38 1051.26L786.38 1051.26L786.38 1051.26C804.75 1035.4 820.93 1012.9 833.82 988.75C846.64 964.57 856.25 938.58 859.14 914.48Z" />
                  <path id="&lt;Path&gt;" class="shp27" d="M851 926.59C840.48 940.5 828.16 959.83 817.41 980.03C806.67 1000.23 797.53 1021.25 791.87 1037.74C799.49 1022.06 809.54 1001.62 820.22 981.52C830.92 961.43 842.25 941.68 851 926.59Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="text-box">
      <h1>Try Every Day</h1>
      <p>- Practise being BRAVE:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; &nbsp;Look people in the eye and smile.</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; &nbsp;Use a brave and strong voice.</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; &nbsp;Try your best and give it a go.</p>
      <p>- Reward yourself for being BRAVE.</p>
    </div>
    <audio autoplay loop ref="audio" src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session8/Birds-In-Sun-And-Snow-Sound-Effect.mp3"/>
    <audio
      @loadeddata="playSoundText"
      src="https://s3.ap-southeast-2.amazonaws.com/uploads.friendsresilience.org/animatedbook-resources/FF/audio/session8/Session8_Page14%2B1.mp3" ref="voice"/>
    <div class="page-number" id="page-light">248</div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: 'Session10Page15',
  methods: {
    animateSvg() {
      let vw = document.querySelector('.interactive-container').clientWidth;
      let clouds = document.querySelector('#cloud').children;
      clouds.forEach(cloud => {
        anime({
          targets: cloud,
          easing: 'linear',
          duration: Math.random() * 5000 + 15000,
          translateX: (Math.round(Math.random() * 0.3 * vw) + 0.3*vw) * (Math.random() < 0.5 ? 1 : -1),
          direction: 'alternate',
          loop: true
        })
      })
      anime({
        targets: '#big-cloud',
        easing: 'linear',
        duration: 5000,
        translateX: -0.1 * vw,
        direction: 'alternate',
        loop: true
      })
    },
    animateText() {
      let text = document.querySelector('.text-box').children;
      let animation = anime.timeline({easing: 'linear',duration: 500})
      animation
        .add({targets: ".text-box", opacity: 0.9}, 500)
        .add({targets: text[1], opacity: 1}, 4600)
        .add({targets: text[2], opacity: 1}, 8100)
        .add({targets: text[3], opacity: 1}, 12000)
        .add({targets: text[4], opacity: 1}, 16000)
        .add({targets: text[5], opacity: 1}, 18500)
    },
    setAudioVolumeLevel(level) {
      this.$refs.audio.volume = level
    },
    playVoiceOver() {
      setTimeout(() => {this.$refs.voice.play()}, 500)
    },
    playSoundText() {
      this.playVoiceOver();
      this.animateText();
    }
  },
  mounted() {
    this.setAudioVolumeLevel(0.3);
    this.animateSvg();
  }
}
</script>

<style scoped>
.text-box {
  position: absolute;
  background-color: rgba(255,255,255,0.8);
  width: 90%;
  height: auto;
  left: 5%;
  top: 20vh;
  padding: 2vh;
  opacity: 0;
}
.text-box h1 {
  text-align: center;
  margin-bottom: 1vh;
  font-size: 6vh;
  font-weight: bold;
}
.text-box p {
  margin-bottom: 1vh;
  font-size: 4vh;
  opacity: 0;
}
.text-box p:last-child {
  margin-bottom: 0;
}
.landscape {
  position: absolute;
  height: auto;
  width: 110%;
  top: -3vh;
}
tspan { white-space:pre }
.shp0 { fill: #dcfeff }
.shp1 { fill: #b6ecf0 }
.shp2 { fill: #aae3e9 }
.shp3 { fill: #acd1e8 }
.shp4 { fill: #e6f8ff }
.shp5 { fill: #ffffff }
.shp6 { fill: #b4dbef }
.shp7 { fill: #9dcb80 }
.shp8 { fill: #8cbb74 }
.shp9 { fill: #5a8b74 }
.shp10 { fill: #325677 }
.shp11 { fill: #446b71 }
.shp12 { fill: #f4d36e }
.shp13 { fill: #6a9f67 }
.shp14 { fill: #8ec26f }
.shp15 { fill: #aad780 }
.shp16 { fill: #d2f4a1 }
.shp17 { fill: #635a3e }
.shp18 { fill: #7e7656 }
.shp19 { fill: #8fc36f }
.shp20 { fill: #caf1a0 }
.shp21 { fill: #c7cbe0 }
.shp22 { fill: #b2b7ce }
.shp23 { fill: #e2e4f3 }
.shp24 { fill: #4e838f }
.shp25 { fill: #599499 }
.shp26 { fill: #8dc183 }
.shp27 { fill: #71a564 }
.shp28 { fill: #d6a455 }
</style>